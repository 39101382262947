import React from "react";
import Modal from "../../../../../components/ui-kit/Modal";
import Typography from "../../../../../components/ui-kit/typography";
import { Box, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

const StyledModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const DeleteButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
  }

  && .MuiTouchRipple-child {
    background-color: red;
  }
`;

const DiscardDraftModel = ({ open, handleClose, handleDelete, id = null }) => {
  const theme = useTheme();

  //If there is a provided id, this makes sure we include it in the
  // handleDelete callback.
  const onDiscard = () => {
    if (id) {
      handleDelete(id);
    } else {
      handleDelete();
    }
    handleClose();
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Typography variant={"body1"} style={{ fontWeight: 500 }}>
        Discarding draft
      </Typography>
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        All the information contained in this draft will be deleted. A new
        application will require all information to be re-input from the
        beginning.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
            Cancel
          </Typography>
        </Button>
        <DeleteButton
          variant={"text"}
          onClick={() => {
            onDiscard();
          }}
        >
          <Typography color={"red"} fontWeight={"700"}>
            Discard
          </Typography>
        </DeleteButton>
      </Box>
    </StyledModal>
  );
};

export default DiscardDraftModel;
