import React, { createRef, useCallback } from "react";
import styled from "styled-components";
import Divider from "./Divider";
import { default as MuiCheckbox } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
`;

const StyledCheckbox = styled(MuiCheckbox)`
  && {
    color: ${({ color }) => color};
    padding: 0;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin: 0;

    && .MuiTypography-root {
      margin-left: 10px;
    }

    &:focus {
      box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);
      border-width: 2px;
      border-color: ${({ theme }) => theme.palette.primary[200]};
    }
  }
`;

export default function Checkbox({
  onChange,
  label,
  error,
  labelFontWeight,
  ...rest
}) {
  const { value, color = "primary" } = rest;
  return (
    <div style={{ display: "flex" }}>
      <StyledFormControlLabel
        labelFontWeight={labelFontWeight}
        control={
          <StyledCheckbox
            {...rest}
            color={color}
            checked={value === true}
            indeterminate={value === undefined || value === null}
            onChange={onChange}
          />
        }
        style={{ height: "100%", flexGrow: "1" }}
        label={label}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}

const OutlinedContainer = styled(FlexContainer)`
  && {
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: 10px;
    min-width: ${({ minWidth }) => minWidth};
    max-width: ${({ maxWidth }) => maxWidth};
  }

  .MuiFormControlLabel-root {
    padding: 1rem;
  }
`;

export function CheckboxCard({ value, onChange, label, ...rest }) {
  const checkRef = createRef();
  const controlRef = createRef();

  const handleOnClick = useCallback(() => {
    const node = checkRef.current;
    const control = controlRef.current;
    if (node && control) {
      node.click();
      control.focus();
    }
  }, [checkRef, controlRef]);

  return (
    <FormGroup>
      <StyledFormControlLabel
        classes={rest.labelClasses}
        ref={controlRef}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            handleOnClick();
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        tabIndex={0}
        control={
          <MuiCheckbox
            ref={checkRef}
            tabIndex={-1}
            {...rest}
            checked={value}
            onChange={(e) => {
              onChange(e);
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}

const StyledFormLabel = styled(FormLabel)`
  && {
    font-family: ${({ theme }) => theme.fontRegular};
    margin-bottom: 0.5rem;
  }
`;

const CheckboxWrapper = styled.div`
  padding-top: 1rem;

  &&:first-child {
    padding-top: 0;
  }
`;

const CheckboxGroupContainer = styled(OutlinedContainer)`
  && {
    padding: 1.5rem 1rem;
    ${({ withShadow, theme }) =>
      withShadow &&
      `
      box-shadow: ${theme.buttonShadow2};
      border: none;
    `}
  }
`;

export function CheckboxGroup({
  minWidth,
  maxWidth,
  error,
  values = {},
  onChange,
  touched,
  fields,
  label,
  withShadow,
}) {
  const handleChange = (event) => {
    onChange({ ...values, [event.target.name]: event.target.checked });
  };

  if (!fields?.length) return null;
  return (
    <CheckboxGroupContainer
      minWidth={minWidth}
      maxWidth={maxWidth}
      withShadow={withShadow}
    >
      <FormControl fullWidth error={touched && error}>
        {label && <StyledFormLabel>{label}</StyledFormLabel>}
        <FormGroup>
          {fields.map((fieldProps, index) => (
            <CheckboxWrapper>
              <Checkbox
                {...fieldProps}
                value={values[fieldProps.name]}
                onChange={handleChange}
              />
              {index !== fields.length - 1 && (
                <Divider margin="1rem 0 0 34px" />
              )}
            </CheckboxWrapper>
          ))}
        </FormGroup>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </CheckboxGroupContainer>
  );
}
