import React from "react";
import MuiButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { darken, transparentize } from "polished";
import IconBase from "./IconBase";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material";

const defaultHover = "#DCE6F280";

const getColorObject = (color, theme, variant) => {
  const primary = color
    ? color
    : variant === "contained"
    ? theme.palette.primary[200]
    : theme.palette.primary[500];
  const primaryDark = color ? darken(0.1, color) : theme.palette.primary[500];
  const primaryLight = color ? transparentize(0.9, color) : defaultHover;
  return {
    primary,
    hover: variant === "contained" ? primaryDark : primaryLight,
  };
};

const Button = ({
  /** @todo Don't override `color` prop from Material UI Button */
  color,
  variant,
  size,
  textType,
  iconPlacement,
  children,
  iconName,
  disabled,
  className,
  endIcon,
  loading,
  ...rest
}) => {
  const theme = useTheme();

  if (!color) {
    color = theme.palette.primary[200];
  }

  const colors = getColorObject(color, variant, size);
  let ButtonTypography = (
    <Typography style={{ width: "100%" }} color="inherit" variant="button">
      {children}
    </Typography>
  );
  if (textType) {
    ButtonTypography = (
      <Typography style={{ width: "100%" }} color="inherit" variant={textType}>
        {children}
      </Typography>
    );
  }
  return (
    <MuiButton
      className={className}
      variant={variant}
      size={size}
      primary={colors.primary}
      disabled={disabled}
      startIcon={
        loading && !endIcon ? (
          <CircularProgress color="inherit" size={20} />
        ) : iconName && !endIcon ? (
          <IconBase color="inherit" iconName={iconName} />
        ) : null
      }
      endIcon={
        loading && endIcon ? (
          <CircularProgress color="inherit" size={20} />
        ) : iconName && endIcon ? (
          <IconBase color="inherit" iconName={iconName} />
        ) : null
      }
      {...rest}
    >
      {ButtonTypography}
    </MuiButton>
  );
};

export default Button;
