import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  findUtilityAccount,
  linkUtilityAccount,
} from "../../../../../core/apis/account";
import Step1 from "./step1";
import Step2 from "./step2";
import AlreadyLinkedScreen from "./already-linked-screen";
import { AuthContext } from "../../../../contexts/AuthContext";
import IconBase from "../../../../components/ui-kit/IconBase";
import classes from "./index.module.scss";
import Dialog from "../../../../components/ui-kit/Dialog";
import { useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../routes/routesData";
import Stepper from "../../../../components/ui-kit/StepNavigator";
import Divider from "../../../../components/ui-kit/Divider";
import Spacer from "../../../../components/ui-kit/Spacer";
import { Home } from "@mui/icons-material";
import WBCBreadcrumbs from "../../../../components/ui-kit/breadcrumbs";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const steps = [{ id: "step1" }, { id: "step2" }];

const LinkUtility = () => {
  const history = useHistory();
  const theme = useTheme();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [account, setAccount] = useState(null) as any;
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const { user } = useContext(AuthContext);
  const windowSize = useWindowSize();

  const getForm = useCallback(
    (stepId: string) => {
      switch (stepId) {
        case "step1":
          return Step1;
        case "step2":
          return account?.userId ? AlreadyLinkedScreen : Step2;
        default:
          return null;
      }
    },
    [account]
  );

  const CurrentPage = useMemo(
    () => getForm(steps[currentPageIndex].id),
    [currentPageIndex, getForm]
  );

  useEffect(() => {
    if (account) {
      setCurrentPageIndex(1);
    }
  }, [account]);

  const onCheckUtilityAvailable = async (values: {
    accountNumber: string;
    accessCode: string;
  }) => {
    const result = await findUtilityAccount(values);
    if (result.account) {
      setAccount({
        accountId: result.account.accountId,
        accountNumber: result.account.accountNumber,
        address: result.account.address,
      });
    }
  };

  const linkAccount = async () => {
    try {
      setOpenSuccessModal(true);
      // @ts-ignore
      await linkUtilityAccount(account.accountId, user.id);
    } catch (e) {
      console.log(e);
    }
  };

  const reset = useCallback(() => {
    setOpenSuccessModal(false);
    setAccount(null);
    setCurrentPageIndex(0);
  }, []);

  return (
    <div>
      <div>
        <WBCBreadcrumbs
          className={classes.back_button}
          children={[
            {
              icon: <Home fontSize={"small"} />,
              text: "HOME",
              onClick: () => history.replace("/portal"),
            },
            {
              text:
                currentPageIndex === 0
                  ? windowSize.width < 900
                    ? "LINK"
                    : "LINK YOUR ACCOUNT"
                  : windowSize.width < 950
                  ? "CONFIRM"
                  : "CONFIRM YOUR ACCOUNT",
            },
          ]}
        />
        <Stepper
          steps={steps}
          onClick={reset}
          currentIndex={currentPageIndex}
          maxWidth={"380px"}
        />
      </div>
      <Divider />
      <Spacer amount={6} />
      <div className={classes.inner_container}>
        {/* @ts-ignore */}
        <CurrentPage
          account={account}
          onCheckUtilityAvailable={onCheckUtilityAvailable}
          linkAccount={linkAccount}
        />
      </div>
      <Dialog
        variant={"updated"}
        open={openSuccessModal}
        handleClose={() => setOpenSuccessModal(false)}
        body={
          <div className={classes.flex_column}>
            <IconBase
              iconName={"check_circle"}
              color={theme.palette.primary[200]}
              size={64}
            />
            <p className={classes.dialog_body_text}>
              You linked your account successfully
            </p>
          </div>
        }
        buttonOneText={"Link another account"}
        buttonTwoText={"Continue"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          reset();
        }}
        handleButtonTwo={() => {
          history.push(routes.UTILITY_DETAILS.codePath + account.accountId);
        }}
      />
    </div>
  );
};

export default LinkUtility;
