import React, { createContext, useMemo, useState } from "react";
import tokenize from "../../core/apis/bambora";
import {
  delete_profile,
  edit_profile,
  get_payment_profiles,
  register_profile,
} from "../../core/apis/payment";

export const PaymentProfileContext = createContext(null);

export const PaymentProfileContextProvider = (props) => {
  const [getProfileError, setGetProfileError] = useState(false);
  const [paymentProfiles, setPaymentProfiles] = useState(null);
  const [newCardAdded, setNewCardAdded] = useState(false);

  const findPaymentProfile = (paymentProfileId) => {
    if (!paymentProfileId) return null;
    return paymentProfiles.find((profile) => profile.id === paymentProfileId);
  };

  const getPaymentProfiles = async () => {
    const getPaymentProfilesRes = await get_payment_profiles();
    if (!getPaymentProfilesRes) return;
    setGetProfileError(false);
    const profiles = getPaymentProfilesRes.data.length
      ? getPaymentProfilesRes.data.sort((card1, card2) => {
          return card1.card.autoPay ? -1 : card2.card.autoPay ? 1 : 0;
        })
      : null;
    setPaymentProfiles(profiles);
  };

  const getDefaultValue = () => {
    if (!paymentProfiles) return "";

    let defaultValue = "";
    const defaultPaymentProfiles = paymentProfiles.filter(
      (paymentProfile) => paymentProfile.card.autoPay
    );
    if (defaultPaymentProfiles.length && !newCardAdded) {
      defaultValue = defaultPaymentProfiles[0];
    } else {
      setNewCardAdded(false);
      return paymentProfiles[paymentProfiles.length - 1];
    }
    return defaultValue;
  };

  const paymentProfileContext = useMemo(() => {
    return {
      findPaymentProfile,
      getProfileError,
      paymentProfiles,
      getPaymentProfiles,
      createProfile: async (cardInfo) => {
        //Tokenizing
        const tokenizationRes = await tokenize(cardInfo);
        const bamboraToken = tokenizationRes.data.token;
        // Creating payment profile
        setNewCardAdded(true);
        return await register_profile(
          cardInfo.name,
          bamboraToken,
          cardInfo.autoPay
        );
      },
      deleteProfile: async (paymentProfileId) => {
        return await delete_profile(paymentProfileId);
      },
      editProfile: async (values, paymentProfileId) => {
        return await edit_profile(values, paymentProfileId);
      },
      getDefaultValue,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfileError, paymentProfiles]);

  return (
    <PaymentProfileContext.Provider value={paymentProfileContext}>
      {props.children}
    </PaymentProfileContext.Provider>
  );
};
