import { createTheme } from "@mui/material";
import { responsiveFontSizes } from "@mui/material";
import { lighten, transparentize } from "polished";
// FSJ colours

export const primary = "#0077C8";
export const primaryLight = "#E5EDF7";
export const secondary = "#F2A900";
export const blueGrey = "#01304e80";
export const grey = "#EEE";
export const lightGrey = "#F6F6F6";
export const white = "#FFFFFF";
export const warning = "#F00F3C";

export const primaryTint = lighten(0.49, primary);

export const warningLight = "#FEF3F5";

/* textColor */
export const textColor = "#011C30";
export const textSecondary = blueGrey;
export const greyTextColor = "#01304E8B";

export const borderColor = "#cdd4dc";

export const lightBlack = "#525252";

const headerWhite = "#D1D7DD";

export const avatarColors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#9E9E9E",
];

const headerBlue = "#082850";
const headerBlueAlt = transparentize(0.75, headerBlue);

export const blackShadowLight = "rgba(0,0,0,0.31)";

const getFontWithDefault = (font = "Rubik") => {
  return `'${font}', 'Roboto', -apple-system, BlinkMacSystemFont,
'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
'Helvetica Neue', sans-serif`;
};

/**
 * Figma UI Kit Colours
 * @see https://www.figma.com/file/HFIjiXO9zD07rig9LyADKg/UI-Kit-%E2%80%94-Material-Design?node-id=2828%3A16173
 */

//Greys
export const GREY_LIGHT = "#FAFAFA";
export const GREY_MEDIUM = "#E8E8E8";
export const GREY_DARK = "#B1B1B1";

//Blacks
export const BLACK_HIGH_EMPHASIS = "#011C30DE";
export const BLACK_MEDIUM_EMPHASIS = "#011C3099";
export const BLACK_LOW_EMPHASIS = "#011C3061";

//Whites
export const WHITE_HIGH_EMPHASIS = "#FFFFFFF2";
export const WHITE_MEDIUM_EMPHASIS = "#FFFFFFCC";
export const WHITE_LOW_EMPHASIS = "#FFFFFF66";

//Primary
export const PRIMARY_50 = "#F2F6FB";
export const PRIMARY_100 = "#E5EDF7";
export const PRIMARY_200 = "#0C55B5";
export const PRIMARY_300 = "#084993";
export const PRIMARY_400 = "#053C70";
export const PRIMARY_500 = "#01304E";
export const PRIMARY_600 = "#01263F";
export const PRIMARY_700 = "#011C30";
export const PRIMARY_800 = "#011220";
export const PRIMARY_900 = "#010811";

//Non-palette
export const WHITE = "#FFFFFF";
export const OUTLINE = "#CCD6DC";
export const RED = "#F00F3C";
export const RED_BACKGROUND = "#FEF3F5";
export const YELLOW = "#F8D41A";
export const YELLOW_BACKGROUND = "#FEF4C0";
export const ORANGE = "#F18805";
export const ORANGE_BACKGROUND = "#FCE8D0";
export const GREEN = "#4AA01F";
export const GREEN_BACKGROUND = "#E6FAE0";
export const BLUE = "#0C55B5";
export const BLUE_BACKGROUND = "#E5EDF7";
export const MAROON = "#9E2A2B";
export const BLUE_GRADIENT =
  "linear-gradient(180deg, #1C6DD6 0%, #0C55B5 100%)";

export const theme = {
  GREY_LIGHT,
  GREY_MEDIUM,
  GREY_DARK,

  BLACK_HIGH_EMPHASIS,
  BLACK_MEDIUM_EMPHASIS,
  BLACK_LOW_EMPHASIS,

  WHITE_HIGH_EMPHASIS,
  WHITE_MEDIUM_EMPHASIS,
  WHITE_LOW_EMPHASIS,

  PRIMARY_50,
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_300,
  PRIMARY_400,
  PRIMARY_500,
  PRIMARY_600,
  PRIMARY_700,
  PRIMARY_800,
  PRIMARY_900,

  WHITE,
  OUTLINE,
  RED,
  RED_BACKGROUND,
  BLUE_GRADIENT,

  /* Colours */
  adBackground: lightGrey,
  background: white,
  backgroundHighlight: primaryLight,

  borderColor,

  buttonBackground: white,
  buttonHover: white,
  buttonShadow0: `0px 1px 1px ${blackShadowLight}`,
  buttonShadow1: `0px 1px 2px ${blackShadowLight}`,
  buttonShadow2: `0px 2px 4px ${blackShadowLight}`,
  buttonText: lightBlack,

  cardShadow: `0px 1px 3px ${blackShadowLight}`,
  headerBackground: PRIMARY_500, // headerBlue,
  headerBackgroundTransparency: PRIMARY_500, // headerBlueAlt,
  headerFontColor: headerWhite,
  dividerColor: headerBlueAlt,

  modalBackground: "rgba(255,255,255,0.8)",

  greyTextColor: greyTextColor,
  sidebarIconColor: primary,
  tableRowBorderColor: grey,
  textColor: textColor,
  textSecondary: textSecondary,

  /* Measurements */
  buttonFontSize: "1rem",
  buttonFontWeight: 600,
  buttonHeight: "2.6rem",
  buttonPadding: "0rem 1rem",
  buttonRadius: "5px",
  buttonWidthSm: "4rem",
  buttonWidthLg: "8rem",
  buttonLeftMargin: "8px",
  buttonContainerMargin: "1rem",

  curvedBorderRadius: "20px",
  curvedBorderRadiusLarge: "30px",
  curvedBorderRadiusSmall: "10px",
  fontRegular: getFontWithDefault("Rubik"),
  fontBold: getFontWithDefault("Rubik Bold"),
  primary: primary,
  logoHeight: "2rem",
  logoBannerWidth: "20rem",
  logoHeaderWidth: "14rem",
  headerButtonHeight: "3rem",
  headerFontHeight: "1.1rem",
  headerHeightWithPadding: "5rem",
  headerHeight: "4rem",
  sidebarWidth: "300px",
  sidebarWidthCollapsed: "5rem",
  tableRowBorderRadius: "6px",
  titleHeight: "3rem",

  palette: {
    primary: {
      main: primary,
      50: PRIMARY_50,
      100: PRIMARY_100,
      200: PRIMARY_200,
      300: PRIMARY_300,
      400: PRIMARY_400,
      500: PRIMARY_500,
      600: PRIMARY_600,
      700: PRIMARY_700,
      800: PRIMARY_800,
      900: PRIMARY_900,
    },
    secondary: {
      main: secondary,
      contrastText: white,
    },
    blacks: {
      BLACK_HIGH_EMPHASIS,
      BLACK_MEDIUM_EMPHASIS,
      BLACK_LOW_EMPHASIS,
    },
    greys: {
      GREY_DARK,
      GREY_MEDIUM,
      GREY_LIGHT,
    },
    whites: {
      WHITE,
      WHITE_HIGH_EMPHASIS,
      WHITE_MEDIUM_EMPHASIS,
      WHITE_LOW_EMPHASIS,
    },
  },
};

export default theme;

export const muiV5 = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: PRIMARY_200,
        50: PRIMARY_50,
        100: PRIMARY_100,
        200: PRIMARY_200,
        300: PRIMARY_300,
        400: PRIMARY_400,
        500: PRIMARY_500,
        600: PRIMARY_600,
        700: PRIMARY_700,
        800: PRIMARY_800,
        900: PRIMARY_900,
      },
      mode: "light",
      nonPalette: {
        GREEN,
        GREEN_BACKGROUND,
        MAROON,
        OUTLINE,
        RED,
        RED_BACKGROUND,
        BLUE,
        BLUE_BACKGROUND,
        BLUE_GRADIENT,
        ORANGE,
        ORANGE_BACKGROUND,
        YELLOW,
        YELLOW_BACKGROUND,
      },
      blacks: {
        main: "#011C30",
        BLACK_HIGH_EMPHASIS,
        BLACK_MEDIUM_EMPHASIS,
        BLACK_LOW_EMPHASIS,
      },
      greys: {
        GREY_DARK,
        GREY_MEDIUM,
        GREY_LIGHT,
      },
      whites: {
        WHITE,
        WHITE_LOW_EMPHASIS,
        WHITE_MEDIUM_EMPHASIS,
        WHITE_HIGH_EMPHASIS,
      },
    },
    typography: {
      fontFamily: getFontWithDefault(),
      button: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "16px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
      },
      h2: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `300`,
        fontSize: "51px",
      },
      h1: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `700`,
        fontSize: "120px",
      },
      h6: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
      },
      body2: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontSize: "14px",
        color: PRIMARY_700,
      },
      body1: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "20px",
        /* or 130%  ??*/
        fontFeatureSettings: "'liga' off",
        color: PRIMARY_700,
      },
      h3: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "39px",
        color: PRIMARY_700,
      },
      h4: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `300`,
        fontSize: "24px",
        color: PRIMARY_700,
      },
      h5: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `700`,
        fontSize: "20px",
        color: PRIMARY_700,
      },
      overline: {
        color: BLACK_MEDIUM_EMPHASIS,
        letterSpacing: "1px",
      },
      caption: {
        letterSpacing: "0.4px",
      },
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {},
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              "& .MuiFilledInput-root": {
                backgroundColor: ownerState.value ? WHITE : GREY_LIGHT,
                border: ownerState.value
                  ? `1px solid ${OUTLINE}`
                  : `1px solid ${BLACK_MEDIUM_EMPHASIS}`,
                "&.Mui-focused": {
                  backgroundColor: WHITE,
                  borderColor: PRIMARY_200,
                  borderWidth: 2,
                  boxShadow:
                    "0 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",

                  "& .MuiFilledInput-input": {
                    backgroundColor: WHITE,
                  },
                },
                "&.Mui-error": {
                  backgroundColor: WHITE,
                  borderColor: RED,
                },
              },
              "& .MuiFilledInput-input": {
                marginLeft: 15,
              },
              "&.Mui-focused": {
                "&& .MuiFilledInput-input": {
                  paddingLeft: 3,
                },
              },
              "& .MuiInputLabel-shrink": {
                fontWeight: 400,
                color: `${BLACK_MEDIUM_EMPHASIS} !important`,
                "&.Mui-focused": {
                  color: BLACK_MEDIUM_EMPHASIS,
                  fontWeight: 400,
                },
              },
            };
          },
          paper: {
            borderRadius: 10,
            boxShadow: `0px 16px 20px rgba(1, 48, 78, 0.1), 0px 6px 28px rgba(1, 48, 78, 0.08), 0px 10px 10px rgba(1, 48, 78, 0.1);`,
          },
          listbox: {
            "& .MuiAutocomplete-option": {
              height: 55,
              "&:hover": {
                backgroundColor: PRIMARY_50,
              },
              "&.Mui-focused": {
                backgroundColor: PRIMARY_50,
              },
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: "inherit",
        },
        styleOverrides: {
          sizeLarge: {
            height: 58,
          },
          sizeMedium: {
            padding: "16px",
          },
          containedSizeLarge: {
            background: "linear-gradient(180deg, #1C6DD6 0%, #0C55B5 100%)",
            color: WHITE,
            boxShadow:
              "0px 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",

            "&:hover": {
              backgroundColor: PRIMARY_200,
              boxShadow: "none",
            },
          },
          outlined: {
            border: `1px solid ${PRIMARY_500}`,
            height: 50,
            color: PRIMARY_500,

            "&:hover": {
              borderColor: PRIMARY_500,
            },
          },
          newOptional: {
            border: `1px solid ${PRIMARY_500}`,
            borderStyle: "dashed",
            height: 50,
            color: PRIMARY_500,

            "&:hover": {
              borderColor: PRIMARY_500,
            },
          },
          outlinedSizeLarge: {
            borderWidth: 2,
            "&:hover": {
              borderWidth: 2,
            },
          },
          contained: {
            background: "linear-gradient(180deg, #1C6DD6 0%, #0C55B5 100%)",
            color: WHITE,
            height: 50,
            "& .material-symbols-rounded": {
              color: "inherit",
            },
            "&.Mui-disabled": {
              background: GREY_MEDIUM,
              color: GREY_DARK,
            },
          },
          text: {
            color: PRIMARY_500,
            ":hover": {
              backgroundColor: PRIMARY_50,
            },
            "&& .MuiTouchRipple-child": {
              color: PRIMARY_200,
            },
          },
          textPrimary: {
            color: PRIMARY_200,
          },
          textError: {
            color: RED,
            ":hover": {
              backgroundColor: RED_BACKGROUND,
            },
          },
          root: {
            borderRadius: 10,
          },
          sizeSmall: {
            textTransform: "none",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            fontFeatureSettings: "'liga' off",
            borderRadius: 5,
            height: 33,
            letterSpacing: 0,
            borderWidth: 1,
            borderColor: OUTLINE,
            backgroundColor: WHITE,

            "&:hover": {
              backgroundColor: PRIMARY_50,
              borderColor: OUTLINE,
            },
          },
          containedSizeSmall: {
            "&:hover": {
              backgroundColor: PRIMARY_200,
              boxShadow: "none",
            },
          },
          containedSizeMedium: {
            "&:hover": {
              backgroundColor: PRIMARY_200,
              boxShadow: "none",
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            color: PRIMARY_500,
            marginLeft: 15,
            "&:hover": {
              backgroundColor: PRIMARY_50,
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides:{
          paper:{
            borderRadius: 20
          }
        }
      },
      MuiDialogActions:{
        styleOverrides:{
          root:{
            borderTop: `1px solid ${OUTLINE}`,
            padding: `1rem`,
          }
        }
      },
      MuiDialogContent: {
        styleOverrides:{
          root:{
            margin: `1rem 0`,
          }
        }
      },
      MuiDialogTitle:{
        styleOverrides:{
          root: {
            borderBottom: `1px solid ${OUTLINE}`
          }
        }
      },
      MuiToggleButtonGroup: {
        defaultProps: {},
        styleOverrides: {
          root: {
            borderRadius: "10px",
            "& .MuiToggleButtonGroup-grouped": {
              border: 0,
              "&.Mui-disabled": {
                border: 0,
              },
              "&:not(:first-of-type)": {
                borderRadius: "10px",
              },
              "&:first-of-type": {
                borderRadius: "10px",
              },
            },
          },
        },
      },
      MuiToggleButton: {
        defaultProps: {},
        styleOverrides: {
          root: {
            borderRadius: "10px",
            color: PRIMARY_700,
            padding: "12px",
            " &.Mui-selected": {
              background: PRIMARY_100,
              color: PRIMARY_200,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
              height: 39,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          inputMultiline: {
            paddingTop: 5,
            paddingLeft: "14px !important",
          },
          root: ({ ownerState }) => ({
            backgroundColor: ownerState.value ? WHITE : WHITE,
            border: ownerState.value
              ? `1px solid ${OUTLINE}`
              : `1px solid ${BLACK_MEDIUM_EMPHASIS}`,
            borderRadius: 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            fontWeight: 700,
            minHeight: 76,
            "&:hover": {
              backgroundColor: PRIMARY_50,
              borderColor: OUTLINE,
            },
            "&.Mui-focused": {
              backgroundColor: WHITE,
              borderColor: PRIMARY_200,
              borderWidth: 2,
              boxShadow:
                "0 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",

              "& .MuiFilledInput-input": {
                paddingLeft: 26,
                backgroundColor: WHITE,
              },
            },

            "&.Mui-focused .MuiSelect-root": {
              backgroundColor: WHITE,
              borderColor: PRIMARY_200,
            },
            "&.Mui-error": {
              backgroundColor: WHITE,
              borderColor: RED,
            },
            "& .MuiFilledInput-input": {
              paddingLeft: 27,
            },
          }),
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            backgroundColor: ownerState.control.props.checked
              ? PRIMARY_100
              : GREY_LIGHT,
            borderRadius: 10,
            marginLeft: 0,
            marginRight: 0,
            height: 76,

            "&:focus-visible": {
              backgroundColor: WHITE,
              boxShadow:
                "0px 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",
              borderWidth: 2,
              borderColor: PRIMARY_200,
            },

            "&:hover": {
              backgroundColor: PRIMARY_50,
            },
          }),
        },
      },
      MuiFormControl: {
        styleOverrides: {},
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          filled: ({ ownerState }) => ({
            top: 11,
            fontWeight: ownerState.shrink ? "400 !important" : 200,
            "&.Mui-focused": {
              color: BLACK_MEDIUM_EMPHASIS,
              fontWeight: 400,
            },
            "&.Mui-error": {},
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            fontWeight: 400,
            color: BLACK_HIGH_EMPHASIS,
            cursor: "pointer",

            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: PRIMARY_700,
            width: 200,
          },
          list: {
            backgroundColor: WHITE,
            color: PRIMARY_700,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: PRIMARY_700,
            "&.Mui-focusVisible": {
              backgroundColor: PRIMARY_500,
              color: WHITE,
            },
            "&:hover": {
              backgroundColor: PRIMARY_50,
              color: PRIMARY_500,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            margin: "8px",
            padding: "16px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            background: GREY_LIGHT,
            borderBottom: `1px solid #E8E8E8`,
            borderTop: `1px solid #E8E8E8`,
            color: WHITE,
          }

        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "filled",
          InputProps: {disableUnderline: true},
        },
        styleOverrides: {
          root: ({ownerState}) => {
            const getColor = () => {
              if (ownerState.optional && !ownerState.value)
                return BLACK_LOW_EMPHASIS;
              return ownerState.value ? BLACK_MEDIUM_EMPHASIS : PRIMARY_700;
            };

            const tColor = getColor();

            return {
              "& .MuiInputLabel-filled": {
                color: tColor,
                fontWeight: ownerState.value ? 400 : 700,
                paddingLeft: 20,
              },
              "& .MuiInputLabel-filled.Mui-focused": {
                fontWeight: 400,
                color: BLACK_MEDIUM_EMPHASIS,
              },
            };
          },
        },
      },
    },
  })
);
