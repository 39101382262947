import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Spacer from "../../../components/ui-kit/Spacer";
import { Box } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { createLicence, getUserLicenses } from "../../../../core/apis/licence";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  ACTIVE,
  APPROVED,
  DECLINED,
  DISCARDED,
  DRAFT,
  ISSUED,
  REVIEWING,
  SAVED,
  SUBMITTED,
} from "../../../../core/constants/licences";
import { routes } from "../../../routes/routesData";
import classes from "./index.module.scss";
import { Link, Storefront, Add, WaterDamage, Home } from "@mui/icons-material";
import ModuleSectionCard from "../../../components/ui-kit/mch/module-section-card";
import { ModuleTypes } from "../../../../core/constants/enums";
import UtilitiesHeaderBackgroundImage from "../../../../assets/images/lacombe/lacombe-utilities.png";
import BusinessLicenceHeaderBackgroundImage from "../../../../assets/images/lacombe/lacombe-business-licence.png";
import { getGlobal } from "../../../../utilities";
import WBCBreadcrumbs from "../../../components/ui-kit/breadcrumbs";
import { getAccountSummaries } from "../../../../core/apis/account";

const InnerContainer = styled.div`
  max-width: 480px;
  padding: 2rem 0;
  width: 100%;
`;

/**
 * Displays a users licences and drafts
 * @returns {JSX.Element}
 * @constructor
 */
const LandingPage = () => {
  const { state } = useLocation();
  const { handleError, handleSuccess } = useContext(NotificationContext);
  const history = useHistory();
  const [licences, setLicences] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [loadingLicences, setLoadingLicences] = useState(false);
  const [loadingUtilities, setLoadingUtilities] = useState(false);
  const accountUtilitiesEnabled =
    getGlobal("CCFG_ACCOUNTS_UTILITIES") === "true";
  const licenceBusinessEnabled = getGlobal("CCFG_LICENCES_BUSINESS") === "true";

  /**
   * With initial render of the component:
   * - Calls the function for getting the list of business licences
   * - Calls the function for getting the list of utilities accounts
   * - if the cancelledSuccess property of location state is true, calls the handle success function of notification context
   */
  useEffect(() => {
    fetchLicences().then();
    fetchUtilities().then();
    if (state?.cancelledSuccess) {
      handleSuccess("Your licence was cancelled");
    }
    // eslint-disable-next-line
  }, []);

  //Navigates to the licence form
  const navigateToNewLicence = async () => {
    try {
      const { licenseId } = await createLicence();

      history.push(`business-licence/form/${licenseId}#1`);
    } catch (e) {
      handleError(e);
    }
  };

  /**
   * Navigates user to linking a utility account screen
   */
  const linkUtilityAccount = (e) => {
    history.push(routes.LINK_UTILITY.path);
  };

  const linkLicence = () => {
    history.push(routes.LINK_LICENCE.codePath);
  };

  /**
   * Gets the list of landing-page by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchLicences = async () => {
    setLoadingLicences(true);
    try {
      let items = [];
      const allLicences = await getUserLicenses();
      allLicences.forEach((item) => {
        switch (item.status) {
          case DRAFT:
          case SAVED:
          case SUBMITTED:
          case REVIEWING:
          case APPROVED:
          case ISSUED:
          case ACTIVE:
            items.push(item);
            break;
          case DISCARDED:
          case DECLINED:
          default:
            break;
        }
      });
      setLicences(items);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoadingLicences(false);
    }
  };

  const fetchUtilities = async () => {
    setLoadingUtilities(true);
    try {
      let items = [];
      const allAccounts = await getAccountSummaries({});
      allAccounts.result.forEach((item) => {
        items.push(item);
      });
      setUtilities(items);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoadingUtilities(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.top_bar}>
        <WBCBreadcrumbs
          children={[
            {
              icon: <Home fontSize={"small"} />,
              text: "HOME",
            },
          ]}
        />
        <div />
      </div>
      <InnerContainer>
        <Box p={1}>
          <p className={classes.title}>Manage your accounts</p>
          <p className={classes.sub_title}>
            {accountUtilitiesEnabled
              ? "MyCityHall is City of Lacombe's one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, managing utilities accounts, making payments, and more."
              : "MyCityHall is City of Lacombe's one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, making payments, and more."}
          </p>
          <Spacer amount={0.8} />
          {accountUtilitiesEnabled && (
            <>
              <ModuleSectionCard
                title={"Utilities"}
                icon={<WaterDamage />}
                moduleType={ModuleTypes.utilities}
                fetchItems={fetchUtilities}
                actionsLabel={"Add account"}
                items={utilities ?? []}
                loading={loadingUtilities}
                actions={[
                  {
                    icon: <Link />,
                    onClick: linkUtilityAccount,
                    label: "Link an existing account",
                  },
                ]}
                backgroundImage={UtilitiesHeaderBackgroundImage}
              />
              <div style={{ marginBottom: "2rem" }} />
            </>
          )}
          {licenceBusinessEnabled && (
            <>
              <ModuleSectionCard
                loading={loadingLicences}
                title={"Business Licences"}
                icon={<Storefront />}
                moduleType={ModuleTypes.businessLicence}
                fetchItems={fetchLicences}
                actionsLabel={"Add licence"}
                items={licences ?? []}
                actions={[
                  {
                    icon: <Link />,
                    onClick: linkLicence,
                    label: "Link an existing licence",
                  },
                  {
                    icon: <Add />,
                    onClick: navigateToNewLicence,
                    label: "Create a new licence",
                  },
                ]}
                backgroundImage={BusinessLicenceHeaderBackgroundImage}
              />
            </>
          )}
        </Box>
      </InnerContainer>
    </div>
  );
};

export default LandingPage;
