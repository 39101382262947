import React from "react";
import classes from "./index.module.scss";
import Button from "@mui/material/Button";

const ModuleItemCard = ({
  isPaymentDue,
  businessName,
  status,
  description,
  autoRenewMessage,
  balance,
  onClick,
  canDiscard,
  navigateToPayDues,
  totalDueText = "Total due: ",
}) => {
  return (
    <>
      <div className={classes.container} onClick={onClick}>
        <div className={classes.left_section}>
          <p className={classes.title}>{businessName}</p>
          {!!description && (
            <p className={classes.description}>{description}</p>
          )}
          {isPaymentDue ? (
            <div className={classes.payment_description}>
              <p>{totalDueText}</p>
              <p>{balance}</p>
            </div>
          ) : !!autoRenewMessage ? (
            <p className={classes.description}>{autoRenewMessage}</p>
          ) : (
            <div />
          )}
        </div>
        <div>
          {isPaymentDue ? (
            <Button
              onClick={navigateToPayDues}
              variant={"contained"}
              size={"small"}
            >
              Pay Dues
            </Button>
          ) : canDiscard ? (
            <div className={classes.un_submitted}>
              <p>Unsubmitted</p>
            </div>
          ) : (
            <div
              className={
                status === "Active" ? classes.active : classes.under_review
              }
            >
              <p>{status !== "Active" ? "Under review" : status}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModuleItemCard;
