import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Form, Formik } from "formik";
import ModalTitle from "../../../../../../components/ui-kit/modal-title";
import Alerts from "../../../../../../components/ui-kit/Alert";
import Select from "../../../../../../components/ui-kit/select";
import Grid from "@mui/material/Unstable_Grid2";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import TextField from "../../../../../../components/ui-kit/TextField";
import {
  collectionServiceOptions, disposableServiceSizeOptions,
  metreSizeOptions, pickupOptions,
  typeOptions,
  wasteMetreOptions
} from "./options";
import {validationSchema} from "./validationSchema";

interface IFormModal {
  open: boolean;
  handleClose: any;
  onSubmit: any;
  values: any;
}

const ServicesFormModal = ({
  open = false,
  handleClose = () => {},
  onSubmit,
  values,
}: IFormModal) => {
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState({}) as any;

  useEffect(() => {
    setInitialValues({
      type: values?.type,
      serial_number: values?.serial_number,
      metre_size: values?.metre_size,
      waste_metre_size: values?.waste_metre_size,
      collection_services: values?.collection_services,
      collection_service_pickup: values?.collection_service_pickup,
      cardboard: values?.cardboard,
      disposable_service_size: values?.disposable_service_size,
    });
  }, [values]);

  return (
    <Formik
      //@ts-ignore
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        resetForm,
        dirty,
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
        isValid,
      }) => {
        return (
          <Dialog open={open} fullWidth={true}>
            <ModalTitle
              title={"Update information"}
              handleClose={() => {
                handleClose();
                resetForm();
              }}
            />
            <DialogContent>
              <Alerts
                title={"Before submitting to review"}
                variant={"warning"}
                body={
                  "Please double-check all your updated information before submitting"
                }
              />
              <Spacer />
              <Form noValidate={true}>
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <p className={"overline_bold"}>PROPERTY TYPE</p>
                    <Select
                      name={"type"}
                      label={"Type"}
                      value={values.type}
                      onChange={handleChange}
                      fullWidth
                      options={typeOptions}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p className={"overline_bold"}>WATER SERVICES</p>
                    <TextField
                      name={"serial_number"}
                      label={"RF Serial Number"}
                      value={values.serial_number}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Select
                      name={"metre_size"}
                      label={"Water metre size"}
                      value={values.metre_size}
                      onChange={handleChange}
                      fullWidth
                      options={metreSizeOptions}
                    />
                  </Grid>
                  {values.type !== "Residential" ? (
                    <Grid xs={12}>
                      <p className={"overline_bold"}>WASTEWATER SERVICES</p>
                      <Select
                        name={"waste_metre_size"}
                        label={"Wastewater metre size"}
                        value={values.waste_metre_size}
                        onChange={handleChange}
                        fullWidth
                        options={wasteMetreOptions}
                      />
                    </Grid>
                  ) : null}
                  <Grid xs={12}>
                    <p className={"overline_bold"}>SOLID WASTE SERVICES</p>
                    {values.type === "Residential" ? (
                      <Select
                        name={"collection_services"}
                        label={"Collection services"}
                        value={values.collection_services}
                        onChange={handleChange}
                        fullWidth
                        options={collectionServiceOptions}
                      />
                    ) : null}
                  </Grid>
                  {values.type !== "Residential" ? (
                    <>
                      <Grid xs={12}>
                        <Select
                          name={"collection_service_pickup"}
                          label={"Collection Service Pickup"}
                          value={values.collection_service_pickup}
                          onChange={handleChange}
                          fullWidth
                          options={pickupOptions}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Select
                          name={"cardboard"}
                          label={"Cardboard"}
                          value={values.cardboard}
                          onChange={handleChange}
                          fullWidth
                          options={pickupOptions}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <Select
                          name={"disposable_service_size"}
                          label={"Disposal service size"}
                          value={values.disposable_service_size}
                          onChange={handleChange}
                          fullWidth
                          options={disposableServiceSizeOptions}
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty}
                size={"small"}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  if (formikRef.current) {
                    //@ts-ignore
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                Submit to review
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ServicesFormModal;
