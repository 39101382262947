import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import {
  BLACK_LOW_EMPHASIS,
  OUTLINE,
  PRIMARY_200,
  PRIMARY_500,
} from "../../../../mui-theme/theme";
import Typography from "../typography";
import IconBase from "../IconBase";

const ButtonWrapper = styled.div`
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  margin: -0.5rem;
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
  border-radius: 10px;

  :hover {
    border: 1px solid ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
    background: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
    color: ${({ theme }) => theme.palette.nonPalette.RED};
    cursor: pointer;
  }

  &&.Mui-disabled * {
    color: ${OUTLINE};
  }
`;

const ClickableArea = styled.div`
  && {
    align-items: center;
    background: white;
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: 10px;
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    text-transform: unset;
  }

  ${({ disablebarclick, theme }) =>
    disablebarclick
      ? `
          &&:hover {
            cursor: default;
            background: white;
         }
        `
      : ` 
          &&:hover {
            cursor: pointer;
            background: ${theme.palette.primary[50]};
        `}
}
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 90%;
`;

const AccountBar = ({
  variant,
  name,
  disableIconButton,
  disableBarClick,
  hideLinkButton,
  onBarClick,
  onUnlink,
  onLink,
  isLinked,
}) => {
  const theme = useTheme();

  const handleBarClick = () => {
    if (onBarClick) onBarClick();
  };

  const handleUnlinkClick = (e) => {
    e.stopPropagation();
    if (onUnlink) onUnlink();
  };

  const handleLinkClick = (e) => {
    e.stopPropagation();
    if (onLink) onLink();
  };

  return (
    <ClickableArea
      disableRipple
      disablebarclick={disableBarClick}
      onClick={!disableBarClick && handleBarClick}
    >
      <InnerContainer>
        <IconBase
          fill={0}
          wght={400}
          iconName={variant === "LINK" ? "add_link" : "link"}
          color={
            variant === "LINK"
              ? theme.palette.primary[200]
              : theme.palette.primary[500]
          }
        />
        <Typography
          variant="h6"
          margin="0 0 0 0.5rem"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          color={variant === "LINK" ? PRIMARY_200 : PRIMARY_500}
        >
          {name}
        </Typography>
      </InnerContainer>
      {!hideLinkButton ? (
        isLinked ? (
          <ButtonWrapper
            onClick={handleUnlinkClick}
            disabled={disableIconButton}
            theme={theme}
          >
            <IconBase
              iconName="link_off"
              color={"inherit"}
              style={{ marginTop: "auto", marginRight: "0.5rem" }}
            />
            <Typography color={"inherit"} style={{ marginTop: "0.15rem" }}>
              Unlink
            </Typography>
          </ButtonWrapper>
        ) : (
          <ButtonWrapper onClick={handleLinkClick} disabled={disableIconButton}>
            <IconBase iconName="link" color={BLACK_LOW_EMPHASIS} />
          </ButtonWrapper>
        )
      ) : null}
    </ClickableArea>
  );
};

export default AccountBar;
