import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { default as TextField } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { CheckboxCard } from "../../../components/ui-kit/Checkbox";
import { verifyPasscode } from "../../../../core/apis/auth";
import { Form, Formik } from "formik";
import { ComponentLoadingScreen } from "../../../components/ui-kit/LoadingScreen";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { routes as navigator } from "../../../routes/routesData";
import { Grid, useTheme } from "@mui/material";
import { ReactComponent as KeyEnvelope } from "../../../../assets/images/key_envelope.svg";
import * as yup from "yup";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { ReactComponent as ThreeDotsSpacer } from "../../../../assets/icons/three-dot-spacer-icon.svg";
import { handleAxiosError } from "../../../../utilities";
import { ADMIN_AUTH_TYPE } from "../../../../core/constants/authTypes";
import jwtDecode from "jwt-decode";
import Button from "../../../components/ui-kit/Button";

const FixedButton = styled(Button)`
  && {
    top: ${({ top }) => top || "1rem"};
    left: ${({ left }) => left || "1rem"};
    bottom: ${({ bottom }) => bottom};
    position: fixed;
    z-index: 10;
  }
`;

const ThreeDotsSpacerWrapper = styled.div`
  padding-inline: 0.5rem;

  svg {
    height: 3.5px;
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 100vh;
`;

const StyledTypography = styled(Typography)`
  max-width: 350px;
`;

const TwoFactorAuthentication = ({ location }) => {
  const [loading] = useState(false);
  const [validateOnChange] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const history = useHistory();
  const { userData, userToken, ...rest } = location.state || {};

  useEffect(() => {
    if (rest.snackbarOpen !== undefined) setSnackbarOpen(rest.snackbarOpen);
    if (rest.email !== undefined) setEmail(rest.email);
  }, [rest.email, rest.snackbarOpen, email, snackbarOpen]);

  const { login } = useContext(AuthContext);

  const initialValues = {
    passcode: "",
    isTrustedDevice: false,
  };

  const validPassCode = yup
    .string()
    .required("Two-factor authentication passcode is required.");

  const validation = yup.object({
    passcode: validPassCode,
  });

  /**
   * Memofied callback for navigating user back to log-in screen
   * @type {(function(): void)|*}
   */
  const navigateBackToLogin = useCallback(() => {
    history.push(navigator.LOGIN.path);
  }, [history]);

  const handleSubmit = async ({ passcode, token, isTrustedDevice }) => {
    try {
      await verifyPasscode({ passcode, token, isTrustedDevice });
      // Index
      await login(userData);
    } catch (err) {
      if (err?.response?.status === 404) {
        throw err;
      }
    }
  };

  return (
    <Container>
      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
        {" "}
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          validateOnChange={validateOnChange}
          onSubmit={async ({ passcode, isTrustedDevice }, { resetForm }) => {
            try {
              await handleSubmit({
                passcode,
                token: userToken,
                isTrustedDevice,
              });

              // Redirect to homepage
              const decodedData = jwtDecode(userToken);
              if (decodedData.auth_type === ADMIN_AUTH_TYPE) {
                history.replace("/admin");
              } else {
                history.replace("/");
              }
            } catch (e) {
              if (e.response?.status >= 400) {
                resetForm({
                  errors: {
                    passcode: e.response?.data.message,
                  },
                  values: { passcode, isTrustedDevice },
                });
              }

              try {
                handleAxiosError(e);
              } catch (e) {
                console.error(e);
              }
            }
          }}
        >
          {({ values, errors, handleChange, setFieldValue }) => (
            <>
              <FixedButton
                onClick={navigateBackToLogin}
                iconName="chevron_left"
                size="medium_icon_left"
                variant="outlined"
                top="4rem"
                left="4rem"
              >
                BACK
              </FixedButton>
              <ComponentLoadingScreen loading={loading} />
              <Form noValidate>
                <Grid container>
                  <Grid item container xs={12}>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignItems: "center",
                        marginBottom: "3rem",
                      }}
                    >
                      <LockOutlined sx={{ height: "32px", width: "32px" }} />
                      <ThreeDotsSpacerWrapper>
                        <ThreeDotsSpacer />
                      </ThreeDotsSpacerWrapper>
                      <KeyEnvelope />
                      <ThreeDotsSpacerWrapper>
                        <ThreeDotsSpacer />
                      </ThreeDotsSpacerWrapper>
                      <LockOpenOutlined
                        sx={{
                          height: "32px",
                          width: "32px",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: "1.5rem" }}>
                    <Typography
                      variant="h4"
                      fontWeight={"300"}
                      color={theme.palette.primary[700]}
                      style={{ paddingBottom: "10px" }}
                    >
                      Safeguard your account
                    </Typography>
                    <StyledTypography variant="body1">
                      Two-factor authentication adds an extra layer of
                      protection on top of your password. Note the code expires
                      after 1 hour or after you request a new one.
                      <br />
                      <br />
                      If you do not see the email in a few minutes, check your
                      spam folder.
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="passcode"
                      label="Enter passcode"
                      value={values.passcode}
                      onChange={(e) =>
                        setFieldValue(
                          "passcode",
                          e.target.value.toUpperCase().trim()
                        )
                      }
                      error={Boolean(errors.passcode)}
                      helperText={errors.passcode}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      justifyContent: "flex-end",
                      marginBottom: "1rem",
                    }}
                  ></Grid>
                  <Grid item xs={12} style={{ marginBottom: "15px" }}>
                    <CheckboxCard
                      name="isTrustedDevice"
                      value={values.isTrustedDevice}
                      onChange={handleChange}
                      label="This is a trusted device"
                      cardVariant="highlight"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      size={"large"}
                      fullWidth
                    >
                      Confirm passcode
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default TwoFactorAuthentication;
