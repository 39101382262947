import React from "react";
import Button from "@mui/material/Button";
import Typography from "../ui-kit/typography";
import styled from "@emotion/styled";
import Modal from "../ui-kit/Modal";
import { Box, useTheme } from "@mui/material";
import { truncate } from "../../../utilities";
import { BUSINESS_NAME_LENGTH } from "../../../core/constants/general";

const ItemModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const BackButton = styled(Button)``;

const RemoveButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
  }

  && .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED};
  }
`;

/**
 * Modal that confirms the deletion of an owner or manager
 * @param removeModalOpen
 * @param setRemoveModalOpen
 * @param removeOwnerManager
 * @param handleModalClose
 * @param index
 * @param setHideModel
 * @returns {JSX.Element}
 * @constructor
 */
export default function RemoveItemModal({
  removeModalOpen,
  removeOwnerManager,
  handleModalClose,
  name,
}) {
  const theme = useTheme();

  return (
    <ItemModal open={removeModalOpen} fullWidth={false}>
      <Typography variant={"body1"} fontWeight={"700"}>
        Remove {truncate(name, BUSINESS_NAME_LENGTH)}?
      </Typography>
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        This {name === "owner" ? "owner" : "manager"} will be removed along with
        all their information.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <BackButton
          variant={"text"}
          onClick={() => {
            handleModalClose();
          }}
        >
          <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
            Cancel
          </Typography>
        </BackButton>
        <RemoveButton
          variant={"text"}
          onClick={() => {
            removeOwnerManager();
            handleModalClose();
          }}
        >
          <Typography color={"red"} fontWeight={"700"}>
            Remove
          </Typography>
        </RemoveButton>
      </Box>
    </ItemModal>
  );
}
