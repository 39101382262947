import {
  BLACK_LOW_EMPHASIS,
  GREY_DARK,
  GREY_LIGHT,
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_700,
  RED,
  RED_BACKGROUND,
  WHITE,
} from "../../mui-theme/theme";
import Typography from "@mui/material/Typography";
import { capitalize } from "../index";
import React, { HTMLAttributes } from "react";
import classes from "./index.module.scss";
import {
  ACTIVE,
  APPROVED,
  CANCELLED,
  DECLINED,
  EXPIRED,
  ISSUED,
  OUTDATED,
  REVIEWING,
  REVOKED,
  SUBMITTED,
} from "../../core/constants/licences";
import IconBase from "../../ui/components/ui-kit/IconBase";
import styled from "@emotion/styled";
import { DataFormat } from "../../core/constants/enums";

export const OPEN = "open";
export const VOID = "void";
export const PAID = "paid";

const StatusContainer = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
  border-radius: 100px;
  padding: 0.3rem 0.7rem;
`;

const Dot = styled.div<any>`
  height: 15px;
  width: 15px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  ${({ borderColor }: any) => `border: 2px solid ${borderColor}`};
  margin-right: 10px;
`;

const StyledIconbase = styled(IconBase)`
  && {
    margin-right: 0.5rem;
  }
`;

interface TransactionStatusContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  bgColor?: string;
}

const TransactionStatusContainer = styled.div<TransactionStatusContainerProps>`
  display: inline-block;
  padding: 0.3rem 0.7rem;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
  border-radius: 100px;
`;

const BalanceDisplay = styled.div<TransactionStatusContainerProps>`
  color: ${({ color }) => color};
  ${({ bgColor }) => `background-color: ${bgColor}`};
`;

const showGreenStatus = ["active", PAID];
const showWhiteStatus = ["inactive", OPEN];
const showRedStatus = [VOID];

//Returns a coloured dot in front of the status. Green if active, grey if inactive
export const statusFormat = (val: string) => {
  const getColor = () => {
    if (val) {
      const status = val.toLowerCase();

      switch (status) {
        case showGreenStatus.find((x) => x === val):
          return "#34C300";
        case showWhiteStatus.find((x) => x === val):
          return WHITE;
        case showRedStatus.find((x) => x === val):
          return "#F00F3C";
        default:
          return WHITE;
      }
    }
  };

  const getBorderColor = () => {
    if (val && typeof val === "string") {
      const status = val.toLowerCase();

      switch (status) {
        case showGreenStatus.find((x) => x === val):
          return "#34C300";
        case showWhiteStatus.find((x) => x === val):
          return BLACK_LOW_EMPHASIS;
        case showRedStatus.find((x) => x === val):
          return "#F00F3C";
        default:
          return BLACK_LOW_EMPHASIS;
      }
    }
  };

  return (
    <StatusContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dot color={getColor()} borderColor={getBorderColor()} />
        <Typography variant={"body2"}>{capitalize(val)}</Typography>
      </div>
    </StatusContainer>
  );
};

export const licenceStatusIconName = (val: string) => {
  let iconName;
  switch (val) {
    case "created":
    case "draft":
      iconName = "edit";
      break;
    case SUBMITTED:
      iconName = "inbox";
      break;
    case REVIEWING:
    case APPROVED:
      iconName = "find_in_page";
      break;
    case ISSUED:
      iconName = "approval";
      break;
    case ACTIVE:
      iconName = "check";
      break;
    case DECLINED:
      iconName = "delete";
      break;
    case CANCELLED:
      iconName = "cancel";
      break;
    case REVOKED:
      iconName = "report";
      break;
    case OUTDATED:
      iconName = "event";
      break;
    case EXPIRED:
      iconName = "alarm_off";
      break;
    default:
  }
  return iconName;
};

export const licenseStatusFormat = (val: any) => {
  return (
    <div className={classes.status_container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconBase
          style={{
            color: PRIMARY_200,
            fontSize: 16,
            paddingRight: 5,
            marginRight: "0.5rem",
          }}
          iconName={licenceStatusIconName(val)}
          className={undefined}
          size={undefined}
          color={undefined}
          hoverColor={undefined}
        />
        <Typography variant={"body2"}>
          {val === APPROVED ? capitalize(REVIEWING) : capitalize(val)}
        </Typography>
      </div>
    </div>
  );
};

export const applicantFormat = (val: any) => {
  const [name, address] = val.split("<br/>");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        <Typography variant={"body2"}>{name}</Typography>
      </div>
      <div>
        <Typography className={"label"} variant="caption">
          {address}
        </Typography>
      </div>
    </div>
  );
};

export const transactionStatusFormat = (val: any) => {
  let iconName, bgColor, txtColor;
  if (val && typeof val === "string") {
    const status = val.toLowerCase();

    switch (status) {
      case "in progress":
        iconName = "refresh";
        bgColor = GREY_LIGHT;
        txtColor = GREY_DARK;
        break;
      case "failed":
        iconName = "cancel";
        bgColor = RED_BACKGROUND;
        txtColor = RED;
        break;
      case "received":
        iconName = "check_circle";
        bgColor = PRIMARY_100;
        txtColor = PRIMARY_200;
        break;
      default:
    }
  }
  return (
    <TransactionStatusContainer bgColor={bgColor}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <StyledIconbase
          style={{ color: txtColor, fontSize: 16 }}
          iconName={iconName}
          className={undefined}
          size={undefined}
          color={undefined}
          hoverColor={undefined}
        />
        <Typography
          style={{ color: txtColor, fontWeight: 500 }}
          variant={"body1"}
        >
          {val}
        </Typography>
      </div>
    </TransactionStatusContainer>
  );
};

export const balanceFormat = (val: any) => {
  const getColor = () => {
    if (val > 0) {
      return PRIMARY_700;
    } else if (val < 0) {
      return PRIMARY_200;
    } else {
      return BLACK_LOW_EMPHASIS;
    }
  };

  const getBackGroundColor = () => {
    if (val > 0) {
      return "";
    } else if (val < 0) {
      return PRIMARY_100;
    } else {
      return "";
    }
  };

  return (
    <BalanceDisplay color={getColor()} bgColor={getBackGroundColor()}>
      <Typography color={"inherit"}>{DataFormat.CURRENCY(val)}</Typography>
    </BalanceDisplay>
  );
};
