import React from "react";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import { Chip as MuiChip, useTheme } from "@mui/material";
import { darken, transparentize } from "polished";
import { IconBase } from "../Icons";

const StyledChip = styled(MuiChip)`
  && {
    border-radius: 5px;
    transition: 0.2s;
    color: ${({ theme }) => theme.palette.blacks.BLACK_HIGH_EMPHASIS};
    font-size: ${({ size }) => (size === "small" ? "0.85rem" : "1rem")};
    font-weight: 400;
    ${({ primarydark, primary, type }) =>
      `color: ${type === "contained" ? primary : primarydark};`}
    ${({ type, selected, primary, primarylight, theme }) => {
      switch (type) {
        case "outlined":
          return `border: 1px solid ${
            selected ? primary : theme.palette.nonPalette.OUTLINE
          };`;
        case "contained":
          return `background:${primarylight}; border: none;`;
        case "dotted":
          return `border: 2px dashed ${
            selected ? primary : theme.palette.nonPalette.OUTLINE
          }`;
        default:
          return `border: none;`;
      }
    }};

    background-color: ${({ theme, active }) =>
      active ? theme.palette.primary[100] : theme.palette.whites.WHITE};
  }

  &&:hover {
    background: ${({ theme }) => theme.palette.primary[50]};
  }

  &&:focus {
    color: ${({ theme }) => theme.palette.primary[200]};
    border-color: ${({ theme }) => theme.palette.primary[200]};
    box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
      0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);
  }
`;

const Chip = ({
  color,
  variant,
  iconName,
  onSelect,
  selected,
  size,
  disabled,
  onClick,
  className,
  iconHasPadding = false,
  ...rest
}) => {
  const theme = useTheme();
  const primary = color ? color : theme.palette.nonPalette.BLUE_GRADIENT;
  const primaryDarkened = color
    ? darken(0.2, primary)
    : theme.palette.primary[700];
  const primaryLightened = transparentize(0.9, "#0C55B5");

  return (
    <StyledChip
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onClick}
      clickable={!!onClick}
      className={className}
      type={variant}
      size={size}
      primary={primary}
      selected={selected}
      primarydark={primaryDarkened}
      primarylight={primaryLightened}
      disabled={disabled}
      icon={
        iconName ? (
          <IconBase
            style={{
              color: "inherit",
              fontSize: 16,
              ...(iconHasPadding
                ? { paddingLeft: "0.5rem", marginRight: "-0.3rem" }
                : {}),
            }}
            iconName={iconName}
          />
        ) : null
      }
      deleteIcon={<CancelIcon size={size} style={{ color: "inherit" }} />}
      {...rest}
    />
  );
};

export default Chip;
