import React, { useEffect, useState } from "react";
import WarningAlert from "../../widgets/warning-alert";
import Grid from "@mui/material/Unstable_Grid2";
import DetailsContainer from "../../widgets/details-container";
import IconBase from "../../../../../../components/ui-kit/IconBase";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import classes from "../../index.module.scss";
import BarGraph from "../../../../../../components/ui-kit/bar-graph";
import CodeTable from "../../widgets/code-table";
import ListItem from "../../../../../../components/ui-kit/list-item";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import AccountFormModal from "../../widgets/account-form-modal";
import ContactFormModal from "../../widgets/contacts-form-modal";
import { IFormField } from "../../../../../../../core/interfaces/utility/IFormField";
import { IContact } from "../../interfaces/IContact";
import IAccountFormValues from "../../interfaces/IAccountFormValues";
import { formatPhoneNumber } from "../../../../../../../utilities/masked-input-formatters";

const accountDetailsItems = [
  {
    name: "account_number",
    icon: "tag"
  },
  {
    name: "access_code",
    icon: "done"
  },
  {
    name: "batch_number",
    icon: "folder"
  },
  {
    name: "account_holder",
    icon: "person"
  },
  {
    name: "service_address",
    icon: "location_on"
  },
  {
    name: "mailing_address",
    icon: "forward_to_inbox"
  },
  {
    name: "phone_number",
    icon: "call"
  },
  {
    name: "is_owner",
    icon: "home"
  }
];

const initialDetails: IAccountFormValues = {
  firstName: "",
  lastName: "",
  country: "",
  address1: "",
  address2: "",
  city: "",
  province_state: "",
  postal_zip: "",
  phone: "",
  isOwner: true,
  mailingSameAsServiceAddress: false
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

/**
 * Info tab for the utilities details screen
 * @constructor
 */
const InfoTab = ({
                   id = 0,
                   accessor = (el: string): IFormField => {
                     return {} as IFormField;
                   },
                   loading = false,
                   showReviewWarning = false
                 }) => {
  const history = useHistory();
  const [barGraphData] = useState([0, 2.0, 3.2]);
  const [openEditAccountModal, setOpenEditAccountModal] = useState(false);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [contactItems, setContactItems] = useState<IContact[]>([]);
  const [editableInfo, setEditableInfo] = useState<IAccountFormValues>(initialDetails);

  const handleServiceWarningClick = () => {
    history.push(`/utility/${id}#${1}`);
  };

  const onSubmitChanges = (values: any, { resetForm }: any) => {
    console.log(values);
    alert("TODO - connect to backend");
    setOpenEditAccountModal(false);
    setOpenEditContactModal(false);
    resetForm();
  };

  const decorateValue = (name: string, value: any) => {
    switch (name) {
      case "mailing_address":
      case "service_address":
        return `${value.address1}\n${value.city} ${value.province_state} ${value.postal_zip}`
      case "phone_number":
        return formatPhoneNumber(value);
    }
    return value;
  }

  const populateEditableInfo = () => {
    let info = initialDetails;
    info.firstName = accessor("account_holder").value.value.split(" ")[0];
    info.lastName = accessor("account_holder").value.value.slice(info.firstName.length).trim();
    info.phone = accessor("phone_number").value.value;
    const address = accessor("mailing_address");
    if (address !== undefined) {
      info = {
        ...info,
        ...address.value.value
      };
    }
    setEditableInfo(info);
  };

  useEffect(() => {
    const contacts = accessor("other_contaccts");
    if (contacts?.value !== undefined && contacts.value.value !== "-") {
      setContactItems(contacts.value.value);
    }
  }, [accessor]);

  return (
    <>
      <p className={typographyClasses.h1}>Utilities Information</p>
      {showReviewWarning ? (
        <WarningAlert onClick={handleServiceWarningClick} />
      ) : (<Spacer />)}
      <Grid container>
        <Grid xs={12} md={12} lg={6}>
          <DetailsContainer
            loading={loading}
            title={"Account Details"}
            buttonLabel={"Edit details"}
            buttonOnClick={() => {
              populateEditableInfo();
              setOpenEditAccountModal(true);
            }}
          >
            {accountDetailsItems.map((item) => {
              const field = accessor(item.name);
              if (item.name === "is_owner") {
                field.value.value = field.value.value ? "I'm the owner" : "I'm a tenant";
              }
              return (
                //@ts-ignore
                <ListItem
                  key={field.id}
                  variant="detail"
                  label={field.label}
                  subLabel={decorateValue(field.name, field.value.value)}
                  originalValue={
                    field?.originalValue ? field.originalValue : undefined
                  }
                  startOrnament={<IconBase iconName={item.icon} size={24} />}
                />
              );
            })}
          </DetailsContainer>
          <Spacer />
          <DetailsContainer
            loading={loading}
            title={"Other Contacts"}
            buttonLabel={"Edit contacts"}
            emptyText={"Add more contacts and share E-bills effortlessly"}
            buttonOnClick={() => setOpenEditContactModal(true)}
          >
            {contactItems.length > 0 ? (
              contactItems.map((item) => {
                return (
                  //@ts-ignore
                  <ListItem
                    key={item.email}
                    label={`${item.firstName} ${item.lastName}`}
                    subLabel={[item.email, item.phone]}
                    startOrnament={<IconBase iconName={"person"} size={24} />}
                  />
                );
              })) : (
              <p>The are no contacts listed.</p>
            )}
          </DetailsContainer>
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <div className={classes.right_column}>
            {!loading ? (
              <>
                {barGraphData ? (
                  <>
                    <p className={typographyClasses.h2}>Consumption History</p>
                    <BarGraph
                      data={barGraphData}
                      title={"VOLUME (CM)"}
                      labels={labels}
                      bottomTitle={"12 Months (2023)"}
                    />
                    <Spacer />
                  </>
                ) : null}
                <p className={typographyClasses.h2}>Service Codes</p>
                <CodeTable />
              </>
            ) : (
              <Skeleton
                variant={"rectangular"}
                style={{ borderRadius: 10 }}
                height={290}
              />
            )}
          </div>
        </Grid>
      </Grid>
      <AccountFormModal
        open={openEditAccountModal}
        handleClose={() => setOpenEditAccountModal(false)}
        onSubmit={onSubmitChanges}
        values={editableInfo}
      />
      <ContactFormModal
        open={openEditContactModal}
        handleClose={() => setOpenEditContactModal(false)}
        onSubmit={onSubmitChanges}
        values={contactItems}
      />
    </>
  );
};

export default InfoTab;
