import React from "react";
import { DataFormat } from "../../../../../core/constants/enums";
import DataTable from "../../../../components/ui-kit/data-table";
import {
  balanceFormat,
  transactionStatusFormat,
} from "../../../../../utilities/data-table-format-helpers";

const transactionFields = [
  {
    id: "createDateTime",
    label: "Date",
    format: DataFormat.DATE,
    filterType: "DATE_PICKER",
  },
  {
    id: "transactionNumber",
    label: "Ref. #",
    filterType: "SEARCH",
  },
  {
    id: "status",
    label: "Status",
    filterType: "SELECT",
    options: [
      { label: "In progress", value: "IN_PROGRESS" },
      { label: "Failed", value: "FAILED" },
      { label: "Received", value: "RECEIVED" },
    ],
    format: transactionStatusFormat,
  },

  {
    id: "amount",
    label: "Amount",
    filterType: "CURRENCY_TEXT_FIELD",
    format: balanceFormat,
    align: "right",
  },
];

export default function TransactionTab({
  transactions,
  loading,
  fetchTransactions,
}) {
  return (
    <DataTable
      ariaLabel="utility accounts"
      data={transactions}
      dataLoading={loading}
      defaultSort=""
      enableFilter={false}
      enableFilterV2={true}
      fields={transactionFields}
      fixedFilters={{ serviceType: "UTILITY" }}
      loadData={fetchTransactions}
      onRowClick={(account, history, e) => {
        history.push(`/admin/transactions/${account.id}`);
      }}
      selectable
    />
  );
}
