import Modal from "./Modal";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { css, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Spacer from "./Spacer";
import { useMemo } from "react";
import React from "react";

const StyledModal = styled(Modal)<any>`
  && {
    max-width: 450px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 3rem 1rem 3rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const DialogButton = styled(Button)<any>`
  color: ${({ buttoncolor }: any) => buttoncolor};
  white-space: nowrap;
  margin-left: 2px;

  && .MuiTouchRipple-child {
    background-color: ${({ buttoncolor }: any) => buttoncolor} !important;
  }

  :hover {
    background: ${({ backgroundcolor }: any) => backgroundcolor};
  }
`;

const Link = styled(Button)<any>(
  ({ theme }) => css`
    color: ${theme.palette.primary[200]};
    margin-top: 10px;
    display: inline;
  `
);

/**
 * Custom dialog component.
 * @param title
 * @param body
 * @param BodyComponent
 * @param open
 * @param handleButtonOne
 * @param handleButtonTwo
 * @param buttonOneText
 * @param buttonTwoText
 * @param buttonOneColor
 * @param buttonOneDisable
 * @param buttonTwoDisable
 * @param buttonTwoColor
 * @param buttonOneVariant
 * @param buttonTwoVariant
 * @param handleClose
 * @param variant - options [original, updated]
 * @returns {JSX.Element}
 * @constructor
 */
export default function Dialog({
  title,
  body,
  BodyComponent = null,
  open = false,
  handleButtonOne,
  handleButtonTwo,
  buttonOneText,
  buttonTwoText,
  buttonOneColor,
  buttonTwoColor,
  buttonOneDisable = false,
  buttonTwoDisable = false,
  buttonOneVariant = "text",
  buttonTwoVariant = "text",
  handleClose,
  variant = "original",
}: any) {
  const theme = useTheme();

  const getTitle = useMemo(() => {
    switch (variant) {
      case "updated":
        return (
          <>
            <Typography variant={"h4"} fontWeight={"400"}>
              {title}
            </Typography>
            <Spacer amount={1} />
          </>
        );
      default:
        return (
          <>
            <Typography variant={"body1"} fontWeight={"700"}>
              {title}
            </Typography>
            <Spacer amount={0.5} />
          </>
        );
    }
  }, [title, variant]);

  const getButtons = useMemo(() => {
    switch (variant) {
      case "updated":
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              flexDirection: "column-reverse",
            }}
            mt={2}
          >
            {buttonOneText && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link
                  size={"small"}
                  variant={buttonOneVariant}
                  onClick={() => {
                    handleButtonOne();
                  }}
                  buttoncolor={buttonOneColor}
                  backgroundcolor={
                    buttonOneColor === theme.palette.nonPalette.RED
                      ? theme.palette.nonPalette.RED_BACKGROUND
                      : "null"
                  }
                  disabled={buttonOneDisable}
                >
                  {buttonOneText}
                </Link>
              </div>
            )}
            {buttonTwoText && (
              <DialogButton
                buttoncolor={buttonTwoColor}
                backgroundcolor={
                  buttonTwoColor === theme.palette.nonPalette.RED
                    ? theme.palette.nonPalette.RED_BACKGROUND
                    : "null"
                }
                variant={buttonTwoVariant}
                onClick={() => {
                  handleButtonTwo();
                }}
                disabled={buttonTwoDisable}
              >
                {buttonTwoText}
              </DialogButton>
            )}
          </Box>
        );
      default:
        return (
          <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
            {buttonOneText && (
              <DialogButton
                variant={buttonOneVariant}
                onClick={() => {
                  handleButtonOne();
                }}
                buttoncolor={buttonOneColor}
                backgroundcolor={
                  buttonOneColor === theme.palette.nonPalette.RED
                    ? theme.palette.nonPalette.RED_BACKGROUND
                    : "null"
                }
              >
                {buttonOneText}
              </DialogButton>
            )}
            {buttonTwoText && (
              <DialogButton
                buttoncolor={buttonTwoColor}
                backgroundcolor={
                  buttonTwoColor === theme.palette.nonPalette.RED
                    ? theme.palette.nonPalette.RED_BACKGROUND
                    : "null"
                }
                variant={buttonTwoVariant}
                onClick={() => {
                  handleButtonTwo();
                }}
              >
                {buttonTwoText}
              </DialogButton>
            )}
          </Box>
        );
    }
  }, [
    buttonOneColor,
    buttonOneText,
    buttonOneVariant,
    buttonOneDisable,
    buttonTwoColor,
    buttonTwoText,
    buttonTwoVariant,
    buttonTwoDisable,
    handleButtonOne,
    handleButtonTwo,
    theme,
    variant,
  ]);

  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      {getTitle}
      {React.isValidElement(BodyComponent) ? (
        BodyComponent
      ) : (
        <Typography
          variant={"body2"}
          style={{
            color:
              (variant === "original" &&
                theme.palette.blacks.BLACK_HIGH_EMPHASIS) ||
              theme.palette.nonPalette.WHITE,
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "18.96px",
          }}
        >
          {body}
        </Typography>
      )}
      {getButtons}
    </StyledModal>
  );
}
