import React, { useContext, useEffect, useRef, useState } from "react";
import * as v from "../../../../utilities/yupValidators";
import * as yup from "yup";
import Alerts from "../../../components/ui-kit/Alert";
import Button from "@mui/material/Button";
import { CheckboxCard } from "../../../components/ui-kit/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import Modal from "../../../components/ui-kit/Modal";
import STRINGS from "../../../../core/constants/strings";
import TextField from "../../../components/ui-kit/TextField";
import Typography from "../../../components/ui-kit/typography";
import jwtDecode from "jwt-decode";
import { routes } from "../../../routes/routesData";
import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { TextField as MuiTextField, useTheme } from "@mui/material";
import { auth_token, sendPasscodeEmail } from "../../../../core/apis/auth";
import { utilGenerateStrongPassword } from "../../../../utilities";
import { get_privacy_policies } from "../../../../core/apis/terms";
import { post_linking_request } from "../../../../core/apis/linking";
import { save_address } from "../../../../core/apis/address";
import { useHistory } from "react-router-dom";
import { user_register } from "../../../../core/apis/user";
import { ReactComponent as LOGOSVG } from "../../../../assets/images/CoLLogo.svg";
import LinkButton from "../../../components/ui-kit/LinkButton";
import classes from "./index.module.scss";
import IconBase from "../../../components/ui-kit/IconBase";
import { PageLoadingScreen } from "../../../components/ui-kit/LoadingScreen";
import { ADMIN_AUTH_TYPE } from "../../../../core/constants/authTypes";
import { AuthContext } from "../../../contexts/AuthContext";

/**
 * Password validators
 */
export const passwordValidationMessages2 = [
  { text: "MUST contain at least 8 characters (12+ recommended)" },
  { text: "MUST contain at least one uppercase letter" },
  { text: "MUST contain at least one lowercase letter" },
  { text: "MUST contain at least one number" },
  { text: `MUST contain at least one special character (!@#$%^&*()_+)` },
];

const topText =
  "Enter your details and get started with MyCityHall. We’ll need your full name, email and password.";
const securePassTopText =
  "We only accept secure passwords. For the full list of requirements";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LOGO = styled(LOGOSVG)`
  margin: 5px;
  width: 350px;

  @media (max-width: 599px) {
    width: 300px;
  }
`;

const SmallLinkButton = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary[200]} !important;
  margin-top: 10px !important;

  :hover {
    color: ${({ theme }) => theme.palette.primary[400]} !important;
    cursor: pointer;
  }
`;

const ErrorMuiTextField = styled(MuiTextField)`
  border: 1px ${({ theme }) => theme.palette.nonPalette.RED} solid !important;
  border-radius: 10px;
`;

const ForgotButtonWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 20px;
  display: flex;
`;

const ReadMoreLessButton = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};

  :hover {
    cursor: pointer;
  }
`;

const TOSModal = styled(Modal)`
  margin-left: auto !important;
  margin-right: auto !important;
  // display: flex;
  align-items: center;
`;

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const validation = yup.object({
  firstName: v.validFirst,
  lastName: v.validLast,
  email: v.validEmail,
  password: v.validStrongPassword,
});

const PasswordHelpTitle = ({ theme }) => (
  <Typography
    variant="body1"
    style={{
      color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
      fontWeight: 500,
    }}
  >
    Secure password
  </Typography>
);

const Register = ({ location }) => {
  const history = useHistory();
  const theme = useTheme();
  const { login } = useContext(AuthContext);
  const [submittedFormValues, setSubmittedFormValues] = useState(initialValues);
  const [accepted, setAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { handleError } = useContext(NotificationContext);
  const [showMore, setShowMore] = useState(false);
  const [tosAgreed, setTosAgreed] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [terms, setTerms] = useState([]);
  const [emailExist, setEmailExist] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const passwordRef = useRef(null);

  const acceptedTerms = location.state?.acceptedTerms;

  useEffect(() => {
    (async () => {
      try {
        setTerms(await get_privacy_policies());
      } catch (err) {
        handleError(STRINGS.ERROR_FAILED_DATA);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!acceptedTerms) history.push(routes.LOGIN.path);
  }, [acceptedTerms, history]);

  const handleSubmit = async (formValues) => {
    if (tosAgreed) {
      let loginDetails = formValues;

      const { primaryAddress, accountLinking } = formValues;
      loginDetails.termIds = location.state?.acceptedTerms;
      await setSubmitting(true);
      try {
        const registered = await user_register(loginDetails);
        const userId = registered?.newUserId;
        if (primaryAddress) {
          await save_address(primaryAddress, "PRIMARY", userId);
        }
        if (accountLinking && accountLinking.length > 0) {
          await post_linking_request(accountLinking, userId);
        }
        setSubmittedFormValues(loginDetails);

        const user = {
          username: loginDetails.email,
          password: loginDetails.password,
        };
        const response = await auth_token(user);
        const userToken = response.data.access_token;
        const decodedData = jwtDecode(userToken);
        const userData = {
          fname: decodedData.firstName,
          lname: decodedData.lastName,
          email: decodedData.sub,
          id: decodedData.id,
          roles: decodedData.roles,
          exp: decodedData.exp,
          cityId: decodedData.cityId,
          isMFA: decodedData.isMFA,
          userToken,
        };

        if (userData.isMFA) {
          await sendPasscodeEmail(userToken);
          const email = decodedData.sub;
          return history.push({
            pathname: routes.TWO_FACTOR_AUTHENTICATION.path,
            state: { userData, userToken, snackbarOpen: true, email },
          });
        }
        await login(userData);
        if (decodedData.auth_type === ADMIN_AUTH_TYPE) {
          history.push("/admin");
        } else {
          history.push("/");
        }
      } catch (err) {
        setEmailExist(true);
      } finally {
        await setSubmitting(false);
      }
    }
  };

  const clickableTextFunction = (e) => {
    e.stopPropagation();
    setShowModal(true);
  };

  const backToLogin = () => {
    history.push(routes.LOGIN.path);
  };

  const checkBoxClick = (e) => {
    e.stopPropagation();
    setTosAgreed(!accepted);
    setAccepted(!accepted);
  };

  /**
   * Handles changes in the password field:
   * - if the input is "space" key, it preserves the current cursor position and leaves the input un-changed
   * - otherwise, sets the form value with the value received from input
   * @param e
   * @param formikProps
   */
  const onPasswordInputChanged = async (e, formikProps) => {
    if (e.nativeEvent?.data === " ") {
      const selectionEnd = e.target.selectionEnd - 1;
      await new Promise((resolve) => setTimeout(resolve, 20));
      passwordRef.current.setSelectionRange(selectionEnd, selectionEnd);
      return;
    }
    const trimmedPassword = e.target.value.trim().replace(" ", "");
    formikProps.setFieldValue("password", trimmedPassword);
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.page_container_back_button}
        size={"medium"}
        startIcon={<IconBase iconName={"close"} />}
        onClick={backToLogin}
        variant={"outlined"}
      >
        cancel
      </Button>
      <PageLoadingScreen loading={submitting} />
      <div className={classes.inner_container}>
        <LOGO className={classes.logo} />
        <Typography
          variant="h4"
          fontWeight={"normal"}
          color={theme.palette.primary[700]}
          style={{ marginBottom: "1rem" }}
        >
          Create an account
        </Typography>
        <Typography
          variant="body2"
          fontWeight={"normal"}
          style={{
            marginBottom: "1rem",
            color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
          }}
        >
          {topText}
        </Typography>
        <div>
          <Formik
            initialValues={submittedFormValues || initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
            validateOnChange={validateOnChange}
            validateOnBlur={false}
          >
            {(formikProps) => {
              let isFilledEmail =
                formikProps.values.email !== null &&
                formikProps.values.email !== "";
              let isFilledFirstName =
                formikProps.values.email !== null &&
                formikProps.values.email !== "";
              let isFilledLastName =
                formikProps.values.email !== null &&
                formikProps.values.email !== "";
              let isFilledPassword =
                formikProps.values.password !== null &&
                formikProps.values.password !== "";
              const generateStrongPassword = () => {
                const suggestion = utilGenerateStrongPassword();
                formikProps.setFieldValue("password", suggestion);
              };
              return (
                <Form noValidate>
                  <div className={classes.name_fields}>
                    <TextField
                      key={"firstName"}
                      name={"firstName"}
                      type={"text"}
                      label={"First name"}
                      onChange={(e) => {
                        formikProps.setFieldValue("firstName", e.target.value);
                      }}
                      value={formikProps.values.firstName}
                      error={Boolean(formikProps.errors["firstName"])}
                      filled={isFilledFirstName.toString()}
                      style={{ marginRight: "0.5rem" }}
                      form={formikProps}
                      helperText={formikProps.errors["firstName"]}
                    />
                    <TextField
                      key={"lastName"}
                      name={"lastName"}
                      type={"text"}
                      label={"Last name"}
                      onChange={(e) => {
                        formikProps.setFieldValue("lastName", e.target.value);
                      }}
                      value={formikProps.values.lastName}
                      error={Boolean(formikProps.errors["lastName"])}
                      filled={isFilledLastName.toString()}
                      style={{ marginLeft: "0.5rem" }}
                      form={formikProps}
                      helperText={formikProps.errors["lastName"]}
                    />
                  </div>
                  {emailExist ? (
                    <div>
                      <ErrorMuiTextField
                        key={"email"}
                        name={"email"}
                        type={"text"}
                        label={"Email address"}
                        fullWidth
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "email",
                            e.target.value.trim()
                          );
                          setEmailExist(false);
                        }}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values["email"]}
                        error={Boolean(formikProps.errors["email"])}
                        filled={isFilledEmail ? "true" : "false"}
                      />
                      <Typography
                        color={theme.palette.nonPalette.RED}
                        fontSize="12.25px"
                        style={{
                          marginLeft: "14px",
                          marginBottom: "1rem",
                        }}
                      >
                        This email address is already registered. Try logging
                        in.
                      </Typography>
                    </div>
                  ) : (
                    <MuiTextField
                      key={"email"}
                      name={"email"}
                      type={"text"}
                      label={"Email address"}
                      fullWidth
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "email",
                          e.target.value.trim()
                        );
                      }}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values["email"]}
                      error={Boolean(formikProps.errors["email"])}
                      filled={isFilledEmail ? "true" : "false"}
                      style={{ marginBottom: "1rem" }}
                      helperText={formikProps.errors["email"]}
                    />
                  )}

                  {showMore ? (
                    <>
                      <PasswordHelpTitle theme={theme} />
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                        }}
                      >
                        {securePassTopText + " refer to this list:"}
                      </Typography>
                      {passwordValidationMessages2.map(({ text }, index) => (
                        <div key={index} style={{ display: "flex" }}>
                          <div
                            style={{
                              marginLeft: "0.5rem",
                              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                            }}
                          >
                            {"\u2022"}
                          </div>
                          <Typography
                            variant="body2"
                            style={{
                              marginLeft: "0.5rem",
                              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                            }}
                          >
                            {text}
                          </Typography>
                        </div>
                      ))}
                      <Typography variant="body2">
                        <ReadMoreLessButton onClick={() => setShowMore(false)}>
                          . Read less
                        </ReadMoreLessButton>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <PasswordHelpTitle theme={theme} />
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                        }}
                      >
                        {securePassTopText}...{" "}
                        <ReadMoreLessButton onClick={() => setShowMore(true)}>
                          read more
                        </ReadMoreLessButton>
                      </Typography>
                    </>
                  )}
                  <TextField
                    ref={passwordRef}
                    key={"password"}
                    name={"password"}
                    type={"password"}
                    label={"Password"}
                    mask={passwordType}
                    fullWidth
                    onChange={(e) => onPasswordInputChanged(e, formikProps)}
                    value={formikProps.values.password}
                    error={Boolean(formikProps.errors["password"])}
                    filled={isFilledPassword.toString()}
                    style={{ marginTop: "1rem" }}
                    form={formikProps}
                    helperText={formikProps.errors["password"]}
                  />
                  <ForgotButtonWrapper>
                    {passwordType === "text" ? (
                      <SmallLinkButton
                        variant="body1"
                        onClick={() => setPasswordType("password")}
                        color={`${theme.palette.blacks.BLACK_MEDIUM_EMPHASIS} !important`}
                      >
                        Hide
                      </SmallLinkButton>
                    ) : (
                      <SmallLinkButton
                        variant="body1"
                        onClick={() => setPasswordType("text")}
                        color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                      >
                        Show
                      </SmallLinkButton>
                    )}
                    <SmallLinkButton
                      variant="body1"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        generateStrongPassword();
                      }}
                    >
                      Suggest strong password
                    </SmallLinkButton>
                  </ForgotButtonWrapper>
                  <br />
                  <CheckboxCard
                    label={
                      <div style={{ display: "flex" }}>
                        <p>I agree to the </p>
                        <LinkButton onClick={clickableTextFunction}>
                          Terms of Service
                        </LinkButton>
                      </div>
                    }
                    value={accepted}
                    onChange={(e) => checkBoxClick(e)}
                  />

                  {!tosAgreed && (
                    <div style={{ marginTop: "0.5rem" }}>
                      <Alerts
                        variant="error"
                        title={
                          "To continue, you must agree to the terms of service."
                        }
                      ></Alerts>
                    </div>
                  )}
                  <ButtonContainer>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setTosAgreed(accepted);
                        setValidateOnChange(true);
                      }}
                      size={"large"}
                      type={"submit"}
                      fullWidth
                      style={{ marginTop: "0.5rem" }}
                    >
                      Create Account
                    </Button>
                  </ButtonContainer>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {showModal && (
        <TOSModal
          title={"Terms of service"}
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <DialogContent
            style={{
              width: "450px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "315px", margin: "auto" }}>
              {terms.map((term, index) => (
                <Typography
                  key={index}
                  dangerouslySetInnerHTML={{ __html: term.content }}
                />
              ))}
            </div>
            <Button
              variant={"outlined"}
              size={"medium"}
              style={{
                width: "315px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              onClick={() => {
                setShowModal(false);
                setAccepted(true);
              }}
            >
              I agree
            </Button>
          </DialogContent>
        </TOSModal>
      )}
    </div>
  );
};

export default Register;
