import React from "react";
import MissingScreen from "../screens/sample-screen";
import Dashboard from "../screens/admin/dashboard";
import LoginScreen from "../screens/general/Login";
import TwoFactorAuthentication from "../screens/general/TwoFactorAuthentication/TwoFactorAuthentication";
import ResetPasswordScreen from "../screens/general/ResetPassword";
import SendResetLink from "../screens/general/SendResetLink";
import Admins from "../screens/admin/people/admins";
import Users from "../screens/admin/people/users";
import { Active, Inactive, Inprogress } from "../screens/admin/licences-list";
import LicenseDetail from "../screens/admin/licence-details";
import {
  ADMIN_AUTH_TYPE,
  USER_AUTH_TYPE,
} from "../../core/constants/authTypes";
import Register from "../screens/general/Register";
import BusinessLicenseCreation from "../screens/my-city-hall/licences/business-licences/business-licence-creation";
import LicenseDetails from "../screens/my-city-hall/licences/business-licences/business-licence-details-screen";
import Checkout from "../screens/my-city-hall/payment/checkout";
import CreditCardAddEdit from "../screens/my-city-hall/payment/credit-card/CreditCardAddEdit";
import LandingPage from "../screens/my-city-hall/landing-page";
import LinkUtility from "../screens/my-city-hall/utilities/link-utility";
import UtilitiesScreen from "../screens/admin/utilities";
import UtilityDetails from "../screens/my-city-hall/utilities/utility-details";
import Welcome from "../screens/my-city-hall/welcome";
import LinkLicence from "../screens/my-city-hall/licences/link-licence";
import UtilitiesDetailScreen from "../screens/admin/utilities-detail";

interface Route {
  authType?: string;
  component: React.ComponentType<any>;
  hideBreadcrumbs?: boolean;
  hideHeader?: boolean;
  hideSidebar?: boolean;
  isExact: boolean;
  path: string;
  title: string;
  skipAuth?: boolean;
  codePath?: string;
  hideIcons?: boolean;
  hideBreadCrumbs?: boolean;
  icon?: string;
}

interface Routes {
  [key: string]: Route;
}

export const routes: Routes = {
  DEFAULT: {
    authType: USER_AUTH_TYPE,
    component: LandingPage,
    hideBreadcrumbs: true,
    isExact: true,
    path: "/",
    title: "Home",
  },
  REGISTER: {
    path: "/register",
    title: "Registration",
    isExact: true,
    component: Register,
    skipAuth: true,
    hideHeader: true,
  },
  RESET_PASSWORD: {
    path: "/reset-password",
    title: "Reset Password",
    isExact: true,
    component: ResetPasswordScreen,
    skipAuth: true,
    hideHeader: true,
  },
  TWO_FACTOR_AUTHENTICATION: {
    path: "/two-factor-authentication",
    title: "Two Factor Authentication",
    isExact: true,
    hideHeader: true,
    component: TwoFactorAuthentication,
    skipAuth: true,
  },
  BUSINESS_LICENCE_CREATION: {
    authType: USER_AUTH_TYPE,
    path: "/licences/business-licence/create",
    title: "Business Licence",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  CHECKOUT: {
    authType: USER_AUTH_TYPE,
    path: "/checkout",
    title: "Checkout",
    isExact: false,
    hideHeader: false,
    component: Checkout,
  },
  CREDIT_CARD_ADD_EDIT: {
    authType: USER_AUTH_TYPE,
    path: "/cc-add",
    title: "Credit card add/edit",
    isExact: false,
    hideHeader: false,
    component: CreditCardAddEdit,
  },
  INVOICE_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/invoices/:id",
    title: "Invoice Details",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  LICENCES: {
    authType: USER_AUTH_TYPE,
    path: "/portal",
    title: "Licences",
    isExact: false,
    component: LandingPage,
  },
  LICENSE_CREATION: {
    authType: USER_AUTH_TYPE,
    path: "/business-licence/form/:id",
    title: "Create licence",
    isExact: false,
    hideHeader: false,
    component: BusinessLicenseCreation,
  },
  LICENSE_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/business-licence/:id",
    title: "Licence Details",
    isExact: false,
    hideHeader: false,
    component: LicenseDetails,
  },
  LINK_LICENCE: {
    authType: USER_AUTH_TYPE,
    path: "/link-licence",
    codePath: "/link-licence",
    title: "Link a licence",
    isExact: false,
    hideHeader: false,
    component: LinkLicence,
  },
  LINK_UTILITY: {
    authType: USER_AUTH_TYPE,
    path: "/utility/link",
    title: "Link a licence",
    isExact: false,
    hideHeader: false,
    component: LinkUtility,
  },
  UTILITY_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/utility/:id",
    codePath: "/utility/",
    title: "Utility details",
    isExact: false,
    hideHeader: false,
    component: UtilityDetails,
  },
  PRIVACY_POLICY: {
    path: "/privacy-policy",
    title: "Privacy Policy Agreement",
    isExact: false,
    component: MissingScreen,
    skipAuth: true,
    hideIcons: true,
    hideHeader: true,
  },
  PROPERTY_TAX: {
    authType: USER_AUTH_TYPE,
    path: "/property-tax/:id",
    title: "Property tax",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  PROPERTY_TAXES: {
    authType: USER_AUTH_TYPE,
    path: "/property-tax",
    title: "Property taxes",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  PRIVACY_POLICY_RECONFIRM: {
    path: "/privacy-policy-reconfirm",
    title: "Privacy Policy Reconfirmation",
    isExact: false,
    hideBreadCrumbs: true,
    component: MissingScreen,
    skipAuth: true,
    hideIcons: true,
    hideHeader: true,
  },
  TERMS_OF_SERVICE: {
    authType: USER_AUTH_TYPE,
    path: "/terms-of-service",
    title: "Terms of Service",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  TRANSACTION_AND_INVOICE_SUMMARY: {
    authType: USER_AUTH_TYPE,
    path: "/transactions-invoices",
    title: "Transactions & Invoices",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  TRANSACTION_DETAILS: {
    authType: USER_AUTH_TYPE,
    path: "/transactions/:id",
    title: "Transaction Details",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  UTILITY: {
    authType: USER_AUTH_TYPE,
    path: "/utilities/:id",
    title: "Utility",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  UTILITIES: {
    authType: USER_AUTH_TYPE,
    path: "/utilities",
    title: "Utilities",
    isExact: false,
    hideHeader: false,
    component: MissingScreen,
  },
  WELCOME: {
    authType: USER_AUTH_TYPE,
    path: "/welcome",
    title: "Welcome page",
    isExact: false,
    hideHeader: false,
    component: Welcome,
  },
  ADMIN_UTILITIES: {
    component: UtilitiesScreen,
    authType: ADMIN_AUTH_TYPE,
    icon: "water_damage",
    isExact: true,
    path: "/admin/utilities",
    title: "Utilities",
  },
  ADMIN_UTILITY_DETAIL: {
    component: UtilitiesDetailScreen,
    authType: ADMIN_AUTH_TYPE,
    isExact: true,
    path: "/admin/utilities/:id",
    title: "Utility Detail",
  },
  LICENCES_DETAIL: {
    component: LicenseDetail,
    authType: ADMIN_AUTH_TYPE,
    icon: "fas fa-chart-bar",
    isExact: true,
    path: "/admin/:status/:id",
    title: "Licences and permits",
  },
  LICENCES_INPROGRESS: {
    authType: ADMIN_AUTH_TYPE,
    component: Inprogress,
    icon: "find_in_page",
    isExact: true,
    path: "/admin/licences-underreview",
    title: "Under review",
  },
  LICENCES_ACTIVE: {
    authType: ADMIN_AUTH_TYPE,
    component: Active,
    icon: "approval",
    isExact: true,
    path: "/admin/licences-active",
    title: "Active",
  },
  LICENCES_INACTIVE: {
    authType: ADMIN_AUTH_TYPE,
    component: Inactive,
    icon: "cancel",
    isExact: true,
    path: "/admin/licences-inactive",
    title: "Inactive",
  },
  LOGIN: {
    component: LoginScreen,
    hideBreadcrumbs: true,
    hideHeader: true,
    hideSidebar: true,
    isExact: true,
    path: "/login",
    title: "Index",
    skipAuth: true,
  },
  SEND_RESET_LINK: {
    component: SendResetLink,
    hideBreadcrumbs: true,
    hideHeader: true,
    hideSidebar: true,
    isExact: true,
    path: "/send-reset-link",
    title: "Reset Password",
    skipAuth: true,
  },
  ADMINS: {
    authType: ADMIN_AUTH_TYPE,
    component: Admins,
    icon: "supervisor_account",
    isExact: true,
    path: "/admin/admins",
    title: "Admins",
  },
  USERS: {
    authType: ADMIN_AUTH_TYPE,
    component: Users,
    icon: "account_circle",
    isExact: true,
    path: "/admin/users",
    title: "Users",
  },
  DASHBOARD: {
    authType: ADMIN_AUTH_TYPE,
    component: Dashboard,
    icon: "dynamic_feed",
    isExact: true,
    path: "/admin",
    title: "Action Centre",
  },
};

export const getAuthType = (path: string) => {
  return path.split("/")[1] === "admin" ? ADMIN_AUTH_TYPE : USER_AUTH_TYPE;
};

export const getScreenOptions = (path: string) => {
  const result = Object.entries(routes).find(
    ([_, screen]) => screen.path === path
  );
  return result ? result[1] : {};
};
