import React from "react";
import LoadingGif from "../../../../assets/gifs/logo-loading-transparent.gif";
import styled from "styled-components";
import { primary } from "../../../../mui-theme/theme";

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  color: ${primary};
  min-height: ${({ minHeight }) => minHeight || null};
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img.attrs(() => ({
  src: LoadingGif,
  alt: "Empty Table",
}))`
  width: 7rem;
`;

const LoadingText = styled.h2`
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

const LoadingLogo = ({ minHeight }) => {
  return (
    <LoadingContainer minHeight={minHeight}>
      <Logo />
      <LoadingText>Loading...</LoadingText>
    </LoadingContainer>
  );
};

export default LoadingLogo;
