import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import Typography from "../../../../components/ui-kit/typography";
import IconBase from "../../../../components/ui-kit/IconBase";
import DocumentCard from "../../../../components/ui-kit/document-card";

export default function DocumentTab({
  documents,
  handleDownloadDoc,
  handleFileUploadModalOpen,
  uploadingDocument,
  editing = false,
}) {
  return (
    <>
      <Grid container spacing={0} style={{ minWidth: "984px" }}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              marginBottom: "2rem",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={400}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Documents
            </Typography>
            {editing && (
              <Button
                variant={"outlined"}
                startIcon={
                  uploadingDocument ? (
                    <CircularProgress color={"inherit"} size={20} />
                  ) : (
                    <IconBase wght={700} iconName={"upload"} />
                  )
                }
                onClick={handleFileUploadModalOpen}
              >
                upload
              </Button>
            )}
          </div>
        </Grid>
        <Grid container spacing={2}>
          {documents.map((item, index) => (
            <Grid item xs={3} key={index}>
              <DocumentCard
                title={item.originalName}
                style={{ marginBottom: "1rem" }}
                onClick={() => {
                  handleDownloadDoc(item.attachmentId);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
