import * as schemas from "../../BusinessLicenceStepYupSchemas";
import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import InfoButton from "../../../../../../components/ui-kit/InfoButton";
import Typography from "../../../../../../components/ui-kit/typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextArea from "../../../../../../components/ui-kit/TextArea";
import { CheckboxCard } from "../../../../../../components/ui-kit/Checkbox";
import Button from "@mui/material/Button";
import Alert from "../../../../../../components/ui-kit/Alert";
import { formatCurrency } from "../../../../../../../utilities";
import MaskedTextField from "../../../../../../components/ui-kit/masked-text-field";
import { EDIT, prices } from "../../../../../../../core/constants/licences";
import classes from "./index.module.scss";
import { InfoRounded } from "@mui/icons-material";

const initialValues = {
  businessName: "",
  businessDescription: "",
  businessType: "",
  hasParking: false,
  homeBased: false,
  include: false,
  isOwner: false,
  isResident: true,
  numVehicles: "",
  parkingPlaceType: "",
  propertyOwnerName: "",
  purpose: "",
  truckSize: "",
};

const validation = schemas.step1Schema;

/**
 * Step 1 of the business licence creation form
 * @param onSubmit
 * @param formId
 * @param submittedFormValues
 * @param setBannerName
 * @returns {JSX.Element}
 * @constructor
 */
const Step1 = ({
  onSubmit,
  submittedFormValues,
  setBannerName,
  mode,
  onCancel,
}) => {
  const [onChangeValidation, setOnChangeValidation] = useState(false);
  const [initValues, setInitValues] = useState(submittedFormValues);
  const theme = useTheme();

  /**
   * Memo version of indicator for checking if prorated fee was applied
   * @type {boolean}
   */
  const isDiscountApplied = useMemo(
    () =>
      submittedFormValues?.balanceOwing !== prices.localBusiness &&
      submittedFormValues?.balanceOwing !== prices.nonLocalBusiness,
    [submittedFormValues?.balanceOwing]
  );

  //Handles step submission
  const handleSubmit = (values) => {
    let valuesToSubmit = values;
    //Unset values
    if (!values.isResident) {
      valuesToSubmit.homeBased = false;
      valuesToSubmit.hasParking = false;
      valuesToSubmit.isOwner = false;
    }
    onSubmit({ ...values, balanceOwing: submittedFormValues?.balanceOwing });
  };

  /**
   * Indicates the amount to be shown on the form for BL
   * @param values
   * @returns {number}
   */
  const calculatePrice = (values) => {
    if (isDiscountApplied) {
      let price = prices.discountedLocalBusiness;

      if (!values.isResident) {
        return prices.discountedNonLocalBusiness;
      }
      return price;
    }
    let price = prices.localBusiness;

    if (!values.isResident) {
      return prices.nonLocalBusiness;
    }
    return price;
  };

  useEffect(() => {
    setInitValues({
      businessName:
        submittedFormValues?.businessName || initialValues.businessName,
      businessDescription:
        submittedFormValues?.businessDescription ||
        initialValues.businessDescription,
      //businessType: newType,
      homeBased: submittedFormValues?.homeBased || initialValues.homeBased,
      include: submittedFormValues?.include || initialValues.include,
      isOwner: submittedFormValues?.isOwner || initialValues.isOwner,
      isResident:
        submittedFormValues?.isResident ?? initialValues.isResident ?? true,
      numVehicles:
        submittedFormValues?.numVehicles || initialValues.numVehicles,
      parkingPlaceType:
        submittedFormValues?.parkingPlaceType || initialValues.parkingPlaceType,
      propertyOwnerName:
        submittedFormValues?.propertyOwnerName ||
        initialValues.propertyOwnerName,
      purpose: submittedFormValues?.purpose || initialValues.purpose,
      truckSize: submittedFormValues?.truckSize || initialValues.truckSize,
      hasParking: submittedFormValues?.hasParking || initialValues.hasParking,
    });
  }, [submittedFormValues]);
  return (
    <div>
      <Formik
        initialValues={initValues}
        validationSchema={validation}
        onSubmit={handleSubmit}
        validateOnChange={onChangeValidation}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({
          errors,
          validateForm,
          handleBlur,
          handleChange: formikChange,
          touched,
          values,
        }) => {
          const handleChange = (e) => {
            formikChange(e);
          };
          return (
            <Form noValidate>
              <Spacer />
              <InfoButton
                onClick={() => {
                  window.open(
                    "https://lacombe.ca/485/Business-Licenses",
                    "_blank"
                  );
                }}
                title={"What do I need for this form?"}
              />
              <Spacer />
              <Typography variant={"h4"} fontWeight={"300"}>
                General information
              </Typography>
              <Spacer amount={0.5} />
              <Typography
                variant={"body1"}
                color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
                fontWeight={"400"}
              >
                Let’s start by creating your business file. We’ll need to know
                the very basics about your business.
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Spacer amount={1} />
                  <Typography variant={"overline"}>BUSINESS DETAILS</Typography>
                  <TextField
                    label="Business name"
                    fullWidth
                    name="businessName"
                    id="businessName"
                    onChange={(e) => {
                      handleChange(e);
                      setBannerName(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.businessName}
                    error={Boolean(errors.businessName)}
                    helperText={errors.businessName}
                    touched={touched.businessName ? "true" : "false"}
                  />
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*  <HighlightedAutoComplete*/}
                {/*    label="Type of business"*/}
                {/*    fullWidth*/}
                {/*    name="businessType"*/}
                {/*    onChange={(e, value) => {*/}
                {/*      //console.log(value);*/}
                {/*      setFieldValue("businessType", value);*/}
                {/*    }}*/}
                {/*    onBlur={handleBlur}*/}
                {/*    value={values.businessType}*/}
                {/*    error={Boolean(errors.businessType)}*/}
                {/*    helperText={errors.businessType}*/}
                {/*    touched={touched.businessType ? "true" : "false"}*/}
                {/*    options={categories}*/}
                {/*  />*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                  <TextArea
                    label="Describe business operation"
                    fullWidth
                    name="businessDescription"
                    id="businessDescription"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessDescription}
                    error={Boolean(errors.businessDescription)}
                    helperText={errors.businessDescription}
                    touched={touched.businessDescription ? "true" : "false"}
                    maxLength={140}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Spacer />
                  <Typography variant={"overline"}>Residency status</Typography>
                  <CheckboxCard
                    label="Is your business located in the City of Lacombe?"
                    cardVariant="highlight"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isResident}
                    error={errors.isResident}
                    name="isResident"
                  />
                </Grid>
                {values.isResident ? (
                  <IsResidencySection
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                  />
                ) : null}
                <Grid item xs={12}>
                  <CheckboxCard
                    label={
                      <p>
                        Include my business in the Online Business <br />
                        Directory
                      </p>
                    }
                    cardVariant="highlight"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.include}
                    name="include"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Spacer />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant={"body1"}
                      color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                    >
                      Yearly fee
                    </Typography>
                    {isDiscountApplied ? (
                      <div className={classes.price_container}>
                        <p>
                          {formatCurrency(
                            calculatePrice(values) ===
                              prices.discountedLocalBusiness
                              ? prices.localBusiness
                              : prices.nonLocalBusiness
                          )}
                        </p>
                        <Typography
                          variant={"h4"}
                          color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                        >
                          {formatCurrency(calculatePrice(values))}
                        </Typography>
                      </div>
                    ) : (
                      <Typography
                        variant={"h4"}
                        color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                      >
                        {formatCurrency(calculatePrice(values))}
                      </Typography>
                    )}
                  </div>
                </Grid>

                {isDiscountApplied && (
                  <Grid item xs={12}>
                    <div className={"discount-alert-container"}>
                      <InfoRounded />
                      <p>
                        Applications issued from July 1st to December 31st will
                        receive a one-time 50% discount off the fee.
                      </p>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Spacer />
                  <Button
                    fullWidth
                    size={"large"}
                    variant="contained"
                    onClick={async () => {
                      try {
                        const validationResults = await validateForm();
                        const err = Object.keys(validationResults);
                        if (err.length) {
                          const input = document.querySelector(`#${err[0]}`);
                          input.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start",
                          });
                        }
                        setBannerName(values.businessName);
                        setOnChangeValidation(true);
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    type="submit"
                  >
                    {mode === EDIT ? "Save" : "Continue"}
                  </Button>
                  {mode === EDIT ? (
                    <>
                      <Spacer />
                      <Button
                        fullWidth
                        size={"large"}
                        variant="outlined"
                        type="cancel"
                        onClick={() => {
                          onCancel("General");
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const IsResidencySection = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Spacer />
        <Typography variant={"overline"}>business location</Typography>
        <CheckboxCard
          label="Home-based business"
          cardVariant="highlight"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.homeBased}
          name="homeBased"
        />
      </Grid>
      {values.homeBased ? (
        <HomeBased
          values={values}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
        />
      ) : null}
    </>
  );
};

// Flag for indicating if home parking information form should be shown or not
const showHomeParkingInformation = false;

const HomeBased = ({ errors, handleBlur, handleChange, touched, values }) => {
  const theme = useTheme();

  /**
   * Memo version of indicator for showing home-parking section
   * @type {boolean}
   */
  const showAtHomeParkingSection = useMemo(() => {
    return (
      (showHomeParkingInformation && !!values.hasParking) ||
      (!!values.hasParking && !!values.numVehicles && !!values.parkingPlaceType)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.hasParking]);

  return (
    <>
      <Grid item xs={12}>
        <CheckboxCard
          label="Business has parking for customers"
          cardVariant="highlight"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.hasParking}
          name="hasParking"
        />
      </Grid>
      {showAtHomeParkingSection ? (
        <>
          <Grid item xs={12}>
            <Spacer />
            <Typography variant={"body1"} fontWeight={"700"}>
              At-home parking
            </Typography>
            <Typography
              variant={"body2"}
              color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
            >
              Please state the number of vehicles or equipment to be used for
              the proposed business, and where it will be parked (if truck,
              please indicate size)
              <br />
              <br />
              On-street parking doesn't count as parking capacity.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MaskedTextField
              label="Number of vehicles"
              type={"NUMBER"}
              initialValue={values.numVehicles}
              fullWidth
              name="numVehicles"
              id="numVehicles"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.numVehicles}
              error={Boolean(errors.numVehicles)}
              helperText={errors.numVehicles}
              touched={touched.numVehicles ? "true" : "false"}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              label="Place of parking"
              fullWidth
              name="parkingPlaceType"
              id="parkingPlaceType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.parkingPlaceType}
              error={Boolean(errors.parkingPlaceType)}
              touched={touched.parkingPlaceType ? "true" : "false"}
              helperText={errors.parkingPlaceType}
              maxLength={140}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              optional={"true"}
              label="Truck size (optional)"
              fullWidth
              name="truckSize"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.truckSize}
              error={Boolean(errors.truckSize)}
              helperText={errors.truckSize}
              touched={touched.truckSize ? "true" : "false"}
              inputProps={{ maxLength: 18 }}
            />
          </Grid>
        </>
      ) : null}
      <Grid item xs={12}>
        <Spacer />
        <Typography variant={"body1"} fontWeight={"700"}>
          Property owner
        </Typography>
        <Typography
          variant={"body2"}
          color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
        >
          If you are not the registered owner of the property or trailer park
          where you plan to operate, please provide their name.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CheckboxCard
          label="I'm the property owner"
          cardVariant="highlight"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.isOwner}
          name="isOwner"
        />
      </Grid>
      {!values.isOwner ? (
        <>
          <Grid item xs={12}>
            <TextField
              label="Property owner"
              fullWidth
              name="propertyOwnerName"
              id="propertyOwnerName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.propertyOwnerName}
              error={Boolean(errors.propertyOwnerName)}
              helperText={errors.propertyOwnerName}
              touched={touched.propertyOwnerName ? "true" : "false"}
              inputProps={{ maxLength: 35 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert
              fill={1}
              variant={"info"}
              title={"Property owner permission"}
              body={
                "Please remember to include a letter from the " +
                "business owner on step 4 granting you permission " +
                "to use the property for the proposed business."
              }
            />
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default Step1;
