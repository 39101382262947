import { useState, useEffect } from "react";
import { routes } from "../routes/routesData";
import { getGlobal } from "../../utilities";

export const DIVIDER = "DIVIDER";

const folders = {
  LICENCES: {
    icon: "approval",
    title: "Licences",
    hideSidebar: false,
    subItems: [
      // routes.LICENCES_OVERVIEW,
      // DIVIDER,
      routes.LICENCES_INPROGRESS,
      DIVIDER,
      routes.LICENCES_ACTIVE,
      routes.LICENCES_INACTIVE,
      DIVIDER,
      // routes.LICENCES_INVOICES,  // Uncomment when the page was finished
    ],
  },
  USER_MANAGEMENT: {
    icon: "group",
    title: "People",
    subItems: [routes.USERS, routes.ADMINS],
  },
};

export default function useSidebarSettings() {
  if (getGlobal("CCFG_ACCOUNTS_UTILITIES") !== "true") {
    routes.ADMIN_UTILITIES.hideSidebar = true;
  }
  if (getGlobal("CCFG_LICENCES_BUSINESS") !== "true") {
    folders.LICENCES.hideSidebar = true;
  }
  const [sidebarSettings] = useState({
    dashboard: routes.DASHBOARD,
    utilities: [routes.ADMIN_UTILITIES],
    cityServices: [folders.LICENCES],
    managementServices: [folders.USER_MANAGEMENT],
  });

  const [sidebarLoading, setSidebarLoading] = useState(true);

  /* Disabled tabs */
  // folders.ACCOUNTING.disabled = true;
  // routes.EXPRESS_CHECKOUT.disabled = true;

  useEffect(() => {
    setSidebarLoading(false);
  }, [sidebarSettings]);

  return [sidebarSettings, sidebarLoading];
}
