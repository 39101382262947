import React from "react";
import tClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import classes from "./index.module.scss";
import { Skeleton } from "@mui/material";
import ServiceItem from "./service-item";

interface IService {
  label: string;
  value: string;
  originalValue?: string;
}

interface IServiceRow {
  title: string;
  services: IService[];
  loading: boolean;
}

const ServiceRow = ({
  title = "",
  services = [],
  loading = false,
}: IServiceRow) => {
  return (
    <div className={classes.service_row_container}>
      {!loading ? (
        <>
          <p className={`${tClasses.h3} ${tClasses.uppercase}`}>{title}</p>
          <div className={classes.row}>
            {services.map((service) => (
              <ServiceItem service={service} />
            ))}
          </div>
        </>
      ) : (
        <Skeleton height={80} width={140} />
      )}
      <div className={"divider"}></div>
    </div>
  );
};

export default ServiceRow;
