import axios from "axios";
import config from "./_config";
import { manageSession } from "../../utilities/manageSession";
import jwt_decode from "jwt-decode";

const base = axios.create({
  baseURL: config.baseURL + "/address",
  crossDomain: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const save_address = async (values, addressType, newUserId) => {
  const userDataInCookie = manageSession.getSessionInfo();
  const userId = newUserId || userDataInCookie?.id;
  const dataToSubmit = {
    userId,
    cityId: "dc9a56b4-f11c-4d71-89df-91ac898b2ee6",
    addressType: addressType,
    address1: values.address1,
    unit: values.unit,
    postalCode: values.code,
    isDefault: values.defaultAddress,
  };

  try {
    base.defaults.headers[
      "Authorization"
    ] = `Bearer ${userDataInCookie?.userToken}`;
    const response = await base.post("/create", dataToSubmit);
    return response.data;
  } catch (error) {}
};

export const get_addresses = async () => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded = jwt_decode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/${decoded.id}`);
    return response.data;
  } catch (error) {}
};

export const delete_address = async (addressID) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const dataToSubmit = {
    cityId: "dc9a56b4-f11c-4d71-89df-91ac898b2ee6",
    id: addressID,
  };

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    return await base.post("/delete", dataToSubmit);
  } catch (error) {}
};

export const update_address = async (addressID, updatingData) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded = jwt_decode(token);

  const dataToSubmit = {
    cityId: "dc9a56b4-f11c-4d71-89df-91ac898b2ee6",
    userId: decoded.id,
    addressId: addressID,
    postalCode: updatingData.code,
    isDefault: updatingData.defaultAddress,
  };
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post("modify", dataToSubmit);
    return response;
  } catch (error) {}
};

export const set_default_address = async (addressID) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded = jwt_decode(token);
  const dataToSubmit = {
    cityId: "dc9a56b4-f11c-4d71-89df-91ac898b2ee6",
    id: addressID,
    userId: decoded.id,
  };
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post("/default", dataToSubmit);

    return response;
  } catch (error) {}
};
