import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import TextField from "../../../../../../components/ui-kit/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import ModalTitle from "../../../../../../components/ui-kit/modal-title";
import { CheckboxCard } from "../../../../../../components/ui-kit/Checkbox";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import MaskedTextField from "../../../../../../components/ui-kit/masked-text-field";
import { contactValidators } from "../../validators";
import IconBase from "../../../../../../components/ui-kit/IconBase";
import Spacer from "../../../../../../components/ui-kit/Spacer";

interface IFormModal {
  open: boolean;
  handleClose: any;
  onSubmit: any;
  values: any;
}

const ContactFormModal = ({
  open = false,
  handleClose = () => {},
  onSubmit,
  values,
}: IFormModal) => {
  const formikRef = useRef();
  const theme = useTheme();
  const [initialValues, setInitialValues] = useState({}) as any;

  useEffect(() => {
    setInitialValues({
      contacts: values,
    });
  }, [values]);

  return (
    <Formik
      //@ts-ignore
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={contactValidators}
      validateOnChange={true}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        resetForm,
        dirty,
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
        isValid,
      }) => {
        return (
          <Dialog open={open} fullWidth={true}>
            <ModalTitle
              title={"Other Contacts"}
              handleClose={() => {
                handleClose();
                resetForm();
              }}
            />
            <DialogContent>
              <Form noValidate={true}>
                <p className={typographyClasses.overline_bold}>
                  Add other contacts
                </p>
                <FieldArray
                  name={"contacts"}
                  render={({ remove }) => {
                    return (
                      <>
                        {values.contacts.map((contact: any, index: number) => (
                          <Grid container spacing={1}>
                            <Grid xs={6}>
                              <TextField
                                name={`contacts.${index}.first_name`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.contacts &&
                                    //@ts-ignore
                                    errors["contacts"][index] &&
                                    //@ts-ignore
                                    touched.contacts &&
                                    //@ts-ignore
                                    touched.contacts[index]
                                    ? //@ts-ignore
                                      errors["contacts"][index].first_name
                                    : null
                                )}
                                value={contact.first_name}
                                onChange={handleChange}
                                label={"Firstname"}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={6}>
                              <TextField
                                name={`contacts.${index}.last_name`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.contacts &&
                                    //@ts-ignore
                                    errors["contacts"][index] &&
                                    //@ts-ignore
                                    touched.contacts &&
                                    //@ts-ignore
                                    touched.contacts[index]
                                    ? //@ts-ignore
                                      errors["contacts"][index].last_name
                                    : null
                                )}
                                value={contact.last_name}
                                onChange={handleChange}
                                label={"Lastname"}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12}>
                              <TextField
                                name={`contacts.${index}.email`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.contacts &&
                                    //@ts-ignore
                                    errors["contacts"][index] &&
                                    //@ts-ignore
                                    touched.contacts &&
                                    //@ts-ignore
                                    touched.contacts[index]
                                    ? //@ts-ignore
                                      errors["contacts"][index].email
                                    : null
                                )}
                                value={contact.email}
                                onChange={handleChange}
                                label={"Email address"}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12}>
                              <MaskedTextField
                                type={"PHONE"}
                                name={`contacts.${index}.phone`}
                                error={Boolean(
                                  //@ts-ignore
                                  errors.contacts &&
                                    //@ts-ignore
                                    errors["contacts"][index] &&
                                    //@ts-ignore
                                    touched.contacts &&
                                    //@ts-ignore
                                    touched.contacts[index]
                                    ? //@ts-ignore
                                      errors["contacts"][index].phone
                                    : null
                                )}
                                value={contact.phone}
                                onChange={handleChange}
                                label={"Phone number (optional)"}
                                optional={true}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={4}>
                              <CheckboxCard
                                value={contact.e_bill}
                                onChange={handleChange}
                                label={"Share E-bill"}
                                name={`contacts.${index}.e_bill`}
                              />
                            </Grid>
                            {values?.contacts?.length > 1 ? (
                              <>
                                <Grid xs={8} className={"flex_end_container"}>
                                  <Button
                                    size={"small"}
                                    variant={"outlined"}
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                                <Grid xs={12}>
                                  <Spacer />
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        ))}
                        <Spacer />
                        <Button
                          size={"small"}
                          variant={"outlined"}
                          startIcon={<IconBase iconName={"add"} size={20} color={theme.palette.primary[200]}/>}
                          onClick={ () => {
                            setFieldValue("contacts", [
                              ...values.contacts,
                              {
                                first_name: "",
                                last_name: "",
                                phone: "",
                                email: "",
                                e_bill: false,
                              },
                            ]);
                          }}
                        >
                          Add contact
                        </Button>
                      </>
                    );
                  }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty}
                size={"small"}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  if (formikRef.current) {
                    //@ts-ignore
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ContactFormModal;
