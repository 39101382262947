import React from "react";
import styled from "@emotion/styled";

//language=SCSS prefix=*{ suffix=}
const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 345px;
  width: 100%;
`;

/**
 * The third column for a triple column layout
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function RightColumn({
  children,
}: {
  children: React.ReactNode;
}) {
  return <RightContainer>{children}</RightContainer>;
}
