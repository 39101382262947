import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTheme } from "@mui/material";
import Dialog from "../components/ui-kit/Dialog";
import { useLocation } from "react-router-dom";

export const EditingContext = createContext({
  isEditing: false,
  setIsEditing: (prev: any) => {},
  handleClickWrapper: (func: any) => {},
  setModalData: (state: any) => {},
});

/**
 * This provider manages editing mode in the admin application.
 * Wrapping functions with handleClickWrapper ensures the user
 * receives a warning before leaving a page while in editing mode.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const EditingProvider = ({ children }: any) => {
  const theme = useTheme();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "Discarding Changes",
    body: "All the changes will be discarded and the licence will remain unchanged.",
    actionButtonText: "Discard Changes",
  });
  const functionToExecute = useRef(null);

  /**
   * Wrap functions that may cause data loss when activated while in
   * editing mode
   * @type {(function(*): void)|*}
   */
  const handleClickWrapper = useCallback(
    (func: any) => {
      if (isEditing) {
        setIsModalOpen(true);
        // @ts-ignore
        functionToExecute.current = () => {
          func();
          setIsEditing(false);
        };
      } else {
        func();
      }
    },
    [isEditing]
  );

  useEffect(() => {
    setIsEditing(false);
  }, [location.pathname]);

  const context = useMemo(
    () => ({
      isEditing,
      setIsEditing,
      handleClickWrapper,
      setModalData,
    }),
    [isEditing, handleClickWrapper]
  );

  return (
    <EditingContext.Provider value={context}>
      {children}
      <Dialog
        title={modalData.title}
        body={modalData.body}
        open={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        buttonOneText={"Back"}
        handleButtonOne={() => {
          setIsModalOpen(false);
        }}
        buttonTwoText={modalData.actionButtonText}
        handleButtonTwo={() => {
          // @ts-ignore
          functionToExecute.current();
          setIsEditing(false);
          setIsModalOpen(false);
        }}
        buttonTwoColor={() => {
          // @ts-ignore
          return theme.palette.nonPalette.RED;
        }}
        buttonOneColor={undefined}
      />
    </EditingContext.Provider>
  );
};
