import React from "react";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { PRIMARY_50 } from "../../../mui-theme/theme";
import ActionListItem from "./ActionListItem";

const ActionList = ({
  data,
  onClickRow,
  loadAdmins,
  background,
  emptyText,
  user,
}) => {
  const theme = useTheme();

  return (
    <List
      background={background}
      disablePadding
      className={"styled-list"}
      style={{ "--background": `${background || PRIMARY_50}` }}
    >
      {data.length > 0 ? (
        data.map((action) => (
          <ActionListItem
            key={action.id}
            user={user}
            itemKey={action.id}
            action={action}
            onClickRow={() => onClickRow(action)}
            loadAdmins={loadAdmins}
          />
        ))
      ) : (
        <div style={{ display: "flex", width: "100%" }}>
          <Typography
            variant={"body2"}
            color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
            style={{
              width: "175px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "4rem",
            }}
          >
            {emptyText}
          </Typography>
        </div>
      )}
    </List>
  );
};

export default ActionList;
