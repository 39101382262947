import React from "react";
import Button from "@mui/material/Button";
import Typography from "../ui-kit/typography";
import styled from "@emotion/styled";
import Modal from "../ui-kit/Modal";
import { Box, useTheme } from "@mui/material";
import { addEllipses } from "../../../utilities";

const ItemModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const BackButton = styled(Button)``;

/**
 * Modal that confirms the deletion of a file
 * @param modalOpen
 * @param deleteFile
 * @param handleModalClose
 * @param data - {name, id}
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function ModalDeleteFile({
  modalOpen,
  deleteFile,
  handleModalClose,
  data,
}) {
  const theme = useTheme();

  if (!data) return null;
  const { name, id } = data;

  return (
    <ItemModal open={modalOpen} fullWidth={false} onClose={handleModalClose}>
      <Typography variant={"body1"} fontWeight={"700"}>
        Delete file
      </Typography>
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        Are you sure you want to delete this file?
      </Typography>
      <Typography variant={"body2"}>{addEllipses(name, 28)}</Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <BackButton
          variant={"text"}
          onClick={() => {
            handleModalClose();
          }}
        >
          <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
            Cancel
          </Typography>
        </BackButton>
        <Button
          variant={"text"}
          sx={{
            color: theme.palette.nonPalette.RED,
            ".MuiTouchRipple-child": {
              color: `${theme.palette.nonPalette.RED} !important`,
            },
            "&:hover": {
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
              ".MuiTouchRipple-child": {
                color: theme.palette.nonPalette.RED,
              },
            },
            "&:active": {
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
              ".MuiTouchRipple-child": {
                color: theme.palette.nonPalette.RED,
              },
            },
          }}
          onClick={() => {
            deleteFile(id);
            handleModalClose();
          }}
        >
          <Typography color={"red"} fontWeight={"700"}>
            Delete
          </Typography>
        </Button>
      </Box>
    </ItemModal>
  );
}
