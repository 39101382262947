import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import Chip from "./mch/Chip";

const Container = styled.div``;

const StyledChip = styled(Chip)``;

const childId = "child-component-id";

/**
 * This component is used to display an input component once a user clicks on the Chip.
 * @param name
 * @param optional
 * @param label
 * @param variant
 * @param iconName
 * @param component
 * @param inputProps
 * @param onChange
 * @param touched
 * @param error
 * @param onBlur
 * @param value
 * @param initialValue
 * @param type
 * @param helperText
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChipInput({
  name,
  optional,
  label,
  variant = "dotted",
  iconName = "add",
  component: Component,
  inputProps,
  onChange,
  touched,
  error,
  onBlur,
  value,
  initialValue,
  helperText,
  type = null,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setOpen(true);
    }
  }, [initialValue]);

  /**
   * Memo version of child component id
   * @type {string}
   */
  const childComponentId = useMemo(() => childId + name, [name]);

  /**
   * Memo version of callback function for toggling [open] in inner state for showing child component
   * and passing focus to it
   * @type {(function(): void)|*}
   */
  const handleShowingChildComponent = useCallback(() => {
    setOpen(true);
    setTimeout(() => {
      const _childComponent = document.getElementById(childComponentId);
      _childComponent.focus();
    }, 200);
  }, [childComponentId]);

  return (
    <Container>
      {open ? (
        <Component
          id={childComponentId}
          type={type}
          optional={optional}
          name={name}
          value={value}
          inputProps={inputProps}
          fullWidth
          label={label}
          onChange={onChange}
          touched={touched}
          error={error}
          onBlur={onBlur}
          initialValue={initialValue}
          helperText={helperText}
        />
      ) : (
        <StyledChip
          iconHasPadding={true}
          variant={variant}
          iconName={iconName}
          label={label}
          onClick={handleShowingChildComponent}
        />
      )}
    </Container>
  );
}

export function ChipInputErrorFix({
  name,
  optional,
  label,
  variant = "dotted",
  iconName = "add",
  component: Component,
  inputProps,
  onChange,
  touched,
  error,
  onBlur,
  value,
  initialValue,
  type = null,
  helperText,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setOpen(true);
    }
  }, [initialValue]);

  /**
   * Memo version of child component id
   * @type {string}
   */
  const childComponentId = useMemo(() => childId + name, [name]);

  /**
   * Memo version of callback function for toggling [open] in inner state for showing child component
   * and passing focus to it
   * @type {(function(): void)|*}
   */
  const handleShowingChildComponent = useCallback(() => {
    setOpen(true);
    setTimeout(() => {
      const _childComponent = document.getElementById(childComponentId);
      _childComponent.focus();
    }, 200);
  }, [childComponentId]);

  return (
    <Container>
      {open ? (
        <Component
          id={childComponentId}
          type={type}
          optional={optional}
          name={name}
          value={value}
          inputProps={inputProps}
          fullWidth
          label={label}
          onChange={onChange}
          touched={touched}
          error={error}
          onBlur={onBlur}
          initialvalue={initialValue}
          helperText={helperText}
        />
      ) : (
        <StyledChip
          iconHasPadding={true}
          variant={variant}
          iconName={iconName}
          label={label}
          onClick={handleShowingChildComponent}
        />
      )}
    </Container>
  );
}
