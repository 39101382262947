import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { default as Typography } from "@mui/material/Typography";
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import GridWrapper from "../../../components/ui-kit/GridWrapper";
import IconBase from "../../../components/ui-kit/IconBase";
import ActionList from "../../../components/ui-kit/ActionList";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { get_admins } from "../../../../core/apis/admin";
import { get_admin_actions } from "../../../../core/apis/action";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [unassignedData, setUnassignedData] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [doneFilter, setDoneFilter] = useState(false);
  // const [previousQueryOptions, setPreviousQueryOptions] = useState(null);
  const [view, setView] = React.useState("pending");
  const [others, setOthers] = useState([]);
  const [mine, setMine] = useState([]);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const theme = useTheme();

  const { handleError } = useContext(NotificationContext);

  const loadAdmins = async () => {
    try {
      const getAdminsRes = await get_admins();
      const filters = {};
      const adminActions = await get_admin_actions({
        filters,
      });

      let unassignedDataRes = [];
      let todoRes = [];
      let otherRes = [];
      let mineRes = [];

      if (adminActions?.length) {
        unassignedDataRes = [
          ...unassignedDataRes,
          ...adminActions.filter(({ actor }) => !actor.userId),
        ];
        todoRes = [
          ...todoRes,
          ...adminActions.filter(
            ({ actionName, actor }) =>
              ((actionName === "Reviewing Application" ||
                actionName === "Reviewing Changes") &&
                actor.status === "assigned") ||
              ((actionName === "Issue/Decline Licence" ||
                actionName === "Issue/Decline Changes") &&
                actor.status === "assigned")
          ),
        ];
        if (user?.id) {
          otherRes = [
            ...otherRes,
            ...adminActions.filter(
              ({ actor }) =>
                actor.userId &&
                actor.status !== "assigned" &&
                actor.userId !== user?.id
            ),
          ];
          mineRes = [
            ...mineRes,
            ...adminActions.filter(
              ({ actor }) =>
                actor.userId &&
                actor.status !== "assigned" &&
                actor.userId === user?.id
            ),
          ];
        }
      }

      todoRes.forEach((action) => {
        action.assignedAdmin = getAdminsRes.result.find(
          (admin) => admin.id === action.assignedAdminId
        );
      });

      setUnassignedData(unassignedDataRes);
      setTodoData(todoRes);
      setAdmins(getAdminsRes?.result);
      setOthers(otherRes);
      setMine(mineRes);
      return { actionItems: [...unassignedDataRes, ...todoRes] };
    } catch (err) {
      console.error(err);
      console.log(admins);
      console.log(loading);
      handleError("Error while loading data. Please refresh.");
    } finally {
      setLoading(false);
    }
  };

  const onClickRow = (action) => {
    history.push(`/admin/licences/${action?.licenseId}`, action);
  };

  const handleToggle = (event, nextView) => {
    setView(nextView);
  };

  useEffect(() => {
    loadAdmins().then();
    // loadData({});
    // eslint-disable-next-line
  }, [doneFilter]);

  return (
    <div className={"dashboard-screen"}>
      <div className={"dashboard-screen-content"}>
        <GridWrapper gap={3}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              marginBottom: "3rem",
              alignItems: "center",
            }}
          >
            <IconBase
              iconName="dynamic_feed"
              size="42"
              wght="700"
              style={{
                marginRight: "0.5rem",
              }}
            />
            <Typography variant="h3">Action Centre</Typography>
            <IconBase
              iconName="refresh"
              size="42"
              wght="700"
              color={theme.palette.blacks.BLACK_LOW_EMPHASIS}
              hoverColor={theme.palette.primary[500]}
              onClick={() => window.location.reload(false)}
              style={{
                marginLeft: "0.5rem",
              }}
            />
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                padding: "0.5rem",
                gap: "2px",
                border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                borderRadius: "15px",
              }}
            >
              <ToggleButtonGroup value={view} exclusive onChange={handleToggle}>
                <ToggleButton
                  value="pending"
                  style={{
                    marginRight: "3px",
                  }}
                  onClick={() => setDoneFilter(false)}
                >
                  <IconBase
                    iconName={"list"}
                    wght="900"
                    color="inherit"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  />
                  Pending
                </ToggleButton>
                <ToggleButton
                  value="history"
                  onClick={() => setDoneFilter(true)}
                >
                  <IconBase
                    iconName={"history"}
                    wght="700"
                    color="inherit"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  />
                  History
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={3}
              justifyContent="flex-end"
              alignContent="center"
            >
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid>
                <Grid style={{ marginBottom: "1rem" }}>
                  <Typography variant="h4">
                    {!doneFilter ? "Unassigned" : "Others"} (
                    {!doneFilter ? unassignedData.length : others.length}):
                  </Typography>
                </Grid>
                <Grid>
                  <ActionList
                    data={doneFilter ? others : unassignedData}
                    onClickRow={onClickRow}
                    loadAdmins={loadAdmins}
                    useState={unassignedData}
                    emptyText={
                      !doneFilter ? "Nothing waiting. You're up to date!" : ""
                    }
                    user={user}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid>
                <Grid container style={{ marginBottom: "1rem" }}>
                  <Grid item xs={8}>
                    <Typography variant="h4">
                      {!doneFilter ? "In progress" : "Mine"} (
                      {!doneFilter ? todoData.length : mine.length}
                      ):
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    {/* <StyledAvatarGroup max={5}>  Leave this comment for when the component is in scope
                      {admins.map((admin) => (
                        <AdminAvatar
                          id={admin.id}
                          firstName={admin.firstName}
                          lastName={admin.lastName}
                          styles={{
                            background: admin.color,
                            color: "white",
                            width: "2rem",
                            height: "2rem",
                            fontSize: "1rem",
                          }}
                        />
                      ))}
                    </StyledAvatarGroup> */}
                  </Grid>
                </Grid>
                <Grid>
                  <ActionList
                    data={doneFilter ? mine : todoData}
                    onClickRow={onClickRow}
                    loadAdmins={loadAdmins}
                    background={theme.palette.primary[100]}
                    emptyText={
                      !doneFilter
                        ? "Start by assigning tasks from the left column."
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Dashboard;
