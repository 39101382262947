import base from "./axiosInstance";
import { manageSession } from "../../utilities/manageSession";
import jwt_decode from "jwt-decode";

export const userLogout = async () => {
  try {
    const response = await base.delete("/user/logout", {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const forgot_password = async (email: string) => {
  try {
    const data = {
      email: email,
      cityId: "dc9a56b4-f11c-4d71-89df-91ac898b2ee6",
    };
    const response = await base.post(`/user/forgot-password`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reset_password = async (token: string, newPassword: string) => {
  try {
    const response = await base.post("/user/reset-password", {
      token,
      newPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//TODO: create type for the input params as well as the decoded
export const get_users = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwt_decode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/user/${decoded.cityId}/summaries`, {
      params,
    });
    return response.data;
  } catch (error) {}
};

//TODO: create type for input as well as the decoded
export const create_user = async (data: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwt_decode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const postData = {
      cityId: decoded.cityId,
      registeredById: decoded.id,
      ...data,
    };
    return await base.post(`/user/register`, postData);
  } catch (error) {}
};

// TODO: check the url of this api
export const get_users_export = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;

  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get("/userexport", { params });
    return response.data;
  } catch (error) {}
};

export const user_register = async ({
  type,
  name,
  firstName,
  lastName,
  email,
  phone,
  password,
  termIds,
}: any) => {
  try {
    const response = await base.post("/user/register", {
      cityId: "dc9a56b4-f11c-4d71-89df-91ac898b2ee6", //TO DO SWAP THIS OUT WITH PROPER VALUE FROM TOKEN WHEN IT IS READY
      type,
      name,
      firstName: firstName,
      lastName: lastName,
      phone,
      email,
      password,
      termIds,
    });
    return response.data;
  } catch (error) {
    // @ts-ignore
    if (error.response.status === 409) {
      throw new Error(
        "This email address has already been registered. Please use a different email."
      );
    }
    throw error;
  }
};

export const getUser = async (id: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/user/${id}`);
    return response.data;
  } catch (error) {}
};
