import React from "react";
import { css, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useState } from "react";
import ListItem from "../list-item";
import IconBase from "../IconBase";
import { GREY_MEDIUM } from "../../../../mui-theme/theme";
import Avatar from "../AdminAvatar";

const RedHoverButton = styled(Button)(
  ({ theme }) => css`
    .material-symbols-rounded {
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &&:hover {
      background-color: ${theme.palette.nonPalette.RED_BACKGROUND};
      color: ${theme.palette.nonPalette.RED};
      border-color: transparent;

      && .material-symbols-rounded {
        color: ${theme.palette.nonPalette.RED};
      }
    }
  `
);

function ApprovalProgress({
  type,
  isUser,
  id,
  firstName,
  lastName,
  approvalStatus,
  lastUpdated,
  handleApprove,
  handleDecline,
  handleUndo,
  handleUnassign,
  data,
  ...rest
}) {
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  switch (approvalStatus) {
    case "approved":
      return (
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ListItem
            variant="detail"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fullWidth
            startOrnament={
              <Avatar
                // updatable
                title={"admins initials"}
                firstName={firstName}
                lastName={lastName}
                styles={{
                  background: theme.palette.primary[500],
                  color: "white",
                }}
              >
                {firstName ? firstName[0] : null}
                {lastName ? lastName[0] : null}
              </Avatar>
            }
            subLabel={`Approved. ${moment(lastUpdated).format("MMM DD, YYYY")}`}
            subLabelStyles={{
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
            endOrnament={
              <IconBase
                iconName={"check"}
                size={20}
                color={theme.palette.primary[200]}
              />
            }
          />
          {isUser && type === "reviewer" && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 50,
              }}
            >
              <Button
                onClick={() => {
                  handleUndo(data.id);
                }}
                style={{ marginRight: 5 }}
                size={"small"}
                variant={"outlined"}
                startIcon={
                  <IconBase
                    iconName={"undo"}
                    color={theme.palette.primary[500]}
                  />
                }
              >
                Undo
              </Button>
            </Box>
          )}
        </div>
      );
    case "complete":
      return (
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ListItem
            variant="detail"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fullWidth
            startOrnament={
              <Avatar
                // updatable
                title={"admins initials"}
                firstName={firstName}
                lastName={lastName}
                styles={{
                  background: theme.palette.primary[500],
                  color: "white",
                }}
              >
                {firstName ? firstName[0] : null}
                {lastName ? lastName[0] : null}
              </Avatar>
            }
            subLabel={`Approved. ${moment(lastUpdated).format("MMM DD, YYYY")}`}
            subLabelStyles={{
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
            endOrnament={
              <IconBase
                iconName={"check"}
                size={20}
                color={theme.palette.primary[200]}
              />
            }
          />
        </div>
      );
    case "declined":
      return (
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ListItem
            variant="detail"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fullWidth
            startOrnament={
              <Avatar
                // updatable
                title={"admins initials"}
                firstName={firstName}
                lastName={lastName}
                styles={{
                  background: theme.palette.primary[500],
                  color: "white",
                }}
              >
                {firstName ? firstName[0] : null}
                {lastName ? lastName[0] : null}
              </Avatar>
            }
            subLabel={`Declined. ${moment(lastUpdated).format("MMM DD, YYYY")}`}
            subLabelStyles={{
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
            endOrnament={
              <IconBase
                size={20}
                color={theme.palette.nonPalette.RED}
                iconName={"close"}
              />
            }
          />
        </div>
      );
    case "denied":
      return (
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ListItem
            variant="detail"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fullWidth
            startOrnament={
              <Avatar
                // updatable
                title={"admins initials"}
                firstName={firstName}
                lastName={lastName}
                styles={{
                  background: theme.palette.primary[500],
                  color: "white",
                }}
              >
                {firstName ? firstName[0] : null}
                {lastName ? lastName[0] : null}
              </Avatar>
            }
            subLabel={`Declined. ${moment(lastUpdated).format("MMM DD, YYYY")}`}
            subLabelStyles={{
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
            endOrnament={
              <IconBase
                size={20}
                color={theme.palette.nonPalette.RED}
                iconName={"close"}
              />
            }
          />
          {isUser && type === "reviewer" && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 50,
              }}
            >
              <Button
                onClick={() => {
                  handleUndo(data.id);
                }}
                style={{ marginRight: 5 }}
                size={"small"}
                variant={"outlined"}
                startIcon={
                  <IconBase
                    iconName={"undo"}
                    color={theme.palette.primary[500]}
                  />
                }
              >
                Undo
              </Button>
            </Box>
          )}
        </div>
      );
    case "assigned":
      return (
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ListItem
            variant="detail"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fullWidth
            startOrnament={
              <Avatar
                // updatable
                title={"admins initials"}
                firstName={firstName}
                lastName={lastName}
                styles={{
                  background: theme.palette.primary[500],
                  color: "white",
                }}
              >
                {firstName ? firstName[0] : null}
                {lastName ? lastName[0] : null}
              </Avatar>
            }
            subLabel={`Reviewing since ${moment(lastUpdated).format(
              "MMMM DD"
            )}`}
            subLabelStyles={{
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
            }}
            endOrnament={
              hover ? (
                <IconBase
                  hoverColor={theme.palette.primary[500]}
                  fill={1}
                  iconName={"cancel"}
                  size={20}
                  color={theme.palette.primary[500]}
                  onClick={() => {
                    handleUnassign(id);
                  }}
                />
              ) : (
                <IconBase
                  size={20}
                  iconName={"find_in_page"}
                  color={GREY_MEDIUM}
                />
              )
            }
          />
          {isUser && type === "reviewer" && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 50,
              }}
            >
              <Button
                onClick={() => {
                  handleApprove(data.id);
                }}
                style={{ marginRight: 5 }}
                variant={"contained"}
                size={"small"}
                startIcon={<IconBase iconName={"check"} color={"#fff"} />}
              >
                Approve
              </Button>
              <RedHoverButton
                onClick={() => {
                  handleDecline(data.id);
                }}
                variant={"outlined"}
                size={"small"}
                startIcon={
                  <IconBase
                    iconName={"close"}
                    color={theme.palette.primary[500]}
                  />
                }
              >
                Reject
              </RedHoverButton>
            </Box>
          )}
        </div>
      );
    default:
      return null;
  }
}

export default ApprovalProgress;
