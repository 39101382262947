import React from "react";
import Button from "@mui/material/Button";
import classes from "./index.module.scss";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import { IStatementBalanceCard } from "./IStatementBalanceCard";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import { currency } from "../../../../../../../utilities";
import { Skeleton } from "@mui/material";

export default function StatementBalanceCard({
  amount,
  lastPaymentDate,
  dueDate,
  loading,
}: IStatementBalanceCard) {
  return !loading ? (
    <div className={classes.statement_balance_card_container}>
      <p className={typographyClasses.overline_bold}>statement balance</p>
      <p className={typographyClasses.currency_text_large}>
        {currency(amount)}
      </p>
      {amount > 0 ? (
        <>
          <p className={typographyClasses.body}>{dueDate}</p>
          <Spacer />
          <Button variant={"contained"} size={"large"} fullWidth>
            Pay now
          </Button>
          <Spacer />
        </>
      ) : null}
      <p className={typographyClasses.body}>{lastPaymentDate}</p>
    </div>
  ) : (
    <Skeleton height={400} />
  );
}
