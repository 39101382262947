import React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import IconBase from "./IconBase";
import { useTheme } from "@mui/material";

const Container = styled.div`
  cursor: pointer;
  display: inline-block;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  && {
    padding-left: 5px;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.primary[200]};
  }
`;

/**
 * A button that is typically used for showing a user more information
 * @param onClick
 * @param title
 * @returns {JSX.Element}
 * @constructor
 */
const InfoButton = ({ onClick = () => {}, title = "not set" }) => {
  const theme = useTheme();
  return (
    <Container onClick={onClick}>
      <InnerContainer>
        <IconBase
          color={theme.palette.primary[200]}
          iconName={"info"}
          className={undefined}
          size={22}
          hoverColor={undefined}
        />
        <StyledTypography>{title}</StyledTypography>
      </InnerContainer>
    </Container>
  );
};

export default InfoButton;
