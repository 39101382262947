import React, { useState, useEffect } from "react";
import {
  PRIMARY_200,
  PRIMARY_300,
  PRIMARY_400,
  PRIMARY_600,
  PRIMARY_700,
  WHITE_HIGH_EMPHASIS,
} from "../../../mui-theme/theme";
import styled, { css } from "styled-components";
import MuiButton from "@mui/material/Button";
import PreferencesModal from "../modals/PreferencesModal";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { get_admin_by_id } from "../../../core/apis/admin";
import Tooltip from "@mui/material/Tooltip";
import jwtDecode from "jwt-decode";

const RightButton = styled(MuiButton)`
  &&.MuiButton-root {
    letter-spacing: 0;
  }

  ${(props) => {
    switch (props.isactive) {
      case "true":
        return css`
          && {
            background: ${PRIMARY_300};
            border-radius: ${({ theme }) => theme.curvedBorderRadiusLarge};
            border: 1px solid ${PRIMARY_200};
            color: ${WHITE_HIGH_EMPHASIS};
            font-family: ${({ theme }) => theme.fontBold};
            font-size: ${({ theme }) => theme.buttonFontSize};
            height: 54px;
            text-transform: none;
            transition: 0.2s;
            padding: 3px 8px 3px 6px;

            .MuiTypography-body1 {
              color: ${WHITE_HIGH_EMPHASIS};
              font-weight: 500;
            }

            .MuiAvatar-root {
              .MuiTypography-body1 {
                color: ${({ theme }) => theme.palette.primary[500]};
                font-weight: 400;
              }

              background-color: ${({ theme }) => theme.palette.whites.WHITE};
            }
          }
        `;
      default:
        return css`
          && {
            background: ${PRIMARY_400};
            border-radius: ${({ theme }) => theme.curvedBorderRadiusLarge};
            border: 1px solid ${PRIMARY_300};
            color: ${WHITE_HIGH_EMPHASIS};
            font-family: ${({ theme }) => theme.fontBold};
            font-size: ${({ theme }) => theme.buttonFontSize};
            height: 54px;
            text-transform: none;
            transition: 0.2s;
            padding: 3px 8px 3px 6px;

            .MuiTypography-body1 {
              color: ${WHITE_HIGH_EMPHASIS};
              font-weight: 500;
            }

            .MuiAvatar-root {
              .MuiTypography-body1 {
                color: ${({ theme }) => theme.palette.primary[400]};
                font-weight: 400;
              }

              background-color: ${({ theme }) => theme.palette.whites.WHITE};
            }

            &&:focus {
              background-color: ${({ theme }) => theme.palette.whites.WHITE};
              border: 2px solid ${({ theme }) => theme.palette.primary[200]};
              color: ${({ theme }) => theme.palette.primary[200]};

              .MuiTypography-body1 {
                color: ${({ theme }) => theme.palette.primary[200]};
              }

              .MuiAvatar-root {
                .MuiTypography-body1 {
                  color: ${({ theme }) => theme.palette.primary[500]};
                }

                background-color: ${({ theme }) =>
                  theme.palette.greys.GREY_LIGHT};
              }
            }
          }
        `;
    }
  }}
  &&:hover {
    background: ${PRIMARY_600};
    border: 1px solid ${PRIMARY_700};
    color: ${({ theme }) => theme.palette.primary[50]};

    .MuiTypography-body1 {
      color: ${({ theme }) => theme.palette.primary[50]};
    }

    .MuiAvatar-root {
      .MuiTypography-body1 {
        color: ${({ theme }) => theme.palette.primary[500]};
      }

      background-color: ${({ theme }) => theme.palette.whites.WHITE};
    }
  }

  &&:active {
    background: ${PRIMARY_300};
    border-radius: ${({ theme }) => theme.curvedBorderRadiusLarge};
    border: 1px solid ${PRIMARY_200};
    color: ${WHITE_HIGH_EMPHASIS};
    font-family: ${({ theme }) => theme.fontBold};
    font-size: ${({ theme }) => theme.buttonFontSize};
    height: 54px;
    text-transform: none;
    transition: 0.2s;
    padding: 3px 8px 3px 6px;

    .MuiTypography-body1 {
      color: ${WHITE_HIGH_EMPHASIS};
      font-weight: 500;
    }

    .MuiAvatar-root {
      .MuiTypography-body1 {
        color: ${({ theme }) => theme.palette.primary[500]};
        font-weight: 400;
      }

      background-color: ${({ theme }) => theme.palette.whites.WHITE};
    }
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.5rem;
`;

const DisplayName = styled(Typography)`
  && {
    margin-left: 0.2rem;
    margin-right: 0.7rem;
  }
`;

/**
 * Displays details and options to a user when opened
 * @param user
 * @param logout
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProfileChip({ user }) {
  const [open, setOpen] = useState(false);
  const [adminName, setAdminName] = useState({});
  const [active, setActive] = useState(false);
  // state of the menu drop down button on the right

  const handleModalClickOpen = () => {
    setOpen(true);
    setActive(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setActive(false);
  };

  const getDisplayName = () => {
    if (!user) {
      return "Profile";
    }

    if (user.lname) {
      return user.lname;
    } else if (user.fname) {
      return user.fname;
    } else {
      return "Profile";
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const admin = await get_admin_by_id(user?.id);
        const names = {
          fName: admin?.firstName,
          lName: admin?.lastName,
          fullName: admin?.fullName,
        };
        setAdminName(names);
      } catch (e) {}
    }

    try {
      const userData = jwtDecode(user.userToken);
      if (userData.user?.profile) {
        setAdminName({
          fName: userData.user.profile.firstName,
          lName: userData.user.profile.lastName,
          fullName: userData.user.profile.fullName
        });
      }
    } catch (e) {
      fetchData().then(r => {});
    }
  }, [user]);

  return (
    <>
      <RightButton
        onClick={handleModalClickOpen}
        isactive={active ? "true" : "false"}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <Tooltip title={adminName?.fullName || adminName?.fName + " " + adminName?.lName}>
          <StyledAvatar key={user?.id}>
            <Typography variant={"body1"}>
              {adminName?.fName?.charAt(0)?.toUpperCase() || "-"}
              {adminName?.lName?.charAt(0)?.toUpperCase() || "-"}
            </Typography>
          </StyledAvatar>
        </Tooltip>
        <DisplayName variant={"body1"}>{getDisplayName()}</DisplayName>
      </RightButton>

      <PreferencesModal open={open} onClose={handleCloseModal} />
    </>
  );
}
