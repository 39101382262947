import React, { useContext } from "react";
import styled from "styled-components";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import {
  theme,
  OUTLINE,
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_500,
} from "../../../../mui-theme/theme";
import IconBase from "../IconBase";
import { EditingContext } from "../../../contexts/EditingContext";
import Tooltip from "@mui/material/Tooltip";

const FAIcon = styled.div``;

const StyledListItem = styled(ListItem)`
  && {
    color: ${({ theme }) => theme.textColor};
    height: 46px;
    padding: 0.6rem 0 0.6rem 1.6rem;
    width: auto;
  }

  &&.MuiListItem-root {
    background-color: ${({ theme }) => theme.palette.whites.WHITE};
  }

  &&.MuiListItem-button:hover,
  &&.MuiListItem-root.Mui-selected {
    background: ${({ theme }) => theme.backgroundHighlight};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    color: #000000;
    display: flex;
    justify-content: center;
    margin-right: 20px;
    min-width: 25px;
  }
`;

const StyledListItemText = styled(ListItemText)`
  && {
    display: ${({ $collapse }) => $collapse && "none"};
    white-space: nowrap;
    ${({ back, selected }) =>
      (back === "true" || selected) && "font-weight: bold"};
    ${({ disabled }) => disabled && "opacity: 0.5"};
  }
`;

const StyledCollapseButton = styled.div`
  min-height: 50px !important;
  min-width: 50px !important;
  box-shadow: 0 2px 2px rgba(1, 48, 78, 0.15);
  margin-left: auto;
  margin-right: -25px !important;
  margin-bottom: 100px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.palette.whites.WHITE};
  border: 1px solid ${OUTLINE};
  border-radius: 50%;

  :hover {
    cursor: pointer;
    background: ${PRIMARY_100};
  }
`;

const ConditionalTooltip = styled(Tooltip)``;

export default function SidebarItem({
  backButton,
  content,
  collapse,
  onListItemClick,
  selectedItem,
  disabled,
}) {
  const isBackButton = backButton && backButton.toString();
  const { handleClickWrapper } = useContext(EditingContext);
  const { icon, subItems, path, title } = content;
  const subSidebarOption = { parent: title, subItems };
  const isListItemSelected = selectedItem === title;

  return (
    <div>
      <ConditionalTooltip
        as={!collapse || isBackButton ? "div" : undefined}
        title={title}
        placement="right"
      >
        <StyledListItem
          button
          disabled={disabled}
          selected={isListItemSelected}
          onClick={() => {
            handleClickWrapper(() =>
              onListItemClick(path, title, subSidebarOption)
            );
          }}
        >
          {typeof icon === "string" ? (
            <StyledListItemIcon back={isBackButton}>
              <FAIcon selected={isListItemSelected}>
                <IconBase
                  iconName={isBackButton ? "chevron_left" : icon}
                  color={isListItemSelected ? PRIMARY_200 : PRIMARY_500}
                  style={{ marginTop: "3px" }}
                />
              </FAIcon>
            </StyledListItemIcon>
          ) : (
            <>
              {icon}
              <span style={{ marginRight: "16px" }}></span>
            </>
          )}

          <StyledListItemText
            back={isBackButton}
            $collapse={collapse}
            disableTypography
            primary={<Typography variant="inherit">{title}</Typography>}
            selected={isListItemSelected}
          />
        </StyledListItem>
      </ConditionalTooltip>
    </div>
  );
}

export const CollapseButton = ({ onClick, $collapse }) => {
  return (
    <StyledCollapseButton onClick={onClick}>
      <IconBase
        iconName={$collapse ? "chevron_right" : "chevron_left"}
        color={PRIMARY_500}
        wght="900"
        size="36px"
      />
    </StyledCollapseButton>
  );
};
