import React from "react";
import Button from "@mui/material/Button";
import IconBase  from "./IconBase";

export default function CloseButton({ onClick }) {
  return (
    <Button
      variant={"outlined"}
      size={"medium"}
      startIcon={<IconBase iconName={"close"} />}
      onClick={onClick}
    >
      Close
    </Button>
  );
}
