import React from "react";
import styled from "@emotion/styled";

const defaultPadding = "0 0 0 0";
const defaultMargin = "0 0 0 0";

const StyledIcon = styled.span`
  &.material-symbols-rounded {
    font-variation-settings: "FILL" ${({ fill }) => fill},
      "wght" ${({ wght }) => wght}, "GRAD" ${({ grad }) => grad},
      "opsz" ${({ opsz }) => opsz};
    color: ${({ color, theme }) => color || theme.palette.primary[500]};
    font-weight: ${({ fontWeight }) => fontWeight || "normal"};
    margin: ${({ margin }) => margin || defaultMargin};
    padding: ${({ padding }) => padding || defaultPadding};
    font-size: ${({ size }) => size || "24px"};
    :hover {
      ${({ hoverColor }) =>
        hoverColor && `color: ${hoverColor}; cursor: pointer;`};
    }
  }
`;

/**
 * Wrap a symbol name with this component to retrieve a material symbol
 * @param className
 * @param iconName
 * @param fill
 * @param wght
 * @param grad
 * @param opsz
 * @param size - Make sure this is a valid unit ie: px, rem, etc
 * @param color
 * @param hoverColor
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const IconBase = ({
  className,
  iconName,
  fill = 0,
  wght = 400,
  grad = 0,
  opsz = 48,
  size,
  color,
  hoverColor,
  ...rest
}) => {
  return (
    <StyledIcon
      className={"material-symbols-rounded"}
      color={color}
      fill={fill}
      wght={wght}
      grad={grad}
      opsz={opsz}
      size={size}
      hoverColor={hoverColor}
      {...rest}
    >
      {iconName}
    </StyledIcon>
  );
};

/* Action */
export const SearchIcon = (props) => (
  <IconBase {...props} iconName="searched" />
);
export const CheckIcon = (props) => <IconBase {...props} iconName="done" />;
export const CheckCircleIcon = (props) => (
  <IconBase {...props} iconName="check_circle" />
);
export const ChecklistIcon = (props) => (
  <IconBase {...props} iconName="checklist" />
);
export const MoneyOffIcon = (props) => (
  <IconBase {...props} iconName="money_off" />
);
export const DescriptionIcon = (props) => (
  <IconBase {...props} iconName="description" />
);
export const ReceiptIcon = (props) => (
  <IconBase {...props} iconName="receipt_long" />
);

/* Alert */
export const WarningIcon = (props) => (
  <IconBase {...props} iconName="warning" />
);

/* Content */
export const AddIcon = (props) => <IconBase {...props} iconName="add" />;

export const LinkIcon = (props) => <IconBase {...props} iconName="link" />;
export const UnlinkIcon = (props) => (
  <IconBase {...props} iconName="link_off" />
);
export const EditIcon = (props) => <IconBase {...props} iconName="edit" />;

export const CalendarIcon = (props) => <IconBase {...props} iconName="event" />;

export const PersonIcon = (props) => <IconBase {...props} iconName="person" />;
export const PersonCheckIcon = (props) => (
  <IconBase {...props} iconName="how_to_reg" />
);
export const PersonAddIcon = (props) => (
  <IconBase {...props} iconName="person_add" />
);

export const ApprovalIcon = (props) => (
  <IconBase {...props} iconName="approval" />
);

export const CityHallIcon = (props) => (
  <IconBase {...props} iconName="account_balance" />
);

export const LocationIcon = (props) => (
  <IconBase {...props} iconName="location_on" />
);
export const HashTagIcon = (props) => <IconBase {...props} iconName="tag" />;
export const StorefrontIcon = (props) => (
  <IconBase {...props} iconName="storefront" />
);

/* Navigation */

export const MoreVertIcon = (props) => (
  <IconBase {...props} iconName="more_vert" />
);

export const CancelIcon = (props) => <IconBase {...props} iconName="cancel" />;

export const ChevronRightIcon = (props) => (
  <IconBase {...props} iconName="chevron_right" />
);

export const ChevronDownIcon = (props) => (
  <IconBase {...props} iconName="expand_more" />
);
export const CloseIcon = (props) => <IconBase {...props} iconName="close" />;
