import React, { useState } from "react";
import { Unstable_Grid2 as Grid, useTheme, Button } from "@mui/material";
import ListItem from "../../../../components/ui-kit/mch/ListItem";
import Typography from "../../../../components/ui-kit/typography";
import IconBase from "../../../../components/ui-kit/IconBase";
import Spacer from "../../../../components/ui-kit/Spacer";
import moment from "moment";
import {
  DRAFT,
  editStatuses,
  getDisplayStatus,
  SAVED,
} from "../../../../../core/constants/statuses";
import { formatPhoneNumber } from "../../../../../utilities/masked-input-formatters";
import { abbreviator } from "../../../../../core/constants/strings";

const GeneralTab = ({ status, licence, editPage, canEdit }) => {
  const [license] = useState(licence);
  const theme = useTheme();
  const getResidencyStatus = () => {
    if (!license) return null;

    let status = "Non-resident";

    if (license.isResident) {
      status = "Resident";
    }

    if (license.homeBased) {
      status = "Home-based";
    }

    return status;
  };
  return (
    <Grid container pl={1}>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        bg={6}
        style={{ marginTop: "2rem", paddingRight: "1rem" }}
      >
        <Typography
          variant="h4"
          fontWeight={"300"}
          style={{ marginBottom: "0.5rem" }}
        >
          Licence details
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
        >
          If it changes at any time, don’t forget to update your information on
          this licence.
        </Typography>
        {(status === SAVED || licence.changeSetStatus === DRAFT) &&
        canEdit &&
        editStatuses.find((x) => x === status) ? (
          <>
            <Spacer amount={2} />
            <Button
              variant="outlined"
              size="small"
              style={{
                borderColor: theme.palette.nonPalette.OUTLINE,
                fontSize: "16px",
                padding: "10px",
              }}
              startIcon={
                <IconBase
                  iconName="edit"
                  color={theme.palette.primary[200]}
                  fill={1}
                  size={12}
                />
              }
              onClick={() => editPage(0)}
            >
              Edit licence details
            </Button>
          </>
        ) : null}
        <Spacer />
        <ListItem
          label="Status"
          subLabel={getDisplayStatus(status)}
          variant="detail"
          startOrnament={<IconBase iconName="task"></IconBase>}
          characterLimit={80}
        />
        <ListItem
          label="Licence Number"
          subLabel={license?.licenceNumber || "-"}
          variant="detail"
          startOrnament={<IconBase iconName="tag"></IconBase>}
          characterLimit={80}
        />
        <ListItem
          label="Business name"
          subLabel={license?.businessName || "-"}
          variant="detail"
          startOrnament={
            <IconBase iconName="drive_file_rename_outline"></IconBase>
          }
          characterLimit={80}
        />
        {/*<ListItem*/}
        {/*  label="Type of business"*/}
        {/*  subLabel={*/}
        {/*    capitalize(license?.businessType[0].split("_").join(" ")) || "-"*/}
        {/*  }*/}
        {/*  variant="detail"*/}
        {/*  startOrnament={<IconBase iconName="storefront"></IconBase>}*/}
        {/*  characterLimit={80}*/}
        {/*/>*/}
        <ListItem
          label="Describe business operation"
          subLabel={license?.businessDescription || "-"}
          variant="detail"
          startOrnament={<IconBase iconName="article"></IconBase>}
          characterLimit={80}
        />
        <ListItem
          label="Residency status"
          subLabel={getResidencyStatus() || "-"}
          variant="detail"
          startOrnament={<IconBase iconName="article"></IconBase>}
          characterLimit={80}
        />
        {license?.expiry ? (
          <ListItem
            label="Auto-renew date"
            subLabel={
              moment(license.expiry).add(1, "d").format("MMM DD, YYYY") || "-"
            }
            variant="detail"
            startOrnament={<IconBase iconName="event"></IconBase>}
            characterLimit={80}
          />
        ) : (
          <></>
        )}
        {license?.hasParking &&
        (!!license?.numVehicles ||
          !!license?.parkingPlaceType ||
          license?.truckSize) ? (
          <>
            <Typography
              variant="caption"
              color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              style={{ marginLeft: "1rem" }}
            >
              Parking capacity
            </Typography>
            <ListItem
              label="Number of vehicles"
              subLabel={license?.numVehicles || "-"}
              variant="detail"
              startOrnament={<IconBase iconName="directions_car"></IconBase>}
              characterLimit={80}
            />
            <ListItem
              label="Place of parking"
              subLabel={license?.parkingPlaceType || "-"}
              variant="detail"
              startOrnament={<IconBase iconName="article"></IconBase>}
              characterLimit={80}
            />
            {license.truckSize && (
              <ListItem
                label="Truck size"
                subLabel={license?.truckSize || "-"}
                variant="detail"
                startOrnament={<IconBase iconName="local_shipping"></IconBase>}
                characterLimit={80}
              />
            )}
            {!license.isOwner && (
              <>
                <Typography
                  variant="caption"
                  color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                  style={{ marginLeft: "1rem" }}
                >
                  Property owner
                </Typography>
                <ListItem
                  label={license?.propertyOwnerName || "-"}
                  startOrnament={<IconBase iconName="person"></IconBase>}
                  characterLimit={80}
                />
              </>
            )}
          </>
        ) : null}
        <Spacer amount={2} />
        <Typography
          variant={"h4"}
          fontWeight={"300"}
          style={{ marginBottom: "0.5rem" }}
        >
          Business contact information
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
        >
          Please, keep this information up to date. It helps speed up our work.
        </Typography>
        {(status === SAVED || licence.changeSetStatus === DRAFT) &&
        canEdit &&
        editStatuses.find((x) => x === status) ? (
          <>
            <Spacer amount={2} />
            <Button
              variant="outlined"
              size="small"
              style={{
                borderColor: theme.palette.nonPalette.OUTLINE,
                fontSize: "16px",
                padding: "10px",
              }}
              startIcon={
                <IconBase
                  iconName="edit"
                  color={theme.palette.primary[200]}
                  fill={1}
                  size={12}
                />
              }
              onClick={() => editPage(1)}
            >
              Edit contact information
            </Button>
          </>
        ) : null}
        <Spacer />
        <ListItem
          label="Business address"
          subLabel={
            `${license.businessAddress + ", "} 
            ${license?.unit !== "" ? licence.unit + ", " : ""}
            ${license.businessAddressCity + ", "} 
            ${
              abbreviator(
                license.businessAddressProvince,
                license.businessCountry
              ) + ", "
            }
            ${license.code} ${
              licence.businessCountry === "CA" ? "Canada" : "USA"
            }` || "-"
          }
          variant="detail"
          startOrnament={<IconBase iconName="location_on"></IconBase>}
          characterLimit={200}
        />
        <ListItem
          label="Mailing address"
          subLabel={
            `${license.mailingAddress + ", "} 
            ${license?.mailingUnit !== "" ? licence.mailingUnit + ", " : ""}
            ${license.mailingAddressCity + ", "} 
            ${
              abbreviator(
                license.mailingAddressProvince,
                license.mailingCountry
              ) + ", "
            }
            ${license.mailingCode} 
            ${license.mailingCountry === "CA" ? "Canada" : "USA"}` || "-"
          }
          variant="detail"
          startOrnament={<IconBase iconName="local_post_office"></IconBase>}
          characterLimit={200}
        />
        <ListItem
          label="Phone number"
          subLabel={formatPhoneNumber(license?.phoneNumber) || "-"}
          variant="detail"
          startOrnament={<IconBase iconName="phone"></IconBase>}
          characterLimit={80}
        />
        <ListItem
          label="Email address"
          subLabel={license?.email || "-"}
          variant="detail"
          startOrnament={<IconBase iconName="email"></IconBase>}
          characterLimit={80}
        />
        {license.secondPhone && (
          <ListItem
            label="Secondary phone number"
            subLabel={formatPhoneNumber(license?.secondPhone) || "-"}
            variant="detail"
            startOrnament={<IconBase iconName="phone"></IconBase>}
            characterLimit={80}
          />
        )}
        {license.faxNumber && (
          <ListItem
            label="Fax number"
            subLabel={formatPhoneNumber(license?.faxNumber) || "-"}
            variant="detail"
            startOrnament={<IconBase iconName="fax"></IconBase>}
            characterLimit={80}
          />
        )}
        {license.websiteAddress && (
          <ListItem
            label="Website address"
            subLabel={license?.websiteAddress || "-"}
            variant="detail"
            startOrnament={<IconBase iconName="language"></IconBase>}
            characterLimit={80}
          />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={6} bg={6} style={{ marginTop: "2rem" }}>
        <Typography
          variant="h4"
          fontWeight={"300"}
          style={{ marginBottom: "0.5rem" }}
        >
          Business management
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: "2rem" }}
          color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
        >
          Please list all owners and managers involved in the business.
        </Typography>
        {(status === SAVED || licence.changeSetStatus === DRAFT) &&
        canEdit &&
        editStatuses.find((x) => x === status) ? (
          <>
            <Button
              variant="outlined"
              size="small"
              style={{
                borderColor: theme.palette.nonPalette.OUTLINE,
                fontSize: "16px",
                padding: "10px",
              }}
              startIcon={
                <IconBase
                  iconName="edit"
                  color={theme.palette.primary[200]}
                  fill={1}
                  size={12}
                />
              }
              onClick={() => editPage(2)}
            >
              Edit business management
            </Button>
            <Spacer amount={2} />
          </>
        ) : null}
        {license?.owners?.map((element, i) => (
          <div key={i}>
            <Typography
              variant="caption"
              color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              style={{ marginLeft: "1rem" }}
            >
              Owner {i + 1}
            </Typography>
            <ListItem
              label={element.name || "-"}
              startOrnament={<IconBase iconName="person"></IconBase>}
              characterLimit={80}
            />
            <ListItem
              label={formatPhoneNumber(element.phone) || "-"}
              startOrnament={<IconBase iconName="call"></IconBase>}
              // endOrnament={
              //   <Button size={"small"} textType={"body1"}>
              //     Update
              //   </Button>
              // }
              characterLimit={80}
            />
          </div>
        ))}

        {license.managers?.map((element, i) => (
          <div key={i}>
            <Typography
              variant="caption"
              color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              style={{ marginLeft: "1rem" }}
            >
              Manager {i + 1}
            </Typography>
            <ListItem
              label={element.name}
              startOrnament={<IconBase iconName="person"></IconBase>}
              characterLimit={80}
            />
            <ListItem
              label={formatPhoneNumber(element.phone)}
              startOrnament={<IconBase iconName="call"></IconBase>}
              // endOrnament={
              //   <Button size={"small"} textType={"body1"}>
              //     Update
              //   </Button>
              // }
              characterLimit={80}
            />
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default GeneralTab;
