import React, { useState } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "../../ui-kit/Modal";
import { get_users } from "../../../../core/apis/user";
import { LicenseCard } from "../../ui-kit/account-cards";
import IconBase from "../../ui-kit/IconBase";
import SearchBar from "../../ui-kit/SearchBar";
import Spacer from "../../ui-kit/Spacer";
import { linkUserToLicence } from "../../../../core/apis/licence";
import ListItem from "../../ui-kit/list-item";
import DetailsContainer from "../../../screens/my-city-hall/utilities/utility-details/widgets/details-container";

const StyledModal = styled(Modal)`
  margin-left: -60px;

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const ModalContent = styled.div`
  padding: 2rem;
  width: 985px;
`;

const StyledCardContainer = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
  width: 480px;
  overflow: hidden;
  align-items: center;
  margin-left: auto;
`;

const IconHolder = styled.div`
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, 0);
`;

const queryOptions = {
  total: true,
  sortBy: "firstName",
  ascending: true,
  filters: { search: "" },
  offset: 0,
  limit: 5,
};

export default function LinkUserToAccountModal({
  open,
  handleClose,
  data,
  refresher,
  refreshValue,
  isUtilityAccount = false,
  accessCode,
  serviceAddress,
  accountNumber,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  const theme = useTheme();

  async function fetchData(queryOptions) {
    try {
      const allUsers = await get_users(queryOptions);
      let filteredData = [];
      allUsers?.result.map((e) =>
        filteredData.push({
          label: e.email,
          sublabel: e.customerCode,
          userId: e.id,
        })
      );
      setSearchOptions(filteredData);
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    }
  }

  const handleReset = () => {
    setSearchValue("");
    setSearchOptions([]);
  };

  // TODO: with each change in the text field, api is called
  //  we should add debounce to avoid frequent api calls
  const handleSearchBarChangeCategories = (value) => {
    const label = value?.label ? value.label : value;
    setSearchValue(label);
    if (label) {
      let newQuery = queryOptions;
      newQuery.filters.search = label;

      fetchData(newQuery);
    } else {
      setSearchOptions([]);
    }
  };

  const handleSubmitLink = async (e) => {
    const found = userFound();
    if (found) {
      if (isUtilityAccount) {
        // TODO: implement the logic for linking utility account to user
      }
      await linkUserToLicence(data.id, found.userId);
    }

    handleReset();
    handleClose();
    refresher(!refreshValue);
  };

  const userFound = () => {
    return searchOptions?.find((x) => {
      return x.label === searchValue;
    });
  };

  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"xl"}
      open={open}
      onClose={() => {
        handleReset();
        handleClose();
      }}
      title="Link and account"
    >
      <ModalContent>
        <div style={{ display: "flex" }}>
          {isUtilityAccount ? (
            <DetailsContainer
              loading={false}
              title={"Account Details"}
              isEditable={false}
              styles={{ width: "44%" }}
            >
              <ListItem
                key={1}
                variant="detail"
                label={"Account Number"}
                subLabel={accountNumber}
                originalValue={undefined}
                startOrnament={<IconBase iconName={"tag"} size={24} />}
              />
              <ListItem
                key={2}
                variant="detail"
                label={"Access Code/PIN"}
                subLabel={accessCode}
                originalValue={undefined}
                startOrnament={<IconBase iconName={"done"} size={24} />}
              />
              <ListItem
                key={3}
                variant="detail"
                label={"Service Address"}
                subLabel={serviceAddress}
                originalValue={undefined}
                startOrnament={<IconBase iconName={"location_on"} size={24} />}
              />
            </DetailsContainer>
          ) : (
            <LicenseCard data={data} hideFee={true} />
          )}
          <IconHolder>
            <IconBase
              fill={0}
              wght={550}
              iconName={"LINK"}
              color={theme.palette.nonPalette.OUTLINE}
              size={55}
            />
          </IconHolder>
          <StyledCardContainer>
            <div style={{ marginTop: "2rem" }}>
              <SearchBar
                label="Users"
                handleChange={(e, value) => {
                  handleSearchBarChangeCategories(value);
                }}
                handleSubmit={(e) => {
                  handleSearchBarChangeCategories(e.target.value);
                }}
                value={searchValue}
                handleClear={() => {
                  setSearchValue("");
                }}
                options={searchOptions}
                style={{ width: "360px" }}
              />
            </div>
            <Typography
              style={{
                marginTop: "2rem",
                width: "400px",
                color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                textAlign: "center",
              }}
            >
              {`When you link a ${
                isUtilityAccount ? "utilities account" : "business licence"
              } to a user account, the user will
              be able to manage it in MyCityHall.`}
            </Typography>
          </StyledCardContainer>
        </div>
        <Spacer amount={0.5} />
        <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
          <Button
            variant={"text"}
            onClick={() => {
              handleReset();
              handleClose();
            }}
            style={{
              color: theme.palette.primary[500],
              marginRight: "0.5rem",
            }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            disabled={!userFound()}
            onClick={(e) => {
              handleSubmitLink(e);
            }}
          >
            link account
          </Button>
        </Box>
      </ModalContent>
    </StyledModal>
  );
}
