import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import { truncate } from "../../../../../../../utilities";
import { DataFormat } from "../../../../../../../core/constants/enums";
import LinkButton from "../../../../../../components/ui-kit/link-button";

export const fields = [
  { id: "date", label: "Invoice date", disableSort: true },
  {
    id: "description",
    label: "Description",
    disableSort: true,
    format: (
      val: string,
      { invoice_number, payment }: any,
      loading: boolean,
      { lastFourDigits }: any
    ) => (
      <>
        {payment ? (
          <p className={`${typographyClasses.body} green`}>
            Payment received from **** **** **** {lastFourDigits}
          </p>
        ) : (
          <>
            <LinkButton
              tooltip={val}
              color={"blue"}
              onClick={async (e: any) => {
                e.stopPropagation();
              }}
            >
              {truncate(val, 35)}
            </LinkButton>
            <p
              className={`${typographyClasses.body2} ${typographyClasses.small_font}`}
            >
              Invoice #{invoice_number}
            </p>
          </>
        )}
      </>
    ),
  },
  {
    id: "payment",
    disableSort: true,
    label: "Payment",
    format: (val: any) => (
      <p className={`${typographyClasses.body} green`}>
        {DataFormat.CURRENCY(val)}
      </p>
    ),
  },
  {
    id: "amount",
    disableSort: true,
    label: "Amount",
    format: (val: any) => (
      <p className={`${typographyClasses.body}`}>{DataFormat.CURRENCY(val)}</p>
    ),
  },
];