import { DataFormat, FilterType } from "./enums";
import { convertLicenseNumber, formatLicenceType } from "../../utilities";
import {
  applicantFormat,
  licenceStatusIconName,
  licenseStatusFormat,
} from "../../utilities/data-table-format-helpers";

export const APPROVED = "approved";
export const ACTIVE = "active";
export const DECLINED = "declined";
export const CANCELLED = "cancelled";
export const ISSUED = "issued";
export const EXPIRED = "expired";
export const REVIEWING = "reviewing";
export const REVOKED = "revoked";
export const SAVED = "saved";
export const SUBMITTED = "submitted";
export const OUTDATED = "outdated";
export const CREATED = "created";
export const DRAFT = "draft";
export const DISCARDED = "discarded";

export const EDIT = "edit";
export const ADD = "add";

export const ACTIVE_GROUP = [ACTIVE, ISSUED];
export const REVIEWING_GROUP = [APPROVED, REVIEWING, SUBMITTED];
export const INACTIVE_GROUP = [CANCELLED, REVOKED, DECLINED, EXPIRED];

export const BUSINESS_NAME_LENGTH = 30;
export const MAX_FILES_SIZE = 64; //mb
export const BYLAW_SEARCH_DROP_DOWN_LENGTH = 50;
export const REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH = 20;

export const licenseFieldsV2 = {
  ref_number: {
    id: "ref",
    label: "LIC. #",
    disableSort: false,
    filterType: FilterType.SEARCH,
    format: convertLicenseNumber,
  },
  project: {
    id: "projectName",
    label: "PROJECT",
    disableSort: true,
    filterType: null,
  },
  type: {
    id: "category",
    label: "Category",
    type: "tags",
    filterType: FilterType.CHIP,
    disableSort: true,
    format: formatLicenceType,
    options: [
      { label: "Business", value: "BUSINESS_LICENSE" },
      { label: "Pet", value: "DOG_LICENSE" },
    ],
  },
  address: {
    id: "address",
    label: "Address",
    disableSort: true,
    filterType: null,
  },
  applicant: {
    id: "applicant",
    label: "Applicant",
    disableSort: false,
    filterType: null,
    format: applicantFormat,
  },
  name: {
    id: "name",
    label: "Applicant",
    disableSort: true,
    filterType: null,
  },
  last_update: {
    id: "lastUpdated",
    label: "Updated",
    disableSort: false,
    filterType: null,
    format: DataFormat.DATE_SHORT,
  },
  balance: {
    id: "balance",
    label: "Balance",
    filterType: null,
    disableSort: true,
    format: DataFormat.CURRENCY,
  },
  estimate: {
    id: "balance",
    label: "Estimate",
    filterType: null,
    disableSort: true,
    format: DataFormat.CURRENCY,
  },
  status: {
    id: "status",
    label: "Status",
    filterType: null,
    disableSort: true,
    format: (val: any) => {
      return licenseStatusFormat(val);
    },
  },
  residency: {
    id: "is_resident",
    label: "Residency",
    filterType: FilterType.SELECT,
    hideFromTable: true,
    defaultValue: "",
    options: [
      { id: "resident", label: "Resident", value: "true" },
      { id: "non-resident", label: "Non-resident", value: "false" },
      { id: "any-resident", label: "Any", value: "" },
    ],
  },
  location: {
    id: "is_homebased",
    label: "Location",
    filterType: FilterType.SELECT,
    hideFromTable: true,
    defaultValue: "",
    options: [
      { id: "storefront", label: "Storefront", value: "false" },
      { id: "homebased", label: "Home-based", value: "true" },
      { id: "any-location", label: "Any", value: "" },
    ],
  },
  underreviewStatuses: {
    id: "status",
    label: "Status",
    filterType: FilterType.SELECT,
    hideFromTable: false,
    defaultValue: "",
    disableSort: false,
    format: (val: any) => licenseStatusFormat(val),
    options: [
      {
        label: "Submitted",
        value: SUBMITTED,
        icon: licenceStatusIconName(SUBMITTED),
      },
      {
        label: "Reviewing",
        value: REVIEWING,
        icon: licenceStatusIconName(REVIEWING),
      },
      { label: "Any", value: "", icon: "web" },
    ],
  },
  activeStatuses: {
    id: "status",
    label: "Status",
    filterType: FilterType.SELECT,
    hideFromTable: false,
    defaultValue: "",
    disableSort: false,
    format: (val: any) => licenseStatusFormat(val),
    options: [
      {
        label: "Issued",
        value: ISSUED,
        icon: licenceStatusIconName(ISSUED),
      },
      {
        label: "Active",
        value: ACTIVE,
        icon: licenceStatusIconName(ACTIVE),
      },
      { label: "Any", value: "", icon: "web" },
    ],
  },
  inactiveStatuses: {
    id: "status",
    label: "Status",
    filterType: FilterType.SELECT,
    hideFromTable: false,
    defaultValue: "",
    disableSort: false,
    format: (val: any) => licenseStatusFormat(val),
    options: [
      {
        label: "Cancelled",
        value: CANCELLED,
        icon: licenceStatusIconName(CANCELLED),
      },
      {
        label: "Revoked",
        value: REVOKED,
        icon: licenceStatusIconName(REVOKED),
      },
      {
        label: "Declined",
        value: DECLINED,
        icon: licenceStatusIconName(DECLINED),
      },
      {
        label: "Expired",
        value: EXPIRED,
        icon: licenceStatusIconName(EXPIRED),
      },
      { label: "Any", value: "", icon: "web" },
    ],
  },
};

/**
 * Different costs of business licences
 */
export const prices = {
  localBusiness: 147,
  nonLocalBusiness: 412,
  discountedLocalBusiness: 73.5,
  discountedNonLocalBusiness: 206,
};
