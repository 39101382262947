import React from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import IconBase from "../IconBase";
import { Box, Link, useTheme } from "@mui/material";
import BreadCrumbs from "@mui/material/Breadcrumbs";
import Row from "../mch/Row";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  align-items: center;
  background: linear-gradient(
      ${({ theme }) => theme.palette.blacks.BLACK_LOW_EMPHASIS},
      ${({ theme }) => theme.palette.blacks.BLACK_LOW_EMPHASIS}
    ),
    url("${({ backgroundImg }) => backgroundImg}");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: inline-block;
  max-width: 1350px;
  padding-top: 1rem;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
`;

/**
 * Component that displays page info and actions. Typically, used
 * under the header at the top of the page.
 * @param backPathName
 * @param breadCrumbs
 * @param close
 * @param name
 * @param noBackButton
 * @param title
 * @param img
 * @returns {JSX.Element}
 * @constructor
 */
const Hero = ({
  backPathName = "/",
  breadCrumbs = [],
  close = false,
  name,
  noBackButton,
  title,
  img,
}) => {
  const history = useHistory();
  const theme = useTheme();

  const btnBackNavigate = () => {
    history.push(backPathName);
  };

  return (
    <Container backgroundImg={img}>
      <InnerContainer>
        <Box p={1}>
          <Row aligned>
            {noBackButton ? (
              <div style={{ height: "50px" }} />
            ) : (
              <Button
                onClick={btnBackNavigate}
                variant={"outlined"}
                style={{
                  color: theme.palette.whites.WHITE,
                  borderColor: theme.palette.whites.WHITE,
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.blacks.BLACK_LOW_EMPHASIS,
                  },
                }}
                startIcon={
                  <IconBase
                    color={theme.palette.whites.WHITE}
                    iconName={close ? "close" : "arrow_back"}
                  />
                }
                size={"medium"}
              >
                {close ? "Close" : "Back"}
              </Button>
            )}
            <BreadCrumbs
              separator={">"}
              style={{
                color: theme.palette.whites.WHITE,
                marginLeft: "1.4rem",
              }}
            >
              {breadCrumbs.map(({ label, href }) => (
                <Link
                  key={label}
                  href={href}
                  style={{
                    color: theme.palette.whites.WHITE,
                    textDecorationColor: theme.palette.whites.WHITE,
                  }}
                >
                  {label}
                </Link>
              ))}
            </BreadCrumbs>
          </Row>
          <div
            style={{
              height: "5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            {name ? (
              <div style={{ display: "flex", overflow: "hidden" }}>
                <Typography
                  variant={"h2"}
                  style={{
                    color: theme.palette.whites.WHITE,
                    marginTop: 15,
                    paddingRight: 5,
                  }}
                  textOverflow={"ellipsis"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                >
                  {name}{" "}
                </Typography>
              </div>
            ) : null}
          </div>
          <Typography
            variant={"h1"}
            style={{ color: theme.palette.whites.WHITE }}
          >
            {title}
          </Typography>
        </Box>
      </InnerContainer>
    </Container>
  );
};

export default Hero;
