import React from "react";
import classes from "./index.module.scss";
import { formatCurrency } from "../../../../utilities";
import moment from "moment";
import { Skeleton } from "@mui/material";

const AdminUtilityBalanceCard = ({
  loading,
  balance,
  dueDate,
  lastPayment,
}) => {
  return (
    <>
      {loading ? (
        <Skeleton variant="rounded" className={classes.loading} />
      ) : (
        <div className={classes.container}>
          <p>STATEMENT BALANCE</p>
          <p>{formatCurrency(balance)}</p>
          {dueDate && (
            <p>{`Due date: ${moment(dueDate).format("MMM DD, yyyy")}`}</p>
          )}
          {lastPayment && (
            <p>{`Last payment: ${moment(lastPayment).format(
              "MMM DD, yyyy"
            )}`}</p>
          )}
        </div>
      )}
    </>
  );
};

export default AdminUtilityBalanceCard;
