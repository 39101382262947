import React, { useEffect, useState } from "react";
import Tabs from "../../../../components/ui-kit/tabs";
import Tab from "../../../../components/ui-kit/tab";
import MainContainer from "../../../../components/layout-helpers/triple-column-layout/MainContainer";
import LeftColumn from "../../../../components/layout-helpers/triple-column-layout/LeftColumn";
import RightColumn from "../../../../components/layout-helpers/triple-column-layout/RightColumn";
import StatementBalanceCard from "./widgets/StatementBalanceCard";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import useHash from "../../../../hooks/useHash";
import IconBase from "../../../../components/ui-kit/IconBase";
import { Tooltip, useTheme } from "@mui/material";
import TabPanel from "../../../../components/ui-kit/tab-pannel";
import InfoTab from "./tabs/info-tab";
import ServiceTab from "./tabs/service-tab";
import BillingTab from "./tabs/billing-tab";
import { getAccountDetail } from "../../../../../core/apis/account";
import { IFormField } from "../../../../../core/interfaces/utility/IFormField";
import { Home } from "@mui/icons-material";
import WBCBreadcrumbs from "../../../../components/ui-kit/breadcrumbs";
import classes from "./index.module.scss";

const tabs = [
  { id: "0", label: "Utilities Info" },
  { id: "1", label: "Services" },
  { id: "2", label: "Billing & Payments" },
];

export default function UtilityDetails() {
  const history = useHistory();
  const [showServiceWarning, setShowServiceWarning] = useState(false);
  const theme = useTheme();
  const hash = useHash();
  const { id }: any = useParams();
  const [item, setItem] = useState<Record<string, any>>({});
  const [tab, setTab] = useState(hash || tabs[0].id);
  const [loading, setLoading] = useState(false);

  const changeTab = (e: any, newTab: any) => {
    history.push(`/utility/${id}#${newTab}`);
  };

  const accountDetailAccessor = (detailName: string) => {
    const detail = item[detailName] as IFormField;
    if (detail === undefined) {
      return {
        id: detailName,
        name: "-",
        type: "-",
        label: "-",
        default: {},
        placeholder: "-",
        value: { value: "-" },
        originalValue: { value: "-" },
        required: false,
      } as IFormField;
    }
    if (detail.value.value === "") {
      detail.value.value = "-";
    }
    return detail;
  };

  const fetchAccount = async () => {
    try {
      setLoading(true);
      const results = await getAccountDetail(id);
      let refractedValues: Record<string, any> = {};
      results.forEach((field) => {
        refractedValues[field.name] = field;
      });
      setItem(refractedValues);

      if (refractedValues["services_reviewed"] !== undefined) {
        setShowServiceWarning(
          !refractedValues["services_reviewed"].value.value
        );
      }
    } catch (e) {
      // handleError("Unable to load licence details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount().then();
    const t = hash || tabs[0].id;
    setTab(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  return (
    <div>
      <WBCBreadcrumbs
        className={classes.bread_crumbs}
        children={[
          {
            icon: <Home fontSize={"small"} />,
            text: "HOME",
            onClick: () => history.replace("/portal"),
          },
          {
            text: "UTILITIES",
          },
        ]}
      />
      <Tabs onChange={changeTab} value={tab} className={"padding_left"}>
        <Tab value={tabs[0].id} label={tabs[0].label}></Tab>
        <Tab
          icon={
            showServiceWarning ? (
              <Tooltip title={"Action required"}>
                <div>
                  <IconBase
                    fill={1}
                    iconName={"warning"}
                    color={theme.palette.nonPalette.ORANGE}
                    style={{ paddingRight: "0.5rem" }}
                  />
                </div>
              </Tooltip>
            ) : (
              <></>
            )
          }
          value={tabs[1].id}
          label={tabs[1].label}
        ></Tab>
        <Tab value={tabs[2].id} label={tabs[2].label}></Tab>
      </Tabs>
      <MainContainer maxWidth={"1440px"}>
        <LeftColumn>
          <TabPanel value={tab} index={tabs[0].id}>
            <InfoTab
              id={id}
              showReviewWarning={showServiceWarning}
              accessor={accountDetailAccessor}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value={tab} index={tabs[1].id}>
            <ServiceTab loading={loading} />
          </TabPanel>
          <TabPanel value={tab} index={tabs[2].id}>
            <BillingTab loading={loading} />
          </TabPanel>
        </LeftColumn>
        <RightColumn>
          <StatementBalanceCard
            loading={loading}
            amount={item["balance"]?.value.value || 0.0}
            dueDate={
              item["due_date"]
                ? `Due date: ${moment(item["due_date"].value.value).format(
                    "MMM DD, YYYY"
                  )}`
                : ""
            }
            lastPaymentDate={
              item["last_payment"]
                ? `Last payment: ${moment(
                    item["last_payment"].value.value
                  ).format("MMM DD, YYYY")}`
                : ""
            }
          />
        </RightColumn>
      </MainContainer>
    </div>
  );
}
