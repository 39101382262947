import React, { createContext, useState, useMemo } from "react";

export const FilterContext = createContext(null);

export const FilterContextProvider = (props) => {
  const [filters, setFilters] = useState({});

  const filterContext = useMemo(() => {
    return {
      filters,
      setFilters,
    };
  }, [filters]);

  return (
    <FilterContext.Provider value={filterContext}>
      {props.children}
    </FilterContext.Provider>
  );
};
