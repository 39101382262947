import * as yup from "yup";
import {
  formattingRegex,
  oneLowerCase,
  oneNumber,
  oneSpecialChar,
  oneSpecialCharPreview,
  oneUpperCase,
  validationRegex,
} from "./regexes";
import valid from "card-validator";
import { provinces } from "../core/constants";

export const validType = yup
  .string()
  .oneOf(["CITIZEN", "BUSINESS"])
  .required("Type must be CITIZEN or BUSINESS.");

export const validName = yup.string().required("Name is required.");

export const validFirst = yup.string().required("First name is required.");

export const validLast = yup.string().required("Last name is required.");

export const validPhone = yup.string().required("Phone number is required.");

export const validEmail = yup
  .string()
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "Must be a valid email."
  )
  .required("Email is required.");

export const passwordValidationMessages = {
  lowercase: "MUST contain at least one lowercase character",
  uppercase: "MUST contain at least one uppercase character",
  number: "MUST contain at least one number",
  special: `MUST contain at least one special character (${oneSpecialCharPreview})`,
  length: "MUST contain at least 8 characters (12+ recommended)",
  required: "Password is required",
};

export const validStrongPassword = yup
  .string()
  .trim()
  .matches(oneUpperCase, passwordValidationMessages.uppercase)
  .matches(oneLowerCase, passwordValidationMessages.lowercase)
  .matches(oneNumber, passwordValidationMessages.number)
  .matches(oneSpecialChar, passwordValidationMessages.special)
  .min(8, passwordValidationMessages.length)
  .required(passwordValidationMessages.required);

export const validRole = yup.string().required("Role is required.");

//TODO: add types
export const fieldValidate = (
  value: any,
  validator: any,
  abortEarly: any,
  required = true
) => {
  if (validator === null) return;
  validator = !required ? validator.notRequired() : validator;
  return validator
    .validate(value, { abortEarly })
    .then(() => null)
    .catch((err: any) => err?.errors);
};

export const validPassword = yup.string().required("Password is required.");
export const validConfirmPassword = yup
  .string()
  .oneOf([yup.ref("password"), undefined], "Password does not match.")
  .required("Password is required.");

/**
 * Address validators
 */
export const validAddress = yup.string().required("Address is required.");
export const validCity = yup.string().required("City is required.");
export const validState = yup
  .string()
  .required("A valid selection is required.");

export const validCountry = yup
  .string()
  .required("Country is required.")
  .test(
    "COUNTRY_MATCH_PROVINCE",
    "Please select the correct country for your Province/State.",
    function (value: any) {
      const stateValue = this.parent["state"] || this.parent["province"];
      let isCanadianProvince = false;
      provinces.forEach((province) => {
        if (province.value === stateValue) {
          isCanadianProvince = true;
        }
      });
      return isCanadianProvince ? value === "CA" : value === "US";
    }
  );

export const validPostalZipCode = (name: string) =>
  yup
    .string()
    .required("Postal/ZIP code is required.")
    .max(10, "Postal/ZIP code is too long.")
    .test({
      name: "POSTAL_CODE_MATCH_COUNTRY",
      test: function (value: any) {
        const postalCodeInput = value;
        const fieldName = name || "code";
        const countryValue =
          name === "code"
            ? this.parent["businessCountry"]
            : this.parent["mailingCountry"];
        const isCA = countryValue !== "US"; // Thus when no value, default to IsCA: true
        const regex = isCA
          ? validationRegex.postalCode
          : validationRegex.zipCode;
        const match = regex.exec(postalCodeInput);
        return match
          ? true
          : this.createError({
              message: isCA ? "Invalid postal code." : "Invalid ZIP code.",
              path: fieldName,
            });
      },
    });

export const validPhoneFormatted = yup
  .string()
  .required("Phone number is required")
  .matches(formattingRegex.phone, "Phone number must be 10 digits.");

export const validPhoneFormattedOptional = yup
  .string()
  .matches(formattingRegex.phone, "Phone number must be 10 digits.");

export const validURLOptional = yup
  .string()
  .matches(validationRegex.url, "Must be a valid URL format.");

/**
 * Credit card validators
 */
export const validNumber = yup
  .number()
  .typeError("Card number must be a number.")
  .positive("Card number must be valid.")
  .required("Card number is required.")
  .test("test-credit", "Card number is invalid.", function (value: any) {
    return valid.number(value).isValid;
  });

export const validExpiry = yup
  .string()
  .required("Expiry is required.")
  .test("test-expiry", "Expiry is invalid.", function (value: any) {
    const test = valid.expirationDate(value);
    return test.isValid;
  });

export const validCvc = yup
  .number()
  .typeError("CVC must be a number.")
  .positive("CVC is invalid.")
  .max(9999, "CVC is invalid.")
  .required("CVC is required.");

export const validPetSex = yup
  .string()
  .oneOf(["male", "female"])
  .required("Please select a sex.");

/**
 * Business licence validators
 */

export const purpose = yup.string().required("Purpose is required");

export const businessName = yup.string().required("Business name is required");

export const businessType = yup
  .string()
  .nullable()
  .required("Business type is required");

export const businessDescription = yup
  .string()
  .max(140, "Cannot exceed 140 characters")
  .required("Cannot leave description empty");
