import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PRIMARY_200 } from "../../../../../mui-theme/theme";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { useHistory, useLocation } from "react-router-dom";
import { deleteComment, editComment } from "../../../../../core/apis/comment";
import Button from "../../../../components/ui-kit/Button";
import HistoryItem from "../../../../components/ui-kit/history-item";
import CommentField from "../../../../components/ui-kit/comment-field";

const HistoryContainer = styled.div`
  overflow: auto;
  ${({ maxToShow }) => (maxToShow > 0 ? `max-height: 50vh` : `height: 35.5vh`)};
`;

export default function HistoryTab({
  history,
  fetchHistories,
  data,
  _addComment,
  maxToShow = 0,
  navigateToDuplicate,
  tabName = "History",
}) {
  const location = useLocation();
  const historyHook = useHistory();
  const [currentHistory, setCurrentHistory] = useState(history);
  const end = useRef(null);
  const { handleError } = useContext(NotificationContext);
  const parseUsername = (username) => {
    if (!username) {
      return { firstName: "-", lastName: "-" };
    }
    if (username.includes(",")) {
      // lastName, firstName
      return {
        lastName: username.substr(0, username.indexOf(",")).trim(),
        firstName: username.substr(username.indexOf(",") + 1).trim(),
      };
    } else if (username.includes(" ")) {
      // firstName lastName
      return {
        firstName: username.substr(0, username.indexOf(" ")).trim(),
        lastName: username.substr(username.indexOf(" ") + 1).trim(),
      };
    }
    return {
      firstName: username.trim(),
      lastName: "-",
    };
  };

  const onDelete = async (licenceId, commentId) => {
    try {
      await deleteComment(licenceId, commentId);
      fetchHistories();
    } catch (e) {
      if (e.response.status === 401) {
        handleError("Permission denied.");
      } else {
        handleError("There was an issue deleting the comment.");
      }
    }
  };

  const onSave = async (licenceId, commentId, comment) => {
    try {
      await editComment(licenceId, commentId, comment);
      fetchHistories();
    } catch (e) {
      if (e.response.status === 401) {
        handleError("Permission denied.");
      } else {
        handleError("There was an issue updating the comment.");
      }
    }
  };

  const scrollToBottom = () => {
    end.current.scrollIntoView();
  };

  useEffect(() => {
    setCurrentHistory(history);
  }, [history]);

  useEffect(() => {
    if (maxToShow <= 0) {
      scrollToBottom();
    } else {
      setCurrentHistory(
        history.slice(history.length - maxToShow, history.length)
      );
    }
  }, [history, maxToShow]);

  return (
    <>
      <HistoryContainer maxToShow={maxToShow}>
        {currentHistory.map((historyItem) => {
          let firstName,
            lastName,
            createDateTime,
            createdByUserName,
            content,
            comment;
          // let link1, link2;

          createdByUserName = historyItem.userName;
          createDateTime = historyItem.lastUpdated;
          content = historyItem.comment;
          /**
           * @todo Add first name and last name to history read model
           */
          const parsedUserName = parseUsername(createdByUserName);
          firstName = parsedUserName.firstName;
          lastName = parsedUserName.lastName;

          switch (historyItem.historyType) {
            case "UserConfirmed":
              content =
                content.substr(
                  content.indexOf("$ActionUserName ") +
                    "$ActionUserName ".length
                ) + ".";
              break;
            case "UserRegistered":
              content = historyItem.isAdmin ? "was registered." : "registered.";
              break;
            case "UserActivated":
              content = "activated this city account.";
              break;
            case "UserDisabled":
              content = "disabled this city account.";
              break;
            case null:
              content = "has a history item with an unknown history type.";
              break;
            case "COMMENT":
              comment = content;
              content = null;
              break;
            default:
          }
          return (
            <div
              key={historyItem.id || historyItem.commentId}
              style={{ marginBottom: "1rem" }}
            >
              <HistoryItem
                onSave={onSave}
                onDelete={onDelete}
                isComment={"commentId" in historyItem}
                firstName={historyItem.firstName}
                lastName={historyItem.lastName}
                createDateTime={createDateTime}
                createdByUserName={firstName + " " + lastName}
                content={content}
                comment={comment}
                item={historyItem}
                data={data}
                navigateToDuplicate={navigateToDuplicate}
              />
            </div>
          );
        })}
        {history.length > maxToShow && maxToShow > 0 && (
          <Button
            style={{ marginLeft: 60, color: PRIMARY_200 }}
            iconName={"arrow_forward"}
            endIcon={true}
            onClick={() => {
              historyHook.push(`${location.pathname}#${tabName}`);
            }}
          >
            See All
          </Button>
        )}
        <div ref={end}></div>
      </HistoryContainer>
      <div style={{ marginTop: "1.5rem" }}>
        <CommentField addComment={_addComment} />
      </div>
    </>
  );
}
