import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import { RED, WHITE } from "../../../../../mui-theme/theme";
import {
  DataFormat,
  FilterType,
  INPUT_FORMATS,
} from "../../../../../core/constants/enums";
import { capitalize } from "../../../../../utilities";
import { validEmail, validRole } from "../../../../../utilities/yupValidators";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { FilterContext } from "../../../../contexts/FilterContext";
import { get_admins, get_admins_export } from "../../../../../core/apis/admin";
import DataTable from "../../../../components/ui-kit/data-table";
import { statusFormat } from "../../../../../utilities/data-table-format-helpers";
import { TableSettingContext } from "../../../../contexts/table-setting-context";

const StyledContainer = styled.div`
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const fields = [
  {
    id: "fullName",
    label: "Name",
    filterType: FilterType.SEARCH,
    disableSort: false,
    format: (val, { firstName, lastName, ...rest }) => {
      const initials = [firstName, lastName];
      const isUserNameNull = firstName === null && lastName === null;
      const displayInitials =
        val === isUserNameNull
          ? "-"
          : initials.length === 2 &&
            initials[0].charAt(0) + initials[1].charAt(0);
      const displayVal = val === isUserNameNull ? "-" : val;
      return (
        <StyledDiv>
          <Avatar
            style={{
              background: RED,
              color: WHITE,
              width: "2rem",
              height: "2rem",
              fontSize: "1rem",
              marginRight: "0.5rem",
            }}
          >
            {displayInitials}
          </Avatar>
          {displayVal}
        </StyledDiv>
      );
    },
  },
  {
    id: "roles",
    subField: "roleName",
    label: "Roles",
    type: "tags",
    disableSort: false,
    format: (val) => val.map((role) => capitalize(role.roleName)),
  },
  {
    id: "disabled",
    label: "Status",
    disableSort: false,
    format: (val) => {
      return statusFormat(val ? "inactive" : "active");
    },
    // filterType: "BOOLEAN",
    // options: [
    //   { label: "Inactive", value: "INACTIVE" },
    //   { label: "Active", value: "ACTIVE" },
    // ],
  },
  {
    id: "lastLoggedIn",
    label: "Last Logged In",
    format: DataFormat.DATE,
    filterType: FilterType.DATE_PICKER,
    disableSort: false,
  },
];

const createFields = [
  {
    label: "Role",
    id: "role_id",
    size: 12,
    type: "select",
    values: [],
    validator: validRole,
  },
  {
    label: "Email",
    id: "email",
    size: 12,
    type: "email",
    validator: validEmail,
    inputFormat: INPUT_FORMATS.EMAIL,
  },
];

const Admins = (...props) => {
  const [tableIdentifier] = useState("adminList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [previousQueryOptions, setPreviousQueryOptions] = useState(null);
  const { filters, setFilters } = useContext(FilterContext);
  const [currentFilters, setCurrentFilters] = useState(filters["admin"] || {});
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[tableIdentifier] || props.initialFilters || {}
  );

  async function fetchData(queryOptions) {
    if (queryOptions) setPreviousQueryOptions(queryOptions);
    setLoading(true);
    try {
      const data = await get_admins(queryOptions || previousQueryOptions);

      if (data?.result) {
        data.result.forEach(
          (user) => (user["roleLabels"] = user.roles?.map((role) => role.name))
        );

        createFields[
          createFields.findIndex((x) => x.id === "email")
        ].validator = createFields[
          createFields.findIndex((x) => x.id === "email")
        ].validator.notOneOf(
          data.result.map((admin) => admin.email),
          "User already exists"
        );
      }

      setData(data);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    filters["admin"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    tableIdentifier,
    setFilters,
    setTableSettings,
  ]);

  return (
    <div style={{ display: "flex" }}>
      <StyledContainer>
        <DataTable
          title="Admins"
          ariaLabel="admin list"
          defaultOrder="asc"
          dataLoading={loading}
          defaultSort="firstName"
          fields={fields}
          onRowClick={(admin, history, e) => {}}
          enableFilter
          getCSV={get_admins_export}
          loadData={fetchData}
          data={data}
          filters={currentFilters}
          setFilters={setCurrentFilters}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          id={tableIdentifier}
        />
      </StyledContainer>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Admins;
