import React, { useContext, useEffect, useMemo, useState } from "react";
import classes from "./index.module.scss";
import { WaterDamage } from "@mui/icons-material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import uuid from "react-uuid";
import Tabs from "../../../components/ui-kit/tabs";
import Tab from "../../../components/ui-kit/tab";
import useHash from "../../../hooks/useHash";
import AdminUtilityStatusCard from "../../../components/ui-kit/admin-utility-status-card";
import moment from "moment";
import AdminUtilityBalanceCard from "../../../components/ui-kit/admin-utility-balance-card";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import IconBase from "../../../components/ui-kit/IconBase";
import Button from "@mui/material/Button";
import TabPanel from "../../../components/ui-kit/tab-pannel";
import UtilityInfoTab from "./info-tab";
import HistoryTab from "../licence-details/history-tab";
import Typography from "@mui/material/Typography";
import Spacer from "../../../components/ui-kit/Spacer";
import ApprovalProgress from "../../../components/ui-kit/approval-progress";
import SearchDropDown from "../../../components/ui-kit/SearchDropDown";
import { AuthContext } from "../../../contexts/AuthContext";
import { get_admins } from "../../../../core/apis/admin";
import theme from "../../../../mui-theme/theme";
import Dialog from "../../../components/ui-kit/Dialog";

const tabs = ["Account Info", "Services", "Invoices & Payments", "History"];

const UtilitiesDetailScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const hash = useHash();
  const { id } = useParams();
  const [utilityInfo, setUtilityInfo] = useState(null);
  const [utilityInfoLoading, setUtilityInfoLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  const [anchorElReviewer, setAnchorElReviewer] = useState(null);
  const [anchorElIssuer, setAnchorElIssuer] = useState(null);
  const [issuer, setIssuer] = useState({});
  const [approveChangeDialogOpen, setApproveChangeDialogOpen] = useState(false);
  const [sendApprovalEmail, setSendApprovalEmail] = useState(null);

  /**
   * With each change in the url params:
   * - Calls the function for getting the utility information
   */
  useEffect(() => {
    fetchUtilityInformation().then();
    fetchReviewer().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let t = hash || tabs[0];
    setSelectedTab(t.replaceAll("%20", " "));
  }, [hash]);

  /**
   * Gets the utility full details from server by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchUtilityInformation = async () => {
    setUtilityInfoLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = {
        id: uuid(),
        serviceNumber: "#123 123414 123123",
        email: "testing@gmail.com",
      };
      setUtilityInfo(response);
    } catch (e) {
      console.log(e);
    } finally {
      setUtilityInfoLoading(false);
    }
  };

  /**
   * Gets the list of reviewers and supervisors from the server by calling related api
   * @returns {Promise<void>}
   *
   */
  const fetchReviewer = async () => {
    // TODO: call the api for getting the list of issuers and reviewer
    let assignedSupervisors = [];
    let assignedReviewers = [];
    setIssuer(assignedSupervisors[0]);
    setAssignedAdmins(
      assignedReviewers.map((admin) => ({
        id: admin.userId,
        first: admin.profile.firstName || "-",
        last: admin.profile.lastName || "-",
        approvalStatus: admin.status,
        lastUpdated: admin.lastUpdated,
      }))
    );
  };

  /**
   * Handles tab changes and sets the url hash
   * @param e
   * @param newTab
   */
  const onTabChanged = (e, newTab) => {
    history.push(`${location.pathname}#${newTab}`);
  };

  const onContactApplicant = () => {
    window.location.href = `mailto:${utilityInfo.email}?subject=City of Lacombe - Your business licence application`;
  };

  const isUser = (id) => {
    return id === user.id;
  };

  const getAdmins = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    let preAssignedIds = [];
    assignedAdmins.map((element) => preAssignedIds.push(element.id));
    return adminsRes.result.filter(
      (element) => !preAssignedIds.includes(element.id)
    );
  };

  /**
   * Handles adding reviewer to the utility account
   * @param value
   */
  const handleAssignReviewer = (value) => {
    setAssignedAdmins((prevState) => [
      ...prevState,
      {
        id: value.id,
        first: value.firstName,
        last: value.lastName,
        approvalStatus: "assigned",
        lastUpdated: moment().toISOString(),
      },
    ]);
  };

  const openReviewerSelection = (e) => {
    e.stopPropagation();
    setAnchorElReviewer(e.currentTarget);
  };

  const unAssignReviewer = (e) => {};

  const onUndoReviewerAction = () => {};

  const onReviewerApprove = async (accountId, reviewerId) => {
    setAssignedAdmins((prevState) =>
      prevState.map((e) =>
        e.id === reviewerId ? { ...e, approvalStatus: "approved" } : e
      )
    );
  };

  const onReviewerDeclined = async (accountId, reviewerId) => {
    setAssignedAdmins((prevState) =>
      prevState.map((e) =>
        e.id === reviewerId ? { ...e, approvalStatus: "approved" } : e
      )
    );
  };

  const fetchIssuers = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    return adminsRes?.result;
  };

  const handleAssignIssuer = (values) => {};

  const handleOpenIssuer = (e) => {
    e.stopPropagation();
    setAnchorElIssuer(e.currentTarget);
  };

  const unAssignIssuer = (e) => {};

  const onApproveButtonClick = () => {
    setApproveChangeDialogOpen(true);
  };

  /**
   * Closes the approval dialog and submits the changes to server
   * - Also sends email to user if option is selected
   * @return void
   */
  const onApproveChanges = () => {
    // TODO: handle the approval of changes
    setSendApprovalEmail(null);
    setApproveChangeDialogOpen(false);
  };

  /**
   * Indicates if the account has client changes
   * @type {boolean}
   */
  const hasClientChanges = useMemo(() => {
    // TODO: check based on changeset status or util info
    return true;
  }, []);

  /**
   * Indicates if the account has full admin approvals
   * @type {boolean}
   */
  const hasFullApproval = useMemo(() => {
    return (
      assignedAdmins.filter((e) => e.approvalStatus === "approved").length ===
        assignedAdmins.length && assignedAdmins.length > 0
    );
  }, [assignedAdmins]);

  /**
   * Sets the value of the [sendApprovalEmail] in inner state based on the selected radio box
   * @param event
   */
  const toggleSendEmail = (event) => {
    setSendApprovalEmail(event.target.value);
  };

  /**
   * Memo version of the content of the approval dialog
   * @type {JSX.Element}
   */
  const approvalDialogContent = useMemo(() => {
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          Approve changes
        </Typography>
        <Typography fontWeight={500} style={{ marginLeft: "0.5rem" }}>
          Email Notification
        </Typography>
        <RadioGroup
          defaultValue={sendApprovalEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
            marginBottom: "-0.5rem",
          }}
          onChange={toggleSendEmail}
        >
          <FormControlLabel
            value={"noEmail"}
            control={<Radio />}
            label="No email"
            style={{ background: "transparent" }}
          />
          <FormControlLabel
            value={"send"}
            control={<Radio />}
            label="Send an email"
            style={{ background: "transparent", marginLeft: "30px" }}
          />
        </RadioGroup>
      </div>
    );
  }, [sendApprovalEmail]);

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.main_container}>
        <div className={classes.page_title}>
          <WaterDamage fontSize={"large"} />
          <p style={{ marginRight: "0.2rem" }}>Utilities - </p>
          {utilityInfoLoading ? (
            <Skeleton variant={"rounded"} width={300} height={40} />
          ) : (
            <p>{`${utilityInfo?.serviceNumber ?? ""}`}</p>
          )}
        </div>
        <div className={classes.tab_bar}>
          <Tabs onChange={onTabChanged} value={selectedTab}>
            <Tab label={tabs[0]} value={tabs[0]} />
            <Tab label={tabs[1]} value={tabs[1]} />
            <Tab label={tabs[2]} value={tabs[2]} />
            <Tab label={tabs[3]} value={tabs[3]} />
          </Tabs>
        </div>
        <TabPanel value={selectedTab} index={tabs[0]}>
          <UtilityInfoTab
            loading={utilityInfoLoading}
            allHistory={[]}
            addComment={() => {}}
            fetchHistories={() => {}}
            setTab={setSelectedTab}
            data={{ ...utilityInfo, email: null }}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={tabs[3]}>
          <HistoryTab
            fetchHistories={() => {}}
            maxToShow={2}
            history={[]}
            _addComment={() => {}}
            data={utilityInfo}
            setTab={setSelectedTab}
          />
        </TabPanel>
      </div>
      {/*TODO: refactor the right sidebar to its own file*/}
      <div className={classes.right_sidebar}>
        <div className={classes.right_sidebar_content}>
          <AdminUtilityStatusCard
            loading={utilityInfoLoading}
            isActive={true}
            linkedDate={moment()}
            isLinked={true}
          />
          <AdminUtilityBalanceCard
            loading={utilityInfoLoading}
            balance={147.51}
            dueDate={moment()}
            lastPayment={moment()}
          />
          {!utilityInfoLoading && hasClientChanges && (
            <>
              <Button
                disabled={!hasFullApproval}
                onClick={onApproveButtonClick}
                variant={"contained"}
                fullWidth
              >
                Approve
              </Button>
              <Spacer amount={1} />
            </>
          )}
          <Button
            disabled={utilityInfoLoading}
            startIcon={<IconBase iconName={"mail"} />}
            onClick={() => {
              onContactApplicant();
            }}
            variant={"text"}
          >
            Contact applicant
          </Button>
          <Divider />
          {hasClientChanges && (
            <div className={classes.approval_section}>
              <Spacer />
              <Typography variant={"h4"} fontWeight={400} fontSize={24}>
                Approval process
              </Typography>
              <Spacer amount={1} />
              <Typography variant={"body1"} fontWeight={700}>
                1. Review the account (
                {`${
                  assignedAdmins.filter(
                    (item) => item.approvalStatus === "approved"
                  ).length
                }/${assignedAdmins.length}`}
                )
              </Typography>
              <Typography variant={"body1"}>
                Assign as many reviewers as necessary. At least one reviewer is
                required.
              </Typography>
              {assignedAdmins?.map((admin) => (
                <ApprovalProgress
                  type="reviewer"
                  id={admin.id}
                  key={admin.id}
                  isUser={isUser(admin.id)}
                  firstName={admin.first}
                  lastName={admin.last}
                  approvalStatus={admin.approvalStatus}
                  lastUpdated={admin.lastUpdated}
                  handleApprove={(id) => onReviewerApprove(id, admin.id)}
                  handleDecline={(id) => onReviewerDeclined(id, admin.id)}
                  handleUndo={onUndoReviewerAction}
                  handleUnassign={unAssignReviewer}
                  data={utilityInfo}
                />
              ))}
              <Spacer amount={0.5} />
              <Button
                size={"small"}
                style={{ color: theme.palette.primary[200] }}
                startIcon={
                  <IconBase
                    iconName={"add"}
                    color={theme.palette.primary[200]}
                  />
                }
                onClick={openReviewerSelection}
              >
                Assign reviewer
              </Button>
              <SearchDropDown
                titleLabel="Approval Required"
                title={"Assign Reviewers:"}
                onSelect={(values) => handleAssignReviewer(values)}
                fetchData={getAdmins}
                anchorEl={anchorElReviewer}
                setAnchorEl={setAnchorElReviewer}
                searchBarPlaceholder={"Search name"}
              />
              <Spacer amount={1} />
              <Typography variant={"body1"} fontWeight={700}>
                2. Approve the account
              </Typography>
              <Typography variant={"body1"}>
                All assigned reviewers must give their approval for the changes
                to be published.
              </Typography>
              {issuer?.userId && (
                <ApprovalProgress
                  type="issuer"
                  id={issuer?.userId}
                  key={issuer?.userId}
                  isUser={isUser(issuer?.userId)}
                  firstName={issuer.profile.firstName}
                  lastName={issuer.profile.lastName}
                  approvalStatus={issuer.status}
                  lastUpdated={issuer.lastUpdated}
                  handleUnassign={unAssignIssuer}
                  data={utilityInfo}
                />
              )}
              {!issuer?.userId && (
                <Button
                  size={"small"}
                  style={{ color: theme.palette.primary[200] }}
                  startIcon={
                    <IconBase
                      iconName={"add"}
                      color={theme.palette.primary[200]}
                    />
                  }
                  onClick={handleOpenIssuer}
                >
                  Assign supervisor
                </Button>
              )}
              <Spacer amount={0.5} />
              <SearchDropDown
                titleLabel="Approval Required"
                title={"Assign Supervisor:"}
                onSelect={(values) => handleAssignIssuer(values)}
                fetchData={fetchIssuers}
                anchorEl={anchorElIssuer}
                setAnchorEl={setAnchorElIssuer}
                searchBarPlaceholder={"Search name"}
              />
            </div>
          )}
        </div>
      </div>
      <Dialog
        variant={"updated"}
        open={approveChangeDialogOpen}
        handleClose={() => setApproveChangeDialogOpen(false)}
        BodyComponent={approvalDialogContent}
        buttonOneText={"Cancel"}
        buttonTwoText={"CONFIRM"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => setApproveChangeDialogOpen(false)}
        handleButtonTwo={onApproveChanges}
        buttonTwoDisable={sendApprovalEmail === null}
      />
    </div>
  );
};

export default UtilitiesDetailScreen;
