import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  css,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  add_issuer,
  add_reviewer,
  applyChangesToLicence,
  get_issuer,
  get_reviewer,
  getPDF,
  mark_license_paid,
  remove_issuer,
  remove_reviewer,
  renewLicence,
  restartStatus,
  update_reviewer_status,
  update_status_discarded,
  updateChangeSetStatus,
  updateStatusDeclined,
  updateStatusIssued,
  updateStatusRevoked,
} from "../../../../../core/apis/licence";
import {
  ACTIVE,
  APPROVED,
  BUSINESS_NAME_LENGTH,
  CANCELLED,
  CREATED,
  DECLINED,
  DRAFT,
  EXPIRED,
  INACTIVE_GROUP,
  ISSUED,
  REVIEWING,
  REVIEWING_GROUP,
  REVOKED,
  SUBMITTED,
} from "../../../../../core/constants/licences";
import FileDownload from "js-file-download";
import { round } from "lodash/math";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { get_admins } from "../../../../../core/apis/admin";
import Spacer from "../../../../components/ui-kit/Spacer";
import IconBase from "../../../../components/ui-kit/IconBase";
import SearchDropDown from "../../../../components/ui-kit/SearchDropDown";
import ListItem from "../../../../components/ui-kit/list-item";
import ApprovalProgress from "../../../../components/ui-kit/approval-progress";
import Dialog from "../../../../components/ui-kit/Dialog";
import { truncate, userHasRole } from "../../../../../utilities";
import NotificationBar from "../../../../components/ui-kit/NotificationBar";
import Button from "@mui/material/Button";
import classes from "./index.module.scss";

const Content = styled(Box)(
  () => css`
    width: 300px;
    @media (max-width: 1740px) {
      width: 270px;
    }
  `
);

const RevokeButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
  }

  && .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED};
  }
`;

/**
 * Right sidebar of the licence details screen.
 * @returns {JSX.Element}
 * @constructor
 */
export default function RightSidebar({
  emailToUse,
  status,
  data,
  preAssigned,
  refresher,
  refreshValue,
  saveAllEdits,
  editing,
  setEditing,
  editData,
  setEditData,
  setErrors,
  tab,
}) {
  const history = useHistory();
  const theme = useTheme();
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  // const [showMore, setShowMore] = useState(false);
  const { handleSuccess, handleError, handleShow } =
    useContext(NotificationContext);
  const [anchorElReviewer, setAnchorElReviewer] = useState(null);
  const [anchorElIssuer, setAnchorElIssuer] = useState(null);
  const { user } = useContext(AuthContext);
  const [issuer, setIssuer] = useState({});
  const [issueNotification, setIssueNotification] = useState(false);
  const [declineNotification, setDeclineNotification] = useState(false);
  const [sendEmail, setSendEmail] = useState(null);
  const [amendmentFee, setAmendmentFee] = useState(null);

  //Modals
  const [openPendingModal, setOpenPendingModal] = useState(false);
  const [openIssueModal, setOpenIssueModal] = useState(false);
  const [openPaidModal, setOpenPaidModal] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [openReviewDeclineModal, setOpenReviewDeclineModal] = useState(false);
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [openRenewModal, setOpenRenewModal] = useState(false);
  const [openRestartModal, setOpenRestartModal] = useState(false);
  const [openUndoEditModal, setOpenUndoEditModal] = useState(false);
  const [openApproveChangesModal, setOpenApproveChangesModal] = useState(false);
  const [openApplyChangesModal, setOpenApplyChangesModal] = useState(false);
  const [openDeclineChangesModal, setOpenDeclineChangesModal] = useState(false);
  const [openDiscardDraftModal, setOpenDiscardDraftModal] = useState(false);

  // //Modal Handlers
  const handleOpenPendingModal = () => {
    setOpenPendingModal(true);
  };
  const handleOpenIssueModal = () => {
    setOpenIssueModal(true);
  };
  const handleCloseIssueModal = () => {
    setOpenIssueModal(false);
  };
  const handleOpenPaidModal = () => {
    setOpenPaidModal(true);
  };
  const handleOpenDeclineModal = () => {
    setOpenDeclineModal(true);
  };
  const handleCloseDeclineModal = () => {
    setOpenDeclineModal(false);
  };
  const handleOpenReviewDeclineModal = () => {
    setOpenReviewDeclineModal(true);
  };
  const handleCloseReviewDeclineModal = () => {
    setOpenReviewDeclineModal(false);
  };
  const handleOpenRevokeModal = () => {
    setOpenRevokeModal(true);
  };
  const handleCloseRevokeModal = () => {
    setOpenRevokeModal(false);
  };
  const handleOpenRenewModal = () => {
    setOpenRenewModal(true);
  };
  const handleCloseRenewModal = () => {
    setOpenRenewModal(false);
  };
  const handleOpenRestartingModal = () => {
    setOpenRestartModal(true);
  };
  const handleCloseRestartingModal = () => {
    setOpenRestartModal(false);
  };
  const handleOpenDiscardModal = () => {
    setOpenDiscardDraftModal(true);
  };

  const handleCloseApprovalModal = () => {
    setOpenApproveChangesModal(false);
    setSendEmail(null);
    setAmendmentFee(null);
  };

  //Modal Functions
  const onIssueLicence = async () => {
    try {
      await updateStatusIssued(data.id, false);
    } catch (e) {
      console.log(e.message);
    }
    refresher(!refreshValue);
    setOpenIssueModal(false);
    setIssueNotification(true);
  };

  const onApproveChanges = async () => {
    try {
      if (data.change_set_status === APPROVED && !editing) {
        if (amendmentFee === "charge") {
          await updateChangeSetStatus(
            data.id,
            data.change_set_id,
            "issued",
            sendEmail
          );
        } else {
          await applyChangesToLicence(data.id, data.change_set_id, sendEmail);
        }
      } else {
        if (amendmentFee === "charge") {
          await saveAllEdits(false, sendEmail);
        } else {
          await saveAllEdits(true, sendEmail);
        }
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setOpenApproveChangesModal(false);
      refresher(!refreshValue);
    }
  };

  const checkChanges = () => {
    for (let i = 0; i < editData.length; i++) {
      if (Object.keys(editData[i]).length > 0) {
        return false;
      }
    }
    return true;
  };

  const handleDecline = async () => {
    try {
      await updateStatusDeclined(data.id);
    } catch (e) {
      console.log(e.message);
    }
    handleCloseDeclineModal();
    handleShow("Business licence declined");
    refresher(!refreshValue);
  };

  const handleDeclineChanges = async () => {
    try {
      await updateChangeSetStatus(data.id, data.change_set_id, "declined");
    } catch (e) {
      console.log(e.message);
    }
    setOpenDeclineChangesModal(false);
    refresher(!refreshValue);
  };

  const onRestart = async () => {
    try {
      await restartStatus(data.id);
    } catch (e) {
      console.log(e.message);
    }
    refresher(!refreshValue);
    handleCloseRestartingModal();
  };

  const onRevoke = async () => {
    try {
      await updateStatusRevoked(data.id);
      handleSuccess("Licence revoked");
    } catch (e) {
      console.log(e.message);
    }
    handleCloseRevokeModal();
    refresher(!refreshValue);
  };

  const onRenew = async () => {
    try {
      await renewLicence(data.id);
    } catch (e) {
      console.log(e.message);
    }
    handleCloseRenewModal();
    refresher(!refreshValue);
  };

  // //Button Handlers
  const onMarkPaid = async () => {
    try {
      await mark_license_paid(data.id);
      handleSuccess(`Licence marked as paid`);
    } catch (e) {
      console.log(e.message);
      handleError(`Error: ${e.message}`);
    }
    setOpenPaidModal(false);
    refresher(!refreshValue);
  };

  const handleEmail = () => {
    window.location.href = `mailto:${emailToUse}?subject=City of Lacombe - Your business licence application`;
  };

  const handlePaid = () => {
    handleOpenPaidModal();
  };

  const handleApprove = async (id) => {
    await update_reviewer_status(id, "approved");
    refresher(!refreshValue);
  };

  const onDeclineReview = async () => {
    await update_reviewer_status(data?.id, "denied");
    refresher(!refreshValue);
    handleCloseReviewDeclineModal();
    handleSuccess("Licence rejected");
  };

  const onUndo = async (id) => {
    await update_reviewer_status(id, "assigned");
    refresher(!refreshValue);
  };

  const onUnassign = async (e) => {
    await remove_reviewer(e, data.id);
    refresher(!refreshValue);
  };

  const onUnassignIssuer = async (e) => {
    await remove_issuer(e, data.id);
    refresher(!refreshValue);
  };

  const handleAssignAdmin = async (e) => {
    await add_reviewer(e.id, data.id);
    refresher(!refreshValue);
  };

  const handleAssignIssuer = async (e) => {
    await add_issuer(e.id, data.id);
    refresher(!refreshValue);
  };

  const handleUndoEdit = () => {
    setEditData([]);
    setErrors({});
    setEditing(false);
    setOpenUndoEditModal(false);
    refresher(!refreshValue);
  };

  // Core functions
  const addAdmin = (e) => {
    let tmpArray = [];
    tmpArray = tmpArray.filter((item) => item.id !== e.id);
    e.map(
      (admin) =>
        (tmpArray = [
          ...tmpArray,
          {
            id: admin.userId,
            first: admin.profile.firstName || "-",
            last: admin.profile.lastName || "-",
            approvalStatus: admin.status,
            lastUpdated: admin.lastUpdated,
          },
        ])
    );
    setAssignedAdmins(tmpArray);
  };

  //User Context
  const userId = 1; //TODO - This will be the user id from context
  const isUser = (id) => {
    return id === user.id;
  };
  const fetchData = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    let preAssignedIds = [];
    assignedAdmins.map((element) => preAssignedIds.push(element.id));
    return adminsRes.result.filter(
      (element) => !preAssignedIds.includes(element.id)
    );
  };

  const fetchIssuers = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) => (admin.color = theme.palette.primary[500])
    );
    return adminsRes?.result;
  };

  const handleEditingModeSubmit = () => {
    if (REVIEWING_GROUP.find((x) => x === data?.status)) {
      setOpenApplyChangesModal(true);
      return;
    }
    if (
      (status === ACTIVE &&
        !REVIEWING_GROUP.find((x) => x === data?.change_set_status)) ||
      (data.balance > 0 &&
        data.status !== DRAFT &&
        data.status !== CREATED &&
        !REVIEWING_GROUP.find((x) => x === data?.change_set_status))
    ) {
      setOpenApproveChangesModal(true);
      return;
    }
    if (editing) {
      if (status === DRAFT || status === CREATED) {
        saveAllEdits(true);
      } else {
        setOpenApplyChangesModal(true);
      }
    }
  };

  //get assigned admins
  useEffect(() => {
    //We might just accept the admins as props
    const fetchReviewer = async () => {
      let assigned = await get_reviewer(data.id);
      let assignedIssuer = await get_issuer(data.id);
      setIssuer(assignedIssuer[0]);
      addAdmin(assigned);
    };
    fetchReviewer().then();
  }, [refreshValue, status, data.id, preAssigned]);

  const handleOpenReviewer = (e) => {
    e.stopPropagation();
    setAnchorElReviewer(e.currentTarget);
  };

  const handleIssue = (data) => {
    if (data.status === APPROVED) {
      handleOpenIssueModal();
    } else {
      handleOpenPendingModal();
    }
  };

  const handleOpenIssuer = (e) => {
    e.stopPropagation();
    setAnchorElIssuer(e.currentTarget);
  };

  const handleDownload = async () => {
    const license = await getPDF(data.id);
    if (license) {
      FileDownload(license, "Lacombe Business Licence.pdf", "application/pdf");
    }
  };

  const handleUndoButton = () => {
    if (status === DRAFT || status === CREATED) {
      handleOpenDiscardModal();
    } else {
      setOpenUndoEditModal(true);
    }
  };

  const discardDraft = async () => {
    try {
      await update_status_discarded(data.id);
      setEditing(false);
      handleShow("Business licence discarded");
      history.goBack();
    } catch (e) {
      handleError("There was an issue discarding your draft. Please try again");
    }
  };

  const handleSendEmail = (event) => {
    setSendEmail(event.target.value);
  };
  const handleSetAmendmentFee = (event) => {
    setAmendmentFee(event.target.value);
  };

  /**
   * Body component of the approve changes dialog
   * @return {JSX.Element}
   * @constructor
   */
  function BodyComponent() {
    let fee = false;
    if (data.balance === 0) {
      fee = true;
    }
    return (
      <div style={{ width: "400px" }}>
        <Typography
          fontSize={"24px"}
          style={{ marginLeft: "0.5rem", marginBottom: "2rem" }}
        >
          {editing ? "Apply changes" : "Approve changes"}
        </Typography>
        {fee === true && (
          <>
            <Typography fontWeight={500} style={{ marginLeft: "0.5rem" }}>
              $20 Amendment Fee
            </Typography>
            <RadioGroup
              defaultValue={amendmentFee}
              name="radio-buttons-group"
              style={{
                display: "flex",
                flexDirection: "row",
                background: "transparent",
                marginTop: "-1rem",
              }}
              onChange={handleSetAmendmentFee}
            >
              <FormControlLabel
                value={"waive"}
                control={<Radio />}
                label="Waive fee"
                style={{ background: "transparent" }}
              />
              <FormControlLabel
                value={"charge"}
                control={<Radio />}
                label="Charge $20 fee"
                style={{ background: "transparent", marginLeft: "25px" }}
              />
            </RadioGroup>
          </>
        )}

        <Typography fontWeight={500} style={{ marginLeft: "0.5rem" }}>
          Email Notification
        </Typography>
        <RadioGroup
          defaultValue={sendEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
            marginBottom: "-0.5rem",
          }}
          onChange={handleSendEmail}
        >
          <FormControlLabel
            value={"noEmail"}
            control={<Radio />}
            label="No email"
            style={{ background: "transparent" }}
          />
          <FormControlLabel
            value={"send"}
            control={<Radio />}
            label="Send an email"
            style={{ background: "transparent", marginLeft: "30px" }}
          />
        </RadioGroup>
      </div>
    );
  }

  return (
    <div className={classes.main_container}>
      <Content p={2}>
        {editing ? (
          <>
            <StatusCard status={data?.status} data={data} editing={editing} />
            <Spacer amount={3} />
            <Button
              onClick={handleEditingModeSubmit}
              variant={"contained"}
              size={"medium"}
              startIcon={
                <IconBase
                  fill={1}
                  iconName={"done"}
                  color={theme.palette.whites.WHITE}
                />
              }
              disabled={checkChanges()}
            >
              {status === DRAFT || status === CREATED
                ? "Submit Licence"
                : "Apply Changes"}
            </Button>
            <Button
              onClick={handleUndoButton}
              size={"medium"}
              startIcon={
                status === DRAFT || status === CREATED ? (
                  <IconBase
                    iconName={"delete"}
                    color={theme.palette.nonPalette.RED}
                  />
                ) : (
                  <IconBase
                    fill={1}
                    iconName={"undo"}
                    color={theme.palette.primary[500]}
                  />
                )
              }
              style={{ marginTop: "0.5rem" }}
              color={
                status === DRAFT || status === CREATED ? "error" : "inherit"
              }
              {...(status === DRAFT || status === CREATED
                ? {
                    sx: {
                      ".MuiTouchRipple-child": {
                        color: `${theme.palette.nonPalette.RED} !important`,
                      },
                      "&:hover": {
                        backgroundColor:
                          theme.palette.nonPalette.RED_BACKGROUND,
                        ".MuiTouchRipple-child": {
                          color: theme.palette.nonPalette.RED,
                        },
                      },
                      "&:active": {
                        backgroundColor:
                          theme.palette.nonPalette.RED_BACKGROUND,
                        ".MuiTouchRipple-child": {
                          color: theme.palette.nonPalette.RED,
                        },
                      },
                    },
                  }
                : {})}
            >
              {status === DRAFT || status === CREATED ? "Discard" : "Undo"}
            </Button>
          </>
        ) : (
          <>
            <StatusCard status={data?.status} data={data} />
            <Spacer amount={3} />
            {REVIEWING_GROUP.find((x) => x === data?.change_set_status) ||
            REVIEWING_GROUP.find((x) => x === data?.status) ? (
              <div>
                <Button
                  onClick={() => {
                    data.change_set_status === APPROVED
                      ? setOpenApproveChangesModal(true)
                      : handleIssue(data);
                  }}
                  variant={"contained"}
                  size={"medium"}
                  fullWidth
                  disabled={
                    (data.change_set_status &&
                      data.change_set_status !== APPROVED) ||
                    (data?.status !== APPROVED && !data.change_set_status)
                  }
                >
                  {REVIEWING_GROUP.find((x) => x === data?.change_set_status)
                    ? "Approve Changes"
                    : "Issue licence"}
                </Button>
              </div>
            ) : null}
            {((data.status === ISSUED || data.status === ACTIVE) &&
              data.balance > 0 &&
              !data.change_set_status) ||
            data.change_set_status === ISSUED ? (
              <Button
                onClick={handlePaid}
                variant={"contained"}
                size={"medium"}
                startIcon={
                  <IconBase
                    fill={1}
                    iconName={"thumb_up"}
                    color={theme.palette.whites.WHITE}
                  />
                }
              >
                Mark as paid
              </Button>
            ) : null}
            {INACTIVE_GROUP.find((x) => x === data?.status) ? (
              <Button
                startIcon={<IconBase iconName={"reply"} />}
                onClick={() => {
                  handleOpenRestartingModal();
                }}
                variant={"text"}
              >
                Restart
              </Button>
            ) : null}
            <Spacer amount={0.5} />
            {(status === ACTIVE ||
              status === SUBMITTED ||
              status === REVIEWING ||
              status === APPROVED) &&
              tab === "General Info" && (
                <Button
                  startIcon={<IconBase iconName={"edit"} />}
                  onClick={() => {
                    setEditing(!editing);
                  }}
                  variant={"text"}
                >
                  Edit
                </Button>
              )}
            <MoreActionsSection
              id={userId}
              data={data}
              handleDecline={handleOpenDeclineModal}
              handleDeclineChanges={() => {
                setOpenDeclineChangesModal(true);
              }}
              handleEmail={handleEmail}
              handleOpenRevokeModal={handleOpenRevokeModal}
              handleOpenRenewModal={handleOpenRenewModal}
              handleDownloadPDF={handleDownload}
            />
          </>
        )}
        <Spacer amount={0.5} />
        <Divider />
        <Spacer />
        {REVIEWING_GROUP.find((x) => x === data?.status) ||
        REVIEWING_GROUP.find((x) => x === data?.change_set_status) ? (
          <>
            <Typography variant={"h4"}>Licence progress</Typography>
            <Spacer amount={1} />
            <Typography variant={"body1"} fontWeight={700}>
              1. Reviewing the licence (
              {`${
                assignedAdmins.filter(
                  (item) => item.approvalStatus === "approved"
                ).length
              }/${assignedAdmins.length}`}
              )
            </Typography>
            <Typography variant={"body1"}>
              Assign as many reviewers as necessary. At least one reviewer is
              required.{" "}
            </Typography>
            {assignedAdmins?.map((admin) => (
              <ApprovalProgress
                type="reviewer"
                id={admin.id}
                key={admin.id}
                isUser={isUser(admin.id)}
                firstName={admin.first}
                lastName={admin.last}
                approvalStatus={admin.approvalStatus}
                lastUpdated={admin.lastUpdated}
                handleApprove={handleApprove}
                handleDecline={handleOpenReviewDeclineModal}
                handleUndo={onUndo}
                handleUnassign={onUnassign}
                data={data}
              />
            ))}
            <Spacer amount={0.5} />
            <Button
              size={"small"}
              style={{ color: theme.palette.primary[200] }}
              startIcon={
                <IconBase iconName={"add"} color={theme.palette.primary[200]} />
              }
              onClick={handleOpenReviewer}
            >
              Assign reviewer
            </Button>
            <SearchDropDown
              titleLabel="Approval Required"
              title={"Assign Reviewers:"}
              onSelect={(values) => handleAssignAdmin(values)}
              fetchData={fetchData}
              anchorEl={anchorElReviewer}
              setAnchorEl={setAnchorElReviewer}
            />
            <Spacer amount={1} />
            <Typography variant={"body1"} fontWeight={700}>
              2. Issuing the licence
            </Typography>
            <Typography variant={"body1"}>
              All assigned reviewers must approve the licence so it can be
              issued.
            </Typography>
            {issuer?.userId && (
              <ApprovalProgress
                type="issuer"
                id={issuer?.userId}
                key={issuer?.userId}
                isUser={isUser(issuer?.userId)}
                firstName={issuer.profile.firstName}
                lastName={issuer.profile.lastName}
                approvalStatus={issuer.status}
                lastUpdated={issuer.lastUpdated}
                handleUnassign={onUnassignIssuer}
                data={data}
              />
            )}
            {!issuer?.userId && (
              <Button
                size={"small"}
                style={{ color: theme.palette.primary[200] }}
                startIcon={
                  <IconBase
                    iconName={"add"}
                    color={theme.palette.primary[200]}
                  />
                }
                onClick={handleOpenIssuer}
              >
                Assign issuer
              </Button>
            )}
            <Spacer amount={0.5} />
            <SearchDropDown
              titleLabel="Approval Required"
              title={"Assign Issuer:"}
              onSelect={(values) => handleAssignIssuer(values)}
              fetchData={fetchIssuers}
              anchorEl={anchorElIssuer}
              setAnchorEl={setAnchorElIssuer}
            />
          </>
        ) : (
          <></>
        )}
      </Content>

      {/*TODO: Refactor below dialogs into separate components*/}
      <Dialog
        title={"Approvals pending"}
        body={
          "Some reviewers have not approved the licence yet. Check the Approval Progress on the right sidebar."
        }
        buttonOneText={"Close"}
        open={openPendingModal}
        handleClose={() => {
          setOpenPendingModal(false);
        }}
        handleButtonOne={() => {
          setOpenPendingModal(false);
        }}
        buttonOneColor={theme.palette.primary[200]}
      />
      <Dialog
        title={"Issue business licence"}
        body={
          truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
          " will receive a notification email asking to pay their fee." +
          " They can download the licence PDF once they pay the fee."
        }
        open={openIssueModal}
        handleClose={() => {
          handleCloseIssueModal(false);
        }}
        buttonOneText={"Close"}
        handleButtonOne={() => {
          handleCloseIssueModal(false);
        }}
        buttonTwoText={"Issue licence"}
        handleButtonTwo={() => {
          onIssueLicence();
        }}
        buttonTwoColor={theme.palette.primary[200]}
      />
      <Dialog
        title={"Declining business licence"}
        body={
          truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
          " will receive an email notifying them their licence application has been declined."
        }
        open={openDeclineModal}
        handleClose={() => {
          setOpenDeclineModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenDeclineModal(false);
        }}
        buttonTwoText={"Decline licence"}
        handleButtonTwo={() => {
          handleDecline();
        }}
        buttonTwoColor={theme.palette.nonPalette.RED}
      />
      <Dialog
        title={"Rejecting licence"}
        body={
          "If a supervisor is assigned, they will receive a ticket to review and decline the licence."
        }
        open={openReviewDeclineModal}
        handleClose={() => {
          setOpenReviewDeclineModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenReviewDeclineModal(false);
        }}
        buttonTwoText={"Reject Licence"}
        handleButtonTwo={() => {
          onDeclineReview();
        }}
        buttonTwoColor={theme.palette.nonPalette.RED}
      />
      <Dialog
        title={"Marking licence as paid"}
        body={
          truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
          " will receive an email notifying them their licence has been paid."
        }
        open={openPaidModal}
        handleClose={() => {
          setOpenPaidModal(false);
        }}
        buttonOneText={"Close"}
        handleButtonOne={() => {
          setOpenPaidModal(false);
        }}
        buttonTwoText={"Mark as Paid"}
        handleButtonTwo={onMarkPaid}
        buttonTwoColor={theme.palette.primary[200]}
      />
      <Dialog
        title={"Revoking business licence"}
        body={
          truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
          " will receive an email notifying them their licence has been revoked."
        }
        open={openRevokeModal}
        handleClose={() => {
          setOpenRevokeModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenRevokeModal(false);
        }}
        buttonTwoText={"Revoke Licence"}
        handleButtonTwo={onRevoke}
        buttonTwoColor={theme.palette.nonPalette.RED}
      />
      <Dialog
        title={"Restarting a licence"}
        body={
          `This will restart the business licence application for ` +
          truncate(data?.business_name, BUSINESS_NAME_LENGTH)
        }
        open={openRestartModal}
        handleClose={() => {
          setOpenRestartModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenRestartModal(false);
        }}
        buttonTwoText={"Restart"}
        handleButtonTwo={onRestart}
        buttonTwoColor={theme.palette.primary[200]}
      />
      <Dialog
        title={"Undoing changes"}
        body={
          "All the changes you made will be discarded and the licence will remain unchanged."
        }
        open={openUndoEditModal}
        handleClose={() => {
          setOpenUndoEditModal(false);
        }}
        buttonOneText={"Back"}
        handleButtonOne={() => {
          setOpenUndoEditModal(false);
        }}
        buttonTwoText={"Undo changes"}
        handleButtonTwo={handleUndoEdit}
        buttonTwoColor={theme.palette.nonPalette.RED}
      />
      <Dialog
        title={"Declining licence changes"}
        body={
          "The applicant will receive an email notifying them their changes are declined."
        }
        open={openDeclineChangesModal}
        handleClose={() => {
          setOpenDeclineChangesModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenDeclineChangesModal(false);
        }}
        buttonTwoText={"Decline"}
        handleButtonTwo={handleDeclineChanges}
        buttonTwoColor={theme.palette.nonPalette.RED}
      />
      <Dialog
        title={"Discarding draft"}
        body={
          "All the information contained in this draft will" +
          " be" +
          " deleted. A new application will require all information" +
          " to be re-input from the beginning."
        }
        open={openDiscardDraftModal}
        handleClose={() => {
          setOpenDiscardDraftModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenDiscardDraftModal(false);
        }}
        buttonTwoText={"Discard"}
        handleButtonTwo={discardDraft}
        buttonTwoColor={theme.palette.nonPalette.RED}
      />
      <Dialog
        title={"Renewing business licence"}
        body={
          truncate(data?.business_name, BUSINESS_NAME_LENGTH) +
          " will receive an email notifying them their licence has been renewed and requires payment."
        }
        open={openRenewModal}
        handleClose={() => {
          setOpenRenewModal(false);
        }}
        buttonOneText={"Cancel"}
        handleButtonOne={() => {
          setOpenRenewModal(false);
        }}
        buttonTwoText={"Renew Licence"}
        handleButtonTwo={onRenew}
        buttonTwoColor={theme.palette.primary[200]}
      />
      <Dialog
        variant={"updated"}
        open={openApproveChangesModal}
        handleClose={handleCloseApprovalModal}
        BodyComponent={BodyComponent()}
        buttonOneText={"Cancel"}
        buttonTwoText={"CONFIRM"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={handleCloseApprovalModal}
        handleButtonTwo={onApproveChanges}
        buttonTwoDisable={(!amendmentFee && data.balance === 0) || !sendEmail}
      />
      <Dialog
        variant={"updated"}
        open={openApplyChangesModal}
        handleClose={() => {
          setOpenApplyChangesModal(false);
        }}
        title={"Apply changes"}
        body={"Your changes will be saved. Do you want to proceed?"}
        buttonOneText={"Cancel"}
        buttonTwoText={"Save"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          setOpenApplyChangesModal(false);
        }}
        handleButtonTwo={onApproveChanges}
        buttonTwoDisable={false}
      />
      <NotificationBar
        open={issueNotification}
        body={`Business licence issued.`}
        secondaryButtonLabel={"DISMISS"}
        onClickSecondary={() => {
          setIssueNotification(false);
        }}
      />
      <NotificationBar
        open={declineNotification}
        body={`Business licence declined.`}
        secondaryButtonLabel={"DISMISS"}
        onClickSecondary={() => {
          setDeclineNotification(false);
        }}
      />
    </div>
  );
}

const RedHoverButton = styled(Button)(
  ({ theme }) => css`
    .material-symbols-rounded {
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &&:hover {
      background-color: ${theme.palette.nonPalette.RED_BACKGROUND};
      color: ${theme.palette.nonPalette.RED};

      && .material-symbols-rounded {
        color: ${theme.palette.nonPalette.RED};
      }
    }
  `
);

const MoreActionsSection = ({
  data,
  handleDecline,
  handleDeclineChanges,
  handleEmail,
  handleOpenRevokeModal,
  handleOpenRenewModal,
  handleDownloadPDF,
}) => {
  const theme = useTheme();
  const daysToExpire = round(
    moment.utc(data.expiry).diff(moment.now()) / 86400000,
    0
  );
  const isSuper = userHasRole("super");

  return (
    <Box>
      {data.status === REVIEWING ||
      data.status === APPROVED ||
      data.status === SUBMITTED ||
      data.status === ISSUED ||
      REVIEWING_GROUP.find((x) => x === data?.change_set_status) ? (
        <RedHoverButton
          sx={{
            // color: theme.palette.nonPalette.RED,
            ".MuiTouchRipple-child": {
              color: `${theme.palette.nonPalette.RED} !important`,
            },
            "&:hover": {
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
              ".MuiTouchRipple-child": {
                color: theme.palette.nonPalette.RED,
              },
            },
            "&:active": {
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
              ".MuiTouchRipple-child": {
                color: theme.palette.nonPalette.RED,
              },
            },
          }}
          startIcon={<IconBase iconName={"delete"} />}
          onClick={() => {
            REVIEWING_GROUP.find((x) => x === data?.change_set_status)
              ? handleDeclineChanges()
              : handleDecline();
          }}
          variant={"text"}
        >
          {REVIEWING_GROUP.find((x) => x === data?.change_set_status)
            ? "Decline Changes"
            : "Decline"}
        </RedHoverButton>
      ) : null}
      {data.status === ACTIVE ? (
        <>
          {isSuper && data.balance === 0 && daysToExpire < 30 ? (
            <Button
              startIcon={<IconBase iconName={"alarm"} />}
              onClick={handleOpenRenewModal}
              variant={"text"}
            >
              Renew
            </Button>
          ) : (
            <></>
          )}
          <Spacer />
          <RevokeButton
            onClick={handleOpenRevokeModal}
            style={{
              color: theme.palette.nonPalette.RED,
              marginBottom: "1rem",
            }}
            startIcon={
              <IconBase
                wght={600}
                color={theme.palette.nonPalette.RED}
                iconName={"report"}
              />
            }
          >
            Revoke
          </RevokeButton>
          <Divider />
          <Spacer />
          <Button
            startIcon={<IconBase iconName={"download"} />}
            onClick={() => {
              handleDownloadPDF();
            }}
            variant={"text"}
          >
            Download PDF
          </Button>
        </>
      ) : null}
      <Spacer />
      <Divider />
      <Spacer />
      <Button
        startIcon={<IconBase iconName={"mail"} />}
        onClick={() => {
          handleEmail();
        }}
        variant={"text"}
      >
        Contact applicant
      </Button>
    </Box>
  );
};

const StatusCard = ({ status, data, editing }) => {
  const theme = useTheme();
  if (editing) {
    return (
      <ListItem
        style={{
          padding: 15,
          backgroundColor: theme.palette.greys.GREY_LIGHT,
        }}
        label={<span style={{ fontWeight: 500 }}>Editing</span>}
        startOrnament={<IconBase size={32} iconName={"edit"} />}
        subLabel={"Unsaved changes"}
      />
    );
  } else {
    switch (status) {
      case REVIEWING:
      case SUBMITTED:
      case APPROVED:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.greys.GREY_LIGHT,
            }}
            label={<span style={{ fontWeight: 500 }}>Under review</span>}
            startOrnament={<IconBase size={32} iconName={"find_in_page"} />}
            subLabel={"Please, complete all steps"}
          />
        );
      case ISSUED:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.greys.GREY_LIGHT,
            }}
            label={<span style={{ fontWeight: 500 }}>Issued</span>}
            startOrnament={<IconBase size={32} iconName={"approval"} />}
            subLabel={"Payment pending."}
          />
        );
      case ACTIVE:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.primary[50],
            }}
            label={<span style={{ fontWeight: 500 }}>Active</span>}
            labelStyles={{ color: theme.palette.primary[200] }}
            startOrnament={
              <IconBase
                size={32}
                color={theme.palette.primary[200]}
                iconName={"check"}
              />
            }
            subLabel={
              "Since " + moment(data.lastUpdated).format("MMM DD, YYYY")
            }
          />
        );
      case DECLINED:
      case EXPIRED:
      case REVOKED:
      case CANCELLED:
        return (
          <ListItem
            style={{
              padding: 15,
              backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
            }}
            labelStyles={{ color: theme.palette.nonPalette.RED }}
            label={<span style={{ fontWeight: 500 }}>Inactive</span>}
            startOrnament={
              <IconBase
                color={theme.palette.nonPalette.RED}
                size={32}
                iconName={"cancel"}
              />
            }
            subLabel={
              "Since " + moment(data.lastUpdated).format("MMM DD, YYYY")
            }
          />
        );
      default:
        return null;
    }
  }
};
