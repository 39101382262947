import React from "react";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Spacer from "../../../../components/layout-helpers/Spacer";
import Modal from "../../../../components/ui-kit/Modal";

const RemoveButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
  }
  && .MuiTouchRipple-child {
    background-color: red;
  }
`;

const StyledModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

export default function DeleteCardModal({ open, handleClose, onDelete }) {
  const theme = useTheme();
  return (
    <StyledModal open={open}>
      <Typography variant={"body1"} fontWeight={"700"}>
        Delete card
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        Are you sure you want to delete your card? If you want to use it later
        you’ll have to add it again.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
            Cancel
          </Typography>
        </Button>
        <RemoveButton
          variant={"text"}
          onClick={() => {
            onDelete();
            handleClose();
          }}
        >
          <Typography color={"red"} fontWeight={"700"}>
            Delete
          </Typography>
        </RemoveButton>
      </Box>
    </StyledModal>
  );
}
