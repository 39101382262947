import IconBase from "../../../../../components/ui-kit/IconBase";
import Banner from "../../../../../components/ui-kit/Banner";
import React from "react";
import {useTheme} from "@mui/material";
import IUtilityAccountResponse
  from "../../../../../../core/interfaces/utility/iUtilityAccountResponse";

export default function UtilityBanner({
  account,
}: {
  account: IUtilityAccountResponse;
}) {
  const theme = useTheme()
  return (
    <Banner
      title={`Account #: ${account?.accountNumber}`}
      body={account?.address}
      styles={{
        backgroundColor: theme.palette.primary[50],
        padding: "0.5rem",
      }}
      Icon={
        <IconBase
          size={38}
          fill={1}
          color={theme.palette.primary[200]}
          wght={700}
          iconName={"water_damage"}
        />
      }
    />
  );
}
