import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import useKeyPress from "../../../hooks/useKeyPress";
import Button from "../Button";
import SearchBar from "../SearchBar";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { useHistory } from "react-router-dom";
import TablePagination from "./data-table-pagination";
import DataTableCell from "./data-table-cell";
import { BLACK_HIGH_EMPHASIS, PRIMARY_700 } from "../../../../mui-theme/theme";
import { downloadCSV, keepFilters } from "../../../../utilities";
import { delay } from "lodash";
import LoadingLogo from "../loading";
import IconBase from "../IconBase";
import DataTableRow from "./data-table-row";
import EmptyTableImg from "../../../../assets/images/empty-table.svg";
import DataTableHeadCell from "./data-table-head-cell";
import SearchFilterV2 from "./filter";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
`;

const EmptyTableContainer = styled(Grid)`
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 80%;
`;

const EmptyTableText = styled(Grid)`
  height: 100%;
`;

const FixedHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

const Label = styled(TableSortLabel)`
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1.25px;

  transform: rotate(0turn);

  &.MuiTableSortLabel-root:hover {
    color: ${PRIMARY_700};

    .material-symbols-rounded {
      color: ${({ theme }) => theme.palette.blacks.BLACK_LOW_EMPHASIS};
    }
  }

  &&.MuiTableSortLabel-active,
  && path {
    color: ${PRIMARY_700};

    .material-symbols-rounded {
      color: ${({ theme }) => theme.palette.primary[500]};
    }
  }

  && .material-symbols-rounded {
    color: White;
    transform: ${({ active, direction }) =>
      active && direction === "desc" ? `rotate(0.5turn)` : "rotate(0turn)"};
  }
`;

const StyledLinearProgress = styled(LinearProgress)`
  margin: 1rem;
`;

const StyledPopper = styled(Popper)`
  z-index: 10000;
`;

const StyledTableContainer = styled(TableContainer)`
  box-sizing: border-box;
  height: 100%;

  && {
    overflow-x: hidden;
    margin-top: ${({ header_height }) => header_height + "px"};
    padding-bottom: 2rem;
  }
`;

const StyledTable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 0 !important;
`;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root.Mui-selected {
    background: ${({ theme }) => theme.palette.primary[100]};
  }

  &.MuiTableRow-root.MuiTableRow-hover:hover {
    background: ${({ theme }) => theme.palette.primary[50]};
    ${({ clickable }) => clickable === "true" && "cursor: pointer"};
  }

  &&&.MuiTableRow-root:focus {
    & .MuiTableCell-body {
      border-top: 2px solid ${({ theme }) => theme.palette.primary[200]};
      border-bottom: 2px solid ${({ theme }) => theme.palette.primary[200]};
    }

    & .MuiTableCell-body:first-child {
      border-left: 2px solid ${({ theme }) => theme.palette.primary[200]};
    }

    & .MuiTableCell-body:last-child {
      border-right: 2px solid ${({ theme }) => theme.palette.primary[200]};
    }
  }
`;

const TableTitle = styled(Typography)`
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.fontBold + "!important"};
  margin: 0;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 1.5rem 0;
`;

const DataTableIcon = styled.div`
  .material-symbols-rounded {
    font-size: 42px !important;
    margin-right: 0.5rem !important;
    color: ${({ theme }) => theme.PRIMARY_200 + "!important"};
  }
`;

const removeHeader = (result) => {
  let splitCSV = result.split("\n");
  splitCSV.splice(0, 1);
  return splitCSV.join("\n");
};

const ALL = "ALL";
const PAGE = "PAGE";
const SELECTED = "SELECTED";

export default function DataTable(props) {
  const {
    ariaLabel,
    customFilterFields,
    createDisabled,
    createLabel,
    data,
    dataLoading,
    defaultSort,
    defaultOrder,
    dense,
    disableSearchBar,
    disablePagination,
    defaultPagesPerRow,
    exportFilename,
    enableFilter,
    hideSearchBar,
    icon,
    fields,
    fixedFilters,
    filtersIgnoreClear = [],
    getCSV,
    loadData,
    onCreateClick,
    onRowClick,
    selectable,
    title,
    enableFilterV2 = false,
    filters,
    setFilters,
    tableSettings,
    setTableSettings = () => {},
    searchOptions,
    tooltipTitle,
    searchBarPlaceHolder,
    onClickHandlers,
  } = props;
  const firstSearchableField =
    fields?.find((field) => field.filterType === "SEARCH")?.id || "";
  const { handleError, setInfoMessage } = useContext(NotificationContext);
  const [exporting, setExporting] = useState(null);
  const [exportAnchor, setExportAnchor] = useState(null);
  const [fieldToSearch, setFieldsToSearch] = useState(firstSearchableField);
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
  const [order, setOrder] = useState(
    tableSettings?.["order"] || defaultOrder || "desc"
  );
  const [orderBy, setOrderBy] = useState(
    tableSettings?.["orderBy"] || defaultSort
  );
  const [page, setPage] = useState(tableSettings?.["page"] || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    tableSettings?.["rowsPerPage"] || defaultPagesPerRow || 10
  ); //TODO save/get value from cookie/localstorage so user doesn't have to change it everytime
  const [selected, setSelected] = useState([]);
  const [searchedField, setSearchedField] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [visitedPages, setVisitedPages] = useState([0]);
  const isControlPressed = useKeyPress(["Control", "Meta"]);
  const isShiftPressed = useKeyPress(["Shift"]);
  const queryLimit = 500;
  const history = useHistory();

  let rows = data?.result ? data.result : data?.results ? data.results : data;
  if (rows === null) rows = [];
  const totalRows = data?.total ? data.total : null;

  const chunker = (pointId, anchorId) => {
    const idsToSelect = [];
    let started = false;
    for (let i = 0; i < rows.length - 1; i++) {
      let x = rows[i];
      if (x.id === pointId || x.id === anchorId) started = !started;
      if (started && x.id !== pointId && x.id !== anchorId)
        idsToSelect.push(x.id);
    }
    return [...idsToSelect, pointId, anchorId];
  };

  const onRecordClick = (e, row) => {
    const pointId = row.id;
    const anchorId = selected[selected.length - 1];
    if (isControlPressed && selectable) handleClick(e, pointId);
    else if (isShiftPressed && selectable) {
      if (selected.length && !selected.includes(pointId))
        setSelected(chunker(pointId, anchorId));
      else if (selected.includes(pointId)) {
        if (pointId === anchorId) setSelected([anchorId]);
        else setSelected(chunker(pointId, anchorId));
      } else handleClick(e, pointId);
    } else onRowClick?.(row, history, e);
  };

  const checkboxClick = (e, row) => {
    const pointId = row.id;
    const anchorId = selected[selected.length - 1];
    if (isShiftPressed && selectable) {
      if (selected.length && !selected.includes(pointId))
        setSelected(chunker(pointId, anchorId));
      else if (selected.includes(pointId)) {
        if (pointId === anchorId) setSelected([anchorId]);
        else setSelected(chunker(pointId, anchorId));
      } else handleClick(e, pointId);
    } else handleClick(e, pointId);
  };

  const queryOptions = {
    total: true,
    sortBy: orderBy,
    ascending: order === "asc",
    filters: filters,
    offset: !disablePagination ? page * rowsPerPage : null,
    limit: !disablePagination ? rowsPerPage : null,
  };

  if (fixedFilters) {
    queryOptions.filters = { ...fixedFilters, ...queryOptions.filters };
  }

  useEffect(() => {
    if (data?.result?.length === 0) {
      setPage(0);
    }

    if (typeof setTableSettings === "function") {
      setTableSettings({
        ...tableSettings,
        page,
        rowsPerPage,
        order,
        orderBy,
      });
    }
    
    if (loadData) loadData(queryOptions);

    if (queryOptions.filters && queryOptions.filters.search) {
      setSearchValue(queryOptions.filters.search);
    }

    // eslint-disable-next-line
  }, [filters, order, orderBy, page, rowsPerPage, data?.result?.length]);

  useEffect(() => {
    const headerHeight = document.querySelector(
      ".table-fixed-header"
    )?.clientHeight;
    setFixedHeaderHeight(headerHeight);
  }, []);

  const handleOpenExport = (e) => {
    setExportAnchor(e.currentTarget);
  };

  const handleCloseExport = () => {
    setExportAnchor(null);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const addFilters = fields
    .map((field) => field.filterType)
    .find((filter) => filter !== undefined && filter !== "SEARCH");

  const showActionBar =
    enableFilter || onCreateClick || exportFilename || enableFilterV2;
  const showActionButtons = onCreateClick || exportFilename || addFilters;
  const fieldsToSearch = fields.filter(
    (field) => field.filterType === "SEARCH"
  );

  const showProgressMessage = async (progressInPercentage) => {
    const progress = Math.floor(progressInPercentage);
    const message = `Export Progress: ${progress}%`;
    await setInfoMessage(message);
  };

  const handleExport = async (exportType) => {
    try {
      setExporting(exportType);
      let results = undefined;

      if (exportType === SELECTED) {
        if (selected.length) {
          results = await getCSV({ filters: { id: selected } });
        }
      } else if (exportType === PAGE) {
        results = await getCSV(queryOptions);
      } else if (exportType === ALL) {
        const exportQueryOptions = {
          total: true,
          ascending: false,
          offset: 0,
          limit: queryLimit,
          filters: queryOptions.filters,
        };
        /* Make export calls separately with limits */
        if (totalRows > queryLimit) {
          const rounds = Math.ceil(totalRows / queryLimit);
          let partialQueryResults = "";

          for (let round = 1; round <= rounds; round++) {
            const progressInPercentage = Math.min(
              (exportQueryOptions.offset / totalRows) * 100,
              100
            );

            await showProgressMessage(progressInPercentage);

            let result = await getCSV(exportQueryOptions);
            if (round !== 1) {
              result = removeHeader(result);
            }
            partialQueryResults += `${result}`;
            if (round !== rounds) {
              exportQueryOptions.offset += queryLimit;
            } else {
              await showProgressMessage(100);
              await delay(250);
            }
          }
          results = partialQueryResults;
        } else {
          results = await getCSV(exportQueryOptions);
        }
      }
      downloadCSV(results, `${exportFilename ? exportFilename : "data"}.csv`);
    } catch (err) {
      handleError("Export failed.");
    } finally {
      setExporting(null);
      setInfoMessage(null);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (e, rowId) => {
    e.stopPropagation();
    const selectedIndex = selected.indexOf(rowId);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(rowId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  const handleChange = (e, value) => {
    setSearchValue(value?.label ? value.label : value);
  };

  const handleChangePage = (newPage) => {
    setSelected([]);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleFilter = (newFilters) => {
    setFilters({ ...newFilters });
  };

  const handleFieldSelect = (e) => {
    const fieldName = e.target.value;
    setFieldsToSearch(fieldName);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchValue) return;

    const newFilters = { ...filters };
    if (searchedField) {
      delete newFilters[searchedField];
    }
    setFilters({
      ...newFilters,
      search: searchValue,
    });
    setSearchedField(fieldToSearch);
  };

  const handleClearSearch = () => {
    const newFilters = { ...filters };
    delete newFilters["search"];
    setOrder(defaultOrder);
    setOrderBy(defaultSort);
    setFilters(newFilters);
    setSearchedField(null);
    setSearchValue("");
    setPage(0);
  };

  const handleSelectAll = (e) => {
    const rowIds = rows.map((row) => row.id);
    const fromOtherPage = selected.filter((item) => !rowIds.includes(item));
    if (e.target.checked) {
      setSelected([...fromOtherPage, ...rowIds]);
      return;
    } else {
      setSelected(fromOtherPage);
    }
  };

  const checkCurrentPageSelected = () => {
    const rowIds = rows.map((row) => row.id);
    if (rows.length === 0) return false;
    for (let n = 0; n < rowIds.length; n++) {
      const isIncluded = selected.includes(rowIds[n]);
      if (!isIncluded) return false;
    }
    return true;
  };

  const clearFilters = () => {
    setFilters((prevState) => {
      return keepFilters(filtersIgnoreClear, prevState);
    });
    setSearchValue("");
  };

  return (
    <Container>
      <FixedHeader className="table-fixed-header">
        {loadData && title && (
          <>
            <TitleContainer>
              <DataTableIcon>
                <IconBase iconName={icon} />
              </DataTableIcon>
              {title && <TableTitle variant={"h3"}>{title}</TableTitle>}
            </TitleContainer>
          </>
        )}
        {showActionBar && (
          <div style={{ display: "flex" }}>
            {(enableFilter || enableFilterV2) && (
              <div style={{ display: "flex", marginBottom: "0.5rem" }}>
                {!hideSearchBar && (
                  <SearchBar
                    placeholder={searchBarPlaceHolder}
                    disabled={disableSearchBar}
                    fields={fieldsToSearch}
                    handleChange={handleChange}
                    handleClear={handleClearSearch}
                    handleSubmit={handleSearch}
                    handleSelect={handleFieldSelect}
                    hideSearchBar={!firstSearchableField}
                    hideClearButton={!searchedField}
                    selectValue={fieldToSearch}
                    textValue={searchValue}
                    width="24rem"
                    withSelect
                    options={searchOptions}
                    tooltipTitle={tooltipTitle}
                  />
                )}
              </div>
            )}
            {showActionButtons && (
              <Grid container justifyContent="flex-end">
                {exportFilename && getCSV && (
                  <>
                    <Button
                      size="medium_icon_left"
                      iconName="download"
                      onClick={handleOpenExport}
                    >
                      Export
                    </Button>
                    <StyledPopper
                      open={Boolean(exportAnchor)}
                      anchorEl={exportAnchor}
                      role={undefined}
                      placement="bottom-end"
                      transition
                    >
                      <ClickAwayListener onClickAway={handleCloseExport}>
                        <Paper>
                          <MenuList id="simple-menu">
                            {exporting === SELECTED ? (
                              <StyledLinearProgress />
                            ) : (
                              <MenuItem
                                onClick={() => handleExport(SELECTED)}
                                disabled={
                                  Boolean(exporting) ||
                                  !Boolean(selected.length)
                                }
                              >
                                Export selected
                              </MenuItem>
                            )}
                            {exporting === PAGE ? (
                              <StyledLinearProgress />
                            ) : (
                              <MenuItem
                                disabled={Boolean(exporting)}
                                onClick={() => handleExport(PAGE)}
                              >
                                Export page
                              </MenuItem>
                            )}
                            {exporting === ALL ? (
                              <StyledLinearProgress />
                            ) : (
                              <MenuItem
                                disabled={Boolean(exporting)}
                                onClick={() => handleExport(ALL)}
                              >
                                Export all
                              </MenuItem>
                            )}
                          </MenuList>
                        </Paper>
                      </ClickAwayListener>
                    </StyledPopper>
                  </>
                )}
                {onCreateClick && (
                  <Button
                    variant="outlined"
                    size="medium_icon_left"
                    iconName="add"
                    onClick={() => onCreateClick()}
                    disabled={createDisabled}
                  >
                    {createLabel ? createLabel : "Create"}
                  </Button>
                )}
              </Grid>
            )}
          </div>
        )}
        {enableFilterV2 && (
          <div>
            {
              <SearchFilterV2
                filters={filters}
                fields={
                  customFilterFields
                    ? [...fields, ...customFilterFields]
                    : fields
                }
                onFilter={handleFilter}
                clearFilters={clearFilters}
                filtersIgnoreClear={filtersIgnoreClear}
              />
            }
          </div>
        )}
      </FixedHeader>
      <StyledTableContainer header_height={fixedHeaderHeight}>
        {dataLoading ? (
          <LoadingLogo minHeight={"25rem"} />
        ) : !rows?.length ? (
          <EmptyTableContainer justifyContent="center" container>
            <Grid item justifyContent="center" xs={6}>
              <EmptyTableText
                container
                direction="column"
                justifyContent="center"
              >
                <Grid item>
                  <Typography component="h4" variant="h2">
                    Nothing here...
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    style={{ color: BLACK_HIGH_EMPHASIS }}
                  >
                    Make sure you spelled it correctly or try changing or
                    clearing the filters.
                  </Typography>
                </Grid>
              </EmptyTableText>
            </Grid>
            <Grid item xs={6}>
              <img src={EmptyTableImg} alt={"Empty Table"} width={"100%"} />
            </Grid>
          </EmptyTableContainer>
        ) : (
          <>
            <StyledTable
              aria-label={ariaLabel}
              aria-labelledby="tableTitle"
              className="noselect"
              size={dense ? "small" : "medium"}
            >
              <TableHead>
                <TableRow>
                  {selectable && (
                    <DataTableHeadCell padding="checkbox">
                      <Checkbox
                        checked={checkCurrentPageSelected()}
                        inputProps={{ "aria-labelledby": "select-all" }}
                        onClick={handleSelectAll}
                      />
                    </DataTableHeadCell>
                  )}
                  {fields?.map(function (headCell) {
                    const {
                      align,
                      customSort,
                      customSortLabel,
                      disableSort,
                      id,
                      label,
                      hideFromTable,
                    } = headCell;

                    if (hideFromTable) {
                      return false;
                    }

                    const sortValue = customSort || id;
                    const sortLabel = customSortLabel || label;
                    const sortCondition =
                      orderBy === id || orderBy === sortValue;
                    return (
                      <DataTableHeadCell
                        key={id}
                        align={align ? align : "left"}
                        padding="normal"
                        sortDirection={sortCondition ? order : false}
                      >
                        <div>
                          <Label
                            active={sortCondition}
                            IconComponent={() => (
                              <IconBase iconName="expand_more" />
                            )}
                            direction={sortCondition ? order : "desc"}
                            disabled={disableSort}
                            onClick={() => handleRequestSort(sortValue)}
                          >
                            {sortLabel}
                          </Label>
                        </div>
                      </DataTableHeadCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody spacing={2}>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const clickable = Boolean(onRowClick).toString();
                  return (
                    <StyledTableRow
                      clickable={clickable}
                      hover
                      key={index}
                      onClick={(e) => onRecordClick?.(e, row)}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onRecordClick?.(e, row);
                        }
                      }}
                      selected={isItemSelected}
                      tabIndex={0}
                    >
                      {selectable && (
                        <DataTableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(e) => checkboxClick(e, row)}
                          />
                        </DataTableCell>
                      )}
                      {fields.map((field, index) => {
                        if (field.hideFromTable) {
                          return false;
                        }

                        return (
                          <DataTableRow
                            key={index}
                            field={field}
                            row={row}
                            index={index}
                            loadData={loadData}
                            onClickHandlers={onClickHandlers}
                          />
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </StyledTable>
          </>
        )}
      </StyledTableContainer>
      {!dataLoading && !disablePagination && (
        <div>
          <TablePagination
            visitedPages={visitedPages}
            setVisitedPages={setVisitedPages}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows || rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Container>
  );
}
