export const fake_data = {
  results: [
    {
      invoice_number: "123456",
      date: "2023-01-04",
      description: "Invoice 2023-01-04.pdf",
      payment: undefined,
      amount: 143.23,
    },
    {
      invoice_number: "123456",
      date: "2023-01-04",
      description: "Invoice 2023-01-06.pdf",
      payment: 50,
      lastFourDigits: 1234,
      amount: undefined,
    },
    {
      invoice_number: "123456",
      date: "2023-01-04",
      description: "Invoice 2023-01-04.pdf",
      payment: undefined,
      amount: 50,
    },
  ],
};