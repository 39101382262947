import React from "react";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import DataTable from "../../../../../../components/ui-kit/data-table";
import Spacer from "../../../../../../components/ui-kit/Spacer";
import {fake_data} from "./fake_data";
import {fields} from "./fields";

const BillingTab = ({ loading = false }) => {
  return (
    <div>
      <p className={typographyClasses.h1}>Billing & Payments</p>
      <Spacer />
      <DataTable
        fields={fields}
        data={fake_data}
        onClickHandlers={{ lastFourDigits: 1234 }}
      />
    </div>
  );
};

export default BillingTab;
