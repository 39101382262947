import React from "react";
import styled from "styled-components";
import PlaceholderImg from "../../../../assets/images/placeholder.png";
import { useTheme } from "@mui/material";
import Typography from "../typography";
import IconBase from "../IconBase";
import { OUTLINE } from "../../../../mui-theme/theme";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${OUTLINE};
  width: 228px;
  height: 210px;
  border-radius: 10px;
  margin-right: 1rem;

  :hover {
    cursor: pointer;
    filter: brightness(70%);
  }
`;

const StyledDocumentCardBanner = styled.div`
  width: 100%;
  height: 150px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: url(${PlaceholderImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const StyledDocumentCardContent = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
`;

/**
 * Document card, displays an uploaded file and offers an onClick function that takes index as a parameter
 * @param title
 * @param subTitle
 * @param onClick
 * @param index
 * @param variant
 * @returns {JSX.Element}
 * @constructor
 */
export default function DocumentCard({ title, subTitle, onClick, variant }) {
  if (variant === "upload") {
    return (
      <UploadDocument title={title} subTitle={subTitle} onClick={onClick} />
    );
  }

  return (
    <Container onClick={onClick}>
      <StyledDocumentCardBanner />

      <StyledDocumentCardContent>
        <Typography
          style={{
            marginRight: 10,
            lineHeight: 1.3,
            whiteSpace: "noWrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
          }}
          variant={"body1"}
          fontWeight={"bold !important"}
        >
          {title}
        </Typography>
        {/* <IconBase
          iconName={"more_vert"}
          wght={900}
          onClick={() => {}}
          style={{ marginLeft: "auto", marginRight: "0.5rem" }}
        /> */}
      </StyledDocumentCardContent>
    </Container>
  );
}

const UploadIconContainer = styled.div`
  width: 100px;
  height: 150px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: ${({ theme }) => theme.palette.primary[50]};
  justify-content: center;
  align-items: center;
  display: flex;
`;

const UploadDocument = ({ title, subTitle, onClick }) => {
  const theme = useTheme();

  return (
    <Container onClick={onClick}>
      <UploadIconContainer>
        <IconBase
          iconName={"upload"}
          wght={700}
          style={{
            color: theme.palette.primary[500],
            fontSize: 100,
          }}
        />
      </UploadIconContainer>
      <StyledDocumentCardContent>
        <Typography
          variant={"body1"}
          fontWeight={"bold !important"}
          color={theme.palette.primary[200]}
        >
          Upload document
        </Typography>
      </StyledDocumentCardContent>
    </Container>
  );
};
