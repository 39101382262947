import React, { useContext } from "react";
import styled from "@emotion/styled";
import { getAuthType, getScreenOptions, routes } from "../../routes/routesData";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { PRIMARY_500 } from "../../../mui-theme/theme";
import CocofloLogo from "../../../assets/images/cocofloLogo.svg";
import ProfileChip from "./ProfileChip";
import IconBase from "./IconBase";
import { USER_AUTH_TYPE } from "../../../core/constants/authTypes";
import Typography from "@mui/material/Typography";
import { ReactComponent as LOGO } from "../../../assets/images/CoLLogo.svg";
import { userLogout } from "../../../core/apis/user";

const mockData = {
  device: "Chrome on Dell 123-12",
  location: "Vancouver, BC",
};

const AdminContainer = styled.header`
  align-items: center;
  background: ${PRIMARY_500};
  display: flex;
  height: 4rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: top 200ms ease-in-out;
`;

const Left = styled.div`
  margin-left: 1rem;
  width: ${({ theme }) => theme.sidebarWidth};
`;

const Right = styled.div`
  display: flex;
  margin-right: 1rem;
  position: fixed;
  right: 0;
  justify-content: flex-end;
`;

const UserContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.whites.WHITE};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.31);
  display: ${({ hideHeader }) => (hideHeader ? "none" : "flex")};
  height: 90px;
  padding: 0 1rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  justify-content: space-between;
  z-index: 2;
`;

const NavContainer = styled.div`
  align-items: center;
  display: flex;
`;

const LogoutButton = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
  border-radius: 10px;
  padding: 8px 30px 8px 15px;
  display: flex;
  align-items: center;

  :hover {
    background: ${({ theme }) => theme.palette.primary[50]};
    cursor: pointer;
  }
`;

export const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const { hideHeader } = getScreenOptions(location.pathname);
  const { user, logout } = useContext(AuthContext);

  const logoutRedirect = async () => {
    const token = await logout();
    await userLogout(token);
    history.push(routes.LOGIN.path);
  };

  const isIE = Boolean(document.documentMode);

  return !hideHeader ? (
    getAuthType(location.pathname) === USER_AUTH_TYPE ? (
      <UserContainer>
        <NavContainer>
          {/* <CityLogo /> */}
          <div>
            <LOGO width={"150px"} height={"90px"} />
          </div>
          {/* Leave the commented code below to be used post release */}
          {/* <NavItems>
          {navItems.map(({ name, path }) => (
            <NavButton
              disableFocusRipple={true}
              key={path}
              onClick={() => handleRedirect(path)}
              disabled={path === routeData.path}
              selected={path === routeData.path}
            >
              {name}
            </NavButton>
          ))}
        </NavItems> */}
        </NavContainer>
        {/* <ProfileChipMCH
        onClick={() => {
          if (navigator.SETTINGS.path !== routeData.path) {
            handleRedirect(navigator.SETTINGS.path);
          }
        }}
        user={user}
        active={navigator.SETTINGS.path === routeData.path}
      /> */}
        <LogoutButton onClick={logoutRedirect}>
          <IconBase
            iconName="logout"
            wght={600}
            style={{ marginRight: "0.5rem" }}
          />{" "}
          <Typography>Log out</Typography>
        </LogoutButton>
      </UserContainer>
    ) : (
      <AdminContainer>
        {isIE ? null : (
          <>
            <Left>
              <img src={CocofloLogo} alt={"Logo"} style={{ height: "40px" }} />
            </Left>
            {/* <Center>{!hideBreadcrumbs && <Breadcrumbs />}</Center> */}
            <Right>
              {user && (
                <ProfileChip
                  user={user}
                  logout={logout}
                  device={mockData.device}
                  location={mockData.location}
                />
              )}
            </Right>
          </>
        )}
      </AdminContainer>
    )
  ) : null;
};

export default Header;
