import React from "react";
import styled from "styled-components";
import { Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "../Divider";
import {
  primary,
  BLACK_MEDIUM_EMPHASIS,
  PRIMARY_500,
  PRIMARY_700,
  GREY_MEDIUM,
  BLACK_LOW_EMPHASIS,
  GREEN,
  GREY_DARK,
  WHITE, ORANGE
} from "../../../../mui-theme/theme";
import classes from "./index.module.scss";
import { currency, formatCurrency, formatDate } from "../../../../utilities";
import IconBase from "../IconBase";
import {
  ACTIVE,
  INACTIVE_GROUP,
  ISSUED,
} from "../../../../core/constants/licences";
import PhotoThumbnail from "../photo-thumbnail";
import { abbreviator } from "../../../../core/constants/province-states";

const Dot = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  ${({ borderColor }) => `border: 2px solid ${borderColor}`};
  margin-right: 10px;
`;

const StyledCardContainer = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.OUTLINE};
  width: 480px;
`;

const StyledGrid = styled(Grid)`
  padding: 1rem;
`;

const StyledAdminCardContainer = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.OUTLINE};
`;

const StyledCardHeader = styled.div`
  display: flex;
  padding: 1rem;
`;

const StyledCardBody = styled.div`
  padding: 2rem;
`;

const active = ["active", "issued"];
const inactive = ["expired", "cancelled", "declined"];
const inProgress = ["submitted", "reviewing", "approved"];
const draft = ["created", "draft", "saved"];
const theme = {
  active: {
    iconColor: GREEN,
    iconBorderColor: GREEN,
    label: "Active",
    labelColor: BLACK_MEDIUM_EMPHASIS,
  },
  inactive: {
    iconColor: WHITE,
    iconBorderColor: BLACK_LOW_EMPHASIS,
    label: "Inactive",
    labelColor: GREY_DARK,
  },
  inProgress: {
    iconColor: WHITE,
    iconBorderColor: BLACK_LOW_EMPHASIS,
    label: "Under review",
    labelColor: BLACK_MEDIUM_EMPHASIS,
  },
  draft: {
    iconColor: ORANGE,
    iconBorderColor: BLACK_LOW_EMPHASIS,
    label: "Created",
    labelColor: BLACK_MEDIUM_EMPHASIS,
  },
};

const getStatus = (val) => {
  if (val && typeof val === "string") {
    const status = val.toLowerCase();

    switch (true) {
      case active.indexOf(status) !== -1:
        return "active";
      case inactive.indexOf(status) !== -1:
        return "inactive";
      case inProgress.indexOf(status) !== -1:
        return "inProgress";
      case draft.indexOf(status) !== -1:
        return "draft";
      default:
        return "inactive";
    }
  }
};

const getColor = (val) => {
  if (val && typeof val === "string") {
    const status = val.toLowerCase();

    switch (true) {
      case active.indexOf(status) !== -1:
        return GREEN;
      case inactive.indexOf(status) !== -1:
        return WHITE;
      case inProgress.indexOf(status) !== -1:
        return GREY_MEDIUM;
      default:
        return WHITE;
    }
  }
};

const getBorderColor = (val) => {
  if (val && typeof val === "string") {
    const status = val.toLowerCase();

    switch (true) {
      case active.indexOf(status) !== -1:
        return GREEN;
      case inactive.indexOf(status) !== -1:
        return BLACK_LOW_EMPHASIS;
      case inProgress.indexOf(status) !== -1:
        return GREY_MEDIUM;
      default:
        return BLACK_LOW_EMPHASIS;
    }
  }
};

export const AdminCard = ({ data, imgUrl }) => {
  return (
    <StyledAdminCardContainer>
      <StyledGrid container alignItems="center" spacing={2}>
        <Grid item>
          <PhotoThumbnail image={imgUrl} />
        </Grid>
        <Grid item>
          <Typography variant="h5">{`${data.firstName} ${data.lastName}`}</Typography>
        </Grid>
      </StyledGrid>
      <Divider />
      <StyledGrid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="subtitle2"
            color={BLACK_MEDIUM_EMPHASIS}
          >{`${data.firstName} ${data.lastName}`}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" fontSize="1rem">
            {data.phone ? data.phone : "123-456-6890"}
          </Typography>
          <Typography variant="h6" fontSize="1rem" color={primary}>
            {data.email}
          </Typography>
        </Grid>
      </StyledGrid>
    </StyledAdminCardContainer>
  );
};

export const UserCard = ({ data }) => {
  return (
    <StyledCardContainer>
      <StyledCardHeader>
        <IconBase
          iconName={"person"}
          color={PRIMARY_500}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "48px",
          }}
        />
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "0.5rem",
          }}
        >
          <Typography variant={"h5"} color={PRIMARY_700}>
            {`${data.firstName} ${data.lastName}`}
          </Typography>
          <Typography variant={"body1"} color={BLACK_MEDIUM_EMPHASIS}>
            See page
          </Typography>
        </div>
        <IconBase
          iconName={"more_vert"}
          color={BLACK_LOW_EMPHASIS}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
          }}
        />
      </StyledCardHeader>
      <Divider />
      <StyledCardBody>
        {data.status === "IN_PROGRESS" ? (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            <Typography
              color={BLACK_MEDIUM_EMPHASIS}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Under review
            </Typography>
          </div>
        ) : data.status === "INACTIVE" ? (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            <Typography
              color={GREY_DARK}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Inactive
            </Typography>
          </div>
        ) : (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            {data.lastBillDate ? (
              <Typography
                color={BLACK_MEDIUM_EMPHASIS}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Active - last paid {formatDate(data.lastBillDate)}
              </Typography>
            ) : (
              <Typography
                color={BLACK_MEDIUM_EMPHASIS}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Active
              </Typography>
            )}
          </div>
        )}
        <br />
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <Typography fontWeight={"500 !important"}>{data.phone}</Typography>
            <Typography fontWeight={"500 !important"}>{data.email}</Typography>
          </div>
        </div>
        <br />
        <Typography>
          {`${data.address?.city || ""} ${data.address?.province || ""} ${
            data.address?.postalCode || ""
          }`.trim()}
        </Typography>
      </StyledCardBody>
    </StyledCardContainer>
  );
};

export const PropertyAccountCard = ({ data }) => {
  const theme = useTheme();
  return (
    <StyledCardContainer>
      <StyledCardHeader>
        <IconBase
          iconName={"gite"}
          color={PRIMARY_500}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "48px",
          }}
        />
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "0.5rem",
          }}
        >
          <Typography variant={"h5"} color={PRIMARY_700}>
            {`
                    ${data.municipalAddUnit ? data.municipalAddUnit : ""} 
                    ${data.municipalAddHouse ? data.municipalAddHouse : ""} 
                    ${data.municipalAddStreet ? data.municipalAddStreet : ""}
                    `}
          </Typography>
          <Typography variant={"body1"} color={BLACK_MEDIUM_EMPHASIS}>
            See page
          </Typography>
        </div>
        <IconBase
          iconName={"more_vert"}
          color={BLACK_LOW_EMPHASIS}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
          }}
        />
      </StyledCardHeader>
      <Divider />
      <StyledCardBody>
        {data.status === "IN_PROGRESS" ? (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            <Typography
              color={BLACK_MEDIUM_EMPHASIS}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Under review
            </Typography>
          </div>
        ) : data.status === "INACTIVE" ? (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            {data.lastBillDate ? (
              <Typography
                color={GREY_DARK}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Inactive - last paid {formatDate(data.lastBillDate)}
              </Typography>
            ) : (
              <Typography
                color={GREY_DARK}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Inactive
              </Typography>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            {data.lastBillDate ? (
              <Typography
                color={BLACK_MEDIUM_EMPHASIS}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Active - last paid {formatDate(data.lastBillDate)}
              </Typography>
            ) : (
              <Typography
                color={BLACK_MEDIUM_EMPHASIS}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Active
              </Typography>
            )}
          </div>
        )}
        <br />
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <Typography variant={"body1"} fontWeight={"700 !important"}>
              Property tax acc.
            </Typography>
            <Typography fontWeight={"500 !important"}>
              #{data.accountNumber}
            </Typography>
          </div>
          <div className={classes.price_tag}>
            <Typography
              variant="overline"
              style={{
                color: BLACK_LOW_EMPHASIS,
                marginLeft: "auto !important",
              }}
            >
              balance
            </Typography>
            <Typography fontWeight={"700 !important"}>
              {formatCurrency(data.balance)}
            </Typography>
          </div>
        </div>
        <br />
        <Typography
          variant={"body1"}
          style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
        >
          {`
                    ${data.municipalAddUnit ? data.municipalAddUnit : ""} 
                    ${data.municipalAddHouse ? data.municipalAddHouse : ""} 
                    ${data.municipalAddStreet ? data.municipalAddStreet : ""}
                    `}
        </Typography>
      </StyledCardBody>
    </StyledCardContainer>
  );
};

export const UtilitiesCard = ({ data }) => {
  const theme = useTheme();
  return (
    <StyledCardContainer>
      <StyledCardHeader>
        <IconBase
          iconName={"flash_on"}
          color={PRIMARY_500}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "48px",
          }}
        />
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "0.5rem",
          }}
        >
          <Typography variant={"h5"} color={PRIMARY_700}>
            {`${data.municipalAddUnit} ${data.municipalAddHouse} ${data.municipalAddStreet}`}
          </Typography>
          <Typography variant={"body1"} color={BLACK_MEDIUM_EMPHASIS}>
            See page
          </Typography>
        </div>
        <IconBase
          iconName={"more_vert"}
          color={BLACK_LOW_EMPHASIS}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
          }}
        />
      </StyledCardHeader>
      <Divider />
      <StyledCardBody>
        {data.status === "IN_PROGRESS" ? (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            <Typography
              color={BLACK_MEDIUM_EMPHASIS}
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              Under review
            </Typography>
          </div>
        ) : data.status === "INACTIVE" ? (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />

            {data.lastBillDate ? (
              <Typography
                color={GREY_DARK}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Inactive - last paid {formatDate(data.lastBillDate)}
              </Typography>
            ) : (
              <Typography
                color={GREY_DARK}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Inactive
              </Typography>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", marginRight: "0.5rem" }}>
            <Dot
              color={getColor(data.status)}
              borderColor={getBorderColor(data.status)}
            />
            {data.lastBillDate ? (
              <Typography
                color={BLACK_MEDIUM_EMPHASIS}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Active - last paid {formatDate(data.lastBillDate)}
              </Typography>
            ) : (
              <Typography
                color={BLACK_MEDIUM_EMPHASIS}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                Active
              </Typography>
            )}
          </div>
        )}
        <br />
        <div style={{ display: "flex" }}>
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <Typography variant={"body1"} fontWeight={"700 !important"}>
              Utilities acc.
            </Typography>
            <Typography fontWeight={"500 !important"}>
              #{data.accountNumber}
            </Typography>
          </div>
          <div className={classes.price_tag}>
            <Typography
              variant="overline"
              style={{
                color: BLACK_LOW_EMPHASIS,
                marginLeft: "auto !important",
              }}
            >
              balance
            </Typography>
            <Typography fontWeight={"700 !important"}>
              {formatCurrency(data.balance)}
            </Typography>
          </div>
        </div>
        <br />
        <Typography
          variant={"body1"}
          style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
        >
          {`${data.municipalAddUnit} ${data.municipalAddHouse} ${data.municipalAddStreet}`}
        </Typography>
      </StyledCardBody>
    </StyledCardContainer>
  );
};

export const LicenseCard = ({ data, hideFee }) => {
  const status = getStatus(data.status);

  const getResidencyStatus = () => {
    if (!data) return "Unknown";

    let status = "Non-resident";
    if (data.is_resident) {
      status = "Resident";

      if (data.is_homebased) {
        status = "Home-based";
      }
    }

    return status;
  };

  const currTheme = theme[status];
  return (
    <StyledCardContainer>
      <StyledCardHeader>
        <IconBase
          iconName={"approval"}
          color={PRIMARY_500}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            fontSize: "48px",
          }}
        />
        <div
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "0.5rem",
            overflow: "hidden",
          }}
        >
          <Typography variant={"h5"} color={PRIMARY_700} noWrap>
            {data.business_name}
          </Typography>
        </div>
        <IconBase
          iconName={"more_vert"}
          color={BLACK_LOW_EMPHASIS}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
          }}
        />
      </StyledCardHeader>
      <Divider />
      <StyledCardBody>
        <div style={{ display: "flex", marginRight: "0.5rem" }}>
          <Dot
            color={currTheme.iconColor}
            borderColor={currTheme.iconBorderColor}
          />
          <Typography
            color={currTheme.labelColor}
            style={{ marginTop: "auto", marginBottom: "auto" }}
          >
            {currTheme.label}
          </Typography>
        </div>
        <br />
        <div style={{ display: "flex" }}>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              overflow: "hidden",
              paddingRight: "0.5rem",
            }}
          >
            <Typography fontWeight={"500 !important"}>
              {"Business Licence"}
            </Typography>
            <Typography fontWeight={"500 !important"} noWrap>
              {getResidencyStatus()}
            </Typography>
          </div>
          {!INACTIVE_GROUP.includes(data.status) ? (
            !hideFee && (
              <div className={classes.price_tag}>
                <Typography
                  variant="overline"
                  style={{
                    color: BLACK_LOW_EMPHASIS,
                    marginLeft: "auto !important",
                  }}
                >
                  {[ISSUED, ACTIVE].includes(data.status)
                    ? "Balance"
                    : "Estimate"}
                </Typography>
                <Typography fontWeight={"700 !important"}>
                  {currency(data.balance)}
                </Typography>
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        <br />
        <Typography>
          {data.business_address_street +
            ", " +
            (data.business_address_street2 !== ""
              ? data.business_address_street2 + ", "
              : "") +
            data.business_address_city +
            ", " +
            abbreviator(
              data.business_address_province,
              data.business_address_country
            ) +
            ", " +
            data.business_address_postal_code +
            " " +
            (data.business_address_country === "CA" ? "Canada" : "USA")}
        </Typography>
      </StyledCardBody>
    </StyledCardContainer>
  );
};
