import React, { useState, useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Input from "@mui/material/Input";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  BLACK_LOW_EMPHASIS,
  BLACK_MEDIUM_EMPHASIS,
  OUTLINE,
  PRIMARY_50,
  PRIMARY_500,
  WHITE,
} from "../../../../mui-theme/theme";
import { AuthContext } from "../../../contexts/AuthContext";
import { get_admin_by_id } from "../../../../core/apis/admin";
import Button from "../Button";
import IconBase from "../IconBase";

const OuterContainer = styled.div`
  && {
    align-items: center;
    background: ${WHITE};
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: 10px;
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    text-transform: unset;
    height: 68px;

    ${({ selected }) => {
      switch (selected) {
        case true:
          return `
            border: 1px solid ${BLACK_MEDIUM_EMPHASIS} !important;
        `;
        case false:
          return `
          border: 1px solid ${OUTLINE} !important;
        `;
        default:
          return `
          border: 1px solid ${OUTLINE} !important;
          `;
      }
    }}
    :hover {
      background: ${PRIMARY_50};
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding: 0 1rem;
`;

export default function CommentField({ addComment }) {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [selected, setSelected] = useState(false);
  const [adminName, setAdminName] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const admin = await get_admin_by_id(user?.id);
        const names = {
          fName: admin?.firstName,
          lName: admin?.lastName,
          fullName: admin?.fullName,
        };
        setAdminName(names);
      } catch (e) {}
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _addComment = () => {
    if (!comment) return;
    addComment(comment, setLoading, setComment);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      _addComment();
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setSelected(false);
      }}
    >
      <OuterContainer selected={selected}>
        <Avatar style={{ backgroundColor: PRIMARY_500, padding: 1 }}>
          {adminName?.fName?.charAt(0)?.toUpperCase() || "-"}
          {adminName?.lName?.charAt(0)?.toUpperCase() || "-"}
        </Avatar>
        <InnerContainer>
          <Input
            onKeyDown={handleKeyDown}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            name="comment"
            placeholder="Write a note..."
            fullWidth
            disabled={loading}
            disableUnderline={true}
            onClick={() => {
              setSelected(!selected);
            }}
          />
        </InnerContainer>
        {selected ? (
          <Button
            size={"medium"}
            variant={"contained"}
            disabled={loading}
            onClick={_addComment}
          >
            <IconBase
              iconName={"send"}
              color={WHITE}
              fill={1}
              style={{
                fontSize: "28px",
              }}
            />
          </Button>
        ) : (
          <Button size={"medium"}>
            <IconBase
              iconName={"send"}
              color={BLACK_LOW_EMPHASIS}
              fill={1}
              disabled={loading}
              style={{
                fontSize: "28px",
              }}
            />
          </Button>
        )}
      </OuterContainer>
    </ClickAwayListener>
  );
}
