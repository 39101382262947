import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import { useTheme } from "@mui/material";
import { forgot_password } from "../../../../core/apis/user";
import { routes } from "../../../routes/routesData";
import { AuthContext } from "../../../contexts/AuthContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import TextField from "../../../components/ui-kit/TextField";
import Button from "../../../components/ui-kit/Button";
import IconBase from "../../../components/ui-kit/IconBase";
import { default as Typography } from "@mui/material/Typography";
import { validEmail } from "../../../../utilities/yupValidators";

const FixedButton = styled(Button)`
  && {
    top: ${({ top }) => top || "1rem"};
    left: ${({ left }) => left || "1rem"};
    bottom: ${({ bottom }) => bottom};
    position: fixed;
    z-index: 10;
  }
`;

const initialValues = {
  email: "",
};

const validation = yup.object({
  email: validEmail,
});

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto !important;
  display: flex;
  flex-direction: column;
  width: 344px;
  height: 100vh;
`;

const CenteredText = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const InnerContainer = styled.div``;

const LowerContainer = styled.div``;

export default function Index() {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { handleError } = useContext(NotificationContext);
  const theme = useTheme();
  const [validateEmailInput, setValidationState] = useState(false);
  const [emailSentTo, setEmailSentTo] = useState(null);
  const [emailExists, setEmailExists] = useState(true);
  useEffect(() => {
    if (user) return history.push({ pathname: "/admin/", state: null });
  }, [user, history]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await forgot_password(values.email);
      await setEmailSentTo(values.email);
    } catch (err) {
      if (err.response?.data?.message === "Admin does not exist") {
        resetForm({
          errors: {
            email:
              "This account does not exist. Please, check the spelling or contact your manager",
          },
          values: { email: values.email },
        });
        setEmailExists(!emailExists);
      } else {
        handleError(err.response?.data?.message || err?.message);
      }
    } finally {
    }
  };

  return (
    <Container>
      <div style={{ marginTop: "auto", marginBottom: "auto" }}>
        <FixedButton
          onClick={() => {
            history.push(routes.LOGIN.path);
          }}
          iconName="chevron_left"
          size="medium_icon_left"
          variant="outlined"
          top="4rem"
          left="4rem"
        >
          BACK
        </FixedButton>
        <InnerContainer>
          <CenteredText>
            <IconBase
              iconName="mail"
              wght={"700"}
              size={64}
              color={theme.palette.primary[200]}
              style={{
                marginBottom: "3rem",
              }}
            />
          </CenteredText>
          <Typography variant="h4" style={{ marginBottom: "1rem" }}>
            Reset password
          </Typography>
          <Typography variant={"body1"} style={{ marginBottom: "1rem" }}>
            Please enter the email address associated with your MyCityHall
            account and we’ll send you the steps to get you back into your
            account.
            <br />
            <br />
            If you do not see the email in a few minutes, check your spam
            folder.
          </Typography>
          <LowerContainer>
            {emailSentTo ? (
              <Typography textAlign="center" fontWeight="bold">
                Email sent to{" "}
                <strong style={{ color: theme.palette.primary[200] }}>
                  {emailSentTo}
                </strong>
                . Please check your email.
              </Typography>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={validation}
                validateOnChange={validateEmailInput}
                validateOnBlur={validateEmailInput}
                onSubmit={handleSubmit}
              >
                {(formikProps) => {
                  return (
                    <Form noValidate>
                      <TextField
                        name="email"
                        label="Email address"
                        fullWidth
                        onChange={(e) => {
                          formikProps.setFieldValue(
                            "email",
                            e.target.value.trim()
                          );
                        }}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.email}
                        error={Boolean(formikProps.errors.email)}
                        helperText={formikProps.errors.email}
                        style={{ marginBottom: "1rem" }}
                      />
                      <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        style={{ marginTop: "0.5rem" }}
                        fullWidth
                        onClick={(e) => {
                          setValidationState(true);
                        }}
                      >
                        SEND
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </LowerContainer>
        </InnerContainer>
      </div>
    </Container>
  );
}
