import moment from "moment";
import {
  CURRENCY,
  DATE_FORMAT,
  DATE_SHORT,
  FULL_FORMAT,
  LOCALE,
} from "../core/constants/general";
import { avatarColors } from "../mui-theme/theme";
import { manageSession } from "./manageSession";
import jwt_decode from "jwt-decode";
import config from "../core/apis/_config";
import Cookies from "js-cookie";
import { TWO_FACTOR_AUTHENTICATION_COOKIE_KEY } from "../core/constants/twoFA";

export const delay = (ms?: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms || 500));
};

export const truncate = (str: string, limit: number) => {
  return str.length > limit ? str.slice(0, limit - 1) + "..." : str;
};

export const formatDateTime = (date: string | undefined) => {
  if (date) {
    return moment(date).format(FULL_FORMAT);
  }
};

export const formatDate = (date: string) => {
  if (date) {
    return moment(date).format(DATE_FORMAT);
  }
};

export const formatDateShort = (date: string) => {
  if (date) {
    return moment(date).format(DATE_SHORT);
  }
};

export const generateAvatarColor = (name: string) => {
  // Basic hash to get consistent colors for avatar
  let sum = 0;
  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i);
  }
  return avatarColors[sum % avatarColors.length];
};

export const getMinutesFromNow = (minutes: number) => {
  if (!minutes) return null;
  return new Date(new Date().getTime() + minutes * 60 * 1000);
};

export const formatCurrency = (num: number | null | undefined) => {
  if (num === undefined || num === null) return null;

  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: CURRENCY,
  }).format(num);
};

export const handleAxiosError = (error: any) => {
  const response = error.response;
  if (response) {
    if (response?.data?.message) {
      console.error(response.data?.message);
    } else {
      console.error("Error detail is not provided");
    }
  } else {
    console.error(error);
  }
  throw error;
};

export const isTrustedDevice = (userId: string) => {
  if (config.skip2FA) {
    return true;
  }
  return Cookies.get(TWO_FACTOR_AUTHENTICATION_COOKIE_KEY) === userId;
};

export const utilGenerateStrongPassword = () => {
  const PASSWORD_LENGTH = 12;
  const strongPassword = ((PASSWORD_LENGTH) => {
    const DISTRIBUTION_LENGTH = 4;
    let charTypeDistribution;
    for (let i = 0; i < 10; i++) {
      // Get an array that adds up to `distributionTotal` with all elements >= 1
      // e.g. [2, 3, 3, 4], where 2 + 3 + 3 + 4 = 12
      // There's a chance that an array element can have a value of 0, so call the function 10 times
      charTypeDistribution = ((distributionLength, distributionTotal) => {
        const countDistribution = Array.from(
          { length: distributionLength },
          () => Math.random()
        );
        const sum = countDistribution.reduce((a, b) => a + b, 0);
        let normalizedCountDistribution = countDistribution.map((val) =>
          Math.floor((val * distributionTotal) / sum)
        );
        // Replace the last number to account to ensure all array elements add up to `distributionTotal`
        const sumOfElementsExceptLast = normalizedCountDistribution.reduce(
          (prev, current, i) =>
            i === distributionLength - 1 ? prev : prev + current,
          0
        );
        normalizedCountDistribution[distributionLength - 1] =
          distributionTotal - sumOfElementsExceptLast;
        return normalizedCountDistribution;
      })(DISTRIBUTION_LENGTH, PASSWORD_LENGTH);
      if (charTypeDistribution.every((val) => val >= 1)) break;
    }
    // Create random password
    return ((uppercase, lowercase, numbers, special) => {
      const chars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ", // uppercase
        "abcdefghijklmnopqrstuvwxyz", // lowercase
        "0123456789", // numbers
        "!@#$%^&*()_+-=[]\\{};':\",./<>?", // special
      ];
      return [uppercase, lowercase, numbers, special]
        .map((len, i) =>
          Array(len)
            .fill(chars[i])
            .map((x) => x[Math.floor(Math.random() * x.length)])
            .join("")
        )
        .concat()
        .join("")
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
      // @ts-ignore
    })(...charTypeDistribution);
  })(PASSWORD_LENGTH);
  return strongPassword;
};

/**
 * Capitalizes the first character of a string
 * @param input
 */
export const capitalize = (input: string | undefined) => {
  if (!input) {
    return "";
  }
  input = input.toLowerCase();
  return (input.charAt(0).toUpperCase() + input.slice(1))
    .split(/[-_]+/)
    .join(" ");
};

export const strToBool = (input: any) => {
  let value = input;
  if (typeof input === "string") {
    value = value.toLowerCase();
    if (value === "true" || value === "false") {
      return value === "true";
    }
  }
  return input;
};

// TODO: logic can be changed and simplified
export const formatBoolean = (value: any) => {
  if (typeof value !== "boolean" && typeof strToBool(value) !== "boolean") {
    return value;
  }
  switch (strToBool(value)) {
    case false:
      return "No";
    case true:
      return "Yes";
    default:
      return value;
  }
};

export const sanitizeUnderscore = (value: any) => {
  if (!value || typeof value !== "string" || !value?.includes("_"))
    return value;
  return value?.toString().replace(/_/g, " ");
};

export const formatCanadianSpelling = (value: any) => {
  if (!value || typeof value !== "string") return value;

  let formatted = value;

  if (value?.includes("license")) {
    formatted = value.replace(/license/g, "licence");
  }
  if (value?.includes("canceled")) {
    formatted = value.replace(/canceled/g, "cancelled");
  }

  return formatted;
};

// TODO: add the types
export const formatDisplayValue = (
  value: any,
  format: any,
  transform: any,
  showUnderscore: any,
  row: any,
  loadData: any,
  onClickHandlers:any
) => {
  value = format ? format(value, row, loadData, onClickHandlers) : value;
  if (value === null || value === undefined || value === "") return "-";

  value = formatBoolean(value);
  value =
    transform === "capitalize"
      ? value?.toString()?.toLowerCase() || value
      : value;
  value = showUnderscore ? value : sanitizeUnderscore(value);
  value = formatCanadianSpelling(value);
  return value;
};

//TODO: add the types
export const keepFilters = (filters: any, prevState: any) => {
  let filtersToKeep = {};
  filters.forEach((filter: any) => {
    if (prevState[filter]) {
      // @ts-ignore
      filtersToKeep[filter] = prevState[filter];
    }
  });

  return filtersToKeep;
};

//TODO: add the types
export const downloadCSV = (csv: any, filename: any) => {
  if (!csv) return false;

  let prefix = "data:text/csv;charset=utf-8,";
  let csvContent = csv;

  const encodedUri = prefix + encodeURIComponent(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

// TODO: add the types
export const convertLicenseNumber = (licenseNumber: any): null | string => {
  if (!licenseNumber || typeof licenseNumber !== "string") return null;

  if (licenseNumber?.includes("COCOFLO")) {
    return "Pending Licence #";
  }
  return licenseNumber;
};

//TODO: add the types
export const formatLicenceType = (value: any) => {
  switch (value) {
    case "BUSINESS_LICENSE":
      return "Business";
    case "DOG_LICENSE":
      return "Pet";
    default:
      return value;
  }
};

export const roundMb = (size: number, fixedAmount = 1) => {
  if (size <= 0) return 0;
  const fixedSize = (size / (1024 * 1024)).toFixed(fixedAmount);
  if (parseFloat(fixedSize) <= 0.1) return 0.1;
  return fixedSize;
};

/**
 * Display number of documents
 * @param length - amount of documents
 * @returns {string}
 */
export const getDocCountDisplay = (length: number) => {
  return length === 1 ? length + " doc" : length + " docs";
};

export const addEllipses = (str: string, max = 35) => {
  if (!str || str.length < max) {
    return str;
  }

  return `${str.substring(0, max)}...`;
};

export const userHasRole = (roleName: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwt_decode(token);
  let hasRole = false;
  Object.keys(decoded?.user?.roles).forEach((v) => {
    if (decoded.user.roles[v].name === roleName) {
      hasRole = true;
    }
  });
  return hasRole;
};

export const currency = (amount: any) => {
  if (isNaN(amount)) return "$0.00";
  const num = amount.toString();
  return (
    "$" +
    Number(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

export const getGlobal = (globaKey: string) => {
  // @ts-ignore
  const globals = window.GLOBAL_CONFIG !== undefined ? window.GLOBAL_CONFIG : {};
  if (globals.hasOwnProperty(globaKey)) {
    return globals[globaKey];
  }
  return undefined;
};