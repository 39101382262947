import React, { useContext, useEffect, useState } from "react";
import { CardActionArea, Popover, useTheme } from "@mui/material";
import { NotificationContext } from "../../contexts/NotificationContext";
import { delay, truncate } from "../../../utilities";
import IconBase from "./IconBase";
import {
  primary,
  PRIMARY_200,
  PRIMARY_500,
  primaryLight,
  WHITE,
  WHITE_LOW_EMPHASIS,
} from "../../../mui-theme/theme";
import Typography from "@mui/material/Typography";
import { BUSINESS_NAME_LENGTH } from "../../../core/constants/general";
import SearchBar from "./SearchBar";
import Grid from "@mui/material/Grid";
import {
  BYLAW_SEARCH_DROP_DOWN_LENGTH,
  REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH,
} from "../../../core/constants/licences";
import Chip from "./Chip";
import styled from "@emotion/styled";
import GridWrapper from "./GridWrapper";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

const headerBackground = "rgba(255, 255, 255, 0.4)";

const StyledGrid = styled(Grid)`
  margin: 0 1.4rem;
`;

const Row = styled(Grid)`
  align-items: center;
  display: flex;
  height: 3rem;
  padding: 0.65rem 0.5rem;
  text-transform: capitalize;
`;

const RowList = styled(GridWrapper)`
  max-height: 65%;
  overflow: auto;
  padding-bottom: 3rem;
`;

const ClickableArea = styled(CardActionArea)`
  && {
    border-radius: 0;
    font-size: inherit;
    padding-left: 0.8rem;

    #hover-icon {
      visibility: hidden;
    }
  }

  &&:hover {
    background: ${primaryLight};

    #hover-icon {
      visibility: visible;
    }
  }

,
`;
const NoResults = styled.h2`
  text-align: center;
`;

const SearchHeader = styled.div`
  background: ${PRIMARY_500};
  box-shadow: ${({ theme }) => theme.buttonShadow1};
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 1.2rem;
`;

const SearchFieldWrapper = styled.div`
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  margin-bottom: 1rem !important;
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme, color }) =>
      color ? color : theme.textColor};
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    height: 2rem;
    width: 2rem;
    margin: 0 0.5rem;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    background-color: ${primary};
    margin: 0.5rem 0;
  }
`;

const sanitizeUnderscore = (value) => {
  if (!value || typeof value !== "string" || !value?.includes("_"))
    return value;
  return value?.toString().replace(/_/g, " ");
};

const getSelectedRows = (selected) => {
  if (Array.isArray(selected)) return selected;
  if (selected && typeof selected === "object" && Object.keys(selected).length)
    return [selected];
  return [];
};

const SearchDropDown = (props) => {
  const {
    searchBarPlaceholder,
    fetchData,
    refresh,
    anchorEl,
    setAnchorEl,
    onSelect,
    selected,
    multiSelect,
    titleLabel,
    title,
    categories,
  } = props;
  // Default dataType = "ADMINS"
  let dataType = props.dataType;
  if (!dataType || !["ROLES", "ADMINS"].includes(dataType)) {
    dataType = "ADMINS";
  }
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [loading, setLoading] = useState(false);
  const { handleError } = useContext(NotificationContext) || {};
  const [unSelectedCategories, setUnSelectedCategories] = useState(categories);
  const selectedRows = getSelectedRows(selected);
  const [searchVal, setSearchValue] = useState("");
  const theme = useTheme();
  const checkRowSelected = (rowToCheck) => {
    if (!rowToCheck) return false;
    const selectedRowIds = selectedRows.map((row) => row.id);
    return selectedRowIds.includes(rowToCheck.id);
  };

  const clearState = () => {
    setData([]);
    if (loading) setLoading(false);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    await delay();
    clearState();
  };

  const handleSelectedCategory = async (selectedRow) => {
    // let addedCategory = unSelectedCategories[selectedRow];
    let tmpCategory = unSelectedCategories.filter(function (value, name) {
      return value !== unSelectedCategories[selectedRow];
    });
    setUnSelectedCategories(tmpCategory);
    onSelect(unSelectedCategories[selectedRow]);
  };

  const handleSelect = async (selectedRow) => {
    const isRemove = checkRowSelected(selectedRow);
    try {
      if (multiSelect) {
        if (isRemove) {
          const filteredRows = selectedRows.filter(
            (row) => row.id !== selectedRow.id
          );
          await onSelect(filteredRows);
        } else {
          await onSelect([...selectedRows, selectedRow]);
        }
      } else {
        if (isRemove) {
          await onSelect(null);
        } else {
          onSelect(selectedRow);
        }
      }
      if (refresh) await refresh();
      if (!multiSelect) await handleClose();
    } catch (err) {
      handleError("Something went wrong.");
    }
  };

  const handleSubmit = async (searchValue) => {
    try {
      await setLoading(true);
      const searchedData = await fetchData(searchValue);
      setData(searchedData);
    } catch (err) {
      handleError("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setUnSelectedCategories(categories);
  }, [categories]);

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          setLoading(true);
          const fetched = await fetchData();
          setData(fetched);
          setFilteredData(fetched);
        } catch (err) {
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [open, fetchData]);

  const handleSearchBarChange = (e) => {
    setFilteredData(data);
    let fliterAdmin = [];
    if (e) {
      setSearchValue(e);
      fliterAdmin = [
        ...fliterAdmin,
        ...data.filter(({ fullName }) =>
          fullName.toLowerCase().includes(e.toLowerCase())
        ),
      ];
      setFilteredData(fliterAdmin);
    }
  };

  const handleSearchBarChangeCategories = (e) => {
    setUnSelectedCategories(categories);
    let filterCategories = [];
    if (e) {
      setSearchValue(e);

      filterCategories = [
        ...filterCategories,
        ...categories.filter((element) =>
          element.toLowerCase().includes(e.toLowerCase())
        ),
      ];
      setUnSelectedCategories(filterCategories);
    }
  };

  return (
    <Popover
      id={"simple-popover"}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={{ marginTop: "0.5rem" }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={"search-drop-down-wrapper"}>
        {categories ? (
          <>
            <SearchHeader>
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <IconBase
                  size={40}
                  color={WHITE_LOW_EMPHASIS}
                  iconName="storefront"
                  style={{
                    marginTop: "auto",
                    marginBottom: "3px",
                    marginRight: "1rem",
                    marginLeft: "0.5rem",
                  }}
                />
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {titleLabel ? (
                    <div>
                      <Typography
                        variant={"overline"}
                        color={WHITE_LOW_EMPHASIS}
                        style={{ font: "overline" }}
                      >
                        {titleLabel}
                      </Typography>
                    </div>
                  ) : null}
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      fontWeight="550"
                      fontSize="1.1rem"
                      color={WHITE}
                    >
                      {truncate(
                        title || "Search and Select",
                        BUSINESS_NAME_LENGTH
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
            </SearchHeader>
            <SearchFieldWrapper style={{ marginRight: "auto" }}>
              <SearchBar
                autoFocus
                fullWidth
                placeholder={searchBarPlaceholder}
                handleSubmit={handleSubmit}
                loading={loading}
                textValue={searchVal}
                handleChange={(e) => {
                  handleSearchBarChangeCategories(e.target.value);
                }}
                handleClear={() => {
                  setSearchValue("");
                }}
              />
            </SearchFieldWrapper>
            {Boolean(categories.length) && (
              <RowList gap={1}>
                {unSelectedCategories.map((name, row) => (
                  <ClickableArea
                    key={row}
                    onClick={() => handleSelectedCategory(row)}
                  >
                    <Row item xs={12}>
                      <IconBase
                        iconName="check"
                        id="hover-icon"
                        style={{ color: PRIMARY_200, fontSize: "18px" }}
                      />
                      <Tooltip title={name} placement="right-start">
                        <StyledGrid>
                          <Chip
                            label={truncate(
                              name,
                              BYLAW_SEARCH_DROP_DOWN_LENGTH
                            )}
                            variant="active"
                            iconColor={PRIMARY_200}
                          />
                        </StyledGrid>
                      </Tooltip>
                    </Row>
                  </ClickableArea>
                ))}
              </RowList>
            )}
          </>
        ) : (
          <>
            <SearchHeader>
              <Grid container alignItems="center" spacing={1}>
                <Grid item style={{ justifyContent: "center" }}>
                  <IconBase
                    size="48"
                    color={headerBackground}
                    iconName="person"
                    style={{ marginBottom: "auto", marginTop: "auto" }}
                    fill={1}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid container direction="column">
                    {titleLabel ? (
                      <Grid item>
                        <Typography
                          variant={"overline"}
                          fontSize="10px"
                          color={theme.palette.whites.WHITE_MEDIUM_EMPHASIS}
                        >
                          {titleLabel}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Typography
                        variant="h5"
                        color={theme.palette.whites.WHITE}
                        style={{ marginTop: "-5px" }}
                      >
                        {title || "Search and Select"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchHeader>
            <SearchFieldWrapper>
              <SearchBar
                fullWidth
                placeholder={searchBarPlaceholder}
                handleSubmit={handleSubmit}
                loading={loading}
                textValue={searchVal}
                handleChange={(e) => {
                  handleSearchBarChange(e.target.value);
                }}
                handleClear={() => {
                  setSearchValue("");
                }}
              />
            </SearchFieldWrapper>
            <RowList gap={1}>
              {Boolean(selectedRows.length) && (
                <>
                  <Typography fontWeight="bold" style={{ paddingLeft: "1rem" }}>
                    Selected:
                  </Typography>
                  {selectedRows.map((row, i) => (
                    <ClickableArea key={i} onClick={() => handleSelect(row)}>
                      <Row item xs={12}>
                        <>
                          <IconBase iconName={"how_to_reg"} color={primary} />
                          {dataType === "ADMINS" ? (
                            <StyledGrid
                              container
                              alignItems="center"
                              justify="space-between"
                            >
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <StyledAvatar
                                      color={theme.palette.primary[500]}
                                    >
                                      {(row.firstName?.[0] || "-") +
                                        (row.lastName?.[0] || "-")}
                                    </StyledAvatar>
                                  </Grid>

                                  <Grid item>
                                    <Typography>{row.fullName}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item>
                                <Grid container spacing={1}>
                                  {row?.roles?.length
                                    ? row.roles.map((role) => (
                                        <Grid item>
                                          <Chip
                                            label={sanitizeUnderscore(
                                              sanitizeUnderscore(role.roleName)
                                            )}
                                            variant="contained"
                                          />
                                        </Grid>
                                      ))
                                    : null}
                                </Grid>
                              </Grid>
                            </StyledGrid>
                          ) : (
                            <StyledGrid>
                              <Chip
                                label={sanitizeUnderscore(row.roleName)}
                                variant="contained"
                              />
                            </StyledGrid>
                          )}
                        </>
                      </Row>
                    </ClickableArea>
                  ))}
                  <Grid item xs={12}>
                    <StyledDivider />
                  </Grid>
                </>
              )}
              {!data?.length && !loading ? (
                <Grid item xs={12}>
                  <NoResults>No results found.</NoResults>
                </Grid>
              ) : (
                filteredData?.map((row, i) => {
                  if (checkRowSelected(row)) return null;
                  return (
                    <ClickableArea
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(row);
                      }}
                    >
                      <Row item xs={12}>
                        <>
                          {checkRowSelected(row) ? (
                            <IconBase iconName={"how_to_reg"} color={"red"} />
                          ) : (
                            <IconBase
                              iconName="person_add"
                              fill={1}
                              id="hover-icon"
                              wght="600"
                              color={theme.palette.primary[200]}
                            />
                          )}

                          {dataType === "ADMINS" ? (
                            <StyledGrid
                              container
                              alignItems="center"
                              justify="space-between"
                            >
                              <Grid item>
                                <Grid container alignItems="center">
                                  <Grid item>
                                    <StyledAvatar
                                      color={theme.palette.primary[500]}
                                    >
                                      {(row.firstName?.[0] || "-") +
                                        (row.lastName?.[0] || "-")}
                                    </StyledAvatar>
                                  </Grid>
                                  <Grid item>
                                    <Typography style={{ flexWrap: "wrap" }}>
                                      {truncate(
                                        row.fullName,
                                        REVIEWER_NAME_SEARCH_DROP_DOWN_LENGTH
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  position: "absolute",
                                  marginLeft: "250px",
                                }}
                              >
                                <Grid container spacing={1}>
                                  {row?.roles.length
                                    ? row.roles.map((role) => (
                                        <Grid
                                          key={role.roleName}
                                          item
                                          style={{ marginLeft: "20px" }}
                                        >
                                          <Chip
                                            label={sanitizeUnderscore(
                                              role.roleName
                                            )}
                                            variant="active"
                                            style={{
                                              marginLeft: "20px",
                                            }}
                                          />
                                        </Grid>
                                      ))
                                    : null}
                                </Grid>
                              </Grid>
                            </StyledGrid>
                          ) : (
                            <StyledGrid>
                              <Chip
                                label={sanitizeUnderscore(row.roleName)}
                                variant="contained"
                              />
                            </StyledGrid>
                          )}
                        </>
                      </Row>
                    </ClickableArea>
                  );
                })
              )}
            </RowList>
          </>
        )}
      </div>
    </Popover>
  );
};

export default SearchDropDown;
