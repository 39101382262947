import React, { createRef, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  formatCreditCard,
  formatCurrency,
  formatDate,
  formatExpiry,
  formatNumber,
  formatPhoneNumber,
  formatPostalCode,
} from "../../../utilities/masked-input-formatters";

//Options
const CURRENCY = "CURRENCY";
const DATE = "DATE";
const PHONE = "PHONE";
const POSTAL = "POSTAL";
const NUMBER = "NUMBER";
const CREDIT_CARD = "CREDIT_CARD";
const EXPIRY = "EXPIRY";

/**
 * Formats a user input into various types
 * @param value
 * @param initialValue
 * @param onChange
 * @param type - options : phone, currency
 * @param handleClear
 * @param endIcon
 * @param InputProps
 * @param endAdornment
 * @param maxAmount
 * @param setFieldValue
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaskedTextField({
  value,
  initialValue,
  onChange,
  type,
  handleClear,
  endIcon = "close",
  InputProps,
  endAdornment,
  maxAmount,
  setFieldValue,
  ...props
}) {
  const updateValue = () => {
    let formattedValue = value;
    switch (type) {
      case CURRENCY:
        formattedValue = formatCurrency(formattedValue, maxAmount);
        break;
      case DATE:
        formattedValue = formatDate(formattedValue);
        break;
      case PHONE:
        formattedValue = formatPhoneNumber(formattedValue);
        break;
      case POSTAL:
        formattedValue = formatPostalCode(formattedValue);
        break;
      case NUMBER:
        formattedValue = formatNumber(formattedValue);
        break;
      case CREDIT_CARD:
        formattedValue = formatCreditCard(formattedValue);
        break;
      case EXPIRY:
        formattedValue = formatExpiry(formattedValue);
        break;
      default:
        formattedValue = value;
    }

    return formattedValue;
  };

  const [inputValue, setInputValue] = useState(updateValue() || "");

  const ref = createRef();

  const handleInput = (e) => {
    let formattedValue = e.target.value;
    switch (type) {
      case CURRENCY:
        formattedValue = formatCurrency(formattedValue, maxAmount);
        break;
      case DATE:
        formattedValue = formatDate(formattedValue);
        break;
      case PHONE:
        formattedValue = formatPhoneNumber(formattedValue);
        break;
      case POSTAL:
        formattedValue = formatPostalCode(formattedValue);
        break;
      case NUMBER:
        formattedValue = formatNumber(formattedValue);
        break;
      case CREDIT_CARD:
        formattedValue = formatCreditCard(formattedValue);
        break;
      case EXPIRY:
        formattedValue = formatExpiry(formattedValue);
        break;
      default:
        formattedValue = value;
    }

    if (formattedValue !== inputValue) {
      onChange(e);
      if (!!setFieldValue) {
        setFieldValue(formattedValue);
      }
    }

    setInputValue(formattedValue);
  };

  useEffect(() => {
    if (!value) {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    if (initialValue && !inputValue) {
      setInputValue(updateValue(initialValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <TextField
      inputRef={ref}
      {...props}
      InputProps={{
        disableUnderline: true,
        endAdornment: endAdornment,
      }}
      value={inputValue}
      onChange={(e) => {
        handleInput(e);
      }}
    />
  );
}
