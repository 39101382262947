import React from "react";
import IconBase from "../../../../../../components/ui-kit/IconBase";
import ListItem from "../../../../../../components/ui-kit/mch/ListItem";
import {useTheme} from "@mui/material";
import Button from "@mui/material/Button";

const WarningAlert = ({onClick = () => {}}) => {
  const theme = useTheme()
  return (
    <ListItem
      style={{backgroundColor: theme.palette.nonPalette.ORANGE_BACKGROUND}}
      startOrnament={<IconBase color={theme.palette.nonPalette.ORANGE} size={30} fill={1} iconName={"warning"} />}
      endOrnament={<Button onClick={onClick} size={"small"} variant={"outlined"}>Review Services</Button>}
      label={"Action required"}
      alignIcon={true}
      subLabel={
        "To ensure accurate billing, review your services information and make any necessary updates."
      }
      subLabelColor={theme.palette.primary[700]}
      labelFontWeight={600}
    />
  );
};

export default WarningAlert;
