import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import styled from "@emotion/styled";

import * as yup from "yup";

import TextField from "../../../../components/ui-kit/TextField";
import Alerts from "../../../../components/ui-kit/Alert";
import Spacer from "../../../../components/layout-helpers/Spacer";
import { IconBase } from "../../../../components/ui-kit/Icons";
import MaskedTextField from "../../../../components/ui-kit/MaskedTextField";
import ListItem from "../../../../components/ui-kit/ListItem";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { PaymentProfileContext } from "../../../../contexts/PaymentProfileContext";

import { INPUT_FORMATS } from "../../../../../utilities/cleaveOptions";

import { normalizeOnSubmit } from "../../../../../utilities/normalize-on-submit";
import * as v from "../../../../../utilities/yupValidators";
import DeleteCardModal from "./DeleteCardModal";

//Constants
const ADD = "ADD";
const EDIT = "EDIT";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  padding-top: 1rem;
  margin-left: 18px;
  margin-right: 18px;
  width: 100%;
`;

const Content = styled.div`
  max-width: 375px;
  margin: 1rem auto 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const emptyInitialValues = {
  autoPay: false,
  name: "",
  ccnumber: "",
  expiry: "",
  cvc: "",
};

const addValidation = yup.object({
  name: v.validName,
  ccnumber: v.validNumber,
  expiry: v.validExpiry,
  cvc: v.validCvc,
  autoPay: yup.boolean(),
});

const editValidation = yup.object({
  autoPay: yup.boolean(),
});

export default function CreditCardAddEdit() {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const { createProfile, editProfile, deleteProfile } = useContext(
    PaymentProfileContext
  );
  const { handleSuccess, handleError } = useContext(NotificationContext);
  const [type, setType] = useState(ADD);
  const [profileId, setProfileId] = useState(null);
  const history = useHistory();
  const formikRef = useRef();

  //Delete modal state
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteProfile(profileId);
      handleSuccess("Card successfully deleted");
      history.goBack();
    } catch (e) {
      handleError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const location = useLocation();
  const theme = useTheme();

  const handleCreate = async (values) => {
    const cleanedValues = normalizeOnSubmit(values);
    setLoading(true);
    try {
      const response = await createProfile(cleanedValues);
      if (response.data.statusCode === 2) {
        formikRef.current.setFieldError(
          "cardValidation",
          "Card verification failed. Please check the number."
        );
        return;
      }
      if (response.data.statusCode === 43) {
        formikRef.current.setFieldError(
          "ccnumber",
          "This card is already saved."
        );
        return;
      }
      if (response.data.statusCode === 19) {
        formikRef.current.setFieldError(
          "cardValidation",
          response.data.content.message
        );
        return;
      }
      if (!response || response.status >= 400) {
        formikRef.current.setFieldError(
          "errorSaving",
          response.data.content.message
        );
        return;
      }

      handleSuccess("Payment Method Added");
      history.goBack();
    } catch (err) {
      handleError("Failed to add payment method");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (values) => {
    setLoading(true);
    try {
      await editProfile(
        {
          autoPay: values.autoPay,
        },
        profileId
      );
      handleSuccess("Payment Method updated");
      history.goBack();
    } catch (err) {
      handleError("Failed to update payment method");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (values) => {
    if (type === ADD) {
      handleCreate(values);
      return;
    }

    if (type === EDIT && profileId) {
      handleEdit(values, profileId);
    }
  };

  const btnBackNavigate = () => {
    history.goBack();
  };

  useEffect(() => {
    if (location.state && location.state.profileId) {
      const profId = location.state.profileId;
      setProfileId(profId);
      const profData = location.state.profileData;

      let newValues = {
        name: profData.name,
        ccnumber: `**** **** **** ${profData.number}`,
        expiry: `${profData.expiryMonth}/${profData.expiryYear}`,
        cvc: "***",
        autoPay: profData.autoPay || false,
      };
      setInitialValues(newValues);

      setType(EDIT);
    }

    setLoadingState(false);
  }, [location.state]);

  return (
    <Container>
      <InnerContainer>
        <Box p={1}>
          <Button
            onClick={btnBackNavigate}
            variant={"outlined"}
            startIcon={<IconBase iconName={"close"} />}
            size={"medium"}
          >
            Cancel
          </Button>
          <Content>
            {loadingState ? null : (
              <Formik
                innerRef={formikRef}
                validationSchema={
                  type === EDIT ? editValidation : addValidation
                }
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnChange={validateOnChange}
              >
                {(props) => {
                  return (
                    <Form>
                      <Grid container spacing={1}>
                        {props.errors.cardValidation || props.errors.expiry ? (
                          <Grid item xs={12}>
                            <Alerts
                              variant={"error"}
                              title={"Card details don’t match"}
                              body={
                                "Please double-check the card details match what’s on your card and try again."
                              }
                            />
                            <Spacer />
                          </Grid>
                        ) : null}
                        <Grid item xs={12}>
                          <Typography variant={"h4"}>Card details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            helperText={props.errors.name}
                            name={"name"}
                            value={props.values.name}
                            onChange={props.handleChange}
                            error={Boolean(props.errors.name)}
                            label={"Name on card"}
                            disabled={type === EDIT}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            format={type === EDIT ? null : INPUT_FORMATS.CC}
                            disabled={type === EDIT}
                            helperText={props.errors.ccnumber}
                            type={"CREDIT_CARD"}
                            fullWidth
                            name={"ccnumber"}
                            value={props.values.ccnumber}
                            onChange={props.handleChange}
                            error={Boolean(props.errors.ccnumber)}
                            label={"Card number"}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MaskedTextField
                            helperText={
                              props.errors.expiry !== "Expiry is invalid."
                                ? props.errors.expiry
                                : ""
                            }
                            error={Boolean(
                              props.errors.expiry &&
                                props.errors.expiry !== "Expiry is invalid."
                            )}
                            type={"EXPIRY"}
                            fullWidth
                            name={"expiry"}
                            value={props.values.expiry}
                            onChange={props.handleChange}
                            label={"Expiration"}
                            disabled={type === EDIT}
                            placeholder={"MM/YY"}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          {type === EDIT ? (
                            <TextField
                              inputProps={{ maxLength: 4 }}
                              helperText={props.errors.cvc}
                              fullWidth
                              name={"cvc"}
                              value={props.values.cvc}
                              onChange={props.handleChange}
                              error={Boolean(props.errors.cvc)}
                              label={"CVC"}
                              disabled
                            />
                          ) : (
                            <TextField
                              inputProps={{ maxLength: 4 }}
                              helperText={props.errors.cvc}
                              fullWidth
                              name={"cvc"}
                              value={props.values.cvc}
                              onChange={props.handleChange}
                              error={Boolean(props.errors.cvc)}
                              label={"CVC"}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Spacer amount={2} />
                          <Typography variant={"h4"}>Auto-pay</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant={"body1"}
                            style={{
                              color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
                            }}
                          >
                            Don’t miss a payment with auto-pay! Your dues for
                            any resource linked to your MyCityHall account will
                            be automatically charged to your preferred payment
                            method.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ListItem
                            label={"Auto-pay"}
                            labelFontWeight={"700"}
                            subLabel={`Dues will be charged to **** ${props.values.ccnumber.substr(
                              props.values.ccnumber.length - 4
                            )}`}
                            startOrnament={
                              <IconBase
                                size={32}
                                wght={700}
                                iconName={"credit_score"}
                              />
                            }
                            endOrnament={
                              <Switch
                                name={"autoPay"}
                                checked={props.values.autoPay}
                                onChange={props.handleChange}
                              />
                            }
                            style={{
                              outline: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                            }}
                          />
                        </Grid>
                        {props.errors.errorSaving ? (
                          <Grid item xs={12}>
                            <Spacer />
                            <Alerts
                              variant={"error"}
                              title={"Card couldn't be saved"}
                              body={
                                "Please, make sure you have connection to the Internet and try again."
                              }
                            />
                          </Grid>
                        ) : null}
                        <Grid item xs={12}>
                          <Button
                            type={"submit"}
                            fullWidth
                            variant={"contained"}
                            size={"large"}
                            onClick={() => {
                              setValidateOnChange(true);
                            }}
                            startIcon={
                              loading && (
                                <CircularProgress
                                  style={{
                                    position: "absolute",
                                    top: 10,
                                    left: 40,
                                    color: theme.palette.primary[50],
                                  }}
                                />
                              )
                            }
                          >
                            {type === ADD ? "Add card" : "Update card"}
                          </Button>
                        </Grid>
                        {type === EDIT ? (
                          <Grid item xs={12}>
                            <Spacer amount={2} />
                            <Button
                              sx={{
                                color: theme.palette.nonPalette.RED,
                                ".MuiTouchRipple-child": {
                                  color: `${theme.palette.nonPalette.RED} !important`,
                                },
                                "&:hover": {
                                  backgroundColor:
                                    theme.palette.nonPalette.RED_BACKGROUND,
                                  ".MuiTouchRipple-child": {
                                    color: theme.palette.nonPalette.RED,
                                  },
                                },
                                "&:active": {
                                  backgroundColor:
                                    theme.palette.nonPalette.RED_BACKGROUND,
                                  ".MuiTouchRipple-child": {
                                    color: theme.palette.nonPalette.RED,
                                  },
                                },
                              }}
                              onClick={() => {
                                handleOpenDeleteModal();
                              }}
                              size={"small"}
                              variant={"text"}
                            >
                              Delete Card
                            </Button>
                            <DeleteCardModal
                              open={openDeleteModal}
                              onDelete={onDelete}
                              handleClose={handleCloseDeleteModal}
                            />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Content>
        </Box>
      </InnerContainer>
    </Container>
  );
}
