import React from "react";
import classes from "../../../assets/styles/typography/typography.module.scss"
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Spacer from "./Spacer";
import IconBase from "./IconBase";

/**
 * A banner is used to display data to the user.
 * @param title
 * @param body
 * @param styles
 * @param icon
 * @returns {JSX.Element}
 * @constructor
 */
const Banner = ({ title = "", body = "", styles = {}, Icon = <IconBase/> }) => {
  return (
    <Paper style={{ borderRadius: 10, width: "100%", ...styles }} elevation={0}>
      <div style={{ padding: "1rem", display: "flex", overflow: "hidden", textOverflow: "ellipsis" }}>
        {Icon ? Icon : null}
        <div style={{ display: "block", paddingLeft: "1rem" }}>
          <Typography variant={"body1"} style={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <Spacer amount={0.3} />
          <p className={classes.body2}>{body}</p>
        </div>
      </div>
    </Paper>
  );
};

export default Banner;
