import base from "./axiosInstance";
import { IFormField } from "../interfaces/utility/IFormField";

export const findUtilityAccount = async (data: {
  accountNumber: string;
  accessCode: string;
}) => {
  const response = await base.get(
    `/account/link?accountNumber=${data.accountNumber}&accessCode=${data.accessCode}`
  );
  return response.data;
};

export const linkUtilityAccount = async (id: string, userId: string | null) => {
  const response = await base.put(`/account/${id}/link?userId=${userId}`);
  return response.data;
};

export const getAccountSummaries = async (param: any) => {
  try {
    const params = { ...param, filters: JSON.stringify(param.filters) };
    const response = await base.get(`/account/summary`, { params });
    return response.data;
  } catch (error) {}
};

export const getAccountDetail = async (id: string): Promise<IFormField[]> => {
  let response = { data: []};
  try {
     response = await base.get(`/account/${id}`);
  } catch (error) {}
  return Promise.resolve(response.data);

};