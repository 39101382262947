import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Chip, CircularProgress, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import {
  PRIMARY_200,
  RED,
  RED_BACKGROUND,
} from "../../../../../mui-theme/theme";
import Typography from "../../../../components/ui-kit/typography";
import IconBase from "../../../../components/ui-kit/IconBase";
import { LicenseCard } from "../../../../components/ui-kit/account-cards";
import { APPROVED } from "../../../../../core/constants/licences";
import ListItem from "../../../../components/ui-kit/list-item";
import TextField from "../../../../components/ui-kit/TextField";
import { CheckboxCard } from "../../../../components/ui-kit/Checkbox";
import { removeLinkedUsers } from "../../../../../core/apis/licence";
import { validPhone } from "../../../../../utilities/yupValidators";
import AccountBar from "../../../../components/ui-kit/account-bar";
import HistoryTab from "../history-tab";
import DocumentCard from "../../../../components/ui-kit/document-card";
import AddressDisplayEditor from "../../../../components/ui-kit/address-display-editor";
import {
  capitalize,
  formatPhoneNumber
} from "../../../../../utilities/masked-input-formatters";
import {
  ModalUnlink,
  RemoveOwnerModal,
} from "../../../../components/modals/modal-decline";
import LinkUserToAccountModal from "../../../../components/modals/link-user-to-account-modal";

const SmallEditButton = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.blacks.BLACK_MEDIUM_EMPHASIS};
    margin-left: 1rem;
    margin-top: 1rem;

    :hover {
      color: ${({ theme }) => theme.palette.primary[200]};
      cursor: pointer;
    }
  }
`;

const EndOrnamentContainer = styled.div`
  display: flex;

  .material-symbols-rounded {
    font-size: 32px;
  }
`;

const DeleteButton = styled(Button)`
  && {
    :hover {
      color: ${RED};
      background: ${RED_BACKGROUND};
      cursor: pointer;
    }
  }
`;

export const managerOwnerValidations = () =>
  yup.object().shape({
    full_name: yup
      .string()
      .max(35, "Full name is too long")
      .required("Full name is required"),
    phone: validPhone,
  });

const ManagersAndOwnersItem = ({
  index,
  type,
  element,
  count,
  handleSubmit,
  handleRemove,
  isOwner,
  editing,
  isOriginalValue = false,
  adding,
  setAddingOwner,
  licenceData,
  errors,
  setErrors,
}) => {
  const [caseEditing, setEditing] = useState(false);
  const [openRemoveOwnerModal, setOpenRemoveOwnerModal] = useState(false);
  const [isOwnerVal, setIsOwnerVal] = useState(element.is_owner);
  const [refreshElement, setRefreshElement] = useState(false);
  const theme = useTheme();

  const initialValues = {
    full_name: element.full_name,
    phone: element.phone,
    is_owner: element.is_owner,
  };

  const handleCancel = () => {
    setEditing(false);
    setRefreshElement(!refreshElement);
  };

  const checkBoxClick = (e) => {
    e.stopPropagation();
    if (errors.managers || errors.owners) {
      setErrors((prev) => ({ ...prev, managers: null, owners: null }));
    }
    setIsOwnerVal(e.target.checked);
  };

  const deleteOwner = () => {
    handleRemove(index);
    setEditing(false);
  };

  const submit = (event, index) => {
    let changedData = event;
    changedData.is_owner = isOwnerVal;
    handleSubmit(changedData, index);
    setEditing(false);
  };

  const add = (event) => {
    let changedData = event;
    changedData.is_owner = isOwnerVal;
    handleSubmit(changedData);
    setAddingOwner(false);
  };

  useEffect(() => {
    setIsOwnerVal(element.is_owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.is_owner, refreshElement]);

  return adding && editing ? (
    <Formik
      initialValues={initialValues}
      validationSchema={managerOwnerValidations(licenceData.is_resident)}
      onSubmit={(event) => {
        add(event, index);
      }}
    >
      {(formikProps) => {
        return (
          <Form style={{ marginTop: "1rem" }}>
            <Typography
              variant="caption"
              color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              style={{ marginLeft: "1rem" }}
            >
              Add manager/owner
            </Typography>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginBottom: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <IconBase iconName="person"></IconBase>
              </EndOrnamentContainer>
              <TextField
                label="Full Name"
                fullWidth
                name="full_name"
                value={formikProps.values.full_name}
                touched={formikProps.touched.full_name ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.full_name)}
                helperText={formikProps.errors.full_name}
              />
            </div>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <IconBase iconName="call"></IconBase>
              </EndOrnamentContainer>
              <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                format={"PHONE"}
                value={formikProps.values.phone}
                touched={formikProps.touched.phone ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.phone)}
                helperText={formikProps.errors.phone}
              />
            </div>
            <div
              style={{
                width: "85%",
                marginLeft: "3rem",
                marginTop: "0.5rem",
              }}
            >
              <CheckboxCard
                onChange={(e) => {
                  checkBoxClick(e);
                }}
                label={"This person is an owner"}
                value={isOwnerVal}
              />
            </div>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginTop: "0.5rem",
                marginLeft: "3rem",
              }}
            >
              <Button variant={"contained"} size={"medium"} type={"submit"}>
                Done
              </Button>
              <Button
                onClick={() => {
                  setAddingOwner(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  ) : !adding && editing && caseEditing ? (
    <Formik
      validationSchema={managerOwnerValidations(licenceData.is_resident)}
      initialValues={initialValues}
      onSubmit={(event) => {
        submit(event, index);
      }}
    >
      {(formikProps) => {
        return (
          <Form>
            <Typography
              variant="caption"
              color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              style={{ marginLeft: "1rem" }}
            >
              {type} {count}
            </Typography>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginBottom: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <IconBase iconName="person"></IconBase>
              </EndOrnamentContainer>
              <TextField
                label="Full Name"
                fullWidth
                name="full_name"
                value={formikProps.values.full_name}
                touched={formikProps.touched.full_name ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.full_name)}
                helperText={formikProps.errors.full_name}
              />
            </div>
            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
              }}
            >
              <EndOrnamentContainer style={{ paddingTop: 20 }}>
                <IconBase iconName="call"></IconBase>
              </EndOrnamentContainer>
              <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                format={"PHONE"}
                value={formikProps.values.phone}
                touched={formikProps.touched.phone ? "true" : "false"}
                onChange={formikProps.handleChange}
                error={Boolean(formikProps.errors.phone)}
                helperText={formikProps.errors.phone}
              />
            </div>
            <div
              style={{
                width: "85%",
                marginLeft: "3rem",
                marginTop: "0.5rem",
              }}
            >
              <CheckboxCard
                onChange={(e) => {
                  checkBoxClick(e);
                }}
                label={"This person is an owner"}
                value={isOwnerVal}
              />
            </div>

            <div
              style={{
                width: "95%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 15,
                marginTop: "0.5rem",
                marginLeft: "3rem",
                marginBottom: "1rem",
              }}
            >
              <Button
                variant={"contained"}
                size={"medium"}
                type={"submit"}
                disabled={
                  formatPhoneNumber(initialValues.phone) ===
                    formatPhoneNumber(formikProps.values.phone) &&
                  initialValues.full_name === formikProps.values.full_name &&
                  element.is_owner === isOwnerVal
                }
              >
                Done
              </Button>
              <DeleteButton
                sx={{
                  ".MuiTouchRipple-child": {
                    color: `${theme.palette.nonPalette.RED} !important`,
                  },
                  "&:hover": {
                    backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
                    ".MuiTouchRipple-child": {
                      color: theme.palette.nonPalette.RED,
                    },
                  },
                  "&:active": {
                    backgroundColor: theme.palette.nonPalette.RED_BACKGROUND,
                    ".MuiTouchRipple-child": {
                      color: theme.palette.nonPalette.RED,
                    },
                  },
                }}
                onClick={() => {
                  setOpenRemoveOwnerModal(true);
                }}
              >
                Delete
              </DeleteButton>
              <Button
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </div>
            <RemoveOwnerModal
              onDecline={deleteOwner}
              open={openRemoveOwnerModal}
              handleClose={() => {
                setOpenRemoveOwnerModal(false);
              }}
              isOwner={isOwner}
            />
          </Form>
        );
      }}
    </Formik>
  ) : (
    isOwner === element.is_owner && (
      <div theme={theme}>
        <Typography
          variant="caption"
          color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
          style={{ marginLeft: "1rem" }}
        >
          {type} {count}
        </Typography>
        <ListItem
          editing={editing}
          props={"manager"}
          label={element.full_name || "-"}
          startOrnament={<IconBase iconName="person"></IconBase>}
          characterLimit={80}
        />
        <ListItem
          editing={editing}
          props={"manager"}
          label={formatPhoneNumber(element.phone) || "-"}
          startOrnament={<IconBase iconName="call"></IconBase>}
          characterLimit={80}
        />
        {editing && !isOriginalValue && (
          <SmallEditButton
            onClick={() => {
              setEditing(true);
            }}
            style={{ marginBottom: "2rem" }}
          >
            Edit {type}
          </SmallEditButton>
        )}
      </div>
    )
  );
};

const GeneralTab = ({
  data,
  originalData,
  setData,
  initialData,
  allHistory,
  addComment,
  setTab,
  managersAndOwners,
  originalManagers,
  documents,
  handleDownloadDoc,
  editing,
  editData,
  setEditData,
  handleFileUploadModalOpen,
  linkedAccounts,
  refresher,
  refreshValue,
  changedFields,
  isDuplicate,
  fetchHistories,
  errors,
  setErrors,
  uploadingDocument,
  showOriginalData,
  setShowOriginalData,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  // const [expanded, setExpanded] = useState(false);
  const [linkedAccountExpanded, setLinkedAccountExpanded] = useState(false);
  const [openUnlinkModal, setOpenUnlinkModal] = useState(false);
  const [unlinkedUsersEmail, setUnlinkedUsersEmail] = useState("");
  const [openLinkUserModal, setOpenLinkUserModal] = useState(false);

  // const totalBalance = fakeLinkedAccounts
  //   .map((acc) => acc.balance)
  //   .reduce((acc, cur) => acc + cur);
  const linkedAccountsCount = linkedAccounts.length;
  const [addingOwner, setAddingOwner] = useState(false);

  const onRemoveOwner = (index) => {
    managersAndOwners.splice(index, 1);
    const fields = [];
    const [field] = Object.values(initialData).filter(
      (item) => item.name === "business_manager"
    );
    let tmpData = editData;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].id === field.id) {
        tmpData = tmpData.splice(i, 1);
        setEditData(tmpData);
      }
    }
    fields.push({
      id: field.id,
      value: { value: managersAndOwners },
      name: field.name,
    });
    const edittedData = fields.concat(editData);
    setEditData(edittedData);
  };

  const onAddOwner = (data) => {
    managersAndOwners.push(data);
    const fields = [];
    const [field] = Object.values(initialData).filter(
      (item) => item.name === "business_manager"
    );
    let tmpData = editData;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].id === field.id) {
        tmpData = tmpData.splice(i, 1);
        setEditData(tmpData);
      }
    }
    fields.push({
      id: field.id,
      value: { value: managersAndOwners },
      name: field.name,
    });
    const edittedData = fields.concat(editData);
    setEditData(edittedData);
    const { business_manager, ...newErrors } = errors;
    setErrors(newErrors);
  };

  const onEditOwner = (data, index) => {
    managersAndOwners[index] = data;
    const fields = [];
    const [field] = Object.values(initialData).filter(
      (item) => item.name === "business_manager"
    );
    let tmpData = editData;
    for (let i = 0; i < tmpData.length; i++) {
      if (tmpData[i].id === field.id) {
        tmpData = tmpData.splice(i, 1);
        setEditData(tmpData);
      }
    }
    fields.push({
      id: field.id,
      value: { value: managersAndOwners },
      name: field.name,
    });
    const edittedData = fields.concat(editData);
    setEditData(edittedData);
  };

  const onSaveEdit = async (values) => {
    const arrayOfFields = [];
    for (let prop in values) {
      const [field] = Object.values(initialData).filter(
        (item) => item.name === prop
      );
      const formattedVal = values[prop];
      arrayOfFields.push({
        id: field.id,
        value: { value: formattedVal },
        name: field.name,
      });
      let tmpData = editData;
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i].id === field.id) {
          tmpData = tmpData.splice(i, 1);
          setEditData(tmpData);
        }
      }
      let tmp = data;
      tmp[prop] = values[prop];
      setData(tmp);
    }
    const edittedData = arrayOfFields.concat(editData);
    setEditData(edittedData);
  };

  const handleUnLink = async () => {
    await removeLinkedUsers(data.id);
    refresher(!refreshValue);
    setOpenUnlinkModal(false);
  };

  const handleOpenUnlinkModal = (email) => {
    setUnlinkedUsersEmail(email);
    setOpenUnlinkModal(true);
  };

  const writeResidency = () => {
    if ("is_resident" in originalData || "is_homebased" in originalData) {
      return originalData?.is_resident ||
        (originalData?.is_resident === undefined && originalData?.is_homebased)
        ? `Resident${originalData?.is_homebased ? " (Home-based)" : ""}`
        : "Non-resident";
    } else {
      return undefined;
    }
  };

  /**
   * Memo version of indicator for showing the home-parking section when it has values
   * @type {boolean}
   */
  const showHomeParkingSection = useMemo(() => {
    return (
      data.has_parking_for_customers &&
      (!!data.parking_place || !!data.number_of_vehicles || !!data.truck_size)
    );
  }, [
    data.has_parking_for_customers,
    data.number_of_vehicles,
    data.parking_place,
    data.truck_size,
  ]);

  let managersCount = 1;
  let ownersCount = 1;
  let originalManagersCount = 1;
  let orignalOwnersCount = 1;
  if (typeof managersAndOwners !== "object") {
    managersAndOwners = [];
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!editing && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4" fontWeight={400}>
                Account info
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: "auto" }}>
              <Typography
                variant="overline"
                color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
              >
                Linked accounts{" "}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
          {!editing && (
            <Grid item xs={6}>
              <LicenseCard data={data} />
            </Grid>
          )}
          {!editing && (
            <Grid item xs={6}>
              {linkedAccounts[0]?.exists === false && (
                <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
                  <AccountBar
                    hideLinkButton
                    variant={"LINK"}
                    name={"Link to account..."}
                    onBarClick={() => {
                      setOpenLinkUserModal(true);
                    }}
                    disablebarclick={false}
                  />
                </Grid>
              )}

              {linkedAccountsCount > 3 &&
              !linkedAccountExpanded &&
              linkedAccounts[0]?.exists !== false ? (
                <Grid container spacing={2}>
                  {linkedAccounts
                    .filter((acc, index) => index < 3)
                    .map((acc, index) => (
                      <Grid key={index} item xs={12}>
                        <AccountBar
                          variant={"USER"}
                          name={acc?.email}
                          isLinked={true}
                          onUnlink={() => {
                            handleOpenUnlinkModal(acc?.email);
                          }}
                        />
                      </Grid>
                    ))}
                  <Button
                    size="small"
                    textType={"body1"}
                    iconName={"expand_more"}
                    onClick={() => {
                      setLinkedAccountExpanded(true);
                    }}
                    style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                  >
                    <IconBase iconName={"expand_more"} />
                    Show all ({linkedAccountsCount - 3} more)
                  </Button>
                </Grid>
              ) : linkedAccountsCount > 3 &&
                linkedAccountExpanded &&
                linkedAccounts[0]?.exists !== false ? (
                <Grid container spacing={2}>
                  {linkedAccounts.map((acc, index) => (
                    <Grid key={index} item xs={12}>
                      <AccountBar
                        variant={"USER"}
                        name={acc?.email}
                        isLinked={true}
                        onUnlink={() => {
                          handleOpenUnlinkModal(acc?.email);
                        }}
                      />
                    </Grid>
                  ))}
                  <Button
                    size="small"
                    textType={"body1"}
                    iconName={"expand_less"}
                    onClick={() => {
                      setLinkedAccountExpanded(false);
                    }}
                    style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                  >
                    <IconBase iconName={"expand_less"} />
                    Show less
                  </Button>
                </Grid>
              ) : (
                linkedAccounts[0]?.exists !== false && (
                  <Grid container spacing={2}>
                    {linkedAccounts.map((acc, index) => (
                      <Grid key={index} item xs={12}>
                        <AccountBar
                          variant={"USER"}
                          name={acc?.email}
                          isLinked={true}
                          onUnlink={() => {
                            handleOpenUnlinkModal(acc?.email);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: editing && "3rem" }}>
        <Grid item xs={6} style={{ marginTop: "2rem", paddingRight: "1rem" }}>
          <Typography
            variant="h4"
            fontWeight={400}
            style={{ marginLeft: "1rem", marginBottom: "2.5rem" }}
          >
            Licence details
          </Typography>
          <ListItem
            editing={editing}
            label="Status"
            subLabel={
              data.status === APPROVED ? "Reviewing" : capitalize(data.status)
            }
            variant="detail"
            startOrnament={<IconBase iconName="check_box"></IconBase>}
            characterLimit={80}
            type={0}
            props={"status"}
            license={data}
          />
          <ListItem
            editing={editing}
            label="Licence #"
            subLabel={data.license_number || "-"}
            variant="detail"
            startOrnament={<IconBase iconName="tag"></IconBase>}
            characterLimit={80}
            type={0}
            props={"license_number"}
            license={data}
          />
          <ListItem
            editing={editing}
            label="Business Name"
            subLabel={data.business_name || "-"}
            originalValue={originalData?.business_name}
            variant="detail"
            startOrnament={
              <IconBase iconName="drive_file_rename_outline"></IconBase>
            }
            characterLimit={40}
            type={0}
            props={"business_name"}
            error={errors.business_name}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          <ListItem
            editing={editing}
            label="Describe business operation"
            subLabel={data.business_operation_description || "-"}
            originalValue={originalData?.business_operation_description}
            variant="detail"
            startOrnament={<IconBase iconName="article"></IconBase>}
            characterLimit={80}
            type={1}
            props={"business_operation_description"}
            error={errors.business_operation_description}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          <ListItem
            editing={editing}
            label="Residency status"
            subLabel={
              data?.is_resident
                ? `Resident${data?.is_homebased ? " (Home-based)" : ""}`
                : "Non-resident"
            }
            originalValue={writeResidency()}
            variant="detail"
            startOrnament={<IconBase iconName="person_pin"></IconBase>}
            characterLimit={80}
            type={2}
            props={["is_resident", "is_homebased"]}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          {data.expiry && (
            <ListItem
              editing={editing}
              label="Auto-renew date"
              subLabel={
                data.expiry
                  ? moment(data.expiry).add(1, "d").format("MMM DD, YYYY")
                  : moment(moment().format("YYYY") + "/12/31")
                      .add(1, "d")
                      .format("MMM DD, YYYY")
              }
              variant="detail"
              startOrnament={<IconBase iconName="event"></IconBase>}
              characterLimit={80}
              type={0}
              props={"expiry"}
              license={data}
              style={{ marginBottom: "1rem" }}
            />
          )}
          {data.is_homebased && (
            <>
              {data.has_parking_for_customers === "true" || editing ? (
                <Typography
                  variant="overline"
                  color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                  style={{ marginLeft: "1rem" }}
                >
                  Parking capacity
                </Typography>
              ) : (
                <></>
              )}
              {editing ? (
                <>
                  <CheckboxCard
                    label={"Business has parking for customers"}
                    cardVariant={"highlight"}
                    onChange={(e) => {
                      onSaveEdit({
                        has_parking_for_customers: e.target.checked,
                      });
                    }}
                    value={data?.has_parking_for_customers}
                  />
                </>
              ) : (
                <></>
              )}
              {showHomeParkingSection ? (
                <>
                  <ListItem
                    positiveIntegerOnly={true}
                    editing={editing}
                    label="Number of vehicles"
                    subLabel={data.number_of_vehicles || "-"}
                    originalValue={originalData?.number_of_vehicles}
                    variant="detail"
                    startOrnament={
                      <IconBase iconName="directions_car"></IconBase>
                    }
                    characterLimit={80}
                    type={0}
                    props={"number_of_vehicles"}
                    error={errors.number_of_vehicles}
                    validationProps={[
                      "is_resident",
                      "has_parking_for_customers",
                      "is_homebased",
                    ]}
                    isFormField={editing}
                    license={data}
                    onSave={onSaveEdit}
                    style={{ marginTop: "0.5rem" }}
                  />
                  <ListItem
                    editing={editing}
                    label="Place of parking"
                    subLabel={data.parking_place || "-"}
                    originalValue={originalData?.parking_place}
                    variant="detail"
                    startOrnament={
                      <IconBase iconName="local_parking"></IconBase>
                    }
                    characterLimit={80}
                    type={0}
                    props={"parking_place"}
                    error={errors.parking_place}
                    isFormField={editing}
                    license={data}
                    onSave={onSaveEdit}
                  />
                  <ListItem
                    editing={editing}
                    label="Truck size"
                    subLabel={data.truck_size || "-"}
                    originalValue={originalData?.truck_size}
                    variant="detail"
                    startOrnament={
                      <IconBase iconName="local_shipping"></IconBase>
                    }
                    characterLimit={80}
                    type={0}
                    props={"truck_size"}
                    isFormField={editing}
                    license={data}
                    onSave={onSaveEdit}
                    style={{ marginBottom: "1rem" }}
                  />
                </>
              ) : (
                <></>
              )}
              <Typography
                variant="overline"
                color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
                style={{ marginLeft: "1rem" }}
              >
                Property owner
              </Typography>
              <ListItem
                editing={editing}
                subLabel={data.property_owner_name || "-"}
                originalValue={originalData?.property_owner_name}
                startOrnament={<IconBase iconName="person"></IconBase>}
                characterLimit={80}
                type={0}
                props={"property_owner_name"}
                validationProps={["is_resident", "is_homebased"]}
                error={errors.property_owner_name}
                variant="detail"
                isFormField={editing}
                license={data}
                onSave={onSaveEdit}
              />
            </>
          )}
          <Typography
            variant="h4"
            fontWeight={400}
            style={{
              marginLeft: "1rem",
              marginBottom: "2.5rem",
              marginTop: "2.5rem",
            }}
          >
            Business contact information
          </Typography>
          <AddressDisplayEditor
            errors={[errors.business_address_street]}
            editing={editing}
            onSaveEdit={(values) => {
              const mapped = {
                business_address_street: values.streetAddress,
                business_address_street2: values.streetAddress2,
                business_address_city: values.city,
                business_address_province: values.province,
                business_address_postal_code: values.postalzip,
                business_address_country: values.country,
              };
              const { business_address_street, ...newErrors } = errors;
              setErrors(newErrors);
              onSaveEdit(mapped);
            }}
            isChanged={
              (changedFields?.includes("business_address_street") ||
                changedFields?.includes("business_address_street2") ||
                changedFields?.includes("business_address_city") ||
                changedFields?.includes("business_address_province") ||
                changedFields?.includes("business_address_postal_code") ||
                changedFields?.includes("business_address_country")) &&
              isDuplicate
            }
            address_street={data.business_address_street}
            address_street2={data.business_address_street2}
            address_city={data.business_address_city}
            address_province_state={data.business_address_province}
            address_zip_postal_code={data.business_address_postal_code}
            address_country={data.business_address_country}
            original_address_street={originalData.business_address_street}
            original_address_street2={originalData.business_address_street2}
            original_address_city={originalData.business_address_city}
            original_address_province_state={
              originalData.business_address_province
            }
            orginal_address_zip_postal_code={
              originalData.business_address_postal_code
            }
            original_address_country={originalData.business_address_country}
            icon_base="location_on"
            widget_label="Business Address"
          />
          <AddressDisplayEditor
            errors={[errors.mailing_address_street]}
            editing={editing}
            onSaveEdit={(values) => {
              const mapped = {
                mailing_address_street: values.streetAddress,
                mailing_address_street2: values.streetAddress2,
                mailing_address_city: values.city,
                mailing_address_province: values.province,
                mailing_address_postal_code: values.postalzip,
                mailing_address_country: values.country,
              };
              const { mailing_address_street, ...newErrors } = errors;
              setErrors(newErrors);
              onSaveEdit(mapped);
            }}
            isChanged={
              (changedFields?.includes("mailing_address_street") ||
                changedFields?.includes("mailing_address_street2") ||
                changedFields?.includes("mailing_address_city") ||
                changedFields?.includes("mailing_address_province") ||
                changedFields?.includes("mailing_address_postal_code") ||
                changedFields?.includes("mailing_address_country")) &&
              isDuplicate
            }
            address_street={data.mailing_address_street}
            address_street2={data.mailing_address_street2}
            address_city={data.mailing_address_city}
            address_province_state={data.mailing_address_province}
            address_zip_postal_code={data.mailing_address_postal_code}
            address_country={data.mailing_address_country}
            original_address_street={originalData.mailing_address_street}
            original_address_street2={originalData.mailing_address_street2}
            original_address_city={originalData.mailing_address_city}
            original_address_province_state={
              originalData.mailing_address_province
            }
            orginal_address_zip_postal_code={
              originalData.mailing_address_postal_code
            }
            original_address_country={originalData.mailing_address_country}
            icon_base="local_post_office"
            widget_label="Mailing Address"
          />
          <ListItem
            editing={editing}
            label="Phone number"
            subLabel={formatPhoneNumber(data.phone_number) || "-"}
            originalValue={formatPhoneNumber(originalData?.phone_number)}
            variant="detail"
            startOrnament={<IconBase iconName="call"></IconBase>}
            characterLimit={80}
            type={0}
            props={"phone_number"}
            error={errors.phone_number}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          <ListItem
            editing={editing}
            label="Email address"
            subLabel={data.email || "-"}
            originalValue={originalData?.email}
            variant="detail"
            startOrnament={<IconBase iconName="mail"></IconBase>}
            characterLimit={80}
            type={0}
            props={"email"}
            error={errors.email}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          <ListItem
            editing={editing}
            label="Secondary phone number"
            subLabel={formatPhoneNumber(data.secondary_phone_number) || "-"}
            originalValue={formatPhoneNumber(originalData?.secondary_phone_number)}
            variant="detail"
            startOrnament={<IconBase iconName="call"></IconBase>}
            characterLimit={80}
            type={0}
            props={"secondary_phone_number"}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          <ListItem
            editing={editing}
            label="Fax number"
            subLabel={formatPhoneNumber(data?.fax_number) || "-"}
            originalValue={formatPhoneNumber(originalData?.fax_number)}
            variant="detail"
            startOrnament={<IconBase iconName="fax"></IconBase>}
            characterLimit={80}
            type={0}
            props={"fax_number"}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
          <ListItem
            editing={editing}
            label="Website address"
            subLabel={data.website_address || "-"}
            originalValue={originalData?.website_address}
            variant="detail"
            startOrnament={<IconBase iconName="web"></IconBase>}
            characterLimit={80}
            type={0}
            props={"website_address"}
            isFormField={editing}
            license={data}
            onSave={onSaveEdit}
          />
        </Grid>
        <Grid item xs={6} style={{ marginTop: "2rem" }}>
          <Typography
            variant="h4"
            fontWeight={400}
            style={{ marginLeft: "1rem", marginBottom: "2.5rem" }}
          >
            Business management
          </Typography>
          {errors.business_manager ? (
            <Typography
              className={"bl-error-message"}
              style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}
              variant={"body2"}
              color={theme.palette.nonPalette.RED}
            >
              {errors.business_manager}
            </Typography>
          ) : null}
          <div
            style={{
              background:
                changedFields.length > 0
                  ? theme.palette.nonPalette.YELLOW_BACKGROUND
                  : "transparent",
              paddingTop: "0.5rem",
              paddingBottom: "0.5rem",
              borderRadius: "10px",
            }}
          >
            {managersAndOwners.length === 0 && (
              <div
                style={{
                  marginLeft: "1rem",
                  marginBottom: "1rem",
                  width: "500px",
                }}
              >
                <Typography>
                  There are no managers/owners attached to this licence.
                </Typography>
              </div>
            )}
            {managersAndOwners.map((element, i) => (
              <ManagersAndOwnersItem
                key={i}
                index={i}
                isOwner={true}
                type={"Owner"}
                element={element}
                count={element.is_owner === true ? ownersCount++ : 0}
                handleSubmit={onEditOwner}
                handleRemove={onRemoveOwner}
                editing={editing}
                edittedManagers={changedFields}
                isDuplicate={isDuplicate}
                licenceData={data}
                errors={errors}
                setErrors={setErrors}
              />
            ))}
            {managersAndOwners?.map((element, i) => (
              <ManagersAndOwnersItem
                licenceData={data}
                key={i}
                index={i}
                isOwner={false}
                type={"Manager"}
                element={element}
                count={element.is_owner === false ? managersCount++ : 0}
                handleSubmit={onEditOwner}
                handleRemove={onRemoveOwner}
                editing={editing}
                edittedManagers={changedFields}
                isDuplicate={isDuplicate}
                errors={errors}
                setErrors={setErrors}
              />
            ))}
            {changedFields.length > 0 && (
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: 1,
                  marginTop: "0.5rem",
                  marginLeft: "1rem",
                }}
                sx={{
                  "&:hover": {
                    color: theme.palette.primary[500],
                    cursor: "pointer",
                  },
                }}
                color={theme.palette.primary[200]}
                onClick={() => {
                  setShowOriginalData(!showOriginalData);
                }}
              >
                {showOriginalData ? "Hide original" : "View original"}
              </Typography>
            )}
            {originalManagers.length > 0 && showOriginalData && (
              <div style={{ marginTop: "1.5rem" }}>
                <Chip
                  label="Original"
                  style={{
                    width: "fit-content",
                    background: theme.palette.greys.GREY_MEDIUM,
                    marginLeft: "0.5rem",
                    marginBottom: "0.25rem",
                  }}
                />
                {managersAndOwners.length === 0 && (
                  <div
                    style={{
                      marginLeft: "1rem",
                      marginBottom: "1rem",
                      width: "500px",
                    }}
                  >
                    <Typography>
                      There are no managers/owners attached to this licence.
                    </Typography>
                  </div>
                )}
                {originalManagers.map((element, i) => (
                  <ManagersAndOwnersItem
                    key={i}
                    index={i}
                    isOwner={true}
                    isOriginalValue={true}
                    type={"Owner"}
                    element={element}
                    count={element.is_owner === true ? orignalOwnersCount++ : 0}
                    handleSubmit={onEditOwner}
                    handleRemove={onRemoveOwner}
                    editing={editing}
                    edittedManagers={changedFields}
                    isDuplicate={isDuplicate}
                    licenceData={data}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ))}
                {originalManagers?.map((element, i) => (
                  <ManagersAndOwnersItem
                    licenceData={data}
                    key={i}
                    index={i}
                    isOwner={false}
                    isOriginalValue={true}
                    type={"Manager"}
                    element={element}
                    count={
                      element.is_owner === false ? originalManagersCount++ : 0
                    }
                    handleSubmit={onEditOwner}
                    handleRemove={onRemoveOwner}
                    editing={editing}
                    edittedManagers={changedFields}
                    isDuplicate={isDuplicate}
                    errors={errors}
                    setErrors={setErrors}
                  />
                ))}
              </div>
            )}
          </div>

          {editing &&
            (addingOwner ? (
              <ManagersAndOwnersItem
                licenceData={data}
                index={0}
                isOwner={false}
                type={"Manager"}
                element={{ is_owner: false, phone: "", full_name: "" }}
                count={0}
                handleSubmit={onAddOwner}
                handleRemove={onRemoveOwner}
                editing={editing}
                adding={addingOwner}
                setAddingOwner={setAddingOwner}
                errors={errors}
                setErrors={setErrors}
              />
            ) : (
              <Button
                size={"small"}
                variant="newOptional"
                startIcon={
                  <IconBase
                    fill={1}
                    iconName={"add"}
                    color={theme.palette.primary[500]}
                  />
                }
                style={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                onClick={() => {
                  if (errors.managers || errors.owners) {
                    setErrors((prev) => ({
                      ...prev,
                      managers: null,
                      owners: null,
                    }));
                  }
                  setAddingOwner(true);
                }}
              >
                Add owner/manager
              </Button>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="h4"
            fontWeight={400}
            style={{ marginBottom: "auto" }}
          >
            Documents
          </Typography>
          {editing && (
            <Button
              variant={"outlined"}
              startIcon={
                uploadingDocument ? (
                  <CircularProgress color={"inherit"} size={20} />
                ) : (
                  <IconBase wght={700} iconName={"upload"} />
                )
              }
              onClick={handleFileUploadModalOpen}
            >
              upload
            </Button>
          )}
        </div>
      </Grid>

      <Grid item xs={12} style={{ display: "flex" }}>
        {documents
          ?.filter((item, index) => index < 4)
          .map((item, index) => (
            <Grid
              item
              xs={3}
              style={{ display: "flex" }}
              key={item.attachmentId}
            >
              <DocumentCard
                key={index}
                title={item.originalName}
                onClick={() => handleDownloadDoc(item.attachmentId)}
              />
            </Grid>
          ))}
      </Grid>
      {!editing && (
        <>
          <Grid item xs={12}>
            <Button
              style={{ marginLeft: "auto", color: PRIMARY_200 }}
              size="medium_icon_right"
              endIcon={<IconBase iconName={"chevron_right"} />}
              onClick={() => {
                history.push(`${location.pathname}#Documents`);
              }}
            >
              SEE ALL
            </Button>
          </Grid>

          {/* <Grid item xs={12} style={{ marginTop: "2rem" }}>
        <Typography variant="h4" fontWeight={400}>
          Amount and payment method
        </Typography>
      </Grid> */}
          {/* <Grid item xs={12}>
        <Typography variant={"overline"} color={BLACK_MEDIUM_EMPHASIS}>
          AMOUNT
        </Typography>
        <StyledAccordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          style={{ marginTop: "0.5rem", padding: "1rem" }}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Button
                  iconName={expanded ? "expand_less" : "expand_more"}
                  size="medium_icon_left"
                >
                  {expanded ? "Hide Details" : "Show Details"}
                </Button>
              </Grid>
              <Grid item>
                <AccordionActions>
                  <Typography fontSize={"39px"} fontWeight={"700 !important"}>
                    {formatCurrency(totalBalance)}
                  </Typography>
                </AccordionActions>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {fakeLinkedAccounts.map((acc, index) => {
                if (acc?.balance)
                  return (
                    <ResourceItem
                      key={index}
                      type={acc.type}
                      name={acc.name}
                      labelFloat={true}
                      label={formatCurrency(acc.balance)}
                    />
                  );
                return null;
              })}
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </Grid> */}
          {/* <Grid item xs={12} style={{ marginTop: "2rem" }}>
        <TransactionsInvoicesTab />
      </Grid> */}
          <Grid item xs={12} style={{ marginBottom: "1rem" }}>
            <Typography
              variant="h4"
              fontWeight={400}
              style={{ marginBottom: "1.5rem", marginTop: "2rem" }}
              id={"__history-section"}
            >
              History and notes
            </Typography>
            <HistoryTab
              fetchHistories={fetchHistories}
              maxToShow={2}
              history={allHistory}
              _addComment={addComment}
              data={data}
              setTab={setTab}
            />
          </Grid>
          <ModalUnlink
            data={data}
            onDecline={handleUnLink}
            open={openUnlinkModal}
            businessName={data.business_name}
            userName={unlinkedUsersEmail}
            handleClose={() => {
              setOpenUnlinkModal(false);
            }}
          />
        </>
      )}
      <LinkUserToAccountModal
        open={openLinkUserModal}
        handleClose={() => {
          setOpenLinkUserModal(false);
        }}
        data={data}
        refresher={refresher}
        refreshValue={refreshValue}
      />
    </Grid>
  );
};

export default GeneralTab;
