import React, { useState } from "react";
import { Box, css, Link, useTheme } from "@mui/material";
import IconBase from "../../../../../components/ui-kit/IconBase";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Spacer from "../../../../../components/ui-kit/Spacer";
import Button from "@mui/material/Button";
import Alerts from "../../../../../components/ui-kit/Alert";
import DiscardDraftModel from "./DiscardDraftModel";
import { formatCurrency } from "../../../../../../utilities";
import ApplicantDeclarationModal from "./ApplicantDeclarationModal";
import ButtonBase from "@mui/material/ButtonBase";
import CancelLicenceModal from "../../../../../components/modals/CancelLicenceModal";
import {
  getPDF,
  updateChangeSetStatus,
} from "../../../../../../core/apis/licence";
import FileDownload from "js-file-download";
import {
  ACTIVE,
  APPROVED,
  CANCELLED,
  DRAFT,
  ISSUED,
  REVIEWING,
  SAVED,
  SUBMITTED,
} from "../../../../../../core/constants/statuses";
import { truncate } from "../../../../../../utilities";
import {
  BUSINESS_NAME_LENGTH,
  prices,
} from "../../../../../../core/constants/licences";
import { ModalSmall } from "../../../../../components/ui-kit/Modal";
import { InfoRounded } from "@mui/icons-material";

// TODO: refactor below several components/styles into their own file

/**
 * The notification and call to action on the right sidebar.
 * @returns {JSX.Element}
 * @constructor
 */
const SideBar = ({
  licence,
  status,
  id,
  onDiscard,
  onSubmit,
  onPayDues,
  onAcknowledge,
  totalDues,
  onCancel,
  editPage,
  setRefresh,
  refresh,
  versionStatus,
  canEditLicense,
  originalValues,
}) => {
  const getSidebarContent = () => {
    switch (status) {
      case DRAFT:
      case SAVED:
        return (
          <DraftLicence
            licence={licence}
            onDiscard={onDiscard}
            onSubmit={onSubmit}
            onAcknowledge={onAcknowledge}
            versionStatus={versionStatus}
          />
        );
      case REVIEWING:
      case SUBMITTED:
      case APPROVED:
        return (
          <UnderReview
            showChanges={versionStatus === "duplicate"}
            original={licence["original"]}
          />
        );
      case ISSUED:
        return <PaymentDue onPayDues={onPayDues} totalDue={totalDues} />;
      case ACTIVE:
        return (
          <ActiveSideBar
            onCancel={onCancel}
            id={id}
            editPage={editPage}
            setRefresh={setRefresh}
            refresh={refresh}
            onPayDues={onPayDues}
            versionStatus={versionStatus}
            totalDues={totalDues}
            canEditLicense={canEditLicense}
            licence={licence}
            originalValues={originalValues}
            onAcknowledge={onAcknowledge}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      style={{
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {getSidebarContent()}
    </div>
  );
};

const DownloadButton = styled(ButtonBase)`
  ${({ theme }) => css`
    &.MuiButtonBase-root {
      align-items: flex-start;
      background-color: ${theme.palette.primary[50]};
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 94px;
      width: 100%;
    }

    && .MuiTouchRipple-child {
      background-color: ${theme.palette.primary[200]};
    }
  `}
`;

const CancelButton = styled(Button)(
  ({ theme }) => css`
    &.MuiButtonBase-root {
      align-items: flex-start;
      color: ${theme.palette.nonPalette.RED};
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 55px;
      padding-left: 15px;
      margin-top: 0.5rem;

      && .MuiTouchRipple-child {
        background-color: ${theme.palette.nonPalette.RED} !important;
      }

      :hover {
        background-color: ${theme.palette.nonPalette.RED_BACKGROUND};
      }
    }
  `
);

const SmallLinkButton = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary[200]} !important;
  margin-top: 1rem !important;

  :hover {
    color: ${({ theme }) => theme.palette.primary[400]} !important;
    cursor: pointer;
  }
`;

/**
 * Displays if the licence has an active status
 * @returns {JSX.Element}
 * @constructor
 */

const ActiveSideBar = ({
  onCancel,
  id,
  totalDues,
  onPayDues,
  editPage,
  setRefresh,
  refresh,
  versionStatus,
  canEditLicense,
  originalValues,
  licence,
  onAcknowledge,
}) => {
  const theme = useTheme();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openApplicantModal, setOpenApplicantModal] = useState(false);
  const [openSubmitChangesModal, setOpenSubmitChangesModal] = useState(false);

  const handleCancelModalOpen = () => {
    setOpenCancelModal(true);
  };
  const handleCancelModalClose = () => {
    setOpenCancelModal(false);
  };

  const handleEdit = async () => {
    await editPage();
    setRefresh(!refresh);
  };

  const handleOnSubmit = async () => {
    await updateChangeSetStatus(id, licence.changeSetId, SUBMITTED);
    setRefresh(!refresh);
  };

  const handleOnDiscard = async () => {
    await updateChangeSetStatus(id, licence.changeSetId, CANCELLED);
    setRefresh(!refresh);
  };

  const handleApplicantModalClose = () => {
    setOpenApplicantModal(false);
  };

  const handleDownload = async (id) => {
    const license = await getPDF(id);
    if (license) {
      FileDownload(license, "Lacombe Business Licence.pdf", "application/pdf");
    }
  };

  const checkChanges = () => {
    const phoneKeys = ["phoneNumber", "secondPhone", "faxNumber"];

    for (const key in originalValues) {
      if (phoneKeys.includes(key)) {
        if (originalValues[key].replace(/\D/g,"")
          !== licence[key].replace(/\D/g,"")) {
          return false;
        }
      } else if (key === "orgManagers") {
        if (
          originalValues.orgManagers.length !== licence.managers.length ||
          !originalValues.orgManagers.every(
            (obj1, index) =>
              obj1.name === licence.managers[index].name &&
              obj1.phone.replace(/\D/g,"") === licence.managers[index].phone.replace(/\D/g,"")
          )
        ) {
          return false;
        }
      } else if (key === "orgOwners") {
        if (
          originalValues.orgOwners.length !== licence.owners.length ||
          !originalValues.orgOwners.every(
            (obj1, index) =>
              obj1.name === licence.owners[index].name &&
              obj1.phone.replace(/\D/g,"") === licence.owners[index].phone.replace(/\D/g,"")
          )
        ) {
          return false;
        }
      } else if (originalValues[key] !== licence[key]) {
        return false;
      }
    }
    return true;
  };

  return (
    <div
      style={{
        flexDirection: "column",
        width: "100%",
      }}
    >
      {versionStatus === "original" ? (
        <div style={{ marginBottom: "10px" }}>
          <Alerts
            variant={"info"}
            title={"There are pending changes"}
            body={
              "The changes were saved on a copy of this licence. You can find" +
              " it on the same page as your other licences. This version will" +
              " remain active until that one is approved."
            }
          />
        </div>
      ) : (
        <></>
      )}
      <div style={{ display: "flex", marginBottom: "0.5rem" }}>
        {licence.changeSetStatus !== DRAFT &&
          licence.changeSetStatus !== REVIEWING &&
          licence.changeSetStatus !== APPROVED &&
          licence.changeSetStatus !== SUBMITTED &&
          licence.changeSetStatus !== ISSUED && (
            <>
              <DownloadButton
                onClick={() => {
                  handleDownload(id);
                }}
              >
                <div
                  style={{
                    padding: 15,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <IconBase
                    iconName={"download"}
                    color={theme.palette.primary[200]}
                    wght={700}
                  />
                  <Spacer amount={0.5} />
                  <Typography
                    variant={"body2"}
                    style={{
                      color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                    }}
                  >
                    Download
                  </Typography>
                </div>
              </DownloadButton>
              {canEditLicense && licence.balanceOwing !== 20 && (
                <DownloadButton
                  onClick={() => {
                    handleEdit();
                  }}
                  style={{
                    width: "100px",
                    marginLeft: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      padding: 15,
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <IconBase
                      iconName={"edit"}
                      color={theme.palette.primary[200]}
                      wght={700}
                    />
                    <Typography
                      variant={"body2"}
                      style={{
                        color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                      }}
                    >
                      Edit
                    </Typography>
                  </div>
                </DownloadButton>
              )}
            </>
          )}
      </div>
      {totalDues > 0 &&
        (!licence.changeSetStatus || licence.changeSetStatus === ISSUED) && (
          <>
            <PaymentDue onPayDues={onPayDues} totalDue={totalDues} />
            <Spacer />
          </>
        )}
      {licence.changeSetStatus === DRAFT ? (
        <>
          <Alerts
            variant="info"
            title={"Edit your details"}
            iconName={"edit"}
            body={
              <Typography>
                Edit your licence, review it, and then submit it. City Staff
                will review your changes. Note changes may be subject to a $20
                amendment fee.
                <br />
                <br />
                Read the{" "}
                <Link
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.keyCode === "13") {
                      setOpenApplicantModal(true);
                    }
                  }}
                  onClick={() => {
                    setOpenApplicantModal(true);
                  }}
                  style={{ color: theme.palette.primary[200] }}
                >
                  Applicant declaration
                </Link>
              </Typography>
            }
          />
          <ApplicantDeclarationModal
            handleClose={handleApplicantModalClose}
            onAcknowledge={onAcknowledge}
            open={openApplicantModal}
          />
          <Button
            size={"large"}
            variant={"contained"}
            fullWidth
            disabled={checkChanges()}
            onClick={() => {
              setOpenSubmitChangesModal(true);
            }}
            style={{ marginTop: "0.5rem" }}
          >
            Submit to Review
          </Button>
          <SmallLinkButton
            onClick={() => handleOnDiscard()}
            variant="body1"
            align="center"
          >
            Cancel editing
          </SmallLinkButton>
        </>
      ) : licence.changeSetStatus === SUBMITTED ||
        licence.changeSetStatus === REVIEWING ||
        licence.changeSetStatus === APPROVED ? (
        <Alerts
          variant={"warning"}
          iconName={"find_in_page"}
          title={"Your changes are under review"}
          body={
            <Typography>
              City staff is currently reviewing the changes you submitted. If
              you have any questions or issues,{" "}
              <Link
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.keyCode === "13") {
                    setOpenApplicantModal(true);
                  }
                }}
                onClick={() => {
                  window.open(
                    "https://www.lacombe.ca/Directory.aspx?DID=42",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
                style={{ color: theme.palette.primary[200] }}
              >
                contact City Hall
              </Link>
              .
            </Typography>
          }
          fill={1}
        />
      ) : (
        <></>
      )}
      {licence.changeSetStatus !== DRAFT && (
        <CancelButton size={"small"} fullWidth onClick={handleCancelModalOpen}>
          Cancel licence
        </CancelButton>
      )}
      <CancelLicenceModal
        open={openCancelModal}
        onClose={handleCancelModalClose}
        onCancel={onCancel}
        licenceType={"business licence"}
      />
      <ModalSmall
        open={openSubmitChangesModal}
        onClose={() => setOpenSubmitChangesModal(false)}
        title={"Submit changes to review"}
        bodyText={submitModalBodyTextDuplicate}
        closeButtonText={"Cancel"}
        onAction={handleOnSubmit}
        actionButtonText={"Submit"}
        actionButtonColor={"primary"}
      />
    </div>
  );
};

//Draft styled components
const DiscardContainer = styled(Box)(
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.palette.nonPalette.RED_BACKGROUND};
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 23px 10px 23px;

    && > .material-symbols-rounded {
      color: ${theme.palette.nonPalette.RED};
    }

    .MuiTypography-body1 {
      margin-left: 13px;
    }

    .MuiButton-root {
      margin-left: auto;
    }
  `
);

const InfoSubmitting = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.nonPalette.OUTLINE};
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1rem;

    && .material-symbols-rounded {
      color: ${theme.palette.primary[200]};
    }

    .MuiTypography-root {
      margin: 0.5rem 0;
    }

    .MuiTypography-body1 {
      margin-bottom: 1rem;
    }

    .MuiButton-root {
      .material-symbols-rounded {
        color: ${theme.palette.whites.WHITE};
        position: absolute;
        left: 20px;
        top: 19px;
      }
    }
  `
);

const DiscardButton = styled(Button)(
  ({ theme }) => css`
    &&.MuiButton-root {
      padding: 0 10px;
      color: ${theme.palette.nonPalette.RED};

      :hover {
        background-color: ${theme.palette.nonPalette.RED_BACKGROUND};
      }
    }

    && .MuiTouchRipple-child {
      color: ${theme.palette.nonPalette.RED};
    }
  `
);

const submitModalBodyTextDuplicate =
  "Are you sure you want to submit your changes for City Staff to review? Please be aware changes may be subject to a $20 amendment fee.";

const submitModalBodyText =
  "Please ensure that you have read the applicant declaration and reviewed your " +
  "application before submitting it.";

/**
 * Displays if the licence has a draft status
 * @param onSubmit
 * @param onDiscard
 * @param onAcknowledge
 * @param isDuplicate
 * @returns {JSX.Element}
 * @constructor
 */
const DraftLicence = ({
  licence,
  onSubmit,
  onDiscard,
  onAcknowledge,
  versionStatus,
}) => {
  const theme = useTheme();

  const [openDiscardModel, setOpenDiscardModel] = useState(false);
  const [openApplicantModal, setOpenApplicantModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);

  const getSubmitLabel = () => {
    if (versionStatus === "duplicate") {
      return "Submit Changes";
    }
    return "Submit Application";
  };

  const handleDiscardModalClose = () => {
    setOpenDiscardModel(false);
  };

  const handleApplicantModalClose = () => {
    setOpenApplicantModal(false);
  };

  const handleOnSubmit = () => {
    setOpenSubmitModal(true);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {(licence.balanceOwing === prices.discountedLocalBusiness ||
        licence.balanceOwing === prices.discountedNonLocalBusiness) && (
        <div className={"discount-alert-container has-margin-bottom"}>
          <InfoRounded />
          <p>
            Applications issued from July 1st to December 31st will receive a
            one-time 50% discount off the fee.
          </p>
        </div>
      )}
      <DiscardContainer style={{ paddingTop: "10px" }}>
        {versionStatus === "duplicate" ? (
          <IconBase iconName={"content_copy"} size={"22px"} fill={0} />
        ) : (
          <IconBase iconName={"edit"} size={"22px"} fill={1} />
        )}
        <Typography variant={"body1"} style={{ fontWeight: 500 }}>
          This is a {versionStatus === "duplicate" ? "copy" : "draft"}
        </Typography>
        <DiscardButton
          size={"small"}
          variant={"outlined"}
          endIcon={<IconBase iconName={"delete"} />}
          onClick={() => {
            setOpenDiscardModel(true);
          }}
        >
          Discard
        </DiscardButton>
        {versionStatus === "duplicate" ? (
          <>
            <div style={{ flexBasis: "100%", height: "0" }}></div>
            <Typography
              variant={"body1"}
              style={{
                marginLeft: "34px",
                paddingTop: "10px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              This is a copy of{" "}
              {truncate(licence.original.name, BUSINESS_NAME_LENGTH)}. You can
              make changes and submit them for approval. If approved, this
              version will replace the active licence.
            </Typography>
          </>
        ) : (
          <></>
        )}
      </DiscardContainer>
      <Spacer />
      <InfoSubmitting>
        <IconBase iconName={"info"} size={"32px"} grad={140} wght={650} />
        <Typography variant={"h4"}>Before submitting...</Typography>
        <Typography variant={"body1"}>
          Please, review all points are correct before submitting your
          application.
        </Typography>
        <Button
          size={"large"}
          variant={"contained"}
          fullWidth
          startIcon={<IconBase fill={1} size={"22px"} iconName={"send"} />}
          onClick={handleOnSubmit}
        >
          {getSubmitLabel()}
        </Button>
      </InfoSubmitting>
      <Typography
        variant={"body1"}
        style={{
          color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          marginLeft: 10,
        }}
      >
        Read the{" "}
        <Link
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.keyCode === "13") {
              setOpenApplicantModal(true);
            }
          }}
          onClick={() => {
            setOpenApplicantModal(true);
          }}
          style={{ color: theme.palette.primary[200] }}
        >
          Applicant declaration
        </Link>
      </Typography>
      <DiscardDraftModel
        open={openDiscardModel}
        handleClose={handleDiscardModalClose}
        handleDelete={onDiscard}
      />
      <ApplicantDeclarationModal
        handleClose={handleApplicantModalClose}
        onAcknowledge={onAcknowledge}
        open={openApplicantModal}
      />
      <ModalSmall
        open={openSubmitModal}
        onClose={() => setOpenSubmitModal(false)}
        title={"Submitting application"}
        bodyText={submitModalBodyText}
        closeButtonText={"Cancel"}
        onAction={onSubmit}
        actionButtonText={"Submit"}
        actionButtonColor={"primary"}
      />
    </Box>
  );
};

/**
 * Displays if the licence has an under review status
 * @returns {JSX.Element}
 * @constructor
 */
const UnderReview = ({ showChanges, original }) => {
  return (
    <>
      {showChanges ? (
        <Alerts
          variant={"warning"}
          iconName={"find_in_page"}
          title={"Changes under review"}
          body={
            "The changes you submitted are currently under review. " +
            "City staff might contact you if they require more information."
          }
          body2={
            "This is a copy of " +
            truncate(original["name"], BUSINESS_NAME_LENGTH) +
            "."
          }
          fill={1}
        />
      ) : (
        <Alerts
          variant={"warning"}
          iconName={"find_in_page"}
          title={"Under review"}
          body={
            "This licence application is currently under review." +
            " Processing time for an application is approximately" +
            " two weeks. We’ll contact you in case we need more" +
            " information."
          }
          fill={1}
        />
      )}
    </>
  );
};

const PaymentDue = ({ onPayDues, totalDue }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {(totalDue === prices.discountedLocalBusiness ||
        totalDue === prices.discountedNonLocalBusiness) && (
        <div className={"discount-alert-container has-margin-bottom"}>
          <InfoRounded />
          <p>
            Applications issued from July 1st to December 31st will receive a
            one-time 50% discount off the fee.
          </p>
        </div>
      )}
      <Alerts
        fill={1}
        variant={"info"}
        title={"Licence payment is due"}
        body={
          "Pay your licence fee the" +
          " sooner the better to avoid " +
          "incurring in penalties."
        }
      />
      <Spacer amount={0.5} />
      <Button size={"large"} variant={"contained"} onClick={onPayDues}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              color: theme.palette.whites.WHITE,
            }}
          >
            Pay dues
          </Typography>
          <Typography
            style={{
              fontWeight: 700,
              color: theme.palette.whites.WHITE,
            }}
          >
            {formatCurrency(totalDue)}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default SideBar;
