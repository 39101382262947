// css imports
import "./assets/styles/index.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Application from "./ui";
import ReactGA from "react-ga4";
import { GA_TRACKING_ID } from "./config";

/**
 * Checks if the current environment is not "local" or "qa" does not exist in current app url:
 * - Initializes the GA
 */
if (
  process.env.REACT_APP_STAGE !== "local" ||
  !window.location.href.includes("qa")
) {
  ReactGA.initialize(GA_TRACKING_ID);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
