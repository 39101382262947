import React, {useRef, useState} from "react";
import typographyClasses from "../../../../../../assets/styles/typography/typography.module.scss";
import classes from "../index.module.scss"
import InfoButton from "../../../../../components/ui-kit/InfoButton";
import Spacer from "../../../../../components/ui-kit/Spacer";
import { Form, Formik } from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as yup from "yup";
import IFindUtilityAccount
  from "../../../../../../core/interfaces/utility/iFindUtilityAccount";
import Modal from "../../../../../components/ui-kit/Modal";
import sampleInvoice from "../../../../../../assets/images/invoice_sample.png"

const validationSchema = yup.object({
  accountNumber: yup.string().required(),
  accessCode: yup.string().required(),
});

export default function Step1({
  onCheckUtilityAvailable = (values: IFindUtilityAccount) => {},
}) {
  const formikRef = useRef(null) as any;
  const [openModal, setOpenModal] = useState(false)

  const handleSubmit = (values: IFindUtilityAccount) => {
    try {
      //TODO - gather details about the utility account.
      onCheckUtilityAvailable(values);
    } catch (e: any) {
      //If the account number isn't found
      if(e.status === 404){
        formikRef.current.setErrors({
          accountNumber:
            "This account number could not be found. Please, verify the number provided.",
        });
      }
    }
  };

  return (
    <>
      <p className={typographyClasses.h1}>Link your account</p>
      <p className={typographyClasses.body}>
        When you link a pre-existing Utilities account,
        you will be able to view account details, statements,
        services, and make payments.
      </p>
      <InfoButton
        onClick={() => {
          setOpenModal(true)
        }}
        title={"Where is my account or code/pin number?"}
      />
      <Spacer />
      <Formik
        innerRef={formikRef.current}
        initialValues={{ accountNumber: "", accessCode: "" }}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form>
              <Grid container className={"flex-column"} spacing={1}>
                <Grid xs={12}>
                  <TextField
                    name={"accountNumber"}
                    error={Boolean(props.errors.accountNumber) && props.touched.accountNumber}
                    helperText={props.touched.accountNumber ? props.errors.accountNumber : null}
                    value={props.values.accountNumber}
                    onChange={props.handleChange}
                    label={"Account number"}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <TextField
                    name={"accessCode"}
                    error={Boolean(props.errors.accessCode)  && props.touched.accessCode}
                    helperText={props.touched.accessCode ? props.errors.accessCode : null}
                    value={props.values.accessCode}
                    onChange={props.handleChange}
                    label={"Code/Pin number"}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12}>
                  <Spacer />
                  <Button
                    disabled={!props.isValid || !props.dirty}
                    size={"large"}
                    fullWidth
                    type={"submit"}
                    variant={"contained"}
                  >
                    Search number
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Modal title={"Where to find this information?"} open={openModal} onClose={()=>setOpenModal(false)}>
        <div style={{padding: "1rem"}}>
          <img className={classes.sample_modal} src={sampleInvoice} alt={"Sample invoice"}/>
        </div>
      </Modal>
    </>
  );
}
