import * as yup from 'yup';

export const validationSchema = yup.object({
  type: yup.string().required(),
  serial_number: yup.number().required(),
  metre_size: yup.string().required(),
  waste_metre_size: yup.string().when("type", {
    is: (value) => value !== "Residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  collection_services: yup.string().when("type", {
    is: (value) => value === "Residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  collection_service_pickup: yup.string().when("type", {
    is: (value) => value !== "Residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  cardboard: yup.string().when("type", {
    is: (value) => value !== "Residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  disposable_service_size: yup.string().when("type", {
    is: (value) => value !== "Residential",
    then: yup.string().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
})