import React, { createContext, useState, useMemo } from "react";

interface TableSettingContextValue {
  tableSettings: any;
  setTableSettings: React.Dispatch<React.SetStateAction<any>>;
  resetTableSettings: () => void;
}

export const TableSettingContext =
  createContext<TableSettingContextValue | null>(null);

export const TableSettingContextProvider = (props: any) => {
  const [tableSettings, setTableSettings] = useState({});

  const tableSettingContext = useMemo(() => {
    return {
      tableSettings,
      setTableSettings,
      resetTableSettings: () => {
        setTableSettings({});
      },
    };
  }, [tableSettings]);

  return (
    <TableSettingContext.Provider value={tableSettingContext}>
      {props.children}
    </TableSettingContext.Provider>
  );
};
