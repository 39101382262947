import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "../../ui-kit/Modal";
import Spacer from "../../ui-kit/Spacer";
import { BUSINESS_NAME_LENGTH } from "../../../../core/constants/general";
import { truncate } from "../../../../utilities";

const StyledModal = styled(Modal)`
  && {
    max-width: 450px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2.5rem 3rem 1.5rem 3rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const RedButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
  }

  && .MuiTouchRipple-child {
    background-color: red;
  }
`;

export default function ModalDecline({
  data,
  open,
  handleClose,
  businessName,
  onDecline,
}) {
  const theme = useTheme();
  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      <Typography variant={"body1"} fontWeight={"700"}>
        Declining business licence
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{
          color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {truncate(businessName, BUSINESS_NAME_LENGTH)} will receive an email
        notifying them their licence application has been declined.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <RedButton
          style={{ color: theme.palette.nonPalette.RED, width: 200 }}
          variant={"text"}
          onClick={() => {
            onDecline(data.id);
          }}
        >
          Decline licence
        </RedButton>
      </Box>
    </StyledModal>
  );
}

export function ModalDeclineChanges({ data, open, handleClose, onDecline }) {
  const theme = useTheme();
  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      <Typography variant={"body1"} fontWeight={"700"}>
        Declining licence changes
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        The applicant will receive an email notifying them their changes are
        declined.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <RedButton
          style={{ color: theme.palette.nonPalette.RED, width: 200 }}
          variant={"text"}
          onClick={() => {
            onDecline(data.id);
          }}
        >
          Decline
        </RedButton>
      </Box>
    </StyledModal>
  );
}

export function ModalReviewDecline({ data, open, handleClose, onDecline }) {
  const theme = useTheme();
  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      <Typography variant={"body1"} fontWeight={"700"}>
        Rejecting licence
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        If a supervisor is assigned, they will receive a ticket to review and
        decline the licence.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <RedButton
          style={{ color: theme.palette.nonPalette.RED, width: 200 }}
          variant={"text"}
          onClick={() => {
            onDecline(data.id);
          }}
        >
          Reject Licence
        </RedButton>
      </Box>
    </StyledModal>
  );
}

export function ModalUndoEdit({ open, handleClose, onDecline }) {
  const theme = useTheme();
  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      <Typography variant={"body1"} fontWeight={"700"}>
        Undoing changes
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        All the changes you made will be discarded and the licence will remain
        unchanged.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Back
        </Button>
        <RedButton
          style={{ color: theme.palette.nonPalette.RED, width: 200 }}
          variant={"text"}
          onClick={() => {
            onDecline();
          }}
        >
          Undo changes
        </RedButton>
      </Box>
    </StyledModal>
  );
}

export function RemoveOwnerModal({ open, handleClose, onDecline, isOwner }) {
  const theme = useTheme();
  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      <Typography variant={"body1"} fontWeight={"700"}>
        Remove {isOwner ? " owner " : " manager "}?
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        This {isOwner ? " owner " : " manager "} will be removed along with all
        their information.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <RedButton
          style={{ color: theme.palette.nonPalette.RED, width: 200 }}
          variant={"text"}
          onClick={() => {
            onDecline();
          }}
        >
          Remove
        </RedButton>
      </Box>
    </StyledModal>
  );
}

// TODO: businessName is not used in this modal, so the prop should be removed from its usages in  BL general tab
export function ModalUnlink({
  data,
  open,
  handleClose,
  businessName,
  onDecline,
  userName,
  isUtilityAccount = false,
}) {
  const theme = useTheme();
  return (
    <StyledModal
      fullWidth={false}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
    >
      <Typography variant={"body1"} fontWeight={"700"}>
        Unlinking account
      </Typography>
      <Spacer amount={0.5} />
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        {`${userName}'s account will be unlinked from this ${
          isUtilityAccount ? "utility" : "business licence"
        } account
        and will lose access to it on MyCityHall. You can re-link the accounts
        later.`}
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <RedButton
          style={{ color: theme.palette.nonPalette.RED }}
          variant={"text"}
          onClick={() => {
            onDecline(data.id);
          }}
        >
          Unlink
        </RedButton>
      </Box>
    </StyledModal>
  );
}
