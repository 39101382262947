import React from "react";
import Routes from "./routes/Routes";
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { muiV5, theme } from "../mui-theme/theme";
import { ThemeProvider } from "styled-components";
import { AuthContextProvider } from "./contexts/AuthContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";
import "../assets/styles/extended-theme";
import { SidebarContextProvider } from "./contexts/SidebarContext";
import { FilterContextProvider } from "./contexts/FilterContext";
import { TableSettingContextProvider } from "./contexts/table-setting-context";
import { PaymentProfileContextProvider } from "./contexts/PaymentProfileContext";
import { BrowserRouter as Router } from "react-router-dom";
import NotificationBarPlaceholder from "./components/ui-kit/NotificationBarPlaceholder";

const compose = (providers: any[], children: any) =>
  providers.reduce((acc, [Provider, value]) => {
    return <Provider theme={value}>{acc}</Provider>;
  }, children);

const providers = [
  [ThemeProvider, theme],
  [StyledEngineProvider, null],
  [MuiThemeProvider, muiV5],
  [AuthContextProvider, null],
  [NotificationContextProvider, null],
  [SidebarContextProvider, null],
  [FilterContextProvider, null],
  [TableSettingContextProvider, null],
  [PaymentProfileContextProvider, null],
];

/**
 * Root component of the application
 * @constructor
 */
function Application() {
  return compose(
    providers,
    <NotificationContextProvider>
      <Router>
        <NotificationBarPlaceholder />
        <Routes />
      </Router>
    </NotificationContextProvider>
  );
}

export default Application;
