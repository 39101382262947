import React from "react";
import { CheckCircle } from "@mui/icons-material";
import classes from "./index.module.scss";
import moment from "moment";
import { Skeleton } from "@mui/material";

const AdminUtilityStatusCard = ({
  loading,
  isActive,
  isLinked,
  linkedDate,
}) => {
  return (
    <>
      {loading ? (
        <Skeleton className={classes.loading} variant={"rounded"} />
      ) : (
        <div
          className={`${classes.container} ${isActive ? classes.active : ""}`}
        >
          <CheckCircle />
          <div>
            <p className={classes.status_title}>
              {isActive ? "Active" : "Cancelled"}
            </p>
            <p className={classes.linked_info}>{`${
              isLinked ? "Linked" : "Created"
            } on ${moment(linkedDate).format("MMM DD, yyyy")}`}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminUtilityStatusCard;
