import React from "react";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import typographyClasses from "../../../../assets/styles/typography/typography.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";

//Sets the font for the graph
defaults.font.family = "Rubik";

//Must register items before they can be rendered by ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({
  data = [0],
  labels = [""],
  title = "",
  bottomTitle = "",
}) => {
  const theme = useTheme();
  return (
    <>
      <Bar
        options={{
          indexAxis: "x" as const,
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: title,
              position: "left",
              color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
              padding: 5,
              font: { weight: "400" },
            },
          },
          scales: {
            y: {
              ticks: {
                callback: function (value: any) {
                  return value > 0 ? value + "M" : 0;
                },
              },
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              data: data,
              backgroundColor: theme.palette.primary[200],
            },
          ],
        }}
      />
      {bottomTitle ? (
        <p
          style={{ fontSize: 12, textAlign: "center", fontWeight: 400 }}
          className={typographyClasses.overline}
        >
          {bottomTitle}
        </p>
      ) : null}
    </>
  );
};

export default BarGraph;
