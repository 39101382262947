export const propertyType = [{ label: "Type", value: "Commercial, Industrial, Institutional" }];

export const waterServices = [
  { label: "RF Serial Number", value: "1546124612" },
  {
    label: "Water metre size",
    value: "1.25”",
    originalValue: "3” to 4”",
  },
];

export const wasteWaterServices = [
  { label: "Wastewater metre size", value: "up to 4”" },
];

export const solidWasteServices = [
  { label: "General Waste", value: "Bi-weekly pickup per bin" },
  { label: "Cardboard", value: "Bi-weekly pickup per bin" },
  { label: "Disposal service size", value: "Up to 20 yd3" },
];

export const residentialSolidWasteServices = [
  { label: "Collection services", value: "Apartment/Condo" },
];