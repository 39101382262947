import React, { useEffect, useMemo, useState } from "react";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import classes from "./index.module.scss";
import InfoButton from "../../../../../../components/ui-kit/InfoButton";
import ServiceRow from "../../widgets/service-row";
import Button from "@mui/material/Button";
import IconBase from "../../../../../../components/ui-kit/IconBase";
import {
  propertyType,
  residentialSolidWasteServices,
  solidWasteServices,
  wasteWaterServices,
  waterServices,
} from "./fake_data";
import Modal from "../../../../../../components/ui-kit/Modal";
import waterMetre from "../../../../../../../assets/images/utilities/water_metre.png";
import ServicesFormModal from "../../widgets/services-form-modal";
import Link from "@mui/material/Link";
import {useTheme} from "@mui/material";

const ServiceTab = ({ loading = false }) => {
  const theme = useTheme()
  const [openWhereToFindDialog, setOpenWhereToFindDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [data, setData] = useState({
    propertyType: propertyType,
    solidWasteServices: solidWasteServices,
    wasteWaterServices: wasteWaterServices,
    waterServices: waterServices,
  });

  useEffect(() => {
    if (propertyType[0].value === "Residential") {
      setData((data) => ({
        ...data,
        solidWasteServices: residentialSolidWasteServices,
      }));
    }
  }, [data]);

  const isResidential = useMemo(() => {
    return data.propertyType[0].value === "Residential";
  }, [data.propertyType]);

  const handleEditSubmit = (values: any, { resetForm }: any) => {
    //TODO - connect to backend
    console.log(values);
    setOpenEditModal(false);
    resetForm();
  };

  return (
    <div>
      <p className={typographyClasses.h1}>Services</p>
      <div className={classes.services_details_container}>
        <p className={typographyClasses.h2}>Confirm your services</p>
        <p
          className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`}
        >
          When it comes to your service size, size does matter. An incorrect
          size can result in overpaying or underpaying. Take a look at your
          information below and let us know if it’s up to date.
        </p>
        <InfoButton
          title={"Where do I find the RF Serial Number?"}
          onClick={() => setOpenWhereToFindDialog(true)}
        />
        <div className={"divider_bold"}></div>
        <ServiceRow
          loading={loading}
          title={"Property Type"}
          services={data.propertyType}
        />
        <ServiceRow
          loading={loading}
          title={"Water Services"}
          services={data.waterServices}
        />
        {!isResidential ? (
          <ServiceRow
            loading={loading}
            title={"WasteWater Services"}
            services={data.wasteWaterServices}
          />
        ) : null}
        <ServiceRow
          loading={loading}
          title={"Solid Waste services"}
          services={data.solidWasteServices}
        />
        <div className={"flex_end_container"}>
          <Button
            className={"margin_right"}
            size={"small"}
            variant={"outlined"}
            onClick={() => setOpenEditModal(true)}
          >
            Update information
          </Button>
          <Button
            startIcon={<IconBase iconName={"check"} />}
            size={"small"}
            variant={"contained"}
          >
            Looks good
          </Button>
        </div>
      </div>
      <Modal
        open={openWhereToFindDialog}
        onClose={() => setOpenWhereToFindDialog(false)}
        title={"Where do I find the RF Serial Number?"}
      >
        <div className={classes.modal_body}>
          <h2>RF (Radio Frequency) Serial Number</h2>
          <div className={classes.modal_flex_group}>
            <p
              className={`${typographyClasses.body} ${typographyClasses.high_emphasis}`}
              style={{ maxWidth: 370 }}
            >
              <span>Locate the water metre</span>
              <br />
              Your water metre is typically located in a utility area such as a
              basement, metre box, or mechanical room.
              <br />
              <br />
              <span>Identify the water metre</span>
              <br />
              Once you’ve identified the water metre, examine in closely. Look
              for any labels or markings that contain the RF serial number.
              <br />
              <br />
              <span>RF Serial Number</span>
              <br />
              The RF serial number is a 9 or 10 digit number located on the
              front of the device. It is displayed in the picture as the number
              on the top right corner.
              <br />
              <br />
              If you are having difficulty finding the RF serial number or if
              it’s not visible on the module,{" "}
              <Link
                style={{color: theme.palette.primary[200]}}
                rel={"noreferrer"}
                target={"_blank"}
                href={"https://lacombe.ca/Directory.aspx?DID=42"}
              >
                contact us
              </Link>
            </p>
            <img
              className={classes.modal_img}
              src={waterMetre}
              alt={"water metre"}
            />
          </div>
        </div>
      </Modal>
      <ServicesFormModal
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        onSubmit={handleEditSubmit}
        values={{
          type: "Residential",
          serial_number: "12345678",
          metre_size: "Up to 1”",
          waste_metre_size: "Up to 4”",
          collection_services: "Residential",
          collection_service_pickup: "Scheduled Bi-weekly pickup per bin",
          cardboard: "Scheduled Bi-weekly pickup per bin",
          disposable_service_size: "Up to 20 yd3",
        }}
      />
    </div>
  );
};

export default ServiceTab;
