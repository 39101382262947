import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/ui-kit/Modal";
import Spacer from "../../../../../components/ui-kit/Spacer";
import styled from "@emotion/styled";
import { Box, css } from "@mui/material";
import Button from "@mui/material/Button";
import { get_applicant_declaration } from "../../../../../../core/apis/terms";

const StyledModal = styled(Modal)(
  () => css`
    && {
      max-width: 620px;
    }
  `
);

const Content = styled.div(({ theme }) => ({
  "&&": {
    h3: {
      ...theme.typography.h3,
    },
    p: {
      ...theme.typography.body1,
    },
  },
}));

const ApplicantDeclarationModal = ({ open, handleClose, onAcknowledge }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    const getDeclaration = async () => {
      const response = await get_applicant_declaration();
      setContent(response.data.content);
    };

    getDeclaration();
  }, []);

  return (
    <StyledModal onClose={handleClose} open={open}>
      <Box p={4}>
        <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
        <Spacer amount={1.5} />
        <Button
          fullWidth
          size={"large"}
          onClick={() => {
            onAcknowledge();
            handleClose();
          }}
          variant={"outlined"}
        >
          Acknowledge
        </Button>
      </Box>
    </StyledModal>
  );
};

export default ApplicantDeclarationModal;
