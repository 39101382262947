import React from "react";
import Spacer from "../../../../../components/ui-kit/Spacer";
import Button from "@mui/material/Button";
import classes from "../../../../../../assets/styles/typography/typography.module.scss";
import IUtilityAccountResponse from "../../../../../../core/interfaces/utility/iUtilityAccountResponse";
import UtilityBanner from "../widgets/UtilityBanner";

export default function Step2({
  account,
  linkAccount = () => {},
}: {
  account: IUtilityAccountResponse;
  linkAccount: any;
}) {
  return (
    <>
      <p className={classes.h1}>Confirm your account</p>
      <p className={classes.body}>
        Please review the information below and confirm this is the Utilities
        Account number you would like to link to your profile.
      </p>
      <Spacer amount={0.1} />
      <p className={classes.overline}>ACCOUNT FOUND</p>
      <UtilityBanner account={account}/>
      <Spacer amount={2} />
      <Button
        size={"large"}
        variant={"contained"}
        fullWidth
        onClick={linkAccount}
      >
        Link account
      </Button>
    </>
  );
}
