import React from "react";
import {css, styled, Tabs as MuiTabs} from "@mui/material";
import {
  BLACK_HIGH_EMPHASIS,
} from "../../../../mui-theme/theme";

const StyledTabs = styled(MuiTabs)(
  () => css`
    && {
      && .Mui-selected {
        background-color: #fff;
      }
    }
  `
);

const Tabs = ({ children, label, ...rest }: any) => {
  return (
    <StyledTabs
      TabIndicatorProps={{
        style: {
          background: BLACK_HIGH_EMPHASIS,
        },
      }}
      {...rest}
    >
      {children}
    </StyledTabs>
  );
};

export default Tabs;
