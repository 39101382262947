import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { Box, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

import { IconBase } from "../../../../components/ui-kit/Icons";
import Spacer from "../../../../components/layout-helpers/Spacer";
import PaymentMethodBar from "../../../../components/ui-kit/PaymentMethodBar";
import { formatCurrency } from "../../../../../utilities/formatter";
import Alerts from "../../../../components/ui-kit/Alert";
import { pay_with_profile } from "../../../../../core/apis/payment";
import { getLicenseInvoices } from "../../../../../core/apis/licence";
import {
  Container,
  InnerContainer,
} from "../../../../components/layout-helpers/pageLayout";

import useQuery from "../../../../hooks/useQuery";
import DeleteModal from "./DeleteModal";
import { prices } from "../../../../../core/constants/licences";
import { InfoRounded } from "@mui/icons-material";
import classes from "./index.module.scss";

const PaymentContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.primary[50]};
  border-radius: 10px;
  padding-top: 1rem;
`;

const initialItemsValues = {
  items: [],
};

const yupSchema = yup.object().shape({
  items: yup
    .array()
    .of(
      yup.object().shape({
        amountToBePaid: yup
          .number()
          .max(
            yup.ref("amountDue"),
            `Amount must be less than or equal to the original amount`
          )
          .required("Amount required"),
      })
    )
    .required(),
});

// TODO: refactor the components implemented below to their own separate file
const Checkout = () => {
  const [cartItems, setCartItems] = useState([]);
  const [paymentProfileId, setPaymentProfileId] = useState(null);
  const [shouldMoveFormik, setShouldMoveFormik] = useState(true);
  const [total, setTotal] = useState(0);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const theme = useTheme();
  const query = useQuery();

  /**
   * With each change in the query, gets the invoice items
   */
  useEffect(() => {
    fetchItems().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  //Modal functions
  const handleModalOpen = (index) => {
    setDeleteModalData({ index });
  };
  const handleModalClose = () => {
    setDeleteModalData(null);
  };

  const history = useHistory();
  const handleSubmit = async (values) => {
    if (!paymentProfileId) {
      history.push("/cc-add");
      return;
    }
    setLoading(true);
    setPaymentError(false);
    try {
      const response = await pay_with_profile({
        amount: total,
        profileId: paymentProfileId,
        invoiceId: values.items?.map((item) => item.id)[0],
      });

      if (!response || response.status >= 400) {
        setPaymentError(true);
      } else {
        history.push(`business-licence/${query.get("licence")}`, {
          showPaymentNotification: `Your card ending in ${
            response.data.content.card.last_four
          } will be charged an amount of ${formatCurrency(
            response.data.content.amount
          )}`,
        });
      }
    } catch (e) {
      setPaymentError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const fetchItems = async () => {
    let result = await getLicenseInvoices(query.get("licence"));

    if (!result) {
      setCartItems([]);
      setTotal(0);
      return;
    }

    const invoices = result.invoicesMCH?.reduce((invoices, invoice) => {
      const amountToBePaid = invoice.amountDue - invoice.amountPaid;
      if (amountToBePaid > 0 && invoice.status !== "void") {
        invoices.push({
          ...invoice,
          amountToBePaid: invoice.amountDue - invoice.amountPaid,
          title: "Business licence",
        });
      }
      return invoices;
    }, []);

    setCartItems(invoices);
    setTotal(result.balance || 0);
  };

  return (
    <Container>
      <InnerContainer>
        <Box p={1}>
          <Button
            size={"medium"}
            onClick={handleBack}
            variant={"outlined"}
            startIcon={<IconBase iconName={"arrow_back"} />}
          >
            Back
          </Button>
          <div
            style={{ maxWidth: 780, marginLeft: "auto", marginRight: "auto" }}
          >
            <Spacer amount={4} />
            <Typography variant={"h3"}>Checkout</Typography>
            <Spacer
              amount={
                total === prices.discountedLocalBusiness ||
                total === prices.discountedNonLocalBusiness
                  ? 0
                  : 2
              }
            />
            {(total === prices.discountedLocalBusiness ||
              total === prices.discountedNonLocalBusiness) && (
              <>
                <div className={"discount-alert-container"}>
                  <InfoRounded />
                  <p>
                    Applications issued from July 1st to December 31st will
                    receive a one-time 50% discount off the fee.
                  </p>
                </div>
                <Spacer amount={1} />
              </>
            )}
            <Formik
              initialValues={initialItemsValues}
              validationSchema={yupSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, errors }) => {
                if (values.items.length < 1 && cartItems.length > 0) {
                  if (shouldMoveFormik) {
                    setTimeout(() => setShouldMoveFormik(false), 0);
                    setFieldValue("items", cartItems);
                  }
                }

                const calculateTotal = () => {
                  let sum = 0;
                  values.items.forEach(({ amountToBePaid }) => {
                    sum += amountToBePaid;
                  });
                  setTimeout(() => setTotal(sum), 0);
                };

                calculateTotal();

                return (
                  <Form noValidate>
                    <PaymentContainer>
                      <FieldArray
                        name={"items"}
                        render={({ remove }) => {
                          const handleRemove = (index) => {
                            remove(index);
                            if (values.items.length - 1 < 1) {
                              setTimeout(() => history.goBack(), 0);
                            }
                          };
                          return (
                            <div>
                              {values.items.map((item, index) => (
                                <div key={index}>
                                  <PaymentItem
                                    item={item}
                                    index={index}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    handleModalOpen={handleModalOpen}
                                  />
                                </div>
                              ))}
                              <DeleteModal
                                data={deleteModalData}
                                open={Boolean(deleteModalData)}
                                handleModalClose={handleModalClose}
                                handleRemove={handleRemove}
                              />
                            </div>
                          );
                        }}
                      />
                      <AdministrationFeeItem></AdministrationFeeItem>
                      <TotalItem total={total} />
                    </PaymentContainer>
                    <Spacer />
                    <Box display={"flex"}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                          <PaymentMethodBar
                            setPaymentProfileId={setPaymentProfileId}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <Button
                            style={{ height: 69 }}
                            disabled={values.items.length < 1}
                            variant={"contained"}
                            size={"large"}
                            fullWidth
                            type={"submit"}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{ color: theme.palette.primary[50] }}
                                />
                              ) : (
                                <span>PAY NOW</span>
                              )}
                              <span style={{ paddingRight: 31 }}>
                                {formatCurrency(total)}
                              </span>
                            </div>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    {paymentError && (
                      <>
                        <Spacer />
                        <Alerts
                          variant={"error"}
                          title={"Payment didn't go through"}
                          body={
                            "Please, make sure you are connected to the Internet and try again."
                          }
                        />
                        <Spacer />
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>
      </InnerContainer>
    </Container>
  );
};

const PaymentItemContainer = styled.div`
  padding: 1rem 55px 1rem 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  &&.MuiTextField-root {
    border: none;
    width: 150px;

    && .MuiFilledInput-root {
      height: 40px;
      min-height: unset;

      .Mui-focused {
        background-color: unset;
      }
    }

    && .MuiFilledInput-input {
      height: 25px;
      padding: 0 10px;
    }
  }
`;

const PaymentItem = ({ item, index, key, errors, setFieldValue }) => {
  const [editAmount, setEditAmount] = useState(false);
  const theme = useTheme();

  const handleSubmit = () => {
    if (!errors.items) {
      setEditAmount(false);
    }
  };

  // const handleRemove = () => {
  //   handleModalOpen(index);
  // };

  return (
    <PaymentItemContainer key={key}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant={"body1"}>{item.title}</Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {editAmount ? (
          <Box display={"flex"} alignItems={"center"}>
            <div style={{ display: "inline-block" }}>
              <StyledTextField
                inputType={"number"}
                name={`items.${index}.amountToBePaid`}
                value={item.amountToBePaid}
                maxAmount={item.amountDue}
                onChange={(e) => {
                  let num = e.target.value;
                  if (num > item.amountDue) {
                    num = item.amountDue;
                  }
                  setFieldValue(`items.${index}.amountToBePaid`, num);
                }}
                endAdornment={
                  <InputAdornment position="end" style={{ marginLeft: -9 }}>
                    <Typography
                      style={{
                        color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                      }}
                      variant={"body1"}
                    >
                      /${item.amountDue}
                    </Typography>
                  </InputAdornment>
                }
                initialValue={item.amountToBePaid}
                error={!!(errors["items"] && errors["items"][index])}
                helperText={
                  errors["items"] && errors["items"][index]
                    ? errors["items"][index].amountToBePaid
                    : null
                }
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            </div>
            <IconButton
              type={"submit"}
              size={"small"}
              style={{ marginLeft: 5 }}
              onClick={() => {
                handleSubmit();
              }}
            >
              <IconBase
                fill={1}
                iconName={"done"}
                color={theme.palette.primary[200]}
              />
            </IconButton>
          </Box>
        ) : item.amountToBePaid === prices.discountedLocalBusiness ||
          item.amountToBePaid === prices.discountedNonLocalBusiness ? (
          <div className={classes.price_container}>
            <p>
              {formatCurrency(
                item.amountToBePaid === prices.discountedLocalBusiness
                  ? prices.localBusiness
                  : prices.nonLocalBusiness
              )}
            </p>
            <Typography>{formatCurrency(item.amountToBePaid)}</Typography>
          </div>
        ) : (
          <Typography>{formatCurrency(item.amountToBePaid)}</Typography>
        )}
      </div>
    </PaymentItemContainer>
  );
};

const AdministrationFeeItemContainer = styled.div`
  padding: 0 55px 1rem 55px;
`;

const AdministrationFeeItem = () => {
  return (
    <AdministrationFeeItemContainer>
      {/*<div*/}
      {/*  style={{*/}
      {/*    display: "flex",*/}
      {/*    alignItems: "center",*/}
      {/*    justifyContent: "space-between",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <div style={{ display: "flex", alignItems: "center" }}>*/}
      {/*    <Typography variant={"body1"}>Administration fee</Typography>*/}
      {/*    <IconBase*/}
      {/*      style={{ marginLeft: 5 }}*/}
      {/*      iconName={"info"}*/}
      {/*      color={theme.palette.primary[200]}*/}
      {/*      fill={1}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <div style={{ display: "flex", alignItems: "center" }}>*/}
      {/*    <Typography>{formatCurrency(fee)}</Typography>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<Spacer amount={2} />*/}
      <Divider />
    </AdministrationFeeItemContainer>
  );
};

const TotalItemContainer = styled.div`
  padding: 0.5rem 55px 1rem 55px;
`;

const TotalItem = ({ total }) => {
  return (
    <TotalItemContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant={"body1"} fontWeight={"700"}>
            Total
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant={"body1"} fontWeight={"700"}>
            {formatCurrency(total)}
          </Typography>
        </div>
      </div>
      <Spacer amount={1} />
    </TotalItemContainer>
  );
};

export default Checkout;
