import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NotificationContext } from "../../../../../contexts/NotificationContext";
import Typography from "../../../../../components/ui-kit/typography";
import Box from "@mui/material/Box";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  createChangeSet,
  getLicence,
  getLicenceSummary,
  getStatus,
  updateStatusCancelled,
  updateStatusDiscarded,
  updateStatusSubmitted,
} from "../../../../../../core/apis/licence";
import Tab from "../../../../../components/ui-kit/tab";
import Tabs from "../../../../../components/ui-kit/tabs";
import MainContainer from "../../../../../components/layout-helpers/triple-column-layout/MainContainer";
import LeftColumn from "../../../../../components/layout-helpers/triple-column-layout/LeftColumn";
import RightColumn from "../../../../../components/layout-helpers/triple-column-layout/RightColumn";
import Spacer from "../../../../../components/ui-kit/Spacer";
import GeneralTab from "../BusinessLicenceDetailsGeneralTab";
import Hero from "../../../../../components/ui-kit/mch/Hero";
import imgLacombe from "../../../../../../assets/images/lacombe/lacombe_blacksmith.jpg";
import DocumentThumbnail from "../../../../../components/ui-kit/mch/DocumentThumbnail";
import Grid from "@mui/material/Unstable_Grid2";
import SideBar from "../DetailsScreen/SideBar";
import CircularProgress from "@mui/material/CircularProgress";
import { mapInto } from "../mappings/mapping";
import {
  download_attachment,
  getAttachment,
  uploadFile,
  editAttachment,
} from "../../../../../../core/apis/attachment";
import {
  ACTIVE,
  editStatuses,
  SAVED,
} from "../../../../../../core/constants/statuses";
import { MAX_FILES_SIZE } from "../../../../../../core/constants/licences";
import useQuery from "../../../../../hooks/useQuery";
import useHash from "../../../../../hooks/useHash";
import UploadFileModal from "../../../../../components/modals/UploadFileModal";
import { saveFormValues } from "../functions/business_licence_functions";
import MaxDocumentDisplay from "../../../../../components/ui-kit/MaxDocumentDisplay";
import { roundMb } from "../../../../../../utilities";
import { useTheme } from "@mui/material";
import RenameModal from "../../../../../components/modals/RenameModal";
import ModalDeleteFile from "../../../../../components/modals/ModalDeleteFile";
import { fromBlob } from "file-type/browser";
import { Buffer } from "buffer";
import classes from "./index.module.scss";
import WBCBreadcrumbs from "../../../../../components/ui-kit/breadcrumbs";
import { Home } from "@mui/icons-material";
import { routes } from "../../../../../routes/routesData";

//Constants
//Sidebar
const DRAFT = "draft";

const Container = styled.div`
  padding-bottom: 4rem;
`;

const LargeScreenSideBar = styled.div`
  display: flex;
  width: 375px;
  position: sticky;
  top: 0;
  height: 100%;

  @media (max-width: 720px) {
    display: none;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15%;
`;

const tabs = ["General", "Documents"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const SmallScreenSideBar = styled.div`
  display: none;
  @media (max-width: 720px) {
    display: block;
  }
`;
// const TransactionsInvoicesTab = () => {
//   const history = useHistory();
//   const theme = useTheme();
//
//   return (
//     <Grid item style={{ marginTop: "3rem" }}>
//       <Grid item xs={12}>
//         <Typography
//           variant="h4"
//           fontWeight={"400"}
//           style={{ marginBottom: "1.5rem" }}
//         >
//           Invoices and Transactions
//         </Typography>
//       </Grid>
//       <Grid container spacing={2}>
//         {fakeTransactionsInvoices
//           ?.filter((tx, index) => index < 3)
//           .map((tx, index) => (
//             <Grid item key={index}>
//               {tx.variant === "TRANSACTION" ? (
//                 <TransactionCard {...tx} />
//               ) : (
//                 <InvoiceCard {...tx} />
//               )}
//             </Grid>
//           ))}
//       </Grid>
//       <Button
//         style={{
//           color: theme.palette.primary[200],
//           marginTop: "0.5rem",
//         }}
//         size="medium_icon_right"
//         iconName={"chevron_right"}
//         endIcon
//         onClick={() => {
//           history.push(navigator.TRANSACTION_AND_INVOICE_SUMMARY.path, {
//             type: "LICENCE",
//           });
//         }}
//       >
//         VIEW ALL
//       </Button>
//     </Grid>
//   );
// };

/**
 * Displays either a draft or licence for business licences
 * @returns {JSX.Element}
 * @constructor
 */
const LicenseDetails = () => {
  const theme = useTheme();
  const query = useQuery();
  const { handleSuccess } = useContext(NotificationContext);
  const [item, setItem] = useState({});
  const { id } = useParams();
  const [documentsData, setDocumentsData] = useState({
    documents: [],
    error: null,
    size: 0,
  });
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [tab, setTab] = useState(query.get("tabName") || tabs[0]);
  const [status, setStatus] = useState(DRAFT);
  const [canEditLicense, setCanEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [childStatus, setChildStatus] = useState("singleton");
  const history = useHistory();
  const { state } = useLocation();
  const hash = useHash();

  const [modalData, setModalData] = useState(null);
  const deleteModalOpen = Boolean(modalData?.type === "delete");
  const renameModalOpen = Boolean(modalData?.type === "rename");

  const onModalOpen = (name, id, type) => {
    setModalData({
      name,
      id,
      type,
    });
  };

  /**
   * Handles renaming of attachments
   * @param attachmentId
   * @param fileName
   * @returns {Promise<void>}
   */
  const onRename = async (attachmentId, fileName) => {
    setRenameLoading(id);
    try {
      await editAttachment(attachmentId, fileName, id);
      await getFiles(item.attachments);
    } catch (e) {
      console.log(e.message);
      handleError("Unable to rename file, please try again.");
    } finally {
      setRenameLoading(false);
    }
  };

  /**
   * Handles the deletion of an attachment
   * @param fileId
   * @returns {Promise<void>}
   */
  const onDelete = async (fileId) => {
    setDeleteLoading(fileId);
    try {
      let fileArray = item.attachments;
      fileArray = fileArray.filter((x) => x !== fileId);
      let newValues = { attachments: fileArray };
      await saveFormValues(id, newValues);
      await fetchLicence();
    } catch (e) {
      console.log(e.message);
      handleError("Unable to delete file, please try again.");
    } finally {
      setDeleteLoading(false);
    }
  };

  const closeModal = () => {
    setModalData(null);
  };

  const changeTab = (e, newTab) => {
    history.push(`/business-licence/${id}#${newTab}`);
  };

  useEffect(() => {
    const t = hash || tabs[0];
    setTab(t);
  }, [hash]);

  /**
   * Adds newly uploaded files to the business licence.
   * @param files
   * @returns {Promise<void>}
   */
  const addFiles = async (files) => {
    setUploadInProgress(true);
    let fileArray = item.attachments;
    if (!fileArray) {
      fileArray = [];
    }
    for (let i = 0; i < files.length; i++) {
      fileArray.push(await uploadFile(files[i].file));
    }

    let newValues = { attachments: fileArray };
    await saveFormValues(id, newValues);
    await getFiles(fileArray);
    setUploadInProgress(false);
  };

  const getFiles = async (ids) => {
    if (!ids) return;
    let newFiles = [];
    let size = 0;
    for (let i = 0; i < ids.length; i++) {
      const newFile = await getAttachment(ids[i], id);
      size += newFile?.fileSize;
      newFiles.push(newFile);
    }
    let error = null;
    if (roundMb(size) > MAX_FILES_SIZE) {
      error = `Total filesize is too big. (Upload ${MAX_FILES_SIZE} MB or less)`;
    }
    setDocumentsData({ documents: newFiles, size: size, error: error });
  };

  const { handleError } = useContext(NotificationContext);

  const onAcknowledge = () => {};

  const onDiscard = async () => {
    let newStatus = await updateStatusDiscarded(id);
    handleSuccess("Business licence discarded");
    setStatus(newStatus.newState);
    history.push("/");
  };

  /**
   * Throws an error if a user attempts to upload more than the max
   * file size of all current files.
   */
  const isMaxedFilesSize = () => {
    if (roundMb(documentsData.size) > MAX_FILES_SIZE) {
      throw new Error("Max file size exceeded");
    }
  };

  const onSubmit = async () => {
    setInitialLoading(true);
    if (uploadInProgress) {
      handleSuccess("File upload in progress");
      setRefresh(!refresh);
      return;
    }
    try {
      isMaxedFilesSize();
      let newStatus = await updateStatusSubmitted(id);
      setStatus(newStatus.newState);
      if (item.prevLicenceId) {
        handleSuccess(
          "Your changes are submitted. City staff might contact you if they need more information."
        );
      }
      setCanEdit(false);
    } catch (e) {
      handleError(e.message);
    } finally {
      setInitialLoading(false);
      setRefresh(!refresh);
    }
  };

  /**
   * Downloads the attached documents:
   *
   * - Gets the file-link from server by calling the related api
   * - Attaches an anchor elements of type download for the link of the file
   * - Gets the file type using the file-type library by calling a xml http request
   * - Triggers a click event over the created anchor element then removes it from the document
   *
   * @param attachmentId
   * @param fileName
   * @returns {Promise<void>}
   */
  const handleDownloadDoc = async (attachmentId, fileName) => {
    if (!attachmentId) {
      handleError("Unable to process the request, please try again later");
      return;
    }

    window.Buffer = Buffer;
    const docLink = await download_attachment(attachmentId, id);

    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";

    // set up the onload event handler
    xhr.onload = async function () {
      let downloadLink = document.createElement("a");
      const fileType = await fromBlob(xhr.response);
      const fileExtension = fileType ? `.${fileType.ext}` : "";

      let url = URL.createObjectURL(xhr.response);

      downloadLink.href = url;

      downloadLink.setAttribute(
        "download",
        `${fileName.split(".")?.[0]}${fileExtension}`
      );

      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
      URL.revokeObjectURL(url);
    };

    // send the XHR request to the server
    xhr.open("GET", docLink.downloadLink);
    xhr.send();
  };

  /**
   * Opens a new tab for previewing the uploaded document
   * @param attachmentId
   * @returns {Promise<void>}
   */
  const handlePreviewDoc = async (attachmentId) => {
    if (!attachmentId) {
      handleError("Unable to process the request, please try again later");
      return;
    }
    const docLink = await download_attachment(attachmentId, id);
    window.open(docLink.downloadLink, "_blank", "noopener,noreferrer");
  };

  const onPayDues = () => {
    history.push(`/checkout?licence=${id}`, {
      id: item.invoiceId,
      licenceId: id,
      changesId: item.changeSetId,
    });
  };

  const onCancelLicence = async () => {
    try {
      await updateStatusCancelled(id);
      history.push("/", { cancelledSuccess: true });
    } catch (e) {
      handleError("Could not cancel the licence, please try again later");
    }
  };

  const editPage = async (page) => {
    try {
      history.push(`/business-licence/form/${id}#${page + 1}`, {
        pageEdit: page,
        changesId: item.changeSetId,
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  const editActivePage = async () => {
    try {
      if (status === ACTIVE) {
        await createChangeSet(id, "business_licence/edit");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  /**
   * Gets the licence information by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchLicence = async () => {
    try {
      setInitialLoading(true);
      const results = await getLicence(id);
      let refractedValues = {};
      let refractedOriginalValues = {};
      results.forEach((val) => {
        let newVal = mapInto(val);
        if (!newVal) return;
        if (newVal.property === "tmpOwners") {
          if (
            newVal.value?.orgOwners.length !== 0 ||
            newVal.value?.orgManagers.length !== 0
          ) {
            refractedOriginalValues["orgOwners"] = newVal.value?.orgOwners
              ? newVal.value?.orgOwners
              : [];
            refractedOriginalValues["orgManagers"] = newVal.value?.orgManagers
              ? newVal.value?.orgManagers
              : [];
          }
          refractedValues["owners"] = newVal.value?.owners
            ? newVal.value?.owners
            : [];
          refractedValues["managers"] = newVal.value?.managers
            ? newVal.value?.managers
            : [];
        } else if (newVal.property === "businessType") {
          refractedValues[newVal.property] = {
            value: newVal.value,
            categories: newVal.categories,
          };
        } else {
          refractedValues[newVal.property] = newVal.value;
          if (newVal.originalValue !== undefined) {
            refractedOriginalValues[newVal.property] = newVal.originalValue;
          }
        }
      });
      getFiles(refractedValues.attachments).then();
      setCanEdit(
        editStatuses.find((x) => x === refractedValues["currentStatus"])
      );
      if (refractedValues["previousLicenceId"] !== "") {
        const originalSummary = await getLicenceSummary(
          refractedValues["previousLicenceId"]
        );
        if (originalSummary) {
          refractedValues["original"] = originalSummary;
          setChildStatus("duplicate");
        }
      }
      if (refractedValues["updatedLicenceId"] !== "") {
        const updatedStatus = await getStatus(
          refractedValues["updatedLicenceId"]
        );
        if (updatedStatus) {
          setCanEdit(editStatuses.find((x) => x === state));
          setChildStatus("original");
        }
      }
      setItem(refractedValues);
      setOriginalValues(refractedOriginalValues);
      setStatus(refractedValues["currentStatus"]);
    } catch (e) {
      handleError("Unable to load licence details");
    } finally {
      setInitialLoading(false);
    }
  };

  const handleUploadDocumentModalOpen = () => {
    setUploadDocumentModalOpen(true);
  };

  const handleUploadDocumentModalClose = () => {
    setUploadDocumentModalOpen(false);
  };

  /**
   * With each change in the refresh property in inner state as well as the initial render:
   * - Calls the fetchLicence callback
   */
  useEffect(() => {
    fetchLicence().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  //Initial load
  useEffect(() => {
    if (state?.showPaymentNotification) {
      handleSuccess(state.showPaymentNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className={classes.top_bar}>
        <WBCBreadcrumbs
          children={[
            {
              icon: <Home fontSize={"small"} />,
              text: "HOME",
              onClick: () => history.replace(routes.LICENCES.path),
            },
            {
              text: "BUSINESS LICENCE",
            },
          ]}
        />
        <div />
      </div>
      <Hero
        backPathName={"/portal"}
        close
        img={imgLacombe}
        name={item?.businessName}
        title={"Business licence"}
      />
      <MainContainer padding={"7px"}>
        {initialLoading ? (
          <LoadingWrapper>
            <CircularProgress size={100} style={{ marginTop: "1rem" }} />
          </LoadingWrapper>
        ) : (
          <>
            <LeftColumn>
              <>
                <Tabs
                  variant={"fullWidth"}
                  onChange={changeTab}
                  value={tab}
                  style={{ marginTop: "1rem" }}
                >
                  <Tab
                    style={{ minWidth: 55 }}
                    label={tabs[0]}
                    value={tabs[0]}
                  />
                  <Tab
                    style={{ minWidth: 55 }}
                    label={tabs[1]}
                    value={tabs[1]}
                  />
                </Tabs>
                <Spacer amount={1} />
                <TabPanel value={tab} index={tabs[0]}>
                  <Grid item xs={12}>
                    <SmallScreenSideBar>
                      <SideBar
                        showChangesMessage={Boolean(item.prevLicenceId)}
                        status={status}
                        onAcknowledge={onAcknowledge}
                        onSubmit={onSubmit}
                        onCancel={onCancelLicence}
                        onDiscard={onDiscard}
                        onPayDues={onPayDues}
                        totalDues={item.balanceOwing}
                        versionStatus={childStatus}
                        canEditLicense={canEditLicense}
                        licence={item}
                        id={id}
                        originalValues={originalValues}
                        editPage={editActivePage}
                        refresh={refresh}
                        setRefresh={setRefresh}
                      />
                    </SmallScreenSideBar>
                  </Grid>
                  <GeneralTab
                    editPage={editPage}
                    status={status}
                    licence={item}
                    canEdit={canEditLicense}
                  />
                </TabPanel>
                <TabPanel value={tab} index={tabs[1]}>
                  {/*<TransactionsInvoicesTab />*/}
                  <Grid container spacing={0} style={{ marginTop: "3rem" }}>
                    <Grid item xs={12}>
                      <div style={{ marginBottom: "1rem" }}>
                        <Typography
                          variant="h4"
                          fontWeight={"300"}
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          Supporting documents
                        </Typography>
                      </div>
                      <Typography variant="body1" fontWeight={"400"}>
                        City staff will contact you if any documents are
                        required in support of your application. Supporting
                        documents may include corporate documentation and
                        registration statements.
                      </Typography>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                      {status === SAVED &&
                      canEditLicense &&
                      editStatuses.find((x) => x === status) ? (
                        <>
                          <Grid item xs={12}>
                            <MaxDocumentDisplay
                              maxFileSize={MAX_FILES_SIZE}
                              fileLength={documentsData.documents.length}
                              fileSize={documentsData.size}
                              justifyContent={"left"}
                            />
                            {documentsData.error ? (
                              <Typography
                                variant={"body2"}
                                color={theme.palette.nonPalette.RED}
                              >
                                {documentsData.error}
                              </Typography>
                            ) : null}
                          </Grid>
                          <Grid item>
                            <DocumentThumbnail
                              onClick={handleUploadDocumentModalOpen}
                              variant={"upload"}
                              loading={uploadInProgress}
                              style={{ marginBottom: "1rem" }}
                            />
                          </Grid>
                        </>
                      ) : null}
                      {documentsData.documents.map((doc) => {
                        let loading = false;
                        if (
                          deleteLoading &&
                          deleteLoading === doc?.attachmentId
                        ) {
                          loading = true;
                        }
                        if (
                          renameLoading &&
                          renameLoading === doc?.attachmentId
                        ) {
                          loading = true;
                        }
                        return (
                          <Grid item key={doc?.attachmentId}>
                            <DocumentThumbnail
                              loading={loading}
                              onClick={() =>
                                handlePreviewDoc(doc?.attachmentId)
                              }
                              canEdit={
                                status === SAVED &&
                                canEditLicense &&
                                editStatuses.find((x) => x === status)
                              }
                              title={doc?.fileLabel || "-"}
                              style={{ marginBottom: "1rem" }}
                              onDownload={() =>
                                handleDownloadDoc(
                                  doc?.attachmentId,
                                  doc?.fileLabel || "-"
                                )
                              }
                              onDelete={() => {
                                onModalOpen(
                                  doc?.fileLabel,
                                  doc?.attachmentId,
                                  "delete"
                                );
                              }}
                              onRename={() => {
                                onModalOpen(
                                  doc?.fileLabel,
                                  doc?.attachmentId,
                                  "rename"
                                );
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </TabPanel>
                <RenameModal
                  open={renameModalOpen}
                  data={modalData}
                  handleClose={closeModal}
                  renameFile={onRename}
                />
                <ModalDeleteFile
                  modalOpen={deleteModalOpen}
                  data={modalData}
                  handleModalClose={closeModal}
                  deleteFile={onDelete}
                />
              </>
            </LeftColumn>
            <LargeScreenSideBar>
              <RightColumn>
                <SideBar
                  showChangesMessage={Boolean(item.prevLicenceId)}
                  status={status}
                  onAcknowledge={onAcknowledge}
                  onSubmit={onSubmit}
                  onCancel={onCancelLicence}
                  onDiscard={onDiscard}
                  onPayDues={onPayDues}
                  totalDues={item.balanceOwing}
                  versionStatus={childStatus}
                  licence={item}
                  id={id}
                  canEditLicense={canEditLicense}
                  editPage={editActivePage}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  originalValues={originalValues}
                />
              </RightColumn>
            </LargeScreenSideBar>
          </>
        )}
      </MainContainer>
      <UploadFileModal
        fileTitle={"File Attachment"}
        files={documentsData.documents}
        handleClose={handleUploadDocumentModalClose}
        modalOpen={uploadDocumentModalOpen}
        addFiles={addFiles}
      />
    </Container>
  );
};

export default LicenseDetails;
