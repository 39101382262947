import base from "./axiosInstance";
import { manageSession } from "../../utilities/manageSession";
import jwt_decode from "jwt-decode";

export const get_invoices = async (params: any) => {
  const token = manageSession.getSessionInfo()?.userToken;
  const decoded: any = jwt_decode(token);
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.get(`/invoice/${decoded.cityId}/summaries`, {
      params,
    });
    return response.data;
  } catch (error) {}
};

export const updateStatusVoid = async (invoiceId: string) => {
  const token = manageSession.getSessionInfo()?.userToken;
  try {
    base.defaults.headers["Authorization"] = `Bearer ${token}`;
    const response = await base.post(`/invoice/${invoiceId}/license/status`, {
      status: "void",
    });
    return response.data;
  } catch (error) {}
};
