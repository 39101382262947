import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  css,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { fields } from "./fields";
import { TableSettingContext } from "../../../../contexts/table-setting-context";
import Button from "@mui/material/Button";
import {
  get_license_invoices,
  renewLicence,
} from "../../../../../core/apis/licence";
import {
  ACTIVE_GROUP,
  REVIEWING_GROUP,
} from "../../../../../core/constants/licences";
import Alerts from "../../../../components/ui-kit/Alert";
import IconBase from "../../../../components/ui-kit/IconBase";
import { currency } from "../../../../../utilities";
import DataTable from "../../../../components/ui-kit/data-table";
import Dialog from "../../../../components/ui-kit/Dialog";
import { useModal } from "../../../../hooks/useModal";
import { updateStatusVoid } from "../../../../../core/apis/invoice";

export const StyledButton = styled(Button)(
  () => css`
    background-color: white;
  `
);

const CreateSection = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.primary[50]};
    border-radius: 10px;
    padding: 0.75rem;
    margin: 1rem 0;
  `
);

//Modal Constants
const CREATE = "create";
const VOID = "void";

const InvoicesTab = ({ licenceData, handleRefresh, fetchHistories }) => {
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings] = useState(tableSettings["invoices"] || {});
  const { modal, handleOpen, handleClose } = useModal();
  const { handleError } = useContext(NotificationContext);
  const [refresh, setRefresh] = useState(false);
  const [sendEmail, setSendEmail] = useState(null);

  const handleCreateInvoice = async () => {
    try {
      await renewLicence(licenceData.id, sendEmail);
      await fetchHistories();
      setRefresh(!refresh);
      handleRefresh();
    } catch (e) {
      console.log(e.message);
    }

    handleClose(CREATE);
  };

  const handleVoidInvoice = async () => {
    try {
      await updateStatusVoid(modal[VOID].data.id);
      await fetchHistories();
      setRefresh(!refresh);
      handleRefresh();
    } catch (e) {
      console.log(e.message);
    }
    handleClose(VOID);
  };

  const handleSendEmail = (event) => {
    setSendEmail(event.target.value);
  };

  async function fetchData(queryOptions) {
    try {
      let res = await get_license_invoices(licenceData.id, queryOptions);

      res.invoices = res.invoices.map((invoice) => {
        return {
          ...invoice,
          license_id: licenceData.id,
        };
      });

      setData({
        total: res.invoices?.length || 0,
        result: res.invoices,
        licenceId: licenceData.id,
      });
      setBalance(res.balance);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    tableSettings["invoices"] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [currentTableSettings, tableSettings, setTableSettings]);

  const disableCreateInvoice = useMemo(() => {
    if (ACTIVE_GROUP.includes(licenceData.status?.toLowerCase())) {
      return !!REVIEWING_GROUP.find(
        (x) => x === licenceData?.change_set_status
      );
    }

    return false;
  }, [licenceData?.change_set_status, licenceData.status]);

  function BodyComponent() {
    return (
      <div style={{ width: "400px" }}>
        <Typography
          variant={"body2"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "18.96px",
          }}
        >
          Generate a new invoice with the latest details. You can download and
          save it for future reference.
        </Typography>
        <br />
        <Typography
          variant={"body2"}
          fontWeight={500}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "18.96px",
          }}
        >
          Do you want to send the invoice via email to the customer?
        </Typography>
        <RadioGroup
          defaultValue={sendEmail}
          name="radio-buttons-group"
          style={{
            display: "flex",
            flexDirection: "row",
            background: "transparent",
            marginTop: "-1rem",
          }}
          onChange={handleSendEmail}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="No, don't Email"
            style={{ background: "transparent" }}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Yes, send an Email"
            style={{ background: "transparent", marginLeft: "25px" }}
          />
        </RadioGroup>
      </div>
    );
  }

  return (
    <div>
      <Typography variant={"h4"} fontWeight={400}>
        Invoices
      </Typography>
      {disableCreateInvoice ? (
        <Alerts
          variant={"info"}
          title={"Review changes"}
          body={
            "Before you can create a new invoice, please note that there are" +
            " changes that require approval. Once the review is completed and " +
            "the changes are approved or declined, you may proceed to create a " +
            "new invoice."
          }
        />
      ) : null}
      <CreateSection>
        <Grid container>
          <Grid item xs={6}>
            <StyledButton
              startIcon={<IconBase iconName={"add"} color={"inherit"} />}
              variant={"outlined"}
              size={"small"}
              disabled={
                REVIEWING_GROUP.find(
                  (x) => x === licenceData.status?.toLowerCase()
                ) ||
                REVIEWING_GROUP.find(
                  (x) => x === licenceData.change_set_status?.toLowerCase()
                ) ||
                licenceData?.balance > 0
              }
              onClick={() => handleOpen(CREATE)}
            >
              Generate new invoice
            </StyledButton>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography>
              <span style={{ fontWeight: 500 }}>BALANCE OWING:</span>
              <span style={{ marginLeft: 10 }}>{currency(balance)}</span>
            </Typography>
          </Grid>
        </Grid>
      </CreateSection>
      <DataTable
        id={"invoices"}
        fields={fields}
        data={data}
        dataLoading={loading}
        loadData={fetchData}
        tableSettings={currentTableSettings}
        setTableSettings={setTableSettings}
        onClickHandlers={{
          handleOpen,
          licenceStatus: licenceData.status,
        }}
        refresh={refresh}
        emptyTableVariant={"basic"}
      />
      <Dialog
        variant={"updated"}
        open={modal[CREATE]?.open}
        handleClose={() => {
          handleClose(CREATE);
          setSendEmail(null);
        }}
        title={"Generate a renewal invoice"}
        BodyComponent={BodyComponent()}
        buttonOneText={"Cancel"}
        buttonTwoText={"Confirm"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => {
          handleClose(CREATE);
          setSendEmail(null);
        }}
        handleButtonTwo={handleCreateInvoice}
        buttonTwoDisable={!sendEmail}
      />
      <Dialog
        variant={"updated"}
        open={modal[VOID]?.open}
        handleClose={() => handleClose(VOID)}
        title={"Mark invoice as void"}
        body={
          'Voiding this invoice will cancel it and add a "Void" stamp to the PDF. This action is irreversible.'
        }
        buttonOneText={"Cancel"}
        buttonTwoText={"Mark as Void"}
        buttonTwoVariant={"contained"}
        buttonFlexDirection={"column-reverse"}
        handleButtonOne={() => handleClose(VOID)}
        handleButtonTwo={handleVoidInvoice}
      />
    </div>
  );
};

export default InvoicesTab;
