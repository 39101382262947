import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from "@mui/material";
import typographyClasses from "../../../../../../../assets/styles/typography/typography.module.scss";
import { Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import HighlightedAutoComplete from "../../../../../../components/ui-kit/mch/HighlightedAutoComplete";
import {
  CanadaProvinces,
  ownerTenant,
  UsStates
} from "../../../../../../../core/constants/strings";
import MaskedTextField from "../../../../../../components/ui-kit/masked-text-field";
import { accountValidators } from "../../validators";
import Alert from "../../../../../../components/ui-kit/Alert";
import IAccountFormValues from "../../interfaces/IAccountFormValues";
import ModalTitle from "../../../../../../components/ui-kit/modal-title";
import { AddressCountry } from "../../../../../../../core/constants";
import { ReactComponent as CanadaFlagLogo } from "../../../../../../../assets/images/canada_flag_icon.svg";
import { ReactComponent as UsFlagLogo } from "../../../../../../../assets/images/usa_flag_icon.svg";
import classes from "./index.module.scss";
import { CheckboxCard } from "../../../../../../components/ui-kit/Checkbox";

interface IFormModal {
  open: boolean;
  handleClose: any;
  onSubmit: any;
  values: IAccountFormValues;
  adminSide?: boolean;
  showReviewAlert?: boolean;
  changedFormKeys?: string[];
}

const FormModal = ({
                     open = false,
                     handleClose = () => {
                     },
                     onSubmit,
                     values,
                     adminSide = false,
                     showReviewAlert = false,
                     changedFormKeys = []
                   }: IFormModal) => {
  const theme = useTheme();
  const formikRef = useRef();
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [initialValues, setInitialValues] = useState({}) as any;

  const [viewCanada, setViewCanada] = React.useState(
    values?.country ? values?.country : AddressCountry.CA
  );

  const handleToggle = (event: any, country: any, setFieldValue: any) => {
    if (country !== null && country !== viewCanada) {
      setViewCanada(country);
      setCountry(country);
      setProvince("");
      setFieldValue("address1", "");
      setFieldValue("address2", "");
      setFieldValue("city", "");
      setFieldValue("province_state", "");
      setFieldValue("postal_zip", "");
      setFieldValue("country", country);
    }
  };

  useEffect(() => {
    setCountry(values.country);
    const fullProvince = values.country === "CA"
      ? CanadaProvinces.find((p) => p.abbrev === values.province_state)
      : UsStates.find((p) => p.abbrev === values.province_state);
    setProvince(fullProvince?.label || values.province_state);
    setInitialValues({
      firstName: values.firstName,
      lastName: values.lastName,
      country: values.country,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      province_state: values.province_state,
      postal_zip: values.postal_zip,
      phone: values.phone,
      isOwner: (values.isOwner ? ownerTenant[0].label : ownerTenant[1].label),
      mailingSameAsServiceAddress: values.mailingSameAsServiceAddress
    });
  }, [values]);

  return (
    <Formik
      //@ts-ignore
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={accountValidators}
      validateOnChange={true}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
          resetForm,
          dirty,
          values,
          setFieldValue,
          handleChange,
          errors,
          touched,
          isValid,
          handleBlur
        }) => {
        const hideAddressSection = values.mailingSameAsServiceAddress;
        return (
          <Dialog open={open} fullWidth={true}>
            <ModalTitle
              title={"Account Details"}
              handleClose={() => {
                handleClose();
                resetForm();
              }}
            />
            <DialogContent>
              <Form noValidate={true}>
                <Grid container spacing={1}>
                  {(!adminSide || showReviewAlert) && (
                    <Grid xs={12}>
                      <Alert
                        variant={"info"}
                        title={
                          showReviewAlert
                            ? "Please review changes"
                            : "Confirm changes"
                        }
                        body={
                          showReviewAlert
                            ? "The applicant submitted changes, highlighted below."
                            : "After saving, City Hall staff will review and validate your changes to ensure accuracy."
                        }
                      />
                    </Grid>
                  )}
                  <Grid xs={12}>
                    <p className={typographyClasses.overline_bold}>
                      Account holder
                    </p>
                  </Grid>
                  <Grid xs={6} sx={{ paddingInline: "0.3rem" }}>
                    <TextField
                      inputProps={
                        changedFormKeys?.includes("firstName")
                          ? {
                            className: classes.changed_field
                          }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("firstName")
                          ? {
                            className: classes.changed_field,
                            disableUnderline: true
                          }
                          : undefined
                      }
                      style={{ width: "100%" }}
                      name={"firstName"}
                      label={"First Name"}
                      value={values.firstName}
                      onChange={handleChange}
                      error={Boolean(errors.firstName && touched.firstName)}
                      helperText={
                        touched.firstName && errors.firstName
                          ? String(errors.firstName)
                          : null
                      }
                    />
                  </Grid>
                  <Grid xs={6} sx={{ paddingInline: "0.3rem" }}>
                    <TextField
                      inputProps={
                        changedFormKeys?.includes("lastName")
                          ? {
                            className: classes.changed_field
                          }
                          : undefined
                      }
                      InputProps={
                        changedFormKeys?.includes("lastName")
                          ? {
                            className: classes.changed_field,
                            disableUnderline: true
                          }
                          : undefined
                      }
                      style={{ width: "100%" }}
                      name={"lastName"}
                      label={"Last name"}
                      value={values.lastName}
                      onChange={handleChange}
                      error={Boolean(errors.lastName && touched.lastName)}
                      helperText={
                        touched.lastName && errors.lastName
                          ? String(errors.lastName)
                          : null
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p className={typographyClasses.overline_bold}>
                      Mailing address
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <CheckboxCard
                      label="Same as service address"
                      cardVariant="highlight"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mailingSameAsServiceAddress}
                      name="mailingSameAsServiceAddress"
                      labelClasses={{
                        root: changedFormKeys?.includes(
                          "mailingSameAsServiceAddress"
                        )
                          ? classes.changed_field
                          : undefined
                      }}
                      classes={{
                        root: changedFormKeys?.includes(
                          "mailingSameAsServiceAddress"
                        )
                          ? classes.changed_field
                          : undefined
                      }}
                    />
                  </Grid>
                  {!hideAddressSection && (
                    <>
                      <Grid xs={12}>
                        {/*// @ts-ignore*/}
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            padding: "0.5rem",
                            gap: "2px",
                            border: `1px solid ${theme.palette.nonPalette.OUTLINE}`,
                            borderRadius: "15px"
                          }}
                        >
                          <ToggleButtonGroup
                            value={viewCanada ?? AddressCountry.CA}
                            exclusive
                            onChange={(e, v) => {
                              handleToggle(e, v, setFieldValue);
                            }}
                            fullWidth
                          >
                            <ToggleButton
                              value={AddressCountry.CA}
                              style={{
                                marginRight: "3px"
                              }}
                            >
                              <CanadaFlagLogo
                                style={{
                                  marginRight: "0.5rem"
                                }}
                              />
                              CANADA
                            </ToggleButton>
                            <ToggleButton value={AddressCountry.US}>
                              <UsFlagLogo
                                style={{
                                  marginRight: "0.5rem"
                                }}
                              />
                              USA
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          inputProps={
                            changedFormKeys?.includes("address1")
                              ? {
                                className: classes.changed_field
                              }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("address1")
                              ? {
                                className: classes.changed_field,
                                disableUnderline: true
                              }
                              : undefined
                          }
                          fullWidth
                          name={"address1"}
                          label={"Mailing address"}
                          value={values.address1}
                          onChange={handleChange}
                          error={Boolean(
                            errors.mailingAddress && touched.address1
                          )}
                          helperText={
                            touched.address1 && errors.address1
                              ? String(errors.address1)
                              : null
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          inputProps={
                            changedFormKeys?.includes("address2")
                              ? {
                                className: classes.changed_field
                              }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("address2")
                              ? {
                                className: classes.changed_field,
                                disableUnderline: true
                              }
                              : undefined
                          }
                          fullWidth
                          //@ts-ignore
                          optional
                          name={"address2"}
                          label={"Address 2 (optional)"}
                          value={values.mailingAddress2}
                          onChange={handleChange}
                          error={Boolean(
                            errors.mailingAddress2 && touched.mailingAddress2
                          )}
                          helperText={
                            touched.mailingAddress2 && errors.mailingAddress2
                              ? String(errors.mailingAddress2)
                              : null
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <TextField
                          inputProps={
                            changedFormKeys?.includes("city")
                              ? {
                                className: classes.changed_field
                              }
                              : undefined
                          }
                          InputProps={
                            changedFormKeys?.includes("city")
                              ? {
                                className: classes.changed_field,
                                disableUnderline: true
                              }
                              : undefined
                          }
                          fullWidth
                          name={"city"}
                          label={"City"}
                          value={values.city}
                          onChange={handleChange}
                          error={Boolean(errors.city && touched.city)}
                          helperText={
                            touched.city && errors.city
                              ? String(errors.city)
                              : null
                          }
                        />
                      </Grid>
                      {country === AddressCountry.CA ? (
                        <>
                          <Grid xs={6}>
                            {/*//@ts-ignore*/}
                            <HighlightedAutoComplete
                              inputProps={
                                changedFormKeys?.includes("province")
                                  ? {
                                    className: classes.changed_field,
                                  }
                                  : undefined
                              }
                              InputProps={
                                changedFormKeys?.includes("province")
                                  ? {
                                    className: classes.changed_field,
                                  }
                                  : undefined
                              }
                              label="Province"
                              fullWidth
                              name="province"
                              id="province"
                              onChange={(e: any, value: any) => {
                                setProvince(value.label);
                                setFieldValue("province", value.label);
                              }}
                              value={province}
                              error={Boolean(errors.province)}
                              helperText={
                                errors.province ? "Province required" : ""
                              }
                              touched={touched.province ? "true" : "false"}
                              options={CanadaProvinces}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <MaskedTextField
                              fullWidth
                              type={"POSTAL"}
                              label="Postal code"
                              name="potal_zip"
                              id="postal_zip"
                              onChange={handleChange}
                              value={values.postal_zip}
                              initialValue={values.postal_zip}
                              error={Boolean(errors.postal_zip)}
                              helperText={errors.postal_zip}
                              touched={touched.postal_zip ? "true" : "false"}
                              inputProps={{
                                maxLength: 10,
                                ...(changedFormKeys?.includes("postal_zip")
                                  ? {
                                    className: classes.changed_field
                                  }
                                  : {})
                              }}
                              InputProps={
                                changedFormKeys?.includes("postal_zip")
                                  ? {
                                    className: classes.changed_field,
                                    disableUnderline: true
                                  }
                                  : undefined
                              }
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={6}>
                            {/*//@ts-ignore*/}
                            <HighlightedAutoComplete
                              label="State"
                              fullWidth
                              name="province"
                              id="province"
                              onChange={(e: any, value: any) => {
                                setProvince(value.label);
                                setFieldValue("province", value.label);
                              }}
                              value={province}
                              error={Boolean(errors.province)}
                              helperText={
                                errors.province ? "State required" : ""
                              }
                              touched={
                                touched.province && errors.province
                                  ? "true"
                                  : "false"
                              }
                              options={UsStates}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <MaskedTextField
                              fullWidth
                              type={"ZIP"}
                              label="Zip code"
                              name="postal_zip"
                              id="postal_zip"
                              onChange={handleChange}
                              value={values.postal_zip}
                              initialValue={values.postal_zip}
                              helperText={errors.postal_zip}
                              error={Boolean(errors.postal_zip)}
                              touched={touched.postal_zip ? "true" : "false"}
                              inputProps={{
                                maxLength: 10,
                                ...(changedFormKeys?.includes("postal_zip")
                                  ? {
                                    className: classes.changed_field
                                  }
                                  : {})
                              }}
                              InputProps={
                                changedFormKeys?.includes("postal_zip")
                                  ? {
                                    className: classes.changed_field,
                                    disableUnderline: true
                                  }
                                  : undefined
                              }
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  <Grid xs={12}>
                    <p className={typographyClasses.overline_bold}>
                      ADDITIONAL INFORMATION
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <MaskedTextField
                      type={"PHONE"}
                      fullWidth
                      name={"phone"}
                      label={"Phone number"}
                      value={values.phone}
                      onChange={handleChange}
                      error={Boolean(errors.phone && touched.phone)}
                      helperText={
                        touched.phone && errors.phone
                          ? String(errors.phone)
                          : null
                      }
                      inputProps={{
                        maxLength: 10,
                        ...(changedFormKeys?.includes("phone")
                          ? {
                            className: classes.changed_field
                          }
                          : {})
                      }}
                      InputProps={
                        changedFormKeys?.includes("phone")
                          ? {
                            className: classes.changed_field,
                            disableUnderline: true
                          }
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid xs={12}>
                    {/*@ts-ignore*/}
                    <HighlightedAutoComplete
                      fullWidth
                      options={ownerTenant}
                      name={"is_owner"}
                      label={"Owner or tenant?"}
                      value={values.is_owner}
                      onChange={(e: any, value: any) => {
                        setFieldValue("is_owner", value.label);
                      }}
                      error={Boolean(errors.is_owner && touched.is_owner)}
                      helperText={
                        touched.is_owner && errors.is_owner
                          ? String(errors.is_owner)
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                size={"small"}
                variant={"outlined"}
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty}
                size={"small"}
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  if (formikRef.current) {
                    //@ts-ignore
                    formikRef.current.handleSubmit();
                  }
                }}
              >
                Save changes
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default FormModal;
