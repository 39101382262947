import React from "react";
import DataTable from "../../../../../../components/ui-kit/data-table";

const fields = [
  { id: "code", label: "Code", disableSort: true },
  { id: "description", label: "Description", disableSort: true },
];

const data = {
  results: [
    { code: "W01", description: "Water Consumption - Residential" },
    { code: "G01", description: "Solid Waste - Residential" },
    { code: "S11", description: "Wastewater Usage - Residential" },
    { code: "S15", description: "Wastewater Basic Fee - Residential" },
    { code: "W15", description: "Water Basic Fee- Residential" },
  ],
};
const CodeTable = () => {
  return <DataTable fields={fields} data={data} disablePagination />;
};

export default CodeTable;
