import React from "react";
import styled from "@emotion/styled";
import Modal from "../ui-kit/Modal";
import Button from "@mui/material/Button";
import { Box, useTheme } from "@mui/material";
import Typography from "../ui-kit/typography";

const CancelModal = styled(Modal)`
  && {
    max-width: 400px;
  }

  .MuiDialog-paperScrollPaper {
    padding: 2rem 2rem 1rem 2rem;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const RemoveButton = styled(Button)`
  &&:hover {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED_BACKGROUND};
  }

  && .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.palette.nonPalette.RED};
  }
`;

/**
 * Modal that allows a user to cancel a licence
 * @param open
 * @param onClose
 * @param licenceType
 * @param onCancel
 * @returns {JSX.Element}
 * @constructor
 */
export default function CancelLicenceModal({
  open,
  onClose,
  licenceType = "licence",
  onCancel,
}) {
  const theme = useTheme();

  return (
    <CancelModal open={open} fullWidth={false}>
      <Typography
        variant={"body1"}
        fontWeight={"700"}
        style={{ paddingBottom: 5 }}
      >
        Cancelling {licenceType}
      </Typography>
      <Typography
        variant={"body2"}
        style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
      >
        Are you sure you want to cancel your {licenceType}? This cannot be
        undone.
      </Typography>
      <Box style={{ display: "flex", justifyContent: "right" }} mt={1}>
        <Button
          variant={"text"}
          onClick={() => {
            onClose();
          }}
        >
          <Typography fontWeight={"700"} color={theme.palette.primary[500]}>
            Back
          </Typography>
        </Button>
        <RemoveButton
          variant={"text"}
          onClick={() => {
            onCancel();
            onClose();
          }}
        >
          <Typography color={"red"} fontWeight={"700"}>
            Cancel
          </Typography>
        </RemoveButton>
      </Box>
    </CancelModal>
  );
}
