import React from "react";
import styled from "@emotion/styled";
import IconBase from "./IconBase";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

const AlertContainer = styled.div`
  ${({ width }) => {
    switch (width) {
      case width:
        return `
        width: ${width};
      `;
      default:
        return `
          width: 340px;
          `;
    }
  }}
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px !important;
}
`;

const StyledAlert = styled.div`
  display: flex;
  width: 100% !important;
`;

const AlertButton = styled(Button)`
  margin-left: auto !important;
  height: min-content;

  :hover {
    background-color: ${({ theme }) => theme.palette.whites.WHITE} !important;
  }
`;

const Alerts = ({
  variant,
  iconName = undefined,
  iconColor = undefined,
  title,
  body,
  buttonTitle = "",
  buttonIcon = undefined,
  buttonOnClick = undefined,
  width = undefined,
}) => {
  const theme = useTheme();
  let backgroundColor;
  if (variant === "warning") {
    backgroundColor = theme.palette.nonPalette.ORANGE_BACKGROUND;
  } else if (variant === "error") {
    backgroundColor = theme.palette.nonPalette.RED_BACKGROUND;
  } else if (variant === "success") {
    backgroundColor = theme.palette.nonPalette.GREEN_BACKGROUND;
  } else {
    backgroundColor = theme.palette.nonPalette.BLUE_BACKGROUND;
  }
  return (
    <AlertContainer
      backgroundColor={backgroundColor}
      variant={variant}
      width={width}
    >
      <StyledAlert>
        <IconBase
          size={"22px"}
          fill={1}
          iconName={
            iconName
              ? iconName
              : variant === "warning" || variant === "error"
              ? "warning"
              : variant === "success"
              ? "check_circle"
              : variant === "info"
              ? "info"
              : null
          }
          style={{
            marginRight: "0.5rem",
            marginTop: "auto",
            marginBottom: "auto",
            color: iconColor
              ? iconColor
              : variant === "warning"
              ? theme.palette.nonPalette.ORANGE
              : variant === "error"
              ? theme.palette.nonPalette.RED
              : variant === "success"
              ? theme.palette.nonPalette.GREEN
              : variant === "info"
              ? theme.palette.nonPalette.BLUE
              : null,
          }}
        />
        <Typography
          fontWeight="500"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            maxWidth: "70%",
            wordWrap: "break-word",
          }}
        >
          {title}
        </Typography>
        {buttonTitle && (
          <AlertButton
            variant="outlined"
            size="small"
            endIcon={
              <IconBase
                fill={1}
                iconName={buttonIcon}
                color={theme.palette.primary[500]}
              />
            }
            onClick={() => {
              buttonOnClick();
            }}
          >
            <Typography
              style={{
                maxWidth: "150px",
                wordWrap: "break-word",
                textTransform: "none",
                color: theme.palette.blacks.BLACK_HIGH_EMPHASIS,
              }}
            >
              {buttonTitle}
            </Typography>
          </AlertButton>
        )}
      </StyledAlert>
      <Typography
        variant={"body2"}
        style={{
          marginTop: "0.5rem",
          marginLeft: "2rem",
          color: theme.palette.primary[700],
        }}
      >
        {body}
      </Typography>
    </AlertContainer>
  );
};

export default Alerts;
