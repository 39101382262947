import React, { useContext } from "react";
import Modal from "../ui-kit/Modal";
import Divider from "../ui-kit/Divider";
import Typography from "@mui/material/Typography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AuthContext } from "../../contexts/AuthContext";
import { userLogout } from "../../../core/apis/user";
import { EditingContext } from "../../contexts/EditingContext";
import { useHistory } from "react-router-dom";
import { routes } from "../../routes/routesData";
import IconBase from "../ui-kit/IconBase";

const preferencesTabs = [
  { label: "EDIT PROFILE", iconName: "account_circle" },
  { label: "Index", iconName: "vpn_key" },
];

const PreferencesModal = ({ onClose, ...rest }) => {
  const { logout } = useContext(AuthContext);
  const { handleClickWrapper } = useContext(EditingContext);
  const history = useHistory();

  const logoutRedirect = async () => {
    await onClose();
    const token = await logout();
    await userLogout(token);
    history.push(routes.LOGIN.path);
  };

  return (
    <Modal onClose={onClose} title="Preferences" {...rest}>
      <div style={{ width: "300px" }}>
        <List>
          {preferencesTabs.map((tab, index) => (
            <ListItem button key={index} disabled style={{ width: "300px" }}>
              <ListItemIcon>
                <IconBase iconName={tab.iconName} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="button">{tab.label}</Typography>}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <ListItem
          button
          onClick={() => {
            handleClickWrapper(logoutRedirect);
          }}
        >
          <ListItemIcon>
            <IconBase iconName="logout" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="button">Log out</Typography>}
          />
        </ListItem>
      </div>
    </Modal>
  );
};
export default PreferencesModal;
