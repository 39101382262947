import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactComponent as Dot } from "../../../assets/icons/stepperIcons/2.svg";
import currentDot from "../../../assets/icons/stepperIcons/current.svg";
import IconBase from "./IconBase";
import { Box, useTheme } from "@mui/material";

const StepContainer = styled.div`
  align-items: center;
  display: flex;

  justify-content: space-between;
`;

const IconsContainer = styled.div`
  padding-right: 10px;
`;

const MaxWidthWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  max-width: ${({ maxwidth }: any) => (maxwidth ? maxwidth : "480px")};

  margin: 0 auto;
`;

const IconWrapper = styled.div`
  display: inline-block;
  ${({ inactive }: any) => inactive && "opacity: 0.25;"}
`;

const BackButton = styled.div`
  && {
    padding-top: 4px;
  }

  & .material-symbols-rounded {
    cursor: pointer;
    font-size: 22px;
    opacity: 0.4;
  }
`;

const StepIconContainer = styled.div`
  margin: 0 0.2rem;
`;

const CurrentStep = styled.img``;

const Step = styled.div`
  circle {
    fill: ${(props) => props.color};
  }
`;

const IconBox = ({ iconName, ...rest }: any) => {
  const { isCurrentStep, theme } = rest;
  const dotColor = theme.palette.blacks.BLACK_MEDIUM_EMPHASIS;
  return (
    <StepIconContainer>
      {isCurrentStep ? (
        <CurrentStep src={currentDot} alt={"current step"} />
      ) : (
        <Step aria-label={"step"} color={dotColor}>
          <Dot />
        </Step>
      )}
    </StepIconContainer>
  );
};

/**
 * The step navigator keeps track of multistep forms and updates the ui to show the current and next steps. User can also go back to previously completed steps
 * @param steps
 * @param currentIndex
 * @param onClick
 * @param maxWidth
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
export default function StepNavigator({
  steps = [],
  currentIndex,
  onClick,
  maxWidth = "480px",
  className,
}: any) {
  const currentStepNumber = `${Number(currentIndex) + 1}/${steps.length}`;
  const theme = useTheme();

  const handleBackButton = () => {
    onClick(currentIndex - 1);
  };

  return (
    <StepContainer className={className}>
      {/* @ts-ignore */}
      <MaxWidthWrapper maxwidth={maxWidth}>
        <Box
          px={1}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 4,
          }}
        >
          {currentIndex !== 0 && (
            <BackButton>
              <IconBase
                onClick={handleBackButton}
                style={{ marginRight: 5 }}
                iconName={"arrow_back"}
              />
            </BackButton>
          )}
          <Typography variant={"overline"}>step {currentStepNumber}</Typography>
        </Box>
        <IconsContainer>
          {steps.map(({ id, iconName }: any, index: number) => {
            const isCurrentStep = index === currentIndex;
            const isCompletedStep = index < currentIndex;
            //const isFirstStep = index === 0;
            return (
              <IconWrapper
                key={id}
                // @ts-ignore
                inactive={!isCurrentStep && !isCompletedStep}
              >
                <IconBox
                  iconName={iconName}
                  completed={isCompletedStep}
                  isCurrentStep={isCurrentStep}
                  theme={theme}
                />
              </IconWrapper>
            );
          })}
        </IconsContainer>
      </MaxWidthWrapper>
    </StepContainer>
  );
}
