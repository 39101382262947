import React from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconBase from "../IconBase";
import TextField from "../TextField";

const StyledArrowIcon = styled(KeyboardArrowDownIcon)`
  && {
    color: ${({ theme }) => theme.textColor};
  }
`;

export default function Select({
  placeholder = "",
  options = [],
  SelectProps = {},
  ...rest
}) {
  return (
    <TextField
      {...rest}
      select
      SelectProps={{
        ...SelectProps,
        IconComponent: StyledArrowIcon,
        displayEmpty: true,
      }}
    >
      {options.map(({ id, value, label, icon }) => (
        <MenuItem key={value} value={value}>
          {icon !== undefined ? (
            <IconBase iconName={icon} style={{ paddingRight: "10px" }} />
          ) : (
            <></>
          )}
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
