import React, { useCallback } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, createFilterOptions, useTheme } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { BYLAW } from "../../../core/constants/autocompleteTypes";
import IconBase from "./IconBase";
import Tooltip from "@mui/material/Tooltip";

/**
 * The highlighted version of the auto complete component.
 * Make sure that a label is supplied for the options, otherwise it
 * will throw an error.
 *
 * Options that have a type property, will be given a prefix that
 * corresponds to the type provided.
 *
 * WARNING - Formik's handleChange function won't work on the auto
 * complete component. Please use setFieldValue to modify the value
 * and pass the function to this component's onChange prop
 *
 * @param value
 * @param freeSolo
 * @param onChange
 * @param onSubmit
 * @param onClear
 * @param options
 * @param RenderComponent
 * @param hideExpandIcon
 * @param tooltipTitle
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function HighlightedAutoComplete({
  value,
  freeSolo = false,
  onChange,
  onSubmit,
  onClear,
  options,
  RenderComponent,
  hideExpandIcon,
  tooltipTitle,
  ...props
}) {
  const theme = useTheme();
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label,
  });

  /**
   * Adds a prefix to the options that have a "type" property
   * @param type
   * @returns {string|null}
   */
  const getPrefix = useCallback((type) => {
    switch (type) {
      case BYLAW:
        return "Bylaw: ";
      default:
        return null;
    }
  }, []);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      disableClearable={!onClear}
      autoHighlight={true}
      value={value}
      filterOptions={filterOptions}
      onChange={onChange}
      onInputChange={(e, value, reason) => {
        if (reason === "clear") {
          onClear();
        } else if (reason !== "reset"){
          onChange(e, value);
        }
      }}
      renderInput={(params) =>
        RenderComponent ? (
          <Tooltip title={tooltipTitle}>
            <div>
              <RenderComponent
                variant={props.variant}
                {...props}
                {...params}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(e);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  ...props.InputProps,
                }}
              />
            </div>
          </Tooltip>
        ) : (
          <TextField
            {...props}
            {...params}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
              disableUnderline: true,
            }}
          />
        )
      }
      noOptionsText={"No results. Try typing something else."}
      options={options}
      renderOption={(props, option, { inputValue }) => {
        if (!option.label) {
          throw new Error("options require a label. (option.label !== null)");
        }

        const matches = match(option.label, inputValue);
        const parts = parse(option.label, matches);

        const prefix = getPrefix(option.type);

        return (
          <li {...props}>
            <div>
              {prefix && (
                <span
                  style={{ color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS }}
                >
                  {prefix}
                </span>
              )}
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
      clearIcon={<IconBase iconName={"close"} />}
      popupIcon={hideExpandIcon ? null : <IconBase iconName={"expand_more"} />}
    />
  );
}
