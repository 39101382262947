import styled from "@emotion/styled";

const defaultPadding = "0 0 0 0";
const defaultMargin = "0 0 0 0";

const StyledIcon = styled.span`
  &.material-symbols-rounded {
    font-variation-settings: "FILL" ${({ fill }) => fill},
      "wght" ${({ wght }) => wght}, "GRAD" ${({ grad }) => grad},
      "opsz" ${({ opsz }) => opsz};
    color: ${({ color, theme }) => color || theme.palette.primary[500]};
    font-weight: ${({ fontWeight }) => fontWeight || "normal"};
    margin: ${({ margin }) => margin || defaultMargin};
    padding: ${({ padding }) => padding || defaultPadding};
    font-size: ${({ size }) => `${size}px` || "24px"};

    :hover {
      ${({ hoverColor }) =>
        hoverColor && `color: ${hoverColor}; cursor: pointer;`};
    }
  }
`;

/**
 * Wrap a symbol name with this component to retrieve a material symbol
 * @param className
 * @param iconName
 * @param fill
 * @param wght
 * @param grad
 * @param opsz
 * @param size - Make sure this is a valid unit ie: px, rem, etc
 * @param color
 * @param hoverColor
 * @param props
 * @returns {JSX.Element}
 */
const IconBase = ({
  className = "",
  iconName = "",
  fill = 0,
  wght = 600,
  grad = 0,
  opsz = 48,
  size = 24,
  color = "",
  hoverColor = "",
  ...props
}) => {
  return (
    <StyledIcon
      className={"material-symbols-rounded"}
      fill={fill}
      wght={wght}
      grad={grad}
      opsz={opsz}
      size={size}
      hoverColor={hoverColor}
      color={color}
      {...props}
    >
      {iconName}
    </StyledIcon>
  );
};

export default IconBase;
