import React from "react";
import moment from "moment";
import FileDownload from "js-file-download";
import LinkButton from "../../../../components/ui-kit/link-button";
import { currency, truncate } from "../../../../../utilities";
import { GREY_DARK } from "../../../../../mui-theme/theme";
import { ISSUED } from "../../../../../core/constants/licences";
import { OPEN, VOID } from "../../../../../core/constants/invoices";
import { statusFormat } from "../../../../../utilities/data-table-format-helpers";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { css } from "@mui/material";
import { getLicenceInvoicePDF } from "../../../../../core/apis/licence";

export const StyledButton = styled(Button)(
  () => css`
    background-color: white;
  `
);

export const fields = [
  {
    id: "id",
    hideFromTable: true,
  },
  {
    id: "pdf_link",
    label: "Invoice",
    disableSort: true,
    format: (val, { license_id, invoice_number }) => (
      <LinkButton
        tooltip={val}
        color={"blue"}
        onClick={async (e) => {
          const invoice = await getLicenceInvoicePDF(
            license_id,
            invoice_number
          );
          if (invoice) {
            FileDownload(invoice, val, "application/pdf");
          }
          e.stopPropagation();
        }}
      >
        {truncate(val, 35)}
      </LinkButton>
    ),
  },
  {
    id: "created_at",
    label: "Invoice Date",
    disableSort: true,
    format: (val) => moment(val).format("MMM DD, YYYY"),
  },
  {
    id: "status",
    label: "Status",
    disableSort: true,
    format: statusFormat,
  },
  {
    id: "actions",
    label: "actions",
    disableSort: true,
    format: (val, data, loading, obj) => {
      const { handleOpen, licenceStatus } = obj || {};
      return (
        <>
          {data?.status !== OPEN || licenceStatus === ISSUED ? (
            <></>
          ) : (
            <StyledButton
              onClick={(e) => {
                e.stopPropagation();
                handleOpen?.("void", { id: data.id });
              }}
              variant={"outlined"}
              size={"small"}
            >
              Mark as void
            </StyledButton>
          )}
        </>
      );
    },
  },
  {
    id: "amount_due",
    label: "Amount",
    disableSort: true,
    align: "right",
    format: (val, data) => {
      let value = currency(val);
      if (data?.status === VOID) {
        value = <s style={{ color: GREY_DARK }}>{value}</s>;
      }
      return value;
    },
  },
];
