import classes from "../index.module.scss";
import React, { useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import Spacer from "../../../../../../../components/ui-kit/Spacer";

const ServiceItem = ({ service }: any) => {
  const theme = useTheme();
  const [showOriginal, setShowOriginal] = useState(false);

  const originalValueClass = useMemo(() => {
    return service.originalValue ? classes.original_background : null;
  }, [service.originalValue]);

  return (
    <>
      <div className={`${classes.item} ${originalValueClass}`}>
        <p className={classes.label}>{service.label}</p>
        <p className={classes.value}>{service.value}</p>
        {service.originalValue !== undefined && (
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 1,
              marginBottom: "0.1rem",
              marginTop: "0.1rem",
            }}
            sx={{
              "&:hover": {
                color: theme.palette.primary[500],
                cursor: "pointer",
              },
            }}
            color={theme.palette.primary[200]}
            onClick={() => {
              setShowOriginal(!showOriginal);
            }}
          >
            {showOriginal ? "Hide original" : "View original"}
          </Typography>
        )}
        {showOriginal && (
          <>
            <Spacer amount={0.5} />
            <Typography
              variant={"caption"}
              color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
            >
              Original
            </Typography>
            <Typography color={theme.palette.primary[700]} variant="body1">
              {service.originalValue || "-"}
            </Typography>
          </>
        )}
      </div>
    </>
  );
};

export default ServiceItem;
