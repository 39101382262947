import React, { useState } from "react";
import IconBase from "./IconBase";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const AdminAvatar = ({
  id,
  firstName,
  lastName,
  styles,
  updatable,
  hoverColor,
  color,
}) => {
  const [hover, setHover] = useState(false);

  const getUpperCase = (str) => {
    try {
      return str[0].toUpperCase();
    } catch (e) {
      return "-";
    }
  };

  return (
    <Tooltip title={firstName + " " + lastName}>
      <Avatar
        key={id}
        className={`admin-avatar ${!!hoverColor ? "has-hover-color" : ""}`}
        style={{ "--hover-color": `${hoverColor}`, ...styles }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        hovercolor={hoverColor}
      >
        {updatable ? (
          hover ? (
            <IconBase iconName="sync" color={color ? color : "inherit"} />
          ) : (
            getUpperCase(firstName) + getUpperCase(lastName)
          )
        ) : (
          getUpperCase(firstName) + getUpperCase(lastName)
        )}
      </Avatar>
    </Tooltip>
  );
};

export default AdminAvatar;
