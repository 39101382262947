import { AxiosRequestConfig } from "axios";
import { getGlobal } from "../../utilities";

/**
 * Module for configuring the API location and the 2FA behaviour
 *
 * current config object:
 * {
 *   baseURL: string (the prefix for all API calls)
 *   skip@FA: boolean (whether to skip asking for a passcode when logging in)
 * }
 */

export interface RequestConfig extends AxiosRequestConfig {
  crossDomain?: boolean;
}

const setupApiUrl = () => {
  const currentURL = new URL(window.location.href);
  return `${currentURL.protocol}//${currentURL.host}/v1`;
};

const getConfig = (stage: any) => {
  switch (stage) {
    case "local":
      return {
        baseURL: "http://localhost:4001/v1",
        skip2FA: true,
        applicantDeclarationId: "e30e6edf-1d5b-4c13-83cc-d8011707c184",
      };
    default:
      return {
        baseURL: getGlobal("API_URL") || setupApiUrl(),
        skip2FA: getGlobal("SKIP_2FA") || false,
        applicantDeclarationId:
          getGlobal("APPLICANT_DECLARATION_ID") ||
          "e30e6edf-1d5b-4c13-83cc-d8011707c184",
      };
  }
};

const config = getConfig(process.env.REACT_APP_STAGE);

export default config;
