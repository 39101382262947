import React, { useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import Typography from "../../../../components/ui-kit/typography";
import HistoryTab from "../../licence-details/history-tab";
import ListItem from "../../../../components/ui-kit/list-item";
import IconBase from "../../../../components/ui-kit/IconBase";
import DetailsContainer from "../../../my-city-hall/utilities/utility-details/widgets/details-container";
import Spacer from "../../../../components/ui-kit/Spacer";
import typographyClasses from "../../../../../assets/styles/typography/typography.module.scss";
import BarGraph from "../../../../components/ui-kit/bar-graph";
import CodeTable from "../../../my-city-hall/utilities/utility-details/widgets/code-table";
import classes from "./index.module.scss";
import { ModalUnlink } from "../../../../components/modals/modal-decline";
import AccountBar from "../../../../components/ui-kit/account-bar";
import LinkUserToAccountModal from "../../../../components/modals/link-user-to-account-modal";
import AccountFormModal from "../../../my-city-hall/utilities/utility-details/widgets/account-form-modal";
import { AddressCountry } from "../../../../../core/constants";

const accountDetailsItems = [
  {
    id: 1,
    name: "account_number",
    label: "Account number",
    value: "010 0024950 001",
    originalValue: undefined,
    icon: "tag",
    validation: "",
  },
  {
    id: 2,
    name: "access_code",
    label: "Access Code/PIN",
    value: "37946",
    originalValue: "1212312",
    icon: "done",
    validation: "",
  },
  {
    id: 3,
    name: "batch_number",
    label: "Batch Number",
    value: "2022123105",
    originalValue: undefined,
    icon: "folder",
    validation: "",
  },
  {
    id: 4,
    name: "account _holder",
    label: "Account Holder",
    value: "Clark, Audrey G.",
    originalValue: undefined,
    icon: "person",
    validation: "",
  },
  {
    id: 5,
    name: "service_address",
    label: "Service Address",
    value: "5432 56 Avenue\nLacombe, AB  T4L 1E9",
    originalValue: undefined,
    icon: "location_on",
    validation: "",
  },
  {
    id: 6,
    name: "mailing_address",
    label: "Mailing Address",
    value: "5432 56 Avenue\nLacombe, AB  T4L 1E9",
    originalValue: undefined,
    icon: "forward_to_inbox",
    validation: "",
  },
  {
    id: 7,
    name: "email",
    label: "Email",
    value: "testing@gmail.com",
    originalValue: "",
    icon: "alternate_email",
    validation: "",
  },
  {
    id: 8,
    name: "phone",
    label: "Phone Number",
    value: "604-555-1234",
    originalValue: undefined,
    icon: "call",
    validation: "",
  },
  {
    id: 9,
    name: "is_owner",
    label: "Owner or tenant?",
    value: "I’m the owner",
    originalValue: undefined,
    icon: "home",
    validation: "",
  },
];

const contactItems = [
  {
    first_name: "Hamid",
    last_name: "Alaei",
    email: "Hamid.alaei@gmail.com",
    phone: "604-555-1234",
    e_bill: false,
  },
];

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const accountDetailsFakeData = {
  first_name: "Audrey",
  last_name: "Kirby",
  country: AddressCountry.CA,
  mailing_address: "5773 123st",
  city: "Surrey",
  province: "Alberta",
  code: "K3S 8M2",
  phone: "778-361-2200",
  is_owner: "I’m the owner",
  mailing_same_as_service_address: false,
};

const UtilityInfoTab = ({
  fetchHistories,
  allHistory,
  addComment,
  data,
  setTab,
  loading,
}) => {
  const [isUnlinkUserModalOpen, setIsUnlinkUserModalOpen] = useState(false);
  const [isLinkUserModalOpen, setIsLinkUserModalOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [barGraphData] = useState([0, 2.0, 3.2]);
  const [refresher, setRefresher] = useState(false);

  const handleUnlinkingUser = (email) => {
    setIsUnlinkUserModalOpen(true);
  };

  const onSubmitChanges = (values, resetForm) => {
    console.log(values);
    setIsEditDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={7}>
        <Grid item xs={5}>
          <DetailsContainer
            loading={loading}
            title={"Account Details"}
            isEditable={true}
            buttonLabel={"Edit details"}
            buttonOnClick={() => setIsEditDialogOpen(true)}
          >
            {accountDetailsItems.map((item) => {
              return (
                <ListItem
                  key={item.id}
                  variant="detail"
                  label={item.label}
                  subLabel={item.value}
                  originalValue={
                    item?.originalValue ? item.originalValue : undefined
                  }
                  startOrnament={<IconBase iconName={item.icon} size={24} />}
                />
              );
            })}
          </DetailsContainer>
          <Spacer />
          {!!contactItems && (
            <DetailsContainer
              loading={loading}
              title={"Other Contacts"}
              isEditable={false}
            >
              {contactItems.map((item) => {
                return (
                  <ListItem
                    key={item.email}
                    label={`${item.first_name} ${item.last_name}`}
                    subLabel={[item.email, item.phone]}
                    startOrnament={<IconBase iconName={"person"} size={24} />}
                  />
                );
              })}
            </DetailsContainer>
          )}
        </Grid>
        <Grid item xs={7}>
          <AccountBar
            hideLinkButton={data?.email === null}
            variant={data?.email === null ? "LINK" : "USER"}
            name={data?.email === null ? "Link to account..." : data?.email}
            isLinked={data?.email !== null}
            onUnlink={() => {
              handleUnlinkingUser(data?.email);
            }}
            onBarClick={
              data?.email !== null
                ? () => {}
                : () => setIsLinkUserModalOpen(true)
            }
            disableBarClick={data?.email !== null}
          />
          <div className={classes.margin_bottom} />
          {!loading ? (
            <>
              {barGraphData ? (
                <>
                  <p
                    className={`${typographyClasses.h2} ${classes.margin_bottom}`}
                  >
                    Consumption History
                  </p>
                  <BarGraph
                    data={barGraphData}
                    title={"VOLUME (CM)"}
                    labels={labels}
                    bottomTitle={"12 Months (2023)"}
                  />
                  <Spacer />
                </>
              ) : null}
              <p className={typographyClasses.h2}>Service Codes</p>
              <CodeTable />
            </>
          ) : (
            <Skeleton
              variant={"rectangular"}
              style={{ borderRadius: 10 }}
              height={290}
            />
          )}
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "1rem", paddingTop: "20px" }}>
          <Typography
            variant="h4"
            fontWeight={400}
            style={{ marginBottom: "1.5rem", marginTop: "2rem" }}
            id={"__history-section"}
          >
            History and notes
          </Typography>
          <HistoryTab
            fetchHistories={fetchHistories}
            maxToShow={2}
            history={allHistory}
            _addComment={addComment}
            data={data}
            setTab={setTab}
          />
        </Grid>
      </Grid>
      <AccountFormModal
        open={isEditDialogOpen}
        handleClose={() => setIsEditDialogOpen(false)}
        onSubmit={onSubmitChanges}
        values={accountDetailsFakeData}
        adminSide={true}
        showReviewAlert={true}
        changedFormKeys={[
          "phone",
          "code",
          "first_name",
          "province",
          "mailing_same_as_service_address",
        ]}
      />
      <ModalUnlink
        data={data}
        onDecline={handleUnlinkingUser}
        open={isUnlinkUserModalOpen}
        userName={data?.email}
        handleClose={() => {
          setIsUnlinkUserModalOpen(false);
        }}
        message={`${data?.email}`}
        isUtilityAccount={true}
      />
      <LinkUserToAccountModal
        isUtilityAccount={true}
        accessCode={37946}
        accountNumber={"010 0024950 001"}
        serviceAddress={"5432 56 Avenue, Lacombe, AB  T4L 1E9"}
        open={isLinkUserModalOpen}
        handleClose={() => {
          setIsLinkUserModalOpen(false);
        }}
        data={data}
        refresher={setRefresher}
        refreshValue={refresher}
      />
    </div>
  );
};

export default UtilityInfoTab;
