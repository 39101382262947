import React, { useState } from "react";
import {
  Chip,
  ListItemAvatar,
  ListItemSecondaryAction,
  useTheme,
} from "@mui/material";
import { BLACK_MEDIUM_EMPHASIS, PRIMARY_500 } from "../../../mui-theme/theme";
import {
  add_issuer,
  add_reviewer,
  remove_issuer,
  remove_reviewer,
} from "../../../core/apis/licence";
import { get_admins } from "../../../core/apis/admin";
import AdminAvatar from "./AdminAvatar";
import SearchDropDown from "./SearchDropDown";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import styled from "@emotion/styled";
import ListItemText from "@mui/material/ListItemText";
import { Flag } from "@mui/icons-material";
import ActionCard from "./ActionCard";
import { formatDateTime, generateAvatarColor } from "../../../utilities";
import { ActionItemTypes } from "../../../core/constants/enums";
import Button from "@mui/material/Button";
import IconBase from "./IconBase";

const StyledAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.palette.primary[50]};
  color: ${({ theme }) => theme.palette.primary[200]};

  &&:hover {
    background: ${({ theme }) => theme.palette.primary[200]};
    color: ${({ theme }) => theme.palette.whites.WHITE};
  }
`;

const H5 = styled.span(({ theme, fontWeight }) => ({
  ...theme.typography.h5,
  marginTop: "-5px",
  fontWeight: fontWeight,
}));

const Body1 = styled.span(({ theme }) => ({
  ...theme.typography.body1,
  display: "inline-block",
  color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
  marginBottom: "1rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: 230,
  whiteSpace: "noWrap",
}));

const ButtonUnassigned = styled(Button)`
  :hover {
    color: ${({ theme }) => theme.palette.nonPalette.RED} !important;
    background: ${({ theme }) =>
      theme.palette.nonPalette.RED_BACKGROUND} !important;
  }
`;

const ActionListItem = ({
  itemKey,
  action,
  onClickRow,
  disabled,
  state,
  loadAdmins,
  user,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const assignAdmin = async (action, values) => {
    // await assign_action(action.id, values.id);
    await add_reviewer(values.id, action.licenseId);
    loadAdmins();
  };

  const handleAssignIssuer = async (action, values) => {
    await add_issuer(values.id, action.licenseId);
    loadAdmins();
  };

  const assignToME = async (action) => {
    // await assign_action_to_me(action.id);
    await add_reviewer(user.id, action.licenseId);
    loadAdmins();
  };

  const handleAssignIssuerToMe = async (action) => {
    await add_issuer(user.id, action.licenseId);
    loadAdmins();
  };

  const unassignAdmin = async (action) => {
    await remove_reviewer(action.actor.userId, action.licenseId);
    // await unassign_action(action.id, action.assignedAdminId);
    loadAdmins();
  };

  const onUnassignIssuer = async (action) => {
    await remove_issuer(action.actor.userId, action.licenseId);
    loadAdmins();
  };

  const fetchdata = async () => {
    const adminsRes = await get_admins();
    adminsRes.result.forEach(
      (admin) =>
        (admin.color = generateAvatarColor(admin.firstName + admin.lastName))
    );
    return adminsRes.result;
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  let admin = null;
  let name = null;
  if (Object.keys(action?.actor).length !== 0) {
    admin = action?.actor?.userName;
    name = admin?.split(/\s+/);
  }

  // Holder for the avatar component
  var avatar;

  if (admin) {
    // If and admin was assigned to the task avatar will be display
    avatar = (
      // <div onClick={disabled ? null : handleOpen}>
      <div>
        <AdminAvatar
          // updatable
          id={admin}
          firstName={name[0]}
          lastName={name[1]}
          styles={{
            background: PRIMARY_500,
            color: "white",
          }}
        />
        <SearchDropDown
          searchBarPlaceholder="admins"
          titleLabel="REQUIRED ROLES:"
          title={"Any role"}
          onSelect={(values) =>
            action.actionName === "Assign Issuer"
              ? handleAssignIssuer(action, values)
              : assignAdmin(action, values)
          }
          fetchData={fetchdata}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </div>
    );
  } else {
    // If the task is unassigned the "assign admin" avatar will display
    avatar = (
      <StyledAvatar>
        <IconButton
          onClick={disabled ? null : handleOpen}
          size="large"
          style={{ color: "inherit" }}
        >
          <IconBase iconName="add" color={"inherit"} wght="600" />
        </IconButton>
        <SearchDropDown
          searchBarPlaceholder="Search..."
          titleLabel="REQUIRED ROLES:"
          title={"Any role"}
          onSelect={(values) =>
            action.actionName === "Assign Issuer"
              ? handleAssignIssuer(action, values)
              : assignAdmin(action, values)
          }
          fetchData={fetchdata}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </StyledAvatar>
    );
  }
  if (state === "flagged") {
    // To show if the acount was flagged
    avatar = <Flag />;
  }

  return (
    <ActionCard
      key={itemKey}
      state={state}
      alignItems="flex-start"
      onClick={disabled ? null : () => onClickRow(action)}
    >
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography
              variant="overline"
              color={theme.palette.blacks.BLACK_LOW_EMPHASIS}
            >
              {formatDateTime(action.createdAt)}
            </Typography>
            <Typography
              variant="h5"
              style={{ marginTop: "-7px", marginBottom: "5px" }}
            >
              {action.actionName}
            </Typography>
          </>
        }
        secondary={
          <>
            <H5 fontWeight={action.status !== "IN_PROGRESS" ? 400 : 700}>
              {ActionItemTypes[action.actionType]}
            </H5>
            <Body1>{action.businessName ? action.businessName : "-"}</Body1>
          </>
        }
      />
      <ListItemSecondaryAction style={{ height: "100%", display: "flex" }}>
        {Object.keys(action?.actor).length === 0 ? (
          <Button
            size="small"
            style={{
              marginTop: "auto",
              marginBottom: "1rem",
            }}
            variant="outlined"
            onClick={
              disabled
                ? null
                : action.actionName === "Assign Issuer"
                ? async () => {
                    await handleAssignIssuerToMe(action);
                  }
                : async () => {
                    await assignToME(action);
                  }
            }
          >
            Assign to me
            <IconBase
              iconName="arrow_forward"
              size="16"
              style={{ marginLeft: "0.5rem" }}
            />
          </Button>
        ) : action.actor.status === "assigned" ? (
          <ButtonUnassigned
            size="small"
            style={{
              color: BLACK_MEDIUM_EMPHASIS,
              marginTop: "auto",
              marginBottom: "1rem",
            }}
            startIcon={
              <IconBase iconName={"arrow_back"} size={16} color="inherit" />
            }
            onClick={
              disabled
                ? null
                : action.actionName === "Issue/Decline Licence" ||
                  action.actionName === "Issue/Decline Changes"
                ? async () => {
                    await onUnassignIssuer(action);
                  }
                : async () => {
                    await unassignAdmin(action);
                  }
            }
          >
            Unassign
          </ButtonUnassigned>
        ) : action.actor.status === "approved" ||
          action.actor.status === "issued" ? (
          <Chip
            variant="active"
            label={"Done"}
            style={{
              marginTop: "auto",
              marginBottom: "1rem",
            }}
          />
        ) : (
          <Chip
            variant="active"
            label={"Declined"}
            style={{
              color: theme.palette.nonPalette.RED,
              background: theme.palette.nonPalette.RED_BACKGROUND,
              marginTop: "auto",
              marginBottom: "1rem",
            }}
          />
        )}
      </ListItemSecondaryAction>
    </ActionCard>
  );
};

export default ActionListItem;
