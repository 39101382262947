import React, { useMemo, useState } from "react";
import classes from "./index.module.scss";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { ModuleTypes } from "../../../../../core/constants/enums";
import {
  ACTIVE,
  APPROVED,
  DRAFT,
  ISSUED,
  REVIEWING,
  SAVED,
  SUBMITTED,
} from "../../../../../core/constants/licences";
import { showTimeAgo } from "../../../../../utilities/showTimeAgo";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import ModuleItemCard from "../module-item-card";
import { formatCurrency } from "../../../../../utilities";
import LoadingGif from "../../../../../assets/gifs/logo-loading-transparent.gif";
import { routes } from "../../../../routes/routesData";

const ModuleSectionCard = ({
  actions,
  actionsLabel,
  title,
  icon,
  backgroundImage,
  items,
  moduleType,
  loading,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAll, setShowAll] = useState(false);

  /**
   * Memo version of indicator for showing the "show More" section at the bottom of module items
   * @type {boolean}
   */
  const hasShowMore = useMemo(() => items?.length > 4, [items]);

  /**
   * Memo version of the indicator for when the popover is open (for drop down)
   * @type {boolean}
   */
  const isPopoverOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  /**
   * Opens the popover for showing the actions
   * @param event
   */
  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the popover
   */
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  /**
   * Navigates user to the licence screen:
   * - if the status is draft navigates user to form screen
   * - otherwise, navigates user to licence details screen
   * @param id
   * @param status
   */
  const navigateToLicenceDetailsScreen = (id, status = "") => {
    if (status !== DRAFT) {
      history.push(`business-licence/${id}`);
    } else {
      history.push(`business-licence/form/${id}`);
    }
  };

  const navigateToUtilityFullDetails = (id) => {
    history.push(`${routes.UTILITY_DETAILS.codePath + id}`);
  };

  /**
   * Navigates user to check out screen
   */
  const navigateToPayDues = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`/checkout?licence=${id}`, {
      id: null,
      licenceId: id,
      changesId: null,
    });
  };

  /**
   * navigates user to the utilities checkout scree
   * @param e
   * @param id
   */
  const navigateToUtilitiesPayDues = (e, id) => {
    // TODO: implement the logic
  };

  /**
   * Toggles the value of the [showAll] in inner state to its opposite boolean value
   */
  const toggleShowMore = () => {
    setShowAll((prevState) => !prevState);
  };

  /**
   * Memo version of the items as content of the module section card
   * @type {JSX.Element}
   */
  const renderChildren = useMemo(() => {
    return loading ? (
      <div
        style={{
          padding: "2rem",
        }}
      >
        <div className={"loading-container"}>
          <img src={LoadingGif} alt="Loading..." />
        </div>
      </div>
    ) : items?.length < 1 ? (
      <p className={classes.place_holder}>
        {moduleType === ModuleTypes.businessLicence
          ? "Add an existing business licence or create a new one here"
          : "Link your existing utilities account here"}
      </p>
    ) : (
      items?.map((e, index) => {
        const renderVoid = index > 3 && hasShowMore && !showAll;
        return renderVoid ? null : moduleType ===
          ModuleTypes.businessLicence ? (
          e.status === DRAFT || e.status === SAVED ? (
            <ModuleItemCard
              key={e.id}
              businessName={e.name}
              onClick={() => {
                navigateToLicenceDetailsScreen(e.id, e.status);
              }}
              description={`Application created ${showTimeAgo(e.createdAt)}`}
              id={e.id}
              isPaymentDue={false}
              canDiscard={true}
            />
          ) : e.status === SUBMITTED ||
            e.status === REVIEWING ||
            e.status === APPROVED ? (
            <ModuleItemCard
              key={e.id}
              businessName={e.name}
              onClick={() => {
                navigateToLicenceDetailsScreen(e.id);
              }}
              description={`Application submitted ${showTimeAgo(
                e.lastUpdated,
                false
              )} ago`}
              id={e.id}
              status={e.status.charAt(0).toUpperCase() + e.status.slice(1)}
              isPaymentDue={false}
              canDiscard={false}
            />
          ) : (
            <ModuleItemCard
              key={e.id}
              navigateToPayDues={(event) => navigateToPayDues(event, e.id)}
              businessName={e.name}
              onClick={() => {
                navigateToLicenceDetailsScreen(e.id);
              }}
              description={
                e.status === ISSUED
                  ? "Business licence issued"
                  : e.status === ACTIVE
                  ? `Licence: #${e.ref}`
                  : null
              }
              id={e.id}
              status={"Active"}
              isPaymentDue={e.balance > 0}
              balance={formatCurrency(e.balance)}
              canDiscard={false}
              autoRenewMessage={
                e.status === ACTIVE
                  ? `Auto-renews on ${moment(e.expiry)
                      .add(1, "d")
                      .format("MMM DD, YYYY")}`
                  : null
              }
            />
          )
        ) : (
          <ModuleItemCard
            key={e.id}
            id={e.id}
            description={e.address}
            balance={formatCurrency(e.balance)}
            isPaymentDue={e.balance > 0}
            navigateToPayDues={navigateToUtilitiesPayDues}
            onClick={() => navigateToUtilityFullDetails(e.id)}
            canDiscard={false}
            autoRenewMessage={
              !(e.balance > 0)
                ? `Statement Balance: ${formatCurrency(0)}`
                : null
            }
            status={!(e.balance > 0) ? "Active" : "PaymentNeeded"}
            businessName={`Account #${e.accountNumber}`}
            totalDueText={"Statement Balance: "}
          />
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, moduleType, loading, showAll, hasShowMore]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.background_image}>
          <img src={backgroundImage} alt="module background" />
        </div>
        <div className={classes.header}>
          <div className={classes.title_section}>
            {icon}
            <p className={classes.title}>{title}</p>
          </div>
          <div
            className={`${classes.drop_down} ${
              isPopoverOpen ? classes.open : ""
            }`}
            onClick={handlePopoverClick}
          >
            <p>{actionsLabel}</p>
            <KeyboardArrowDown />
          </div>
          {actions?.length > 0 && (
            <Popover
              className={"module-section-popover"}
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={handleClosePopOver}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {actions?.map((e) => (
                <div
                  className={"popover_option"}
                  onClick={e.onClick}
                  key={e.label}
                >
                  {e.icon}
                  <p>{e.label}</p>
                </div>
              ))}
            </Popover>
          )}
        </div>
        <div className={classes.content}>
          <>
            {renderChildren}
            {hasShowMore && (
              <div className={classes.show_more_container}>
                <div />
                <div
                  className={showAll ? classes.open : ""}
                  onClick={toggleShowMore}
                >
                  <p>
                    {showAll ? "Show less" : `Show ${items.length - 4} more`}
                  </p>
                  <KeyboardArrowDown />
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ModuleSectionCard;
