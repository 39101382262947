import React, { useContext, useState, useEffect } from "react";
import {
  validEmail,
  validFirst,
  validLast,
  validName,
  validPhone,
  validStrongPassword,
  validType,
} from "../../../../../utilities/yupValidators";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { FilterContext } from "../../../../contexts/FilterContext";
import { DataFormat, FilterType } from "../../../../../core/constants/enums";
import { statusFormat } from "../../../../../utilities/data-table-format-helpers";
import {
  create_user,
  get_users,
  get_users_export,
} from "../../../../../core/apis/user";
import CreateUserModal from "../../../../components/modals/CreateUserModal";
import DataTable from "../../../../components/ui-kit/data-table";
import { TableSettingContext } from "../../../../contexts/table-setting-context";

const fields = [
  {
    id: "fullName",
    label: "Name",
    disableSort: false,
    filterType: FilterType.SEARCH,
  },
  {
    id: "email",
    label: "Email",
    filterType: FilterType.SEARCH,
    disableSort: false,
    showUnderscore: true,
  },
  {
    id: "disabled",
    label: "Status",
    format: (val) => {
      return statusFormat(val ? "inactive" : "active");
    },
    disableSort: false,
    // filterType: "BOOLEAN",
    // options: [
    //   { label: "Inactive", value: "INACTIVE" },
    //   { label: "Active", value: "ACTIVE" },
    // ],
  },
  {
    id: "lastLoggedIn",
    label: "Last Logged In",
    format: DataFormat.DATE,
    filterType: FilterType.DATE_PICKER,
    disableSort: false,
  },
  {
    id: "createDateTime",
    label: "Created On",
    format: DataFormat.DATE,
    filterType: FilterType.DATE_PICKER,
    disableSort: false,
  },
];

const createFields = [
  {
    label: "User Type",
    id: "type",
    size: 6,
    type: "radio",
    validator: validType,
    values: [
      {
        label: "Citizen",
        value: "CITIZEN",
      },
      {
        label: "Business",
        value: "BUSINESS",
      },
    ],
  },
  {
    label: "Name",
    id: "name",
    size: 6,
    type: "text",
    validator: validName,
    show: (formValues) => formValues.type === "BUSINESS",
  },
  {
    label: "First Name",
    id: "firstName",
    size: 6,
    type: "text",
    validator: validFirst,
    required: (formValues) => formValues.type === "CITIZEN",
  },
  {
    label: "Last Name",
    id: "lastName",
    size: 6,
    type: "text",
    validator: validLast,
    required: (formValues) => formValues.type === "CITIZEN",
  },
  {
    label: "Email",
    id: "email",
    size: 12,
    type: "email",
    validator: validEmail,
  },
  {
    label: "Phone Number",
    id: "phone",
    size: 12,
    type: "phone",
    validator: validPhone,
  },
  {
    label: "Password",
    id: "password",
    size: 12,
    type: "password",
    validator: validStrongPassword,
  },
];

const initialValues = {
  type: "CITIZEN",
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
};

const Users = (...props) => {
  const [tableIdentifier] = useState("userList");
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [previousQueryOptions, setPreviousQueryOptions] = useState(null);
  const { filters, setFilters } = useContext(FilterContext);
  const [currentFilters, setCurrentFilters] = useState(filters["users"] || {});
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[tableIdentifier] || props.initialFilters || {}
  );

  const fetchData = async (queryOptions) => {
    if (queryOptions) setPreviousQueryOptions(queryOptions);
    setLoading(true);
    try {
      const data = await get_users(queryOptions || previousQueryOptions);
      setData(data);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filters["users"] = currentFilters;
    setFilters(filters);
    tableSettings[tableIdentifier] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    tableIdentifier,
    setFilters,
    setTableSettings,
  ]);

  return (
    <div className={"users-screen"}>
      <div className={"users-screen-content"}>
        <DataTable
          ariaLabel="Users"
          data={data}
          dataLoading={loading}
          defaultSort="firstName"
          defaultOrder="asc"
          enableFilter={true}
          fields={fields}
          getCSV={get_users_export}
          loadData={fetchData}
          createDisabled={true}
          icon="account_circle"
          title="Users"
          filters={currentFilters}
          setFilters={setCurrentFilters}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          id={tableIdentifier}
        />
        <CreateUserModal
          title="New Account"
          open={openCreate}
          setOpen={setOpenCreate}
          fetchData={fetchData}
          fields={createFields}
          initialValues={initialValues}
          create_function={create_user}
          successMessage="User created"
        />
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

export default Users;
