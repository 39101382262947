export const DRAFT = "draft";
export const SUBMITTED = "submitted";
export const REVIEWING = "reviewing";
export const SAVED = "saved";
export const CANCELLED = "cancelled";
export const DECLINED = "declined";
export const DISCARDED = "discarded";
export const ACTIVE = "active";
export const APPROVED = "approved";
export const ISSUED = "issued";

//Only licences with these statuses can be edited
export const editStatuses = [ACTIVE, SAVED];

export const getDisplayStatus = (status) => {
  let displayStatus = "Missing";
  switch (status) {
    case DRAFT:
    case SAVED:
      displayStatus = "Draft";
      break;
    case SUBMITTED:
    case REVIEWING:
    case APPROVED:
      displayStatus = "Under review";
      break;
    case ISSUED:
      displayStatus = "Approved";
      break;
    case ACTIVE:
      displayStatus = "Active";
      break;
    default:
      break;
  }
  return displayStatus;
};
