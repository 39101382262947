import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import FormColumn from "../../../components/layout-helpers/FormColumn";
import Spacer from "../../../components/ui-kit/Spacer";
import { auth_token, sendPasscodeEmail } from "../../../../core/apis/auth";
import { default as Typography } from "@mui/material/Typography";
import { routes } from "../../../routes/routesData";
import { AuthContext } from "../../../contexts/AuthContext";
import Button from "@mui/material/Button";
import TextField from "../../../components/ui-kit/TextField";
import { mainEffect } from "../../../components/ui-kit/Main";
import NotificationBar from "../../../components/ui-kit/NotificationBar";
import { Collapse, useTheme } from "@mui/material";
import Alerts from "../../../components/ui-kit/Alert";
import { ReactComponent as Logo } from "../../../../assets/images/CoLLogo.svg";
import { validEmail } from "../../../../utilities/yupValidators";
import classes from "./Login.module.scss";
import { AxiosResponse } from "axios";
import styled from "@emotion/styled";
import { get_privacy_policies } from "../../../../core/apis/terms";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { ADMIN_AUTH_TYPE } from "../../../../core/constants/authTypes";
import { getGlobal } from "../../../../utilities";
import { KeyboardArrowDown } from "@mui/icons-material";

const SmallLinkButton = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary[200]} !important;
  margin-top: 10px !important;

  :hover {
    color: ${({ theme }) => theme.palette.primary[400]} !important;
    cursor: pointer;
  }
`;

interface FormValues {
  email: string;
  password: string;
}

const initialValues = {
  email: "",
  password: "",
};

const validPassword = yup.string().trim().required("Password is required.");

const validation = yup.object({
  email: validEmail,
  password: validPassword,
});

const LoginScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, login, isInitialLogin } = useContext(AuthContext);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [incorrect, setIncorrect] = useState(false);
  const [infoExpanded, setInfoExpanded] = useState(false);
  const [terms, setTerms] = useState([]) as any[];
  const { handleError } = useContext(NotificationContext);
  const theme = useTheme();
  const appVersion = getGlobal("BRANCH_VER");
  const passwordRef: React.MutableRefObject<any> = useRef(null);
  const accountUtilitiesEnabled =
    getGlobal("CCFG_ACCOUNTS_UTILITIES") === "true";

  useEffect(() => {
    (async () => {
      try {
        setTerms(await get_privacy_policies());
      } catch (err) {
        // @ts-ignore
        handleError("Unable to load privacy policy.");
      }
    })();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values: FormValues) => {
    try {
      const credentials = {
        username: values.email,
        password: values.password,
      };
      const response = (await auth_token(credentials)) as AxiosResponse;
      const userToken = response.data.access_token;

      const decodedData = jwtDecode(userToken) as any;
      const userData = {
        fname: decodedData.firstName,
        lname: decodedData.lastName,
        email: decodedData.sub,
        id: decodedData.id,
        roles: decodedData.roles,
        exp: decodedData.exp,
        cityId: decodedData.cityId,
        isMFA: decodedData.isMFA,
        userToken,
      };
      // Redirect to two-factor authentication screen
      if (userData.isMFA) {
        await sendPasscodeEmail(userToken);
        const email = decodedData.sub;
        return history.push({
          pathname: routes.TWO_FACTOR_AUTHENTICATION.path,
          state: { userData, userToken, snackbarOpen: true, email },
        });
      }
      await login(userData);
      if (decodedData.auth_type === ADMIN_AUTH_TYPE) {
        history.push("/admin");
      } else {
        history.push("/");
      }
    } catch (err) {
      setIncorrect(true);
    } finally {
    }
  };

  useEffect(() => {
    if (user) {
      /**
       * @todo Find out how to call Main > useEffect instead of manually
       *       running it
       */
      mainEffect(history);
    } else if (!isInitialLogin) {
      setAlertMessage(
        "For your security, the session expires after 24 hours of inactivity. Please, log in again."
      );
      setShowAlert(true);
    }
  }, [user, history, isInitialLogin]);

  useEffect(() => {
    // @ts-ignore
    if (location.state?.isPasswordReset) {
      // @ts-ignore
      history.replace(location.state.pathname);
      setAlertMessage("Password updated successfully.");
      setShowAlert(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (location.state?.resetTokenIsNotValid) {
      setAlertMessage(
        "Your link expired. On the login page, click forgot password and request a new email."
      );
      setShowAlert(true);
    }
    // eslint-disable-next-line
  }, []);

  const toggleInfoBeingExpanded = () => {
    setInfoExpanded((prevState) => !prevState);
  };

  /**
   * Handles changes in the password field:
   * - if the input is "space" key, it preserves the current cursor position and leaves the input un-changed
   * - otherwise, sets the form value with the value received from input
   * @param e
   * @param formikProps
   */
  const onPasswordInputChanged = async (
    e: any,
    formikProps: FormikProps<{ email: string; password: string }>
  ) => {
    if (e.nativeEvent?.data === " ") {
      const selectionEnd = e.target.selectionEnd - 1;
      await new Promise((resolve) => setTimeout(resolve, 20));
      passwordRef.current.setSelectionRange(selectionEnd, selectionEnd);
      return;
    }
    const trimmedPassword = e.target.value.trim().replace(" ", "");
    formikProps.setFieldValue("password", trimmedPassword);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.info_container_mobile}>
          <div className={classes.info}>
            <Logo />
            <p>Simplify your life.</p>
            <p>Connect to City Hall online.</p>
            <Collapse in={infoExpanded} unmountOnExit={false}>
              <p className={classes.description}>
                {accountUtilitiesEnabled
                  ? "MyCityHall is City of Lacombe's one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licenses, managing utilities accounts, making payments, and more."
                  : "MyCityHall is City of Lacombe's one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, making payments, and more."}
              </p>
            </Collapse>
            <div
              className={`${classes.show_more_container} ${
                infoExpanded ? classes.open : ""
              }`}
              onClick={toggleInfoBeingExpanded}
            >
              <p>{infoExpanded ? "Hide" : "Learn more"}</p>
              <KeyboardArrowDown />
            </div>
          </div>
        </div>
        <div className={classes.info_container}>
          <div className={classes.info}>
            <Logo />
            <p>Simplify your life.</p>
            <p>Connect to City Hall online.</p>
            <p className={classes.description}>
              {accountUtilitiesEnabled
                ? "MyCityHall is City of Lacombe's one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, managing utilities accounts, making payments, and more."
                : "MyCityHall is City of Lacombe's one convenient place for managing your essential needs. With our platform, you can easily handle tasks such as managing business licences, making payments, and more."}
            </p>
          </div>
        </div>
        <div className={classes.form_container}>
          <FormColumn>
            <div className={classes.form_title}>
              <Typography
                variant="h3"
                style={{ fontSize: 24, fontWeight: 500 }}
                color={theme.palette.primary[700]}
              >
                Sign in to MyCityHall
              </Typography>
            </div>
            {appVersion && (
              <Typography variant={"caption"}>{appVersion}</Typography>
            )}
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={handleSubmit}
              validateOnChange={validateOnChange}
              validateOnBlur={false}
            >
              {(formikProps) => {
                return (
                  <Form noValidate>
                    <Spacer />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          label="Email Address"
                          fullWidth
                          name="email"
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.email}
                          error={Boolean(formikProps.errors.email)}
                          helperText={formikProps.errors.email}
                          touched={formikProps.touched.email ? "true" : "false"}
                          onChange={(e: any) => {
                            const trimmedEmail = e.target.value.trim();
                            formikProps.setFieldValue("email", trimmedEmail);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          ref={passwordRef}
                          label="Password"
                          fullWidth
                          name="password"
                          type="password"
                          mask={passwordType}
                          onChange={(e: any) =>
                            onPasswordInputChanged(e, formikProps)
                          }
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.password}
                          error={Boolean(formikProps.errors.password)}
                          helperText={formikProps.errors.password}
                          touched={
                            formikProps.touched.password ? "true" : "false"
                          }
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.forgot_button_wrapper}>
                      {passwordType === "text" ? (
                        <Button
                          style={{
                            textTransform: "none",
                            color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                          }}
                          size={"small"}
                          variant="text"
                          onClick={() => setPasswordType("password")}
                        >
                          Hide
                        </Button>
                      ) : (
                        <Button
                          style={{
                            textTransform: "none",
                            color: theme.palette.blacks.BLACK_MEDIUM_EMPHASIS,
                          }}
                          size={"small"}
                          variant="text"
                          onClick={() => setPasswordType("text")}
                        >
                          Show
                        </Button>
                      )}
                      <Typography
                        variant={"body1"}
                        className={classes.small_link_button}
                        style={{ marginLeft: "auto" }}
                        onClick={() =>
                          history.push(routes.SEND_RESET_LINK.path)
                        }
                      >
                        Forgot password
                      </Typography>
                    </div>
                    {
                      // checking to see if the entered email or password are correct
                      incorrect && (
                        <div>
                          <Alerts
                            variant={"error"}
                            title={"Incorrect credentials"}
                            body={
                              "There is a mistake or this account does not exist. Please double-check your email address and password and try again."
                            }
                          />
                        </div>
                      )
                    }
                    <br />
                    <div className={classes.buttons_container}>
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          setValidateOnChange(true);
                        }}
                      >
                        LOG IN
                      </Button>
                      <SmallLinkButton
                        onClick={() =>
                          history.push({
                            pathname: routes.REGISTER.path,
                            state: {
                              acceptedTerms: terms.map(
                                (term: { id: string }) => term.id
                              ),
                            },
                          })
                        }
                        variant="body1"
                      >
                        Create an account
                      </SmallLinkButton>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </FormColumn>
        </div>
      </div>
      <NotificationBar
        open={showAlert}
        body={alertMessage}
        secondaryButtonLabel={"DISMISS"}
        onClickSecondary={() => {
          setShowAlert(false);
        }}
        iconName={undefined}
        title={undefined}
        onClickPrimary={undefined}
        primaryButtonLabel={undefined}
      />
    </>
  );
};

export default LoginScreen;
