import base from "./axiosInstance";
import config from "../apis/_config";

export const get_privacy_policies = async () => {
  try {
    const termsId = "64feda3d-525f-495a-b294-a7597a72fa7a";
    const response = await base.get(`/terms/${termsId}`);
    return [response.data];
  } catch (error) {
    throw error;
  }
};

export const get_terms_by_ids = async (ids) => {
  const params = {
    filters: { id: ids },
  };
  try {
    const cityId = "dc9a56b4-f11c-4d71-89df-91ac898b2ee6";
    const response = await base.get(`/terms/${cityId}/allCityTerms`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get_applicant_declaration = async () => {
  const id = config.applicantDeclarationId;

  return await base.get(`/terms/${id}`);
};
