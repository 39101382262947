export const typeOptions = [
  {
    value: "Residential",
    label: "Residential",
  },
  {
    value: "Commercial, Industrial, Institutional",
    label: "Commercial, Industrial, Institutional",
  },
];

export const metreSizeOptions = [
  {
    label: "Up to 1”",
    value: "Up to 1”",
  },
  {
    label: "1.25 to 2”",
    value: "1.25 to 2”",
  },
  {
    label: "3” to 4”",
    value: "3” to 4”",
  },
  {
    label: "6”+",
    value: "6”+",
  },
  {
    label: "I don’t know",
    value: "I don’t know",
  },
];

export const wasteMetreOptions = [
  {
    label: "Up to 4”",
    value: "Up to 4”",
  },
  {
    label: "6”",
    value: "6”",
  },
  {
    label: "8”",
    value: "8”",
  },
  {
    label: "10”+",
    value: "10”+",
  },
  {
    label: "I don’t know",
    value: "I don’t know",
  },
];

export const collectionServiceOptions = [
  {
    label: "Residential",
    value: "Residential",
  },
  {
    label: "Apartment/Condo",
    value: "Apartment/Condo",
  },
  {
    label: "Manufactured Home Park",
    value: "Manufactured Home Park",
  },
];

export const pickupOptions = [
  {
    label: "Scheduled Bi-weekly pickup per bin",
    value: "Scheduled Bi-weekly pickup per bin",
  },
  {
    label: "Scheduled Weekly pickup per bin",
    value: "Scheduled Weekly pickup per bin",
  },
  {
    label: "Scheduled weekly pickup per shared bin",
    value: "Scheduled weekly pickup per shared bin",
  },
  {
    label: "Non-scheduled extra pickup per bin",
    value: "Non-scheduled extra pickup per bin",
  },
];

export const disposableServiceSizeOptions = [
  {
    label: "Up to 20 yd3",
    value: "Up to 20 yd3",
  },
  {
    label: "21 yd3 to 50 yd3",
    value: "21 yd3 to 50 yd3",
  },
  {
    label: "51 yd3 to 100 yd3",
    value: "51 yd3 to 100 yd3",
  },
  {
    label: "Over 100 yds",
    value: "Over 100 yds",
  },
  {
    label: "I don’t know",
    value: "I don’t know",
  },
];