import React, { createRef, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import IconBase from "../../../../components/ui-kit/IconBase";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Link, useTheme } from "@mui/material";
import Spacer from "../../../../components/ui-kit/Spacer";
import { Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import Banner from "../../../../components/ui-kit/Banner";
import {
  findLicenceByReference,
  linkLicence,
} from "../../../../../core/apis/licence";
import { LINK_CONTACT_CITY_HALL } from "../../../../../core/constants/links";
import * as yup from "yup";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import classes from "./index.module.scss";
import WBCBreadcrumbs from "../../../../components/ui-kit/breadcrumbs";
import { Home } from "@mui/icons-material";
import { routes } from "../../../../routes/routesData";
import Stepper from "../../../../components/ui-kit/StepNavigator";
import Divider from "../../../../components/ui-kit/Divider";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  padding-top: 1rem;
  margin-left: 18px;
  margin-right: 18px;
  width: 100%;
`;

const Content = styled.div`
  max-width: 375px;
  margin: 2rem auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const initialValues = {
  refNumber: "",
};
const validation = yup.object({
  refNumber: yup.string().required("Licence number is required."),
});

const steps = [{ id: "step1" }, { id: "step2" }];

/**
 * Page for linking a licence to the user.
 * @returns {JSX.Element}
 * @constructor
 */
const LinkLicence = () => {
  const history = useHistory();
  const [licence, setLicence] = useState(null);
  const { handleError, handleSuccess } = useContext(NotificationContext);

  const linkAccount = async () => {
    try {
      const response = await linkLicence(licence.id);
      if (response.status === 401) {
        handleError("Error processing your request");
        return;
      }
      if (response.status === 403) {
        handleError("Licence has been already linked to an account");
      }
      if (response.status === 404) {
        handleError("Licence could not be found");
      }

      if (response.data.licenseId) {
        history.push(`business-licence/${response.data.licenseId}`);
      } else {
        handleSuccess("Licence has been linked");
      }
    } catch (e) {
      handleError("Error linking licence");
    }
  };
  return (
    <Container>
      <div className={classes.top_bar_container}>
        <WBCBreadcrumbs
          className={classes.breadcrumbs}
          children={[
            {
              icon: <Home fontSize={"small"} />,
              text: "HOME",
              onClick: () => history.replace(routes.LICENCES.path),
            },
            {
              text: "LINK YOUR LICENCE",
            },
          ]}
        />
        <Stepper
          className={classes.stepper_container}
          steps={steps}
          onClick={() => setLicence(null)}
          currentIndex={!!licence ? 1 : 0}
          maxWidth={"380px"}
        />
      </div>
      <Divider width={"100%"} />
      <InnerContainer>
        <Box p={1}>
          <Spacer />
          <Content>
            {licence ? (
              <LicenceFound licence={licence} linkAccount={linkAccount} />
            ) : (
              <NoLicence setLicence={setLicence} />
            )}
          </Content>
        </Box>
      </InnerContainer>
    </Container>
  );
};

const NoLicence = ({ setLicence }) => {
  const theme = useTheme();
  const formRef = createRef();
  const { handleError } = useContext(NotificationContext);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const onSubmit = async (values) => {
    try {
      const response = await findLicenceByReference(values.refNumber);

      if (response.status === 401) {
        handleError("Error finding licence by reference number");
      }

      if (!response.data) {
        formRef.current.setFieldError(
          "refNumber",
          "This licence does not exist. Please, verify the number."
        );
        return;
      }

      const licenceData = response.data;
      setLicence(licenceData);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <IconBase
        wght={"700"}
        size={"64"}
        iconName={"link"}
        color={theme.palette.primary[200]}
      />
      <Spacer amount={3} />
      <div>
        <Typography variant={"h4"}>Linking an existing licence</Typography>
        <Spacer />
        <Typography
          variant={"body1"}
          color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
        >
          When you link a pre-existing licence, you will be able to view it,
          print it, edit its information, and pay any fees.{" "}
        </Typography>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validation}
        validateOnChange={validateOnChange}
      >
        {({ values, handleChange, errors }) => {
          return (
            <Form
              noValidate
              style={{
                width: "100%",
              }}
            >
              <Spacer amount={2} />
              <TextField
                fullWidth
                label={"Licence number"}
                name={"refNumber"}
                value={values.refNumber}
                error={Boolean(errors.refNumber)}
                helperText={errors.refNumber}
                onChange={handleChange}
              />
              <Spacer amount={0.5} />
              <Link
                color={theme.palette.primary[200]}
                href={
                  "https://lacombeca.sharepoint.com/:x:/g/public/EQkzAXVplb1Il6yUDX3En4sB2RviTNqMq2_0v1FMY4ZiBw?e=qyU9RL"
                }
                target={"_blank"}
              >
                Where do I find my licence number?
              </Link>
              <Spacer amount={2} />
              <Button
                size={"large"}
                variant={"contained"}
                type={"submit"}
                onClick={() => setValidateOnChange(true)}
                fullWidth
              >
                Continue
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

//Licence found functions
const getIcon = (isLinked) => (isLinked ? "info" : "link");
const getHeader = (isLinked) =>
  isLinked ? "Is this your licence?" : "Confirm linking";
const getBodyText = (isLinked) =>
  isLinked ? (
    <>
      This licence is already linked to a user account. Double-check this is
      indeed your business licence. If you didn't link this licence to any other
      account, please{" "}
      <a
        rel="noopener noreferrer"
        target={"_blank"}
        href={LINK_CONTACT_CITY_HALL}
      >
        contact City Hall
      </a>
      .
    </>
  ) : (
    "Please, review the information below and confirm this is the " +
    "licence you want to be linked to."
  );

const LicenceFound = ({ licence, linkAccount }) => {
  const theme = useTheme();
  const isLinked = licence?.isLinked;

  return (
    <>
      <IconBase
        wght={"700"}
        size={"64"}
        iconName={getIcon(isLinked)}
        color={theme.palette.primary[200]}
      />
      <Spacer amount={3} />
      <div>
        <Typography variant={"h4"}>{getHeader(isLinked)}</Typography>
        <Spacer />
        <Typography
          variant={"body1"}
          color={theme.palette.blacks.BLACK_HIGH_EMPHASIS}
        >
          {getBodyText(isLinked)}
        </Typography>
      </div>
      <Spacer amount={3} />
      <Banner
        title={`Business licence - ${licence.ref}`}
        body={
          <>
            {licence.address} <br />
            {licence.city}, {licence.province} {licence.postalCode}
          </>
        }
        styles={{ backgroundColor: theme.palette.primary[50] }}
        Icon={
          <IconBase
            size={"36"}
            color={theme.palette.primary[200]}
            wght={"500"}
            iconName={"Storefront"}
          />
        }
      />
      {isLinked ? null : (
        <>
          <Spacer amount={0.5} />
          <Button
            fullWidth
            variant={"contained"}
            size={"large"}
            onClick={linkAccount}
          >
            Link licence
          </Button>
        </>
      )}
    </>
  );
};

export default LinkLicence;
