import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import { EditingContext } from "../../../contexts/EditingContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import useHash from "../../../hooks/useHash";
import {
  formatPhoneNumber,
  formatPostalCode
} from "../../../../utilities/masked-input-formatters";
import {
  ACTIVE, CREATED,
  DRAFT,
  ISSUED,
  REVIEWING_GROUP
} from "../../../../core/constants/licences";
import IconBase from "../../../components/ui-kit/IconBase";
import SearchDropDown from "../../../components/ui-kit/SearchDropDown";
import EditingAlerts from "../../../components/ui-kit/editing-alerts";
import {
  get_issuer,
  get_licenses_summaries_by_id,
  get_reviewer,
  getLicense,
  getLinkedUsers,
  update_license,
  update_status_submitted,
  updateChangeSet,
} from "../../../../core/apis/licence";
import { get_invoices } from "../../../../core/apis/invoice";
import { getComments, getHistory } from "../../../../core/apis/history";
import { addComment } from "../../../../core/apis/comment";
import {
  download_attachment,
  get_attachment_info,
  upload_file,
} from "../../../../core/apis/attachment";
import { licenceValidations } from "../../../../core/constants/validations";
import { object } from "yup";
import classes from "./index.module.scss";
import LoadingLogo from "../../../../assets/gifs/logo-loading-transparent.gif";
import Alerts from "../../../components/ui-kit/Alert";
import Chip from "../../../components/ui-kit/Chip";
import { PRIMARY_200, PRIMARY_500 } from "../../../../mui-theme/theme";
import Tabs from "../../../components/ui-kit/tabs";
import Tab from "../../../components/ui-kit/tab";
import Typography from "../../../components/ui-kit/typography";
import TabPanel from "../../../components/ui-kit/tab-pannel";
import UploadFileModal from "../../../components/modals/UploadFileModal";
import RightSidebar from "./right-sidebar";
import GeneralTab from "./general-tab";
import HistoryTab from "./history-tab";
import InvoiceTab from "./invoice-tab";
import TransactionTab from "./transaction-tab";
import DocumentTab from "./document-tab";

const tabs = [
  "General Info",
  "Documents",
  "Invoices",
  "Transactions",
  "History",
];

const LicenseDetail = (props) => {
  // TODO: refactor loading state to separate the concerns to avoid unexpected behaviour
  const [loading, setLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [allHistory, setAllHistory] = useState([]);
  const [tab, setTab] = useState(tabs[0]);
  const [invoices, setInvoices] = useState(null);
  const transactions = null;
  const [categories, setCategories] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [managers, setManagers] = useState([]);
  const [originalManagers, setOriginalManagers] = useState([]);
  const [unSelectedCategories, setUnSelectedCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allCategories, setAllCategories] = useState();
  const [categoryFieldId, setCategoryFieldId] = useState();
  const [documents, setDocuments] = useState([]);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [reviewers, setReviewers] = useState();
  const [issuer, setIssuer] = useState({});
  const [editData, setEditData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [changedFields, setChangedFields] = useState([]);
  const [showOriginalData, setShowOriginalData] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { isEditing, setIsEditing, setModalData } = useContext(EditingContext);
  const { handleError } = useContext(NotificationContext);
  const hash = useHash();
  /** @type {React.MutableRefObject<HTMLDivElement>}*/
  const addByLawChipRef = useRef(null);
  /**@type {React.MutableRefObject<boolean>}*/
  const byLawPopOverOpen = useRef(false);

  /**
   * With each change in the [fetchingData]:
   * if the fetching process is finished and the bylaw selection pop-over was already open previous to loading
   * sets the addByLawChipRef current element as [anchorEl]
   */
  useEffect(() => {
    if (fetchingData) return;
    if (!byLawPopOverOpen.current) return;
    if (!addByLawChipRef.current) return;
    setAnchorEl(addByLawChipRef.current);
  }, [fetchingData]);

  /**
   * Before un-mounting the layout, set the byLawPopOverOpen with the previous render value of [anchorEl]
   * to indicate if the popover was already open before loading or not
   */
  useLayoutEffect(() => {
    return () => {
      byLawPopOverOpen.current = Boolean(anchorEl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const changeTab = (e, newTab) => {
    history.push(`${location.pathname}#${newTab}`);
  };

  useEffect(() => {
    let t = hash || tabs[0];
    if (t === "General%20Info") {
      t = tabs[0];
    }
    setTab(t);
  }, [hash]);

  // Will convert the licence received from the backend to object
  // shape required for the front end
  const createLicense = (item) => {
    let all = {};
    let allOriginal = {};
    let element = {};
    let originalElement = {};
    for (let i = 0; i < item.length; i++) {
      if (item[i].name === "business_manager") {
        element = { [item[i].name]: item[i].value };
        if (
          item[i].originalValue &&
          item[i].originalValue.value !== item[i].value.value
        ) {
          originalElement = { [item[i].name]: item[i].originalValue.value };
        }
      } else if (item[i].name === "category") {
        let tmp = item[i].value.value;
        let res = [];
        if (!Array.isArray(tmp)) {
          element = { category: [] };
        } else {
          tmp.map((e) => res.push(item[i].default[e]));
          element = { category: res };
        }
      } else if (
        item[i].name === "mailing_address_postal_code" ||
        item[i].name === "business_address_postal_code"
      ) {
        // checking if it's not US zip code to enforce canadian postal code spacing
        if (!/^\d{5}(?:[-\s]\d{4})?$/.test(item[i].value.value)) {
          element = { [item[i].name]: formatPostalCode(item[i].value.value) };
        } else {
          element = { [item[i].name]: item[i].value.value };
        }
        if (
          item[i].originalValue &&
          !/^\d{5}(?:[-\s]\d{4})?$/.test(item[i].originalValue.value)
        ) {
          if (
            item[i].originalValue &&
            formatPostalCode(item[i].originalValue.value) !==
              formatPostalCode(item[i].value.value)
          ) {
            originalElement = {
              [item[i].name]: formatPostalCode(item[i].originalValue.value),
            };
          }
        } else {
          if (
            item[i].originalValue &&
            item[i].originalValue.value !== item[i].value.value
          ) {
            originalElement = { [item[i].name]: item[i].originalValue.value };
          }
        }
      } else if (
        item[i].name === "phone_number" ||
        item[i].name === "secondary_phone_number"
      ) {
        element = { [item[i].name]: formatPhoneNumber(item[i].value.value) };
        if (
          item[i].originalValue &&
          formatPhoneNumber(item[i].originalValue.value) !==
          formatPhoneNumber(item[i].value.value)
        ) {
          originalElement = {
            [item[i].name]: formatPhoneNumber(item[i].originalValue.value),
          };
        }
      } else if (item[i].name === "attachments") {
        element = { [item[i].name]: item[i].value.value };
      } else {
        element = { [item[i].name]: item[i].value.value };
        if (
          item[i].originalValue &&
          item[i].originalValue.value !== item[i].value.value
        ) {
          originalElement = { [item[i].name]: item[i].originalValue.value };
        }
      }
      allOriginal = { ...allOriginal, ...originalElement };
      all = { ...all, ...element };
    }
    return [all, allOriginal];
  };

  // Creates the Owners/Managers Array
  const createManagers = (item) => {
    let params = item.business_manager;
    if (!params.value) {
      params.value = [];
    }
    return params.value;
  };

  // Creates the Owners/Managers Array
  const createOriginalManagers = (item) => {
    let params = item.business_manager;
    if (!params) {
      params = [];
    }
    return params;
  };

  // Adds ByLaws that are pre-selected in the licence
  const addSelectedCategories = (item) => {
    const params = item.category;
    let all = [];
    for (let i = 0; i < params.length; i++) {
      all.push(params[i]);
    }
    return all;
  };

  // Adds all the ByLaws which will be used to create unselected categories
  const addAllCategories = (item) => {
    const params = item.filter((element) => element.name === "category");
    setCategoryFieldId(params[0].id);
    return params[0].default;
  };

  // Removes selected ByLaws from a licence
  const handleRemoveCategory = async (categoryName) => {
    let newSelected = selectedCategories.filter(
      (item) => item !== categoryName
    );
    let key = [];
    let added = { id: categoryFieldId, value: { value: key } };
    newSelected.map((categoryName) =>
      added.value.value.push(
        Object.keys(allCategories).find(
          (key) => allCategories[key] === categoryName
        )
      )
    );
    await update_license(data.id, [added], null);
    setRefresh(!refresh);
  };

  // Selects a new ByLaw and adds it to the licence
  const handleAddCategory = async (categoryName) => {
    let key = Object.keys(allCategories).find(
      (key) => allCategories[key] === categoryName
    );
    let added = { id: categoryFieldId, value: { value: [key] } };
    selectedCategories.map((categoryName) =>
      added.value.value.push(
        Object.keys(allCategories).find(
          (key) => allCategories[key] === categoryName
        )
      )
    );
    await update_license(data.id, [added], null);
    setRefresh(!refresh);
  };

  // TODO connect to BE and remove fake data
  async function fetchTransactions(queryOptions) {
    try {
      setLoading(true);
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  async function fetchInvoices(queryOptions) {
    try {
      setLoading(true);
      const data = await get_invoices(queryOptions);
      setInvoices(data);
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  // Receives history items and comments, combines them and sorts them based on date
  async function fetchHistories() {
    setLoading(true);
    try {
      const comments = await getComments(props.match.params.id);
      let histories = await getHistory(props.match.params.id);
      let linked = await getLinkedUsers(props.match.params.id);
      histories = histories.filter(
        (item) =>
          "Review" in item.data.historyType ||
          "StatusChange" in item.data.historyType ||
          "Payment" in item.data.historyType ||
          "Linked" in item.data.historyType ||
          "Unlinked" in item.data.historyType ||
          "Versioned" in item.data.historyType ||
          "Renew" in item.data.historyType ||
          "Marked" in item.data.historyType
      );
      let stuff = comments.concat(histories);
      stuff = stuff.sort(function (a, b) {
        let c = new Date(a.lastUpdated);
        let d = new Date(b.lastUpdated);
        return c - d;
      });
      setLinkedAccounts(linked);
      setAllHistory(stuff);
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  // Adds a comment to the history section
  async function _addComment(
    comment,
    setCommentFieldLoading,
    setComment,
    isGeneralTab = false
  ) {
    setLoading(true);
    setCommentFieldLoading(true);
    try {
      await addComment(props.match.params.id, comment);
      setComment("");
      await fetchHistories();
    } catch (err) {
      console.log(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
      setCommentFieldLoading(false);
      if (isGeneralTab) {
        setTimeout(() => {
          const historySection = document.querySelector("#__history-section");
          if (historySection) {
            historySection.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      }
    }
  }

  // Allow documents to be downloaded by opening them in a new tab
  async function handleDownloadDoc(id) {
    const docLink = await download_attachment(id, data?.id);
    window.open(docLink.downloadLink, "_blank", "noopener,noreferrer");
  }

  // Opens ByLaws selector
  const handleOpenByLaws = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCategories(!categories);
  };

  // Receiving documents from their already retrieved id's
  const receiveDocs = async (ids) => {
    let tmp = [];
    for (let i = 0; i < ids.length; i++) {
      let tmpval = await get_attachment_info(ids[i], data?.id);
      tmp.push(tmpval);
    }
    return tmp;
  };

  const checkIfString = (prop) => {
    return typeof prop === "string";
  };

  //Saves all the edit changes
  const saveAllEdits = async (waive, sendEmail) => {
    try {
      let tmpData = data;
      editData.forEach((item) => {
        if (item.name) {
          if (item.name === "business_manager") {
            tmpData[item.name] = { value: item.value.value };
          } else {
            tmpData[item.name] = item.value.value;
          }
        }
      });
      if (tmpData.business_manager.value) {
        tmpData.business_manager = tmpData.business_manager.value;
      }
      if (checkIfString(tmpData.is_resident)) {
        tmpData.is_resident = false;
      }
      if (checkIfString(tmpData.is_homebased)) {
        tmpData.is_homebased = false;
      }
      if (checkIfString(tmpData.has_parking_for_customers)) {
        tmpData.has_parking_for_customers = false;
      }
      if (checkIfString(tmpData.is_business_address_same_as_mailing_address)) {
        tmpData.is_business_address_same_as_mailing_address = false;
      }
      if (checkIfString(tmpData.is_included_in_online_business_directory)) {
        tmpData.is_included_in_online_business_directory = false;
      }
      if (checkIfString(tmpData.is_property_owner)) {
        tmpData.is_property_owner = false;
      }
      if (checkIfString(tmpData.is_property_owner)) {
        tmpData.is_property_owner = false;
      }
      if (!tmpData.number_of_vehicles) {
        tmpData.number_of_vehicles = 0;
      }
      if (!tmpData.business_manager) {
        tmpData.business_manager = [];
      }

      let schema = {};
      Object.entries(tmpData).forEach(([prop]) => {
        schema[prop] = licenceValidations[prop];
      });

      await object(schema).validate(tmpData, {
        abortEarly: false,
      });

      if (
        REVIEWING_GROUP.find((x) => x === data?.change_set_status) ||
        data.change_set_status === ISSUED
      ) {
        await updateChangeSet(data.id, data.change_set_id, editData, sendEmail);
      } else {
        await update_license(data.id, editData, waive, sendEmail);
        if (data?.status === DRAFT || data?.status === CREATED) {
          await update_status_submitted(data.id);
        }
      }
      setIsEditing(false);
      setEditData([]);
      setErrors({});
      setRefresh(!refresh);
    } catch (e) {
      let error = {};
      e?.inner?.forEach((err) => {
        error[err.path] = err.message;
      });
      setErrors(error);
      scrollErrorIntoView();
    }
  };

  /**
   * Finds the error message elements in the document and brings it to the middle of the screen
   * @type {(function(): void)|*}
   */
  const scrollErrorIntoView = useCallback(() => {
    if (!!errors) {
      setTimeout(() => {
        const errorMessages =
          document.getElementsByClassName("bl-error-message");
        if (errorMessages.length > 0) {
          errorMessages[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 200);
    }
  }, [errors]);

  // Navigate to original version of a duplicate licence
  const handleNavigateToDuplicate = (id, toHistory) => {
    const navigateTo = `/admin/licences/${id}` + (toHistory ? "#History" : "");
    history.push(navigateTo);
    setRefresh(!refresh);
  };

  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const handleFileUploadModalClose = () => {
    setFileUploadModalOpen(false);
  };
  const handleFileUploadModalOpen = () => {
    setFileUploadModalOpen(true);
  };

  const addFiles = async (files) => {
    setUploadingDocument(true);
    try {
      let attachmentIds = documents.map((doc) => {
        return doc.attachmentId;
      });
      for (let i = 0; i < files.length; i++) {
        attachmentIds.push(await upload_file(files[i].file));
      }
      const field = getFieldData("attachments");
      await update_license(data.id, [
        { id: field.id, value: { value: [...attachmentIds] } },
      ]);

      //Updates the documents on the screen without removing other data.
      let allDocs = await receiveDocs(attachmentIds);
      setDocuments(allDocs);
    } catch (e) {
      handleError("Documents failed to upload.");
    } finally {
      setUploadingDocument(false);
    }
  };

  /**
   * Returns the email we should contact the applicant at. We should
   * email the linked account, and if there is no linked
   * account, we use the email from the form.
   * @type {function(): *}
   */
  const emailToUse = useMemo(() => {
    let email = data?.email;
    if (linkedAccounts.length > 0 && linkedAccounts[0]?.email) {
      email = linkedAccounts[0].email;
    }
    return email;
  }, [linkedAccounts, data?.email]);

  const getFieldData = (name) => {
    const [field] = Object.values(initialData).filter(
      (item) => item.name === name
    );
    return field;
  };

  // prettier-ignore
  async function fetchData() {
    setLoading(true);
    setFetchingData(true);
    try {
      const data = await getLicense(props.match.params.id);
      setInitialData(data);
      const result = createLicense(data)[0];
      let originalResult = {};
      if (REVIEWING_GROUP.find((x) => x === result?.change_set_status)) {
        originalResult = createLicense(data)[1];
      }

      let changedManagers = [];
      if (originalResult["business_manager"]) {

        if (result["business_manager"]?.value?.length !== originalResult["business_manager"]?.length) {
          changedManagers.push("removed managers/owners");
        }

        for (let i = 0; i < result["business_manager"]?.value?.length; i++) {
          if (
            !originalResult["business_manager"]?.filter(
              (e) =>
                e.full_name === result["business_manager"].value[i].full_name &&
                e.is_owner === result["business_manager"].value[i].is_owner &&
                e.phone === result["business_manager"].value[i].phone
            ).length > 0
          ) {
            if (originalResult["business_manager"][i]?.phone) {
              changedManagers.push(originalResult["business_manager"][i]?.phone);
            }
          }
        }
      }

      setChangedFields(changedManagers);
      let tmpids = result?.attachments;                            // Reviving document ids
      let allDocs = await receiveDocs(tmpids);                     // Retrieving documents from endpoints
      setDocuments(allDocs);                                       // Updating docs hook
      setManagers(createManagers(result));
      setOriginalManagers(createOriginalManagers(originalResult));                         // Updating managers hook
      let tmpSelectedCategories = addSelectedCategories(result);   // Retrieving pre-assigned ByLaws from data
      setSelectedCategories(tmpSelectedCategories);                // Updating ByLaws hook
      let allCats = addAllCategories(data);                        // Retrieves all the ByLaws
      setUnSelectedCategories(                                     // Setting unselected ByLaws to be shown in the drop-down selector
        Object.values(allCats).filter(
          (item) => !tmpSelectedCategories.includes(item)
        )
      );
      let assigned = await get_reviewer(props.match.params.id);    // Receiving reviewers
      let issuerRes = await get_issuer(props.match.params.id);     // Receiving Issuer
      if (result.updated_license_id) {
        const updated = await get_licenses_summaries_by_id(result.updated_license_id);
        result.updated_license_status = updated.status;
      }
      setAllCategories(allCats);                                   // Updating all categories hook
      setReviewers(assigned);                                      // Updating reviewers hook
      setIssuer(issuerRes);                                        // Updating issuer hook
      let licenseId = { id: props.match.params.id };               // Creating Licence id object
      const all = { ...result, ...licenseId };                     // Merging licence data with licence id object
      setData(all);                                                // Updating data hook
      setOriginalData(originalResult);
      setIsDuplicate(result.previous_license_id && !(result.status === ACTIVE || result.status === ISSUED)); // Sets the licence duplicate status based on previous_license_id and current licence status
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setFetchingData(false);
    }
  }

  useEffect(() => {
    fetchData().then();
    fetchHistories().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, data?.status]);

  useEffect(() => {
    if (location.state?.newLicence && (data?.status === DRAFT || data?.status === CREATED)) {
      setIsEditing(true);
      setModalData({
        title: "Discarding Draft",
        body:
          "All the" +
          " information contained in this draft will be" +
          " deleted. A new application will require all information" +
          " to be re-input from the beginning.",
        actionButtonText: "Discard",
      });
    } else {
      setModalData({
        title: "Discarding Changes",
        body: "All the changes will be discarded and the licence will remain unchanged.",
        actionButtonText: "Discard",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.newLicence, data?.status]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {loading || fetchingData ? (
        <div className={classes.loading_component_wrapper}>
          <img src={LoadingLogo} alt={"Loading..."} />
        </div>
      ) : !!data ? (
        <>
          <div className={classes.container}>
            <>
              <div className={classes.content}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "3rem",
                  }}
                >
                  <IconBase
                    iconName="approval"
                    size="48"
                    color={theme.palette.primary[500]}
                  />
                  <Typography
                    variant="h3"
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {"Business licence"}
                    {data.license_number ? " - #" + data.license_number : ""}
                  </Typography>
                  {isDuplicate && (
                    <Typography
                      variant="h3"
                      style={{
                        marginTop: "auto",
                        marginBottom: "20px",
                        marginLeft: "0.5rem",
                        fontSize: 20,
                      }}
                    >
                      Duplicate
                    </Typography>
                  )}
                </div>
                {data.status === ACTIVE &&
                  REVIEWING_GROUP.find(
                    (x) => x === data?.change_set_status
                  ) && (
                    <div style={{ marginBottom: "0.5rem" }}>
                      <Alerts
                        variant={"info"}
                        title={"Review changes"}
                        body={
                          "The applicant submitted changes for this licence. Please, review the changes. When approving the changes, the system will let you decide if the applicant will be charged with an amendment fee ($20) or not."
                        }
                      />
                    </div>
                  )}
                <EditingAlerts
                  editing={isEditing}
                  data={data}
                  newLicence={location.state?.newLicence}
                />
                <div
                  style={{
                    marginBottom: isEditing ? "-4rem" : "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <div style={{ marginBottom: "1rem" }}>
                    <Typography variant={"overline"}>
                      Land use bylaws
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "1rem",
                      marginTop: "0.5rem",
                      width: "1000px",
                    }}
                  >
                    <Chip
                      ref={addByLawChipRef}
                      variant="new_optional_field"
                      iconName="add"
                      iconColor={PRIMARY_500}
                      style={{
                        marginRight: "0.5rem",
                      }}
                      label="Add bylaw"
                      onClick={(e) => {
                        handleOpenByLaws(e);
                      }}
                    />
                    {selectedCategories.map((name, row, i) => (
                      <Chip
                        key={row}
                        variant="active"
                        label={i[row]}
                        iconColor={PRIMARY_200}
                        onDelete={() => {
                          handleRemoveCategory(i[row]);
                        }}
                        style={{
                          marginRight: "0.5rem",
                          marginBottom: "20px",
                        }}
                      />
                    ))}
                    <SearchDropDown
                      searchBarPlaceholder="Search..."
                      titleLabel="SELECT LAND USE BYLAWS FOR:"
                      title={data.business_name}
                      onSelect={(name) => handleAddCategory(name)}
                      categories={unSelectedCategories}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    />
                  </div>
                </div>
                {!isEditing && (
                  <div style={{ width: "100%" }}>
                    <Tabs onChange={changeTab} value={tab}>
                      <Tab label={tabs[0]} value={tabs[0]} />
                      <Tab label={tabs[1]} value={tabs[1]} />
                      <Tab label={tabs[2]} value={tabs[2]} />
                      {/* <tab label={tabs[3]} value={tabs[3]} /> */}
                      <Tab label={tabs[4]} value={tabs[4]} />
                    </Tabs>
                  </div>
                )}
                <TabPanel value={tab} index={tabs[0]}>
                  <GeneralTab
                    documents={documents}
                    initialData={initialData}
                    data={data}
                    originalData={originalData}
                    setData={setData}
                    changedFields={changedFields}
                    allHistory={allHistory}
                    addComment={(comment, setCommentFieldLoading, setComment) =>
                      _addComment(
                        comment,
                        setCommentFieldLoading,
                        setComment,
                        true
                      )
                    }
                    setTab={setTab}
                    managersAndOwners={managers}
                    originalManagers={originalManagers}
                    handleDownloadDoc={handleDownloadDoc}
                    handleRefresh={() => setRefresh(!refresh)}
                    editing={isEditing}
                    editData={editData}
                    setEditData={setEditData}
                    saveAllEdits={saveAllEdits}
                    errors={errors}
                    setErrors={setErrors}
                    linkedAccounts={linkedAccounts}
                    refresher={setRefresh}
                    refreshValue={refresh}
                    handleFileUploadModalOpen={handleFileUploadModalOpen}
                    isDuplicate={isDuplicate}
                    fetchHistories={fetchHistories}
                    uploadingDocument={uploadingDocument}
                    showOriginalData={showOriginalData}
                    setShowOriginalData={setShowOriginalData}
                  />
                </TabPanel>

                <TabPanel value={tab} index={tabs[1]}>
                  <DocumentTab
                    handleRefresh={() => setRefresh(!refresh)}
                    documents={documents}
                    handleDownloadDoc={handleDownloadDoc}
                    initialData={initialData}
                    data={data}
                    handleFileUploadModalOpen={handleFileUploadModalOpen}
                    uploadingDocument={uploadingDocument}
                    editing={isEditing}
                  />
                </TabPanel>
                <TabPanel value={tab} index={tabs[2]}>
                  <InvoiceTab
                    loading={loading}
                    fetchInvoices={fetchInvoices}
                    fetchHistories={fetchHistories}
                    invoices={invoices}
                    licenceData={data}
                    handleRefresh={() => setRefresh(!refresh)}
                  />
                </TabPanel>
                <TabPanel value={tab} index={tabs[3]}>
                  <TransactionTab
                    fetchTransactions={fetchTransactions}
                    loading={loading}
                    transactions={transactions}
                  />
                </TabPanel>
                <TabPanel value={tab} index={tabs[4]}>
                  <HistoryTab
                    fetchHistories={fetchHistories}
                    history={allHistory}
                    navigateToDuplicate={handleNavigateToDuplicate}
                    _addComment={_addComment}
                    data={data}
                  />
                </TabPanel>
              </div>
            </>
          </div>
          <RightSidebar
            emailToUse={emailToUse}
            data={data}
            status={data?.status}
            preAssigned={reviewers}
            preAssignedIssuer={issuer}
            refresher={setRefresh}
            refreshValue={refresh}
            saveAllEdits={saveAllEdits}
            editing={isEditing}
            setEditing={setIsEditing}
            editData={createLicense(editData)}
            setEditData={setEditData}
            isDuplicate={isDuplicate}
            setErrors={setErrors}
            tab={tab}
          />
          <UploadFileModal
            addFiles={addFiles}
            files={documents}
            handleClose={handleFileUploadModalClose}
            modalOpen={fileUploadModalOpen}
          />
        </>
      ) : null}
    </div>
  );
};

export default LicenseDetail;
