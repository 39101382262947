import { LOCALE, CURRENCY } from "../core/constants/general";

export const formatCurrency = (num: any) => {
  if (num === undefined || num === null) return null;

  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: CURRENCY,
  }).format(num);
};
