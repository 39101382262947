import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import StepNavigator from "../../../../../components/ui-kit/StepNavigator";
import Typography from "../../../../../components/ui-kit/typography";
import Step1 from "../business-licence-steps/step-one";
import Step3 from "../business-licence-steps/Step3";
import Step4 from "../business-licence-steps/Step4";
import { PageLoadingScreen } from "../../../../../components/ui-kit/LoadingScreen";
import useWarnBeforeLeaving from "../../../../../hooks/useWarnBeforeLeaving";
import Divider from "../../../../../components/ui-kit/Divider";
import { Box, CircularProgress, useTheme } from "@mui/material";
import Hero from "../../../../../components/ui-kit/mch/Hero";
import lacombeImage from "../../../../../../assets/images/lacombe/lacombe_street_view.jpg";
import Step2 from "../business-licence-steps/Step2";
import {
  getLicence,
  getStatus,
  updateStatusSaved,
} from "../../../../../../core/apis/licence";
import { mapInto } from "../mappings/mapping";
import { ADD, CREATED, EDIT } from "../../../../../../core/constants/licences";
import { ScrollContext } from "../../../../../components/ui-kit/Main";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DRAFT } from "../../../../../../core/constants/licences";
import useHash from "../../../../../hooks/useHash";
import { saveFormValues } from "../functions/business_licence_functions";
import classes from "./index.module.scss";
import WBCBreadcrumbs from "../../../../../components/ui-kit/breadcrumbs";
import { Home } from "@mui/icons-material";
import { routes } from "../../../../../routes/routesData";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;
  position: relative;
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40%;
`;

const InnerContainer = styled.div`
  flex: 1;
`;

const FormContainer = styled.div`
  max-width: 480px;
  margin: 0 auto;

  padding-bottom: 3rem;
`;

//Steps for the business form
const steps = [
  {
    id: "step1",
  },
  {
    id: "step2",
  },
  {
    id: "step3",
  },
  {
    id: "step4",
  },
];

//returns the current form
const getForm = (stepId) => {
  switch (stepId) {
    case "step1":
      return Step1;
    case "step2":
      return Step2;
    case "step3":
      return Step3;
    case "step4":
      return Step4;
    default:
      return null;
  }
};

/**
 * Business licence creation screen
 * @returns {JSX.Element}
 * @constructor
 */
const BusinessLicenseCreation = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const hash = useHash();
  const { scrollToTop } = useContext(ScrollContext);
  const [loading, setLoading] = useState(true);
  const status = useRef();
  //The index of the current step of the form
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  //Holds submitted form values, gets updated after every step
  const [submittedFormValues, setSubmittedFormValues] = useState({});
  const [licenceId, setLicenceId] = useState(params.id);
  const [mode, setMode] = useState(ADD);
  //Holds the business name to be changed and displayed by the banner
  const [bannerName, setBannerName] = useState("");
  const [submitting] = useState(false);
  const [setIsDirty] = useWarnBeforeLeaving();
  const currentStep = steps[currentStepIndex];
  const CurrentForm = getForm(currentStep.id);

  /**
   * With initial render of the component:
   * - call the fetchLicence callback for getting licence information and status
   */
  useEffect(() => {
    fetchLicence().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentStepIndex(hash ? hash - 1 : 0);
  }, [hash]);

  useEffect(() => {
    setLicenceId(params.id);
    if (location?.state?.pageEdit >= 0) {
      setMode(EDIT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  /**
   * Gets licence and its status by calling the api regarding this action
   * @returns {Promise<void>}
   */
  const fetchLicence = async () => {
    setLoading(true);
    try {
      const results = await getLicence(licenceId);
      const statusResult = await getStatus(licenceId);
      let refractedValues = {};
      results.forEach((val) => {
        let newVal = mapInto(val);
        if (!newVal) return;
        if (newVal.property === "tmpOwners") {
          refractedValues["owners"] = newVal.value?.owners
            ? newVal.value?.owners
            : [];
          refractedValues["managers"] = newVal.value?.managers
            ? newVal.value?.managers
            : [];
        } else if (newVal.property === "businessType") {
          refractedValues[newVal.property] = {
            value: newVal.value,
            categories: newVal.categories,
          };
        } else {
          refractedValues[newVal.property] = newVal.value;
        }
      });
      setSubmittedFormValues(refractedValues);
      setBannerNameHelper(refractedValues.businessName);
      status.current = statusResult.state;
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  //Sets the business name in the banner of the PageContainer component
  const setBannerNameHelper = (name) => {
    if (name) {
      setBannerName(name);
    } else {
      setBannerName("");
    }
  };

  //Navigates to specified step based on the index
  const handleNavigate = (index) => {
    history.push(`${location.pathname}#${index + 1}`);
    scrollToTop();
  };

  //Once a step is completed, the current step moves to the next.
  //If the form has no next step, calls the handle submit function to upload all the form data
  const submitFormValues = async (formValues) => {
    try {
      const values = await saveFormValues(
        licenceId,
        formValues,
        mode,
        location?.state?.changesId,
        status.current
      );
      if (mode === ADD) {
        setSubmittedFormValues(values);
        handleNavigate(currentStepIndex + 1);
      } else {
        history.push(`/business-licence/${licenceId}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onFinish = async (tabName = "General") => {
    try {
      if (status.current === DRAFT || status.current === CREATED) {
        await updateStatusSaved(licenceId);
      }
    } catch (e) {
      console.log(e.message);
    }
    history.push(`/business-licence/${licenceId}#${tabName}`);
  };

  const onCancel = (tabName = "General") => {
    history.push(`/business-licence/${licenceId}#${tabName}`);
  };

  return (
    <Container>
      <PageLoadingScreen loading={submitting} />
      <div className={classes.top_bar}>
        <WBCBreadcrumbs
          children={[
            {
              icon: <Home fontSize={"small"} />,
              text: "HOME",
              onClick: () => history.replace(routes.LICENCES.path),
            },
            {
              text: "CREATE NEW LICENCE",
            },
          ]}
        />
        <div />
      </div>
      <Hero
        backPathName={`/portal`}
        img={lacombeImage}
        name={bannerName}
        title={"Business licence"}
      />
      {mode === ADD ? (
        <>
          <StepNavigator
            steps={steps}
            currentIndex={currentStepIndex}
            onClick={handleNavigate}
          />
          <Divider />
        </>
      ) : null}
      <InnerContainer>
        <div style={{ flex: 1 }}>
          <Typography
            variant="h5"
            fontWeight="normal"
            color={theme.palette.blacks.BLACK_MEDIUM_EMPHASIS}
            style={{ alignSelf: "flex-start" }}
          >
            {currentStep.label}
          </Typography>
          <br />
          <FormContainer>
            <Box px={1}>
              {loading ? (
                <CircularProgressWrapper>
                  <CircularProgress size={42} />
                </CircularProgressWrapper>
              ) : (
                <CurrentForm
                  mode={mode}
                  onFinish={onFinish}
                  saveFormValues={saveFormValues}
                  licenceId={licenceId}
                  formId={currentStep.id}
                  submittedFormValues={submittedFormValues}
                  onSubmit={submitFormValues}
                  setIsDirty={setIsDirty}
                  setBannerName={setBannerNameHelper}
                  onCancel={onCancel}
                />
              )}
            </Box>
          </FormContainer>
        </div>
      </InnerContainer>
    </Container>
  );
};

export default BusinessLicenseCreation;
