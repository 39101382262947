import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  ACTIVE_GROUP,
  INACTIVE_GROUP,
  licenseFieldsV2,
  REVIEWING_GROUP,
} from "../../../../core/constants/licences";
import DataTable from "../../../components/ui-kit/data-table";
import { FilterContext } from "../../../contexts/FilterContext";
import { TableSettingContext } from "../../../contexts/table-setting-context";
import classes from "./index.module.scss";
import { get_bylaw_list } from "../../../../core/apis/bylaw";
import {
  create_business_license,
  get_licenses_summaries,
  removeLinkedUsers,
} from "../../../../core/apis/licence";

const StyledTable = styled(DataTable)`
  margin: 1rem auto;
  height: 100%;
  width: 1000px;
  display: flex;
`;

const searchFields = ["Business name", "Licence number", "Bylaw"];

const LicenseTable = ({ status, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const { filters, setFilters } = useContext(FilterContext);
  const { tableSettings, setTableSettings } = useContext(TableSettingContext);
  const { handleError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [currentFilters, setCurrentFilters] = useState(
    filters[props.id] || props.initialFilters || {}
  );
  const [currentTableSettings, setCurrentTableSettings] = useState(
    tableSettings[props.id] || props.initialFilters || {}
  );

  async function fetchData(queryOptions) {
    try {
      if (queryOptions.filters.status === undefined) {
        queryOptions.filters.status = status;
      }
      setLoading(true);
      let data = await get_licenses_summaries(queryOptions);
      const bylaws = await get_bylaw_list();
      data.result = data.result.map((summary) => {
        return {
          ...summary,
          applicant: `${summary.name}<br/>${summary.address}`,
        };
      });
      setData(data);
      setSearchOptions(bylaws);
    } catch (err) {
      handleError(err.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleOnCreate = async () => {
    try {
      const licenseId = await create_business_license();
      await removeLinkedUsers(licenseId);
      history.push(`${location.pathname}/${licenseId}`, {
        newLicence: true,
      });
    } catch (e) {
      handleError("Sorry, there was an issue creating your licence.");
    }
  };

  /**
   * This generates text containing all fields currently in use for
   * search functionality. If no fields are available, we return a
   * default string.
   * @type {(function(): (string|string))|*}
   */
  const generateTooltipTitle = useCallback(() => {
    if (!searchFields || searchFields.length <= 0)
      return "Enter search terms here";
    let title = `Searching by:`;
    searchFields.forEach((field, index) => {
      if (index >= searchFields.length - 1) {
        title += ` and ${field}`;
      } else {
        title += ` ${field},`;
      }
    });
    return title;
  }, []);

  useEffect(() => {
    filters[props.id] = currentFilters;
    setFilters(filters);
    tableSettings[props.id] = currentTableSettings;
    setTableSettings(tableSettings);
  }, [
    currentFilters,
    filters,
    currentTableSettings,
    tableSettings,
    props.id,
    setFilters,
    setTableSettings,
  ]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <StyledTable
          onCreateClick={handleOnCreate}
          data={data}
          dataLoading={loading}
          loadData={fetchData}
          createLabel={"New Licence"}
          icon="approval"
          enableFilterV2={true}
          onRowClick={(account, history) => {
            history.push(`${location.pathname}/${account.id}`);
          }}
          filters={currentFilters}
          setFilters={setCurrentFilters}
          tableSettings={currentTableSettings}
          setTableSettings={setCurrentTableSettings}
          searchOptions={searchOptions}
          tooltipTitle={generateTooltipTitle()}
          {...props}
        />
      </div>
      <div className={"secondary-sidebar"} />
    </div>
  );
};

const fieldsInprogress = [
  licenseFieldsV2.ref_number,
  licenseFieldsV2.applicant,
  licenseFieldsV2.underreviewStatuses,
  licenseFieldsV2.last_update,
  licenseFieldsV2.estimate,
  licenseFieldsV2.residency,
  licenseFieldsV2.location,
];

const fieldsActive = [
  licenseFieldsV2.ref_number,
  licenseFieldsV2.applicant,
  licenseFieldsV2.activeStatuses,
  licenseFieldsV2.last_update,
  licenseFieldsV2.balance,
  licenseFieldsV2.residency,
  licenseFieldsV2.location,
];

const fieldsInactive = [
  licenseFieldsV2.ref_number,
  licenseFieldsV2.applicant,
  licenseFieldsV2.inactiveStatuses,
  licenseFieldsV2.last_update,
  licenseFieldsV2.residency,
  licenseFieldsV2.location,
];

const Inprogress = () => (
  <LicenseTable
    id="review"
    title="Licences - Under review"
    defaultOrder="desc"
    defaultSort="lastUpdated"
    enableFilter
    fields={fieldsInprogress}
    initialFilters={{ status: REVIEWING_GROUP }}
    status={REVIEWING_GROUP}
  />
);

const Active = () => (
  <LicenseTable
    id="active"
    title="Licences - Active"
    defaultOrder="desc"
    defaultSort="lastUpdated"
    enableFilter
    fields={fieldsActive}
    initialFilters={{ status: ACTIVE_GROUP }}
    status={ACTIVE_GROUP}
  />
);

const Inactive = () => (
  <LicenseTable
    id="inactive"
    title="Licences - Inactive"
    defaultOrder="desc"
    defaultSort="lastUpdated"
    enableFilter
    fields={fieldsInactive}
    initialFilters={{ status: INACTIVE_GROUP }}
    status={INACTIVE_GROUP}
  />
);

export { Inprogress, Active, Inactive };
