import * as yup from "yup";
import * as v from "../../../../../utilities/yupValidators";
import { validationRegex } from "../../../../../utilities/regexes";
import {AddressCountry} from "../../../../../core/constants";

export const accountValidators = yup.object({
  first_name: yup
    .string()
    .max(35, "First name is too long")
    .required("First name is required"),
  last_name: yup
    .string()
    .max(35, "Last name is too long")
    .required("Last name is required"),
  mailing_address: v.validAddress,
  city: v.validCity,
  province: v.validState,
  phone: v.validPhoneFormattedOptional,
  code: yup
    .string()
    .required("Postal/ZIP code is required.")
    .max(10, "Postal/ZIP code is too long.")
    .test({
      name: "POSTAL_CODE_MATCH_COUNTRY",
      test: function (value: any) {
        const postalCodeInput = value;
        const fieldName = "code";
        const countryValue = this.parent["country"];
        const isCA = countryValue !== AddressCountry.US; // Thus when no value, default to IsCA: true
        const regex = isCA
          ? validationRegex.postalCode
          : validationRegex.zipCode;
        const match = regex.exec(postalCodeInput);
        return match
          ? true
          : this.createError({
              message: isCA ? "Invalid postal code." : "Invalid ZIP code.",
              path: fieldName,
            });
      },
    }),
});

export const contactValidators = yup.object({
  contacts: yup.array().of(yup.object().shape({
    first_name: yup
      .string()
      .max(35, "First name is too long")
      .required("First name is required"),
    last_name: yup
      .string()
      .max(35, "Last name is too long")
      .required("Last name is required"),
    phone: v.validPhoneFormattedOptional,
    email: v.validEmail
  }))
})
