import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import PlaceholderImg from "../../../../assets/images/placeholder.png";
import { PRIMARY_200, textColor, WHITE } from "../../../../mui-theme/theme";
import IconBase from "../IconBase";
import Typography from "../typography";

const StyledCardMedia = styled(CardMedia)`
  && {
    background-image: url(${PlaceholderImg});
    border: 1px solid ${({ theme }) => theme.borderColor};
    border-radius: 5px;
    position: relative;
    height: ${({ height }) => height || "92px"};
    width: ${({ width }) => width || "92px"};
  }

  &&:hover .close-button {
    opacity: 1;
  }
`;

const CancelButton = styled(IconButton)`
  && {
    background-color: ${WHITE};
    border: 1px solid ${textColor};
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.buttonShadow2};
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    width: 24px;
    height: 24px;
    opacity: 0;
  }

  &&:hover {
    background-color: ${WHITE};
  }
`;

export default function PhotoThumbnail({ onClick, ...rest }) {
  return (
    <StyledCardMedia {...rest}>
      {onClick && (
        <CancelButton className="close-button">
          <IconBase
            iconName="close"
            onClick={onClick}
            color={textColor}
            size="17.5px"
          />
        </CancelButton>
      )}
    </StyledCardMedia>
  );
}

const AddPhotoContainer = styled(Button)`
  && {
    background: rgba(var(--${PRIMARY_200}), 0.1);
    border: 1px solid ${PRIMARY_200};
    border-radius: 5px;
    padding: 0.75rem 1rem;
    height: ${({ height }) => height || "92px"};
    width: ${({ width }) => width || "92px"};
    text-transform: unset;
  }

  && * {
    color: ${PRIMARY_200};
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AddPhoto = (props) => {
  return (
    <AddPhotoContainer {...props}>
      <InnerContainer>
        <IconBase size="30px" margin="0 0 5px 0" iconName="add_a_photo" />
        <Typography fontSize="14px" whiteSpace="nowrap">
          Add photo
        </Typography>
      </InnerContainer>
    </AddPhotoContainer>
  );
};
